import { Modal, ModalFooter, Button, ModalBody } from "reactstrap";

const ConfirmCancel = (props) => {

  const handleChange = (e) => {
    props.close();
  };

  return (
    // <div
    //   className="bd-example-modal"
    //   style={{ background: "rgb(0, 0, 0, 0.4)" }}
    // >
    <Modal
      id="cancel"
      size="md"
      // className="modal-dialog-centered"
      centered
      isOpen={props.show}
      fade={false}
      backdrop={true}
      wrapClassName="cancel"
    //container=".bd-example-modal"
    >
      <ModalBody
        className="text-center"
        style={{ borderBottom: "1px solid #68686740" }}
      >
        <div className="popup-text">
          Are you sure you want to cancel?
        </div>
      </ModalBody>
      <ModalFooter style={{ alignSelf: "center", borderTop: "none" }} >
        <Button
          color="standard-secondary"
          className="rounded-pill btn-style"
          size="sm"

          onClick={handleChange}
        >
          No
        </Button>{" "}
        <Button
          className="rounded-pill btn-style"
          color="standard-primary"
          size="sm"
          onClick={props.handleYes}
        >
          Yes
        </Button>{" "}
      </ModalFooter>
    </Modal>
    // </div>
  );
};

export default ConfirmCancel;
