import React, { useState } from "react";
import { Button, Form, Input, FormGroup, Label } from "reactstrap";
import base from "../../Services/BaseService";
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import "../../Content/stylesheet/common.css";
import signature from "../../Content/Images/signature.png";

function PrivacyContent({ close, fetchUser, scrolltotop, mandatory }) {
  const [page2, setpage2] = useState(false);
  const [ischecked, setischecked] = useState("");

  function updateprivacy() {
    base
      .update("privacy/upd/Y", {})
      .then((res) => {
        fetchUser();
        close(false)
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      {!page2 ?
        <>
          <div>
            <p>Dear Colleague</p>

            <p>
              Important: Data privacy relating to Employees’ training data
              administered in CADOPS
            </p>
            <p>
              The delivery of effective training is vital if Aviation is to
              maintain Safe, Reliable and Compliant Operations.
            </p>
            <p>
              Aviation has to make sure that those working for, or on behalf of,
              the organisation have the necessary competencies to do so. This
              means that periodically, those in Safety Critical Roles shall be
              assessed to make sure they have the necessary skills and
              competencies.
            </p>
            <p>
              In 2014, Aviation deployed a computer system called the Competency
              Assessment Database for Operations Staff (CADOPS). The System has
              been implemented to ensure that the training we deliver is
              continuing to build and maintain your competencies.
            </p>
            <p>
              CADOPS provides us with information about how well front-line
              Operations staff understand and can apply the training they complete
              as part of the Competency Assurance System. It does this by
              recording the assessment results from Task Observations, Driving
              Assessments; and classroom tests.
            </p>
            <p>
              CADOPS is provided with certain basic pieces of information about
              each of our Operations staff so that it can function. This
              information is limited to the following:
            </p>
            <ul className="marginleft5">
              <li>Employee’s name</li>
              <li>Site where they are based</li>
              <li>Job role</li>
              <li>The training they are required to complete</li>
              <li>
                The rating they achieve for each assessment completed as part of
                their training; plus an over-all competency rating assigned as a
                reflection of your combined assessment results
              </li>
            </ul>
            <p>
              bp takes data privacy very seriously and all information held within
              and generated by CADOPS is treated in accordance with the Privacy
              Statement attached herein. This information will only be used for
              two specific purposes:
            </p>

            <p className="marginleft5">
              {" "}
              1. To help us understand how effective our overall training is as
              delivered as part of the Competency Assurance System; and to help
              develop Operational staff within their roles
            </p>
            <p className="marginleft5">
              {" "}
              2. To make sure we are compliant in the training that we deliver
            </p>
            <p>
              All information held in CADOPS relating to an individual employee is
              available to that employee at their request.
            </p>
            <p>
              As a CADOPS user, you are required to acknowledge that you have read
              understand and agree to abide by the restrictions that the privacy
              statement places on the use of employees’ personal data held in
              CADOPS – See page 2. Then click the relevant tab in CADOPS to
              confirm your agreement.
            </p>
            <p>
              If you are a site manager, or regional trainer, or otherwise have
              responsibilities for administering employee training data in CADOPS,
              by signing this document you are also confirming that, to the best
              of your knowledge, all employees within your region whose training
              records are held in CADOPS have been provided with a copy of this
              document so that they understand how we manage their training data,
              and so that they have the opportunity to ask any questions relating
              to this communication.
            </p>
            <p>Thanks for your support</p>
            <div>
              Regards,
              <br></br>
              <img src={signature} className="signature" alt="signature"></img>
              <br></br>
              Olivia Stone
              <br></br>
              Vice President
              <br></br>
              Technical Services and HSSE Aviation
            </div>
          </div>


          <Button
            onClick={() => {
              setpage2(true);
              scrolltotop();
            }}
            className="rounded-pill btn-style rightfloat"
            color="standard-primary"
            size="sm"
          >
            Next
          </Button>
        </>
        :
        <div>
          <p>
            <u>Information about the Controller</u>
          </p>
          <p>
            Air bp Limited, (hereby referred to as Aviation) whose registered
            office is at Chertsey Road, Sunbury upon Thames, Middlesex TW16 7LN,
            UK (registered number 1150609), is responsible for this database,
            which is governed in accordance with the laws of England and Wales.
            The courts of England and Wales shall have exclusive jurisdiction
            over any dispute arising in connection thereto.
          </p>
          <p>
            <u>Collection & Use of the Personal Information</u>
          </p>
          <p>
            Collection of personal data via the CADOPS site is based on
            Aviation’s compliance with its legal obligations, inasmuch as the
            monitoring and recording of the operational training provided to
            staff members is necessary for the company to comply with its
            obligations under local Health and Safety at Work laws. Employee’s
            name, job title, training history and scores will be entered
            manually into the database by the trainer/assessor, and employees
            will be able to review them on the Task Assessment Records and/or
            the Training Programme and Certification sheet provided to them from
            time to time. An overall competency rating will be generated in
            relation to employees’ training history.
          </p>
          <p>
            <u>Recipients of the Personal Information</u>
          </p>
          <p>
            bp does not sell or otherwise release employees’ personal
            information to third parties to allow them to market to employees.
            Access to the database is restricted to employees of Aviation who
            have accountabilities and/or responsibilities for training, and
            disclosure to any service provider will be subject to appropriate
            technical and organisational protections of employees’ personal
            information. We also reserve the right to disclose employees’
            personal information as required by law, or when disclosure is
            necessary to protect our rights and/or comply with a judicial
            proceeding, court order, request from a regulator or any other legal
            process served on bp. In the event that bp is subject to a takeover,
            divestment or acquisition we may disclose employees’ personal
            information to the new owner of the business.
          </p>
          <p>
            <u>Transfer of the Personal Information outside of the EEA</u>
          </p>
          <p>
            Aviation may disclose employees’ personal data to other bp entities
            that may be located outside of the EEA. Where this is the case,
            employees’ personal data shall remain at all-time protected by bp’s
            Binding Corporate Rules which guarantee an adequate level of data
            protection throughout the bp group.
          </p>
          <p>
            <u>Retention Period</u>
          </p>
          <p>
            Employees’ data shall be kept for as long as necessary for Aviation
            to comply with its Health and Safety obligations and in line with
            our internal retention policies, after which it may be deleted or
            aggregated so that it no longer directly identifies you.
          </p>

          <p>
            <u>Rights of Access, Rectification and Erasure</u>
          </p>
          <p>
            In accordance with our internal policies employees may ask us to
            provide them with access to any personal data we process about them.
            If employees want access to their personal information, please
            contact{" "}
            <span
              className="anchortag"
              onClick={() => {
                window.open("mailto:AirCadops@bp.com");
              }}
            >
              AirCadops@bp.com
            </span>{" "}
            in the first instance.
          </p>
          <p>
            Employees are also entitled to request that any incomplete or
            inaccurate personal data which relates to them is corrected.
          </p>
          <p>
            Depending on employees’ country of residence, they may have the
            right to lodge a complaint with their national data protection
            supervisory authority. However, they should first think about using
            bp’s EU-approved complaint resolution mechanism under its Binding
            Corporate Rules.
          </p>
          <p>
            <u>Changes to This Privacy Statement</u>
          </p>
          <p>
            We review this privacy statement regularly and may modify it from
            time to time. This privacy statement was last updated on 1st
            December 2021.
          </p>
          <br></br>

          {mandatory && (
            <>
              <Form>
                <FormGroup check inline>
                  <Input
                    type="checkbox"
                    checked={ischecked}
                    onChange={() => {
                      setischecked(!ischecked);
                    }}
                    className=""
                  />
                  <Label check className="mt-3">
                    I have read and agree to the privacy statement
                  </Label>
                </FormGroup>
                {ischecked === false ? (
                  <span className="privacyerrmsg">
                    Please indicate that you have read and agreed to the privacy
                    statement
                  </span>
                ) : (
                  ""
                )}
              </Form>
              <Button
                disabled={!ischecked}
                className="rounded-pill btn-style rightfloat"
                color="standard-primary"
                size="sm"
                onClick={() => {
                  updateprivacy();
                }}
              >
                Submit
              </Button>
            </>
          )}
        </div>
      }
    </>
  );
}

export default PrivacyContent;
