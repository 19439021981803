import React, { useState, createContext } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Spinner } from "reactstrap";
const LoadingOverlayWrapperContext = createContext(null);
LoadingOverlay.propTypes = undefined

export const LoadingOverlayWrapperProvider = ({ children }) => {

  const [toggleLoadingOverlayWrapper, setToggleLoadingOverlayWrapper] =
    useState(false);

  return (
    <LoadingOverlayWrapperContext.Provider
      value={{ setToggleLoadingOverlayWrapper }}
    >
      <LoadingOverlay
        active={toggleLoadingOverlayWrapper}
        spinner={
          <Spinner
            style={{ color: "#111", top: "45%", position: "absolute" }}
          />
        }
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(0, 0, 0, 0.5)",
          }),
        }}
        fadeSpeed={100}
      >
        {children}
      </LoadingOverlay>
    </LoadingOverlayWrapperContext.Provider >
  );
};
