import { navLinkItemsIDs } from "../Constants";

const useActiveNavLink = ({ currentRoute }) => {
  // logic dependent on route paths defined in AppRoutes.js and navLinkItemsIDs

  const currentRouteLowerCase = currentRoute.toLowerCase();

  if (
    currentRouteLowerCase === "/" ||
    currentRouteLowerCase === "/home" ||
    currentRouteLowerCase === "/home1"
  ) {
    return {
      activeNavLinkID: navLinkItemsIDs.home,
      activeNavLinkRouteLowerCase: currentRouteLowerCase,
    };
  } else if (currentRouteLowerCase.includes("/mytraining")) {
    return {
      activeNavLinkID: navLinkItemsIDs.myTraining,
      activeNavLinkRouteLowerCase: currentRouteLowerCase,
    };
  } else if (currentRouteLowerCase === "/manageassessment") {
    return {
      activeNavLinkID: navLinkItemsIDs.assessment,
      activeNavLinkRouteLowerCase: currentRouteLowerCase,
    };
  } else if (
    currentRouteLowerCase === "/evaluationreport" ||
    currentRouteLowerCase === "/compliancereport" ||
    currentRouteLowerCase === "/preformatteddocument" ||
    currentRouteLowerCase === "/myfavouritereports"
  ) {
    return {
      activeNavLinkID: navLinkItemsIDs.reports,
      activeNavLinkRouteLowerCase: currentRouteLowerCase,
    };
  } else if (
    currentRouteLowerCase === "/assignemployeestomodule" ||
    currentRouteLowerCase === "/assignmoduletoemployee" ||
    currentRouteLowerCase === "/managecurriculum" ||
    currentRouteLowerCase === "/manageemployees" ||
    currentRouteLowerCase === "/managenewsfeed" ||
    currentRouteLowerCase === "/managequestions" ||
    currentRouteLowerCase === "/manageregion" ||
    currentRouteLowerCase === "/managesystemusers" ||
    currentRouteLowerCase === "/managethreats"
  ) {
    return {
      activeNavLinkID: navLinkItemsIDs.manageData,
      activeNavLinkRouteLowerCase: currentRouteLowerCase,
    };
  } else {
    return null;
  }
};

export default useActiveNavLink;
