import { useEffect, useState, useRef, useCallback } from "react";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../Content/stylesheet/ag-grid-style.css";
import base from "../../../Services/BaseService";
import CreateNewsFeed from "./CreateNewsFeed";
import Viewnewsfeeddata from "../../Homescreen/viewnewsfeed.js";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew from "../../Toast/ErrorToastNew";
import { Messages } from "../../../Constants";
import { Input } from "reactstrap";
import "../../../Content/stylesheet/ManageData.css";
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { Trash32, Edit32, Alert32 } from "@bphxd/ds-core-react/lib/icons";
import { createRoot } from "react-dom/client";

const ManageNewsFeed = (props) => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [gridData, setGridData] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setviewModal] = useState(false);
  const [editNewsFeed, setEditNewsFeed] = useState({});
  const [deleteNewsFeed, setDeleteNewsFeed] = useState({});
  const [viewNewsFeed, setviewNewsFeed] = useState({});
  const [deleteNewsFeedConfirmToast, setDeleteNewsFeedConfirmToast] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initGridVar();
    getAllNewsFeed();
    // eslint-disable-next-line
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  const postProcessPopup = useCallback((params) => {
    if (params.type !== "columnMenu") {
      return;
    }
    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      minWidth: 75,
      sortable: true,
      filter: true,
      resizable: true,
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("Newsfeedpage.LandingGridHeader.HEADLINES"),
        headerTooltip: t("Newsfeedpage.LandingGridHeader.HEADLINES"),
        minWidth: 200,
        maxWidth: 400,
        resizable: false,
        comparator: customComparator,
        sort: "asc",
        suppressMovable: true,
        field: "news_Headline",
        cellRenderer: NewsHeadlineCellRenderer,
      },
      {
        headerName: t("Newsfeedpage.LandingGridHeader.MESSAGE"),
        headerTooltip: t("Newsfeedpage.LandingGridHeader.MESSAGE"),
        minWidth: 0,
        maxWidth: 1200,
        resizable: false,
        comparator: customComparator,
        headerClass: 'sm-hide-header', cellClass: 'sm-hide-cell',
        sort: "asc",
        suppressMovable: true,
        field: "news_Desc",
      },
      {
        headerName: t("Newsfeedpage.LandingGridHeader.Display"),
        headerTooltip: t("Newsfeedpage.LandingGridHeader.Display"),
        field: "text01",
        headerClass: 'sm-hide-header', cellClass: 'sm-hide-cell',
        minWidth: 0,
        maxWidth: 200,
        comparator: customComparator,
        resizable: false,
      },
      {
        headerName: t("Newsfeedpage.LandingGridHeader.PRIORITY"),
        headerTooltip: t("Newsfeedpage.LandingGridHeader.PRIORITY"),
        field: "news_Priority",
        minWidth: 0,
        maxWidth: 200,
        headerClass: 'sm-hide-header', cellClass: 'sm-hide-cell',
        resizable: false,
        filter: false,
        sortable: false,
        suppressMovable: true,
        suppressMenu: true,
        cellRenderer: newsPriorityRenderer,
      },
      {
        headerName: t("Newsfeedpage.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("Newsfeedpage.LandingGridHeader.EDIT_DELETE"),
        resizable: true,
        sortable: false,
        suppressMovable: true,
        minWidth: 200,
        maxWidth: 200,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressMenu: true,
        cellRenderer: NewsEditCellRenderer,
      },
    ]);
  };
  const customComparator = (valueA, valueB) => {
    if (valueA !== undefined && valueB !== undefined) {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    }
  };

  const getAllNewsFeed = () => {
    base.getAll("newsfeed/all").then((res) => {
      var data = res.filter((x) => x.rel_rec !== 0);
      setGridData(data);
    });
  };
  class newsPriorityRenderer {
    init(params) {
      var divElement = document.createElement("div");
      this.eGui = divElement;
      const root = createRoot(divElement);
      if (params.value) {
        root.render(
          <>
            <Alert32 />
          </>
        );
      }
    }
    getGui() {
      return this.eGui;
    }
  }

  const editNewsFeedClick = (newsFeed) => {
    setEditNewsFeed(newsFeed);
    setEditModal(true);
  };

  const deleteNewsFeedClick = (params) => {
    setDeleteNewsFeedConfirmToast(true);
    setDeleteNewsFeed(params);
  };

  const cancelDelete = () => {
    setDeleteNewsFeedConfirmToast(false);
    setDeleteNewsFeed({});
  };

  const handleDeleteNewFeed = () => {
    if (!!deleteNewsFeed) {
      try {
        base.delete(process.env.REACT_APP_NEWSFEED_DELETE, deleteNewsFeed.id).then((response) => {
          if (response === 200) {
            setDeleteSuccess(true);
            getAllNewsFeed();
            document.addEventListener(
              "mousedown",
              handleSuccessClickOutside
            );
          }
        });
      } catch (err) {
        setShowError(true);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeleteNewsFeedConfirmToast(false);
      }
    }
  };
  class NewsEditCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <Edit32
            onClick={() => editNewsFeedClick(params.node.data)}
            className="pointer"
          />
          <Trash32

            onClick={() => deleteNewsFeedClick(params.node.data)}
            className="pointer trashOnSmallerScreen ms-2"
          />

        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  function handleSuccessClickOutside() {
    setDeleteSuccess(false);
  }

  function handleErrorClickOutside() {
    setShowError(false);
  }
  class NewsHeadlineCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridCrudIcons");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <label
            style={{ cursor: "pointer" }}
            className=""
            onClick={() => callViewNews(params.node.data)}
          >
            {params.node.data.news_Headline}
          </label>
        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  const callViewNews = (data) => {
    setviewNewsFeed(data);
    setviewModal(true);
  };

  const closeCreateNewsFeed = (val) => {
    setCreateModal(val);
    setEditModal(val);
  };

  const dataSaved = () => {
    setShowToast({ Name: "successSave", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const dataNotSaved = () => {
    setShowToast({ Name: "errorSave", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const dataCreated = () => {
    setShowToast({ Name: "successCreate", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const dataNotCreated = () => {
    setShowToast({ Name: "errorCreate", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  function DataSavingToastonClick() {
    setShowToast(false);
  }

  const closeViewFeed = (val) => {
    setviewModal(val);
  };

  return (
    <div>

      <div>
        <TitleBanner
          heading={t("Newsfeedpage.News Feed")}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("Common.Manage Data"), t("Newsfeedpage.News Feed")]}
          showButton1={false}
          functionButton2={() => setCreateModal(true)}
          showButton2={true}
          textButton2={t("Newsfeedpage.Create News Feed")}
          buttonHeading={""}
          showButton3={false}
        />
        <ConfirmDeleteToast
          show={deleteNewsFeedConfirmToast}
          handleCancel={cancelDelete}
          Message={Messages.NewsFeed.Delete}
          handleDelete={handleDeleteNewFeed}
        />
        <SuccessToastNew
          show={deleteSuccess}
          Message={t("Newsfeedpage.OnDeleteSuccessMsg")}
        />
        <ErrorToastNew
          show={showError}
          Message={t("Newsfeedpage.OnErrorMsg")}
        />
        {showToast.Name === "successSave" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Newsfeedpage.SaveSuccess")}
          />
        )}
        {showToast.Name === "errorSave" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Newsfeedpage.SaveFailure")}
          />
        )}
        {showToast.Name === "successCreate" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Newsfeedpage.CreateSuccess")}
          />
        )}
        {showToast.Name === "errorCreate" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Newsfeedpage.CreateFailure")}
          />
        )}
      </div>
      <div className="ps-7 pe-7 pb-5 pt-4">
        <div className="gridDiv">
          <div className="ag-theme-material gridHtWd " style={{ width: "100%", position: 'relative' }} >
            <div className="rowDataInput" >
              {gridData ? (
                <Input type="select"
                  onChange={onPageSizeChanged}
                  id="page-size"
                  defaultValue={10}
                >
                  <option value="10" >
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </Input>
              ) : (
                ""
              )}
            </div>
            <AgGridReact
              ref={gridRef}
              columnDefs={gridColDefs}
              rowData={gridData}
              suppressMenuHide={true}
              overlayNoRowsTemplate="No records found"
              defaultColDef={gridDefaultColDef}
              pagination={true}
              paginationPageSize={10}
              suppressScrollOnNewData={true}
              enableRangeSelection={true}
              onFilterChanged={onFilterChanged}
              postProcessPopup={postProcessPopup}
              suppressHorizontalScroll={false}
            ></AgGridReact>

          </div>
        </div>
      </div>
      <div className="col-lg-12 mb-3 p1-0 pr-1 " style={{ width: "98.9%" }}>
        {createModal ? (
          <CreateNewsFeed
            create={true}
            close={closeCreateNewsFeed}
            createSuccess={dataCreated}
            createerror={dataNotCreated}
            getAll={getAllNewsFeed}
            isopen={createModal}
          ></CreateNewsFeed>
        ) : (
          ""
        )}
        {editModal ? (
          <CreateNewsFeed
            create={false}
            isopen={editModal}
            newsFeed={editNewsFeed}
            close={closeCreateNewsFeed}
            saveSuccess={dataSaved}
            saveerror={dataNotSaved}
            getAll={getAllNewsFeed}
          ></CreateNewsFeed>
        ) : (
          ""
        )}
        {viewModal ? (
          <Viewnewsfeeddata
            isopen={viewModal}
            data={viewNewsFeed}
            closed={closeViewFeed}
          ></Viewnewsfeeddata>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ManageNewsFeed;
