// TrainerActionContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from "./userContext";

const ModuleContext = createContext();

export const useModuleContext = () => useContext(ModuleContext);

export const ModuleProvider = ({ children, module }) => {
  const { userData, languagePreference, globalUser } = useUser();
  const englishID = "en-GB"
  const [moduleData, setModuleData] = useState(module);
  const [moduleRefetch, setModuleRefetch] = useState("");
  const [moduleLanguagePreference, setModuleLanguagePreference] = useState(userData.fk_languageID)
  const [showToast, setShowToast] = useState({
    Name: "",
    show: false,
    Message: "",
  });


  const updateModuleData = (res) => {
    setModuleData(res);
  };

  const refetchDataPostAdd = (value) => {
    setModuleRefetch(value);
  }

  const toastOpenClick = () => {
    setShowToast((prevToast) => {
      // Ensure state update only occurs if necessary
      if (prevToast.show) {
        return { Name: "", Message: "", show: false };
      }
      return prevToast;
    });
  };

  const moduleDataSaved = (tostName, message) => {
    setShowToast({ Name: tostName, Message: message, show: true });
    document.addEventListener("mousedown", toastOpenClick);
  }

  useEffect(() => {
    setModuleLanguagePreference(languagePreference);
  }, [languagePreference]);

  const updateLanguagePreference = (res) => {
    setModuleLanguagePreference(res);
  }


  return (
    <ModuleContext.Provider value={{ refetchDataPostAdd, moduleRefetch, updateModuleData, moduleData, setModuleData, showToast, moduleDataSaved, moduleLanguagePreference, updateLanguagePreference, englishID, globalUser }}>
      {children}
    </ModuleContext.Provider>
  );
};
