import { Row, Col } from "reactstrap";
import { Accordion } from '@bphxd/ds-core-react';
import base from "../../Services/BaseService";
import { Checklist32, SendBasic32 } from "@bphxd/ds-core-react/lib/icons";
import SuccessToastNew from "../Toast/SuccessToastNew";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Label, Modal } from '@bphxd/ds-core-react';

const InfoForCompletedEmployees = (props) => {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [lastEmailDate, setlastEmailDate] = useState(null);
  const [isAccordionOpen, setAccordionOpen] = useState("0");

  const cancelHandler = () => {
    props.close(false);
  };

  function handleEmailClick(e) {
    var empIDs = props.pendingAssessment.map((value, index) => {
      return value.fk_EmployeeID;
    });
    var tskNum = encodeURIComponent(props.selectedAssessment.task_Number);

    for (let i = 0; i < empIDs.length; i++) {
      base.get("Email/SendEmailPart1", `${empIDs[i]}/${props.selectedAssessment.assessment_Type}/${tskNum}/${false}/${props.selectedAssessment.taskID}/${props.selectedAssessment.assessmentID}`).then(() => {
        if (i === empIDs.length - 1) {
          setEmailSentSuccess(true);
          setEmailSent(true);
          var date = new Date();
          var formattedDate = date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
          });
          setlastEmailDate(formattedDate)
          document.addEventListener("mousedown", DataSavingToastonClick);
        }
      });
    }
    setEmailSent(true);
    e.stopPropagation();
  }

  function DataSavingToastonClick() {
    setEmailSentSuccess(false);
  }

  // function getLastEmailSendDate() {
  //   var assessmentID = props.data.assessmentID;
  // }

  const handleRecordTraningResultClick = (e) => {
    props.isyearvalid();
    e.stopPropagation();
    props.close();
  };

  const handleAccordionToggle = (isOpen, event, id) => {
    if (id === "1" && event.target.classList.contains("sendBasic32Icon")) {
      event.preventDefault();
    } else {
      setAccordionOpen(isOpen ? id : null); // Update the state to reflect the open/closed status
    }
  };

  return (
    <Modal isOpen className="modal-dialog-centered modal-70 " >
      <Modal.Header className="comm-header-bgPosition"
        onToggleOpen={cancelHandler} >
        <SuccessToastNew show={emailSentSuccess} Message={t("assessment.sentEmailSuccessMassage")} form={true} />
        <div>
          <Label className="modalHeaderGlobal m-4">
            {t("assessment.Assessment Summary")}
          </Label>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        <Row>
          <Col xs="12" lg="6" className="pb-3">
            <Label>
              {t("Common.Module")}
            </Label>
            <Input disabled={true} value={props.selectedAssessment.task_Number} />
          </Col>
          <Col xs="12" lg="6" className="pb-3">
            <Label>
              {t("assessment.Total Employees assigned to the assessment")}
            </Label>
            <Input disabled={true} value={props.totalEmployeesAssigned} />
          </Col>
        </Row>
        <Accordion
          size="md"
          className="mt-4"
          defaultOpen={isAccordionOpen}
          onToggle={(isOpen, event, id) => handleAccordionToggle(isOpen, event, id)}
        >
          {props.selectedAssessment.isMCQ === 1 && (
            <>
              <Accordion.Item targetId="1" disabled={props.pendingAssessment.length === 0 ? true : false} >
                <Accordion.Header targetId="1">
                  <span style={{ width: "55%" }} className={`${props.pendingAssessment.length === 0 && "text-muted"} mt-2`}>{t("assessment.Pending Assessments")}</span>
                  <span style={{ width: "15%" }} className={`${props.pendingAssessment.length === 0 && "text-muted"} mt-2`}> {props.pendingAssessment.length} </span>

                  <SendBasic32
                    className={`sendBasic32Icon ${emailSent || props.pendingAssessment.length === 0 ? "disableEmail" : ""}`}
                    onClick={handleEmailClick}
                  />
                  {((emailSent) || (props.pendingAssessment.length === 0)) && (
                    ""
                  )}
                  {props.pendingAssessment.length !== 0 ? (
                    <div className="customtooltip1 ms-4">
                      <span
                        style={{
                          fontSize: "10px",
                          opacity: "0.6",
                        }}
                      >
                        {lastEmailDate}
                      </span>
                      <span className="tooltiptext1" style={{ fontSize: "10px" }} >
                        {t("assessment.Last email sent on")}{" "}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </Accordion.Header>
                <Accordion.Body accordionId="1">
                  {/* <div style={{ display: "table" }}> */}
                  <div>
                    {props.pendingAssessment.map((value, index) => {
                      return (
                        <div key={`part1-${index}`}

                          style={{
                            display: "grid",
                            gridTemplateColumns: "40% 25% 35%",
                          }}
                        >
                          <span style={{ textAlign: "left" }}>
                            {value.emp_DisplayName}
                          </span>

                          <span style={{ textAlign: "left" }}>
                            {value.fk_LocationID}
                          </span>
                          <span style={{ textAlign: "left" }}>
                            {value.jobTitle}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )
          }
          <Accordion.Item targetId="2" disabled={props.trainerActions.length === 0 ? true : false}>
            <Accordion.Header targetId="2" style={{ fontFamily: "Roboto !important" }} >
              <span style={{ width: "55%" }} className={`${props.trainerActions.length === 0 && "text-muted"} mt-2`}>
                {t("assessment.Trainer Action Required")}

              </span>
              <span style={{ width: "15%" }} className={`${props.trainerActions.length === 0 && "text-muted"} mt-2`}>
                {props.trainerActions.length}{" "}
              </span>
              <Checklist32 onClick={handleRecordTraningResultClick} />
            </Accordion.Header>
            <Accordion.Body accordionId="2">
              <span>
                {/* <ul> */}
                {props.trainerActions.map((value, index) => {
                  return (
                    <div key={`notcompleted-${index}`}

                      style={{
                        display: "grid",
                        gridTemplateColumns: "40% 25% 35%",
                      }}
                    >
                      <span style={{ textAlign: "left" }}>
                        {value.emp_DisplayName}
                      </span>

                      <span style={{ textAlign: "left" }}>
                        {value.fk_LocationID}
                      </span>
                      <span style={{ textAlign: "left" }}>
                        {" "}
                        {value.jobTitle}
                      </span>
                    </div>
                  );
                })}
              </span>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item targetId="3" disabled={props.completedAssessment.length === 0 ? true : false}>
            <Accordion.Header targetId="3">
              <span style={{ width: "55%" }} className={`${props.completedAssessment.length === 0 && "text-muted"} mt-2`}>{t("assessment.Completed Assessments")}</span>
              <span style={{ width: "20%" }} className={`${props.completedAssessment.length === 0 && "text-muted"} mt-2`}>
                {props.completedAssessment !== undefined
                  ? props.completedAssessment.length
                  : 0}
              </span>
            </Accordion.Header>
            <Accordion.Body accordionId="3">
              <div>
                {props.completedAssessment !== undefined
                  ? props.completedAssessment.map((value, index) => {
                    return (
                      <div key={`comp-${index}`}

                        style={{
                          display: "grid",
                          gridTemplateColumns: "40% 25% 35%",
                        }}
                      >
                        <span style={{ textAlign: "left" }}>
                          {value.emp_DisplayName}
                        </span>
                        <span style={{ textAlign: "left" }}>
                          {value.fk_LocationID}
                        </span>
                        <span style={{ textAlign: "left" }}>
                          {value.jobTitle}
                        </span>
                      </div>
                    );
                  })
                  : ""}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
    </Modal >
  );
};

export default InfoForCompletedEmployees;
