import React, { useState, useEffect } from "react";
import "../../../Content/stylesheet/systemuser.css"
import base from "../../../Services/BaseService";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import Infobox from "../../Toast/Infobox";
import Select from "react-select";
import { components } from "react-select";
import { useTranslation } from "react-i18next";
import LoadingView from "../../Shared/Layout/LoadingView";
import { useAssessmentContext } from '../../Shared/assessmentContext'
import { Button, Label, Modal } from '@bphxd/ds-core-react';

function UserdataMigration(props) {
  const { t } = useTranslation();
  const { updateAssessmentCount } = useAssessmentContext()

  const Option = (props) => {
    return (
      <div id="empMulti ">
        <components.Option {...props}>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
    }),
  };

  function touserselect(params) {
    setTouser(params.value);
    setTousererr(false);
    setIschanged(true);
    setTouserselected(params);
  }

  function fromuserselect(params) {
    setFromuser(params.value);
    setFromusername(params.label);
    setFromusererr(false);
    setIschanged(true);
    setFromuserselected(params);
  }
  const [isloading, setIsloading] = useState(false);
  const [fromuser, setFromuser] = useState("");
  const [fromusername, setFromusername] = useState("");
  const [fromuserselected, setFromuserselected] = useState({});
  const [touserselected, setTouserselected] = useState({});
  const [touser, setTouser] = useState("");
  const [fromuserlist, setFromuserlist] = useState([]);
  const [touserlist, setTouserlist] = useState([]);
  const [fromusererr, setFromusererr] = useState(false);
  const [tousererr, setTousererr] = useState(false);
  const [iscancel, setIscancel] = useState(false);
  const [showinfo, setShowinfo] = useState(false);
  const [infomsg, setInfomsg] = useState("");
  const [ischanged, setIschanged] = useState(false);

  useEffect(() => {
    getuserlist();
  }, []);

  function closeModal() {
    props.close();
  }

  function checkusers() {
    if (fromuser.length < 1) {
      setFromusererr(true);
    }
    if (touser.length < 1) {
      setTousererr(true);
    }
    if (touser.length < 1 || fromuser.length < 1) {
    } else if (fromuser === touser) {
      setShowinfo(true);
      setInfomsg(t("SystemUserpage.SelectedSame"));
    } else {
      checkassessments();
      setIsloading(true);
    }
  }

  function checkassessments() {
    base.get("assessment/Qry/ALL", fromuser).then((res) => {
      if (res === 0) {
        setShowinfo(true);
        setInfomsg(
          `${fromusername} ${t("SystemUserpage.NoData")}`
        );
      } else {
        migrateUser();
      }
    });
  }

  function getuserlist() {
    var fromusers = [];
    base.getAll("user/Qry").then((res) => {
      for (let i = 0; i < res.length; i++) {
        if (!res[i].email) continue; // exclude users with email = null or empty string ("")
        var item = {};
        if (res[i].email !== "") {
          if (res[i].email !== null) {
            item.value = res[i].userID;
            item.label = res[i].userDisplayName;

            fromusers.push(item);
          }
        }
      }
      setTouserlist(fromusers);
      setFromuserlist(fromusers);
    });
  }

  function migrateUser() {
    base.update(`assessment/Upd/${fromuser}/${touser}`, "").then((res) => {
      setIsloading(false);
      props.success();
      closeModal();
      updateAssessmentCount();
    })
      .catch((err) => {
        setIsloading(false);
        props.error("User data not migrated. Try again");
        closeModal();
      });
  }

  return (
    <>
      <Modal
        isOpen={true}
        className="modal-dialog-centered modal-50"
      >
        {isloading && <LoadingView />}
        {iscancel ? (
          <ConfirmDeleteToast
            show={true}
            Message={t("ErrorMessages.CancelSure")}
            handleCancel={() => {
              setIscancel(false);
            }}
            handleDelete={() => {
              closeModal();
            }}
          ></ConfirmDeleteToast>
        ) : (
          ""
        )}
        <Modal.Header
          className="comm-header-bgPosition"

          onToggleOpen={() => {
            ischanged ? setIscancel(true) : closeModal()
          }}
        >
          <Label className="modalHeaderGlobal m-4">
            {t("SystemUserpage.User Data Migration")}
          </Label>
          <Infobox
            message={infomsg}
            show={showinfo}
            close={() => {
              setShowinfo(false);
              infomsg.includes(t("SystemUserpage.NoData"))
                ? closeModal()
                : setShowinfo(false);
            }}
          ></Infobox>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <Label className="">
                {t("SystemUserpage.From User")} <span className="redstar">*</span>
              </Label>
              <Select
                classNamePrefix={fromusererr ? "react-select" : "react-select"}
                placeholder={""}
                value={fromuserselected}
                options={fromuserlist}
                onChange={fromuserselect}
                components={{
                  Option,
                  IndicatorSeparator: () => null,
                }}
                invalid={fromusererr}
                styles={customStyles}
              ></Select>
            </div>
            <div>
              <Label className="mt-5 textcolor">
                {t("SystemUserpage.To User")} <span className="redstar">*</span>
              </Label>
              <Select
                classNamePrefix={tousererr ? "react-select" : "react-select"}
                placeholder={""}
                value={touserselected}
                options={touserlist}
                onChange={touserselect}
                components={{
                  Option,
                  IndicatorSeparator: () => null,
                }}
                invalid={tousererr}
                styles={customStyles}
                menuPosition="fixed"
              ></Select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-pill btn-style"
            color="standard-secondary"
            size="sm"
            onClick={() => {
              ischanged ? setIscancel(true) : closeModal();
            }}
          >
            {t("Common.Cancel")}
          </Button>
          <Button
            className="rounded-pill btn-style"
            color="standard-primary"
            size="sm"
            onClick={() => {
              checkusers();
            }}
          >
            {t("Common.Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserdataMigration;
