import moment from "moment";

// default format for API -> YYYY-MM-DD
export function convertToYMD(date) {
  // Tue Jan 26 2022 00:00:00 GMT+0530 (India Standard Time)

  if (!date) return "";
  let mm = "";
  let date1 = date.toString().split(" ");
  if (date1[1] === "Jan") {
    mm = "01";
  } else if (date1[1] === "Feb") {
    mm = "02";
  } else if (date1[1] === "Mar") {
    mm = "03";
  } else if (date1[1] === "Apr") {
    mm = "04";
  } else if (date1[1] === "May") {
    mm = "05";
  } else if (date1[1] === "Jun") {
    mm = "06";
  } else if (date1[1] === "Jul") {
    mm = "07";
  } else if (date1[1] === "Aug") {
    mm = "08";
  } else if (date1[1] === "Sep") {
    mm = "09";
  } else if (date1[1] === "Oct") {
    mm = "10";
  } else if (date1[1] === "Nov") {
    mm = "11";
  } else if (date1[1] === "Dec") {
    mm = "12";
  }
  let dateRes = `${date1[3]}-${mm}-${date1[2]}`;
  return dateRes;
}
// format on UI -> 21 Jan 2022
export function convertToDMY(date) {
  // YYYY-MM-DD
  if (!date) return "";
  let dateArr = date.toString().split("-");
  let mm = "";
  if (dateArr[1] === "01") {
    mm = "Jan";
  } else if (dateArr[1] === "02") {
    mm = "Feb";
  } else if (dateArr[1] === "03") {
    mm = "Mar";
  } else if (dateArr[1] === "04") {
    mm = "Apr";
  } else if (dateArr[1] === "05") {
    mm = "May";
  } else if (dateArr[1] === "06") {
    mm = "Jun";
  } else if (dateArr[1] === "07") {
    mm = "Jul";
  } else if (dateArr[1] === "08") {
    mm = "Aug";
  } else if (dateArr[1] === "09") {
    mm = "Sep";
  } else if (dateArr[1] === "10") {
    mm = "Oct";
  } else if (dateArr[1] === "11") {
    mm = "Nov";
  } else if (dateArr[1] === "12") {
    mm = "Dec";
  }
  let dateRes = `${dateArr[2]} ${mm} ${dateArr[0]}`;
  return dateRes;
}

export function changeDueDateFormat(dueDate, dateFormat) {
  // dueDate: 44286 , dateFormat : "YYYY-MM-DD", "DD MMM YYYY" ...

  let someDate = new Date("1900-01-01");
  let result = someDate.setDate(someDate.getDate() + parseInt(dueDate));

  return moment(result).format(dateFormat);
}

export function calculateYearDiff(moduleCriticality) {
  let yearDiff;

  if (moduleCriticality === "Y") yearDiff = 1;
  else if (moduleCriticality === "P") yearDiff = 2;
  else if (moduleCriticality === "N") yearDiff = 3;

  return yearDiff;
}

export function getMinTrainedDate(yearDiff, dateFormat = "DD MMM YYYY") {
  const today = new Date();
  today.setFullYear(today.getFullYear() - yearDiff);

  return moment(today).format(dateFormat);
}
