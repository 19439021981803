import React, { useEffect, useRef, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Spinner, Row, Col } from "reactstrap";
import { Kebab24, Remove16, Remove24, Right24 } from "@bphxd/ds-core-react/lib/icons";
import base from "../../Services/BaseService";
import "./home.css";
import Star from "../../Content/Icons/star.svg";
import LoadingOverlay from "react-loading-overlay";
import { withRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";
import Cookies from "universal-cookie";
import { Trans, useTranslation } from "react-i18next";
import { EmptyChartTextComponent } from "./homeScreen";
import fileDownload from "js-file-download";

function Favouritechart(props) {
  var cookies = new Cookies();
  const { t } = useTranslation();
  const [chart1, setchart1] = useState("line");
  const [task, settask] = useState("");
  const [chartname, setchartname] = useState("");
  const [comptask, setcomptask] = useState([]);
  const [isopen, setisopen] = useState(false);
  const [showtask, setshowtask] = useState(true);
  const [taskNumber, settaskNumber] = useState("");
  const [taskid, setTaskid] = useState("");
  const navigate = useNavigate();
  const [toreports, setToreports] = useState(false);
  const [isloadingchartfav, setisloadingchartfav] = useState(true);
  const [isFavChartDataEmpty, setIsFavChartDataEmpty] = useState(false);
  const [assessmentExpLabelName, setAssessmentExpLabelName] = useState("");

  useEffect(() => {
    setisloadingchartfav(true);
    if (assessmentExpLabelName === "Overdue") {
      if (taskid === "") {
        let endPorint = `report_ASSEXP/Qry/ALL/OVERDUE/NULL/NULL/NULL/NULL/NULL/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      } else {
        let endPorint = `report_ASSEXP/Qry/TASK/OVERDUE/NULL/NULL/NULL/NULL/${taskid}/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      }
      setAssessmentExpLabelName("");
    }
    if (assessmentExpLabelName === "Expired") {
      if (taskid === "") {
        let endPorint = `report_ASSEXP/Qry/ALL/EXPIRED/NULL/NULL/NULL/NULL/NULL/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      } else {
        let endPorint = `report_ASSEXP/Qry/TASK/EXPIRED/NULL/NULL/NULL/NULL/${taskid}/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      }
      setAssessmentExpLabelName("");
    }
    if (assessmentExpLabelName === "0-30 days") {
      if (taskid === "") {
        let endPorint = `report_ASSEXP/Qry/ALL/EXPIRED30/NULL/NULL/NULL/NULL/NULL/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      } else {
        let endPorint = `report_ASSEXP/Qry/TASK/EXPIRED30/NULL/NULL/NULL/NULL/${taskid}/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      }
      setAssessmentExpLabelName("");
    }
    if (assessmentExpLabelName === "31-60 days") {
      if (taskid === "") {
        let endPorint = `report_ASSEXP/Qry/ALL/EXPIRED60/NULL/NULL/NULL/NULL/NULL/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      } else {
        let endPorint = `report_ASSEXP/Qry/TASK/EXPIRED60/NULL/NULL/NULL/NULL/${taskid}/NULL/${startdate}/${enddate}/Excel`;
        getReport(endPorint);
      }
      setAssessmentExpLabelName("");
    }
    // eslint-disable-next-line
  }, [assessmentExpLabelName]);

  const Toggle = () => {
    setisopen(!isopen);
  };

  var date = new Date("01-01-1900");
  var diff = new Date() - date;
  var dateindays = Math.ceil(diff / (1000 * 60 * 60 * 24));
  var chartNameGlobal = "";

  function refreshagain() {

    setIsFavChartDataEmpty(false);
    settaskNumber("");
    setTaskid("");
    setchartdata1({ columndata: [], linedata: [], piedata: [] });

    personaldashboard();
  }

  useEffect(() => {
    if (props.favouriteChart === 0) {
      personaldashboard();
    }
    // eslint-disable-next-line
  }, [props.res, props.favouriteChart]);


  const positionRef = useRef(props.position)
  const favouriteChartRef = useRef(parseInt(props.favouriteChart))

  useEffect(() => {
    favouriteChartRef.current = parseInt(props.favouriteChart);
  }, [props.favouriteChart]);

  useEffect(() => {
    if (positionRef.current === favouriteChartRef.current) {
      refreshagain();
    }
    // eslint-disable-next-line
  }, [props.refreshCharts]);

  const getchartdata = () => {
    chartNameGlobal = chartname;
    let chrtnm = chartname;
    if (chartname === "DAT PART 2 (TRN007)")
      chrtnm = "driver assessment part 2";
    if (chartname === "DAT PART 1 (TRN006)")
      chrtnm = "driver assessment part 1";

    if (chartname === "ASSESSMENT EXPIRY") {
    } else {
      if (chartname === "COMPLIANCE ANALYSIS") {
        navigate(`/compliancereport?reportname=${chartname}&module=${taskid}`);
      } else {
        navigate(`/evaluationreport?reportname=${chrtnm}&module=${taskid}`);
      }
    }
  }

  function calltask(data) {
    settask(data);
    // COMPLIANCE ANALYSIS
    // ASSESSMENT EXPIRY
    // COMPETENCY DISTRIBUTION
    // UPK QUESTION RESULTS
    // SYLLABUS QUESTION RESULTS
    // DAT PART 1 (TRN006)
    // DAT PART 2 (TRN007)
    if (chartname === "COMPLIANCE ANALYSIS") {
      compliance(data);
    } else if (chartname === "ASSESSMENT EXPIRY") {
      assessment(data);
    } else if (chartname === "UPK QUESTION RESULTS") {
      upk(data);
    } else if (chartname === "SYLLABUS QUESTION RESULTS") {
      syllabus(data);
    }
    if (props.favourite) {
      updatedashboard({ charttype: "", task: data });
    }
  }

  // Date Range fields
  let todaydate = new Date();
  //one year before date
  let stdate = new Date(todaydate.valueOf());
  stdate.setFullYear(stdate.getFullYear() - 1);

  let maxdatetreq = new Date(todaydate.valueOf());
  maxdatetreq.setFullYear(maxdatetreq.getFullYear() + 1);

  //30th date from today's date
  let d30thdate = new Date(todaydate.valueOf());
  d30thdate.setDate(d30thdate.getDate() + 30);
  //31st date from today's date
  let d31thdate = new Date(todaydate.valueOf());
  d31thdate.setDate(d30thdate.getDate() + 31);
  //60th date from today's date
  let d60thdate = new Date(todaydate.valueOf());
  d60thdate.setDate(d60thdate.getDate() + 60);

  const startdate = stdate.toISOString().slice(0, 10);
  const enddate = todaydate.toISOString().slice(0, 10);

  function getReport(reportendpoint) {

    base.getReport(reportendpoint).then(({ responseData, fileName }) => {
      setisloadingchartfav(false);
      // fileDownload library will covert the byte array(data from API) into PDF/Excel respectively.
      fileDownload(responseData, fileName);
    })
      .catch((err) => {
        setisloadingchartfav(false);
      });
  }

  function updatedashboard(data) {
    if (props.favourite) {
      data = {
        chartLevel: "PERSONAL",
        chartName: "Chart " + props.position,
        chartReport: "T_COMP1",
        chartType:
          data.charttype.length > 1
            ? data.charttype === "line"
              ? "4"
              : data.charttype === "pie"
                ? "5"
                : "51"
            : chart1 === "line"
              ? "4"
              : chart1 === "pie"
                ? "5"
                : "51",
        chartPos: "0" + props.position,
        rVal1:
          data.task.length < 1 && task.length < 1
            ? null
            : data.task.length > 1
              ? data.task
              : task,
        rVal2: chartname,
        fk_UserID: cookies.get("email"),
      };
      base.update("dashboard/upd", data)
    }
  }

  function updatefavourites(charttype, chartname, chartpos, task) {
    var data = {
      chartLevel: "PERSONAL",
      chartName: "Chart " + chartpos,
      reportType:
        chartname === "COMPLIANCE ANALYSIS" || chartname === "ASSESSMENT EXPIRY"
          ? "COMPLIANCE"
          : "EVALUATION",
      chartReport: "T_COMP1",
      chartType: charttype === "line" ? "4" : charttype === "pie" ? "5" : "51",
      chartPos: "0" + chartpos,
      rVal1: task.length > 0 ? task : null,
      rVal2: chartname,
      fk_UserID: cookies.get("email"),
    };

    base.update("dashboard/upd", data).then((res) => {
      var millisecond = new Date().getMilliseconds()
      props.setDataTrigger(millisecond);
      props.setFavouriteChart(chartpos);
    });
  }


  function personaldashboard() {
    // COMPLIANCE ANALYSIS
    // ASSESSMENT EXPIRY
    // COMPETENCY DISTRIBUTION
    // UPK QUESTION RESULTS
    // SYLLABUS QUESTION RESULTS
    // DAT PART 1 (TRN006)
    // DAT PART 2 (TRN007)
    // setdone(true);
    setisloadingchartfav(true);

    if (
      props.res.rVal1 === "" ||
      props.res.rVal1 === null
    ) {
      setshowtask(false);
    } else {
      setshowtask(true)
    }
    setTaskid(
      props.res.rVal1 === null
        ? ""
        : props.res.rVal1
    );
    if (
      props.res !== undefined &&
      props.res.rVal2 !== undefined
    ) {
      chartNameGlobal = props.res.rVal2;
      setchartname(props.res.rVal2);
    }
    if (
      props.res.chartType === "51"
    ) {
      setchart1("column");
    } else if (
      props.res.chartType === "5"
    ) {
      setchart1("pie");
    } else if (
      props.res.chartType === "4"
    ) {
      setchart1("line");
    }
    if (
      props.res.rVal2 === "COMPLIANCE ANALYSIS"
    ) {
      compliance(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    else if (
      props.res.rVal2 === "ASSESSMENT EXPIRY"
    ) {
      assessment(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    else if (
      props.res.rVal2 === "COMPETENCY DISTRIBUTION"
    ) {
      competency(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    else if (
      props.res.rVal2 === "UPK QUESTION RESULTS"
    ) {
      upk(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    else if (
      props.res.rVal2 === "SYLLABUS QUESTION RESULTS"
    ) {
      syllabus(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    else if (
      props.res.rVal2 === "DAT PART 1 (TRN006)"
    ) {
      dat1results(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    else if (
      props.res.rVal2 === "DAT PART 2 (TRN007)"
    ) {
      dat2results(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
      setTaskid(
        props.res.rVal1 === null
          ? ""
          : props.res.rVal1
      );
    }
    props.postupdate();
  }

  function upk(param) {
    if (param.length < 1) {
      param = "NULL";
    }
    base
      .get(
        "dashboardupk/qry",
        dateindays - 365 + "/" + dateindays + "/" + param
      )
      .then((res) => {
        convertchartdata(res, "upk");
      });
  }

  function compliance(param) {
    //Compliance Analysis
    if (param.length < 1) {
      param = "NULL";
    }
    base.get("dashboardtcomp/qry", param).then((res) => {
      convertchartdata(res, "compliance");
    });
  }

  function assessment(param) {
    //Compliance Analysis
    if (param.length < 1) {
      param = "NULL";
    }
    base.get("dashboardtcomp/qry", param).then((res) => {
      convertchartdata(res, "assessment");
    });
  }

  function competency(param) {
    // Competency Distribution
    base.get("dashboardcomplvl/qry", dateindays - 365 + "/" + dateindays).then((res) => {
      convertchartdata(res, "competency");
    });
  }

  function dat1results(param) {
    // DAT1
    base
      .get("dashboarddat1/qry", dateindays - 365 + "/" + dateindays)
      .then((res) => {
        convertchartdata(res, "dat1");
      });
  }

  function dat2results(param) {
    // DAT2
    base
      .get("dashboarddat2/qry", dateindays - 365 + "/" + dateindays)
      .then((res) => {
        convertchartdata(res, "dat2");
      });
  }

  function syllabus(param) {
    if (param.length < 1) {
      param = "NULL";
    }
    // Syllabus
    base
      .get(
        "dashboardsyl/qry",
        dateindays - 365 + "/" + dateindays + "/" + param
      )
      .then((res) => {
        convertchartdata(res, "syllabus");
      });
  }

  function convertchartdata(data, type) {
    var piebuf = [],
      linebuf = [],
      columnbuf = [];
    if (data.length > 0) {
      if (type === "dat2" || type === "upk") {
        if (data[0].l_1 === 0 && data[0].l_2 === 0 && data[0].l_3 === 0) {
          setIsFavChartDataEmpty(true);
        } else {
          piebuf.push({
            label: labels.dat2[0],
            value: data[0].l_1,
          });
          piebuf.push({
            label: labels.dat2[1],
            value: data[0].l_2,
          });
          piebuf.push({
            label: labels.dat2[2],
            value: data[0].l_3,
          });

          linebuf.push({ l1: data[0].l_1, l3: data[0].l_1, label: "L1" });
          linebuf.push({ l1: data[0].l_2, l3: data[0].l_2, label: "L2" });
          linebuf.push({ l1: data[0].l_3, l3: data[0].l_3, label: "L3" });

          columnbuf.push({ label: "L1", Q1: data[0].l_1, Q2: null, Q3: null, Q4: null });
          columnbuf.push({ label: "L2", Q2: data[0].l_2, Q1: null, Q3: null, Q4: null });
          columnbuf.push({ label: "L3", Q3: data[0].l_3, Q1: null, Q2: null, Q4: null });
        }
      } else if (type === "syllabus") {
        if (data[0].c === 0 && data[0].i === 0) {
          setIsFavChartDataEmpty(true);
        } else {
          piebuf.push({
            label: labels.syllabusquestions[0],
            value: data[0].c,
          });
          piebuf.push({
            label: labels.syllabusquestions[1],
            value: data[0].i,
          });

          linebuf.push({
            l1: data[0].c,
            l3: data[0].c,
            label: labels.syllabusquestions[0],
          });
          linebuf.push({
            l1: data[0].i,
            l3: data[0].i,
            label: labels.syllabusquestions[1],
          });

          columnbuf.push({ label: labels.syllabusquestions[0], Q1: data[0].c, Q2: null, Q3: null, Q4: null });
          columnbuf.push({ label: labels.syllabusquestions[1], Q2: data[0].i, Q1: null, Q3: null, Q4: null });
        }
      } else if (type === "dat1") {
        if (data[0].daty === 0 && data[0].datn === 0) {
          setIsFavChartDataEmpty(true);
        } else {
          piebuf.push({
            label: labels.dat1[0],
            value: data[0].daty,
          });
          piebuf.push({
            label: labels.dat1[1],
            value: data[0].datn,
          });

          linebuf.push({
            l1: data[0].daty,
            l3: data[0].daty,
            label: labels.dat1[0],
          });
          linebuf.push({
            l1: data[0].datn,
            l3: data[0].datn,
            label: labels.dat1[1],
          });

          columnbuf.push({ label: labels.dat1[0], Q1: data[0].daty, Q2: null, Q3: null, Q4: null });
          columnbuf.push({ label: labels.dat1[1], Q2: data[0].datn, Q1: null, Q3: null, Q4: null });
        }
      } else if (type === "compliance") {
        if (
          data[0].overdue_Percent === 0 &&
          data[0].expired_Percent === 0 &&
          data[0].compliance_Percent === 0
        ) {
          setIsFavChartDataEmpty(true);
        } else {
          piebuf.push({
            label: labels.complainceanalysis[0],
            value: data[0].overdue_Percent,
          });
          piebuf.push({
            label: labels.complainceanalysis[1],
            value: data[0].expired_Percent,
          });
          piebuf.push({
            label: labels.complainceanalysis[2],
            value: data[0].compliance_Percent,
          });

          linebuf.push({
            l1: data[0].overdue_Percent,
            l3: data[0].overdue_Percent,
            label: labels.complainceanalysis[0],
          });
          linebuf.push({
            l1: data[0].expired_Percent,
            l3: data[0].expired_Percent,
            label: labels.complainceanalysis[1],
          });
          linebuf.push({
            l1: data[0].compliance_Percent,
            l3: data[0].compliance_Percent,
            label: labels.complainceanalysis[2],
          });

          columnbuf.push({
            label: labels.complainceanalysis[0],
            Q1: data[0].overdue_Percent,
            Q2: null, Q3: null, Q4: null
          });
          columnbuf.push({
            label: labels.complainceanalysis[1],
            Q2: data[0].expired_Percent,
            Q1: null, Q3: null, Q4: null
          });
          columnbuf.push({
            label: labels.complainceanalysis[2],
            Q3: data[0].compliance_Percent,
            Q1: null, Q2: null, Q4: null
          });
        }
      } else if (type === "competency") {
        let flag = false;
        for (let i = 0; i < data.length; i++) {
          if (data[i].count !== 0) {
            flag = true;
            break;
          }
        }
        if (flag) {
          for (let i = 0; i < data.length; i++) {
            piebuf.push({
              label: data[i].competencyLevel,
              value: data[i].count,
            });

            linebuf.push({
              l1: data[i].count,
              l3: data[i].count,
              label: data[i].competencyLevel,
            });

            columnbuf.push({
              label: data[i].competencyLevel,
              Q1: data[i].count,
              Q2: null, Q3: null, Q4: null
            });
          }
        } else {
          setIsFavChartDataEmpty(true);
        }
      } else if (type === "assessment") {
        if (
          data[0].expired_Number === 0 &&
          data[0].overdue_Number === 0 &&
          data[0].exP_30 === 0 &&
          data[0].exP_60 === 0
        ) {
          setIsFavChartDataEmpty(true);
        } else {
          piebuf.push({
            label: labels.assessmentexpiry[0],
            value: data[0].expired_Number,
          });
          piebuf.push({
            label: labels.assessmentexpiry[1],
            value: data[0].overdue_Number,
          });
          piebuf.push({
            label: labels.assessmentexpiry[2],
            value: data[0].exP_30,
          });
          piebuf.push({
            label: labels.assessmentexpiry[4],
            value: data[0].exP_60,
          });

          linebuf.push({
            l1: data[0].expired_Number,
            l3: data[0].expired_Number,
            label: labels.assessmentexpiry[0],
          });
          linebuf.push({
            l1: data[0].overdue_Number,
            l3: data[0].overdue_Number,
            label: labels.assessmentexpiry[1],
          });
          linebuf.push({
            l1: data[0].exP_30,
            l3: data[0].exP_30,
            label: labels.assessmentexpiry[2],
          });
          linebuf.push({
            l1: data[0].exP_60,
            l3: data[0].exP_60,
            label: labels.assessmentexpiry[3],
          });

          columnbuf.push({
            label: labels.assessmentexpiry[0],
            Q1: data[0].expired_Number,
            Q2: null, Q3: null, Q4: null
          });
          columnbuf.push({
            label: labels.assessmentexpiry[1],
            Q2: data[0].overdue_Number,
            Q1: null, Q3: null, Q4: null
          });
          columnbuf.push({
            label: labels.assessmentexpiry[2],
            Q3: data[0].exP_30,
            Q1: null, Q2: null, Q4: null
          });
          columnbuf.push({
            label: labels.assessmentexpiry[3],
            Q4: data[0].exP_60,
            Q1: null, Q2: null, Q3: null
          });
        }
      }
    }
    else {
      setIsFavChartDataEmpty(true);
    }
    setchartdata1({
      columndata: columnbuf,
      linedata: linebuf,
      piedata: piebuf,
    });

    setisloadingchartfav(false);
  }

  var labels = {
    dat1: ["Yes", "No"],
    dat2: ["L1", "L2", "L3"],
    complainceanalysis: ["Overdue %", "Expired %", "Compliance %"],
    syllabusquestions: ["Correct", "Incorrect"],
    upkquestions: ["L1", "L2", "L3"],
    competencydistribution: [],
    assessmentexpiry: [
      "Expired",
      "Overdue",
      "0-30 days",
      "   31-60 days",
      "31-60 days",
    ],
  };


  useEffect(() => {
    if (taskid !== null) {
      var module = comptask.filter(f => f.taskID === props.res.rVal1)
      if (module.length !== 0) {
        settaskNumber(module[0].task_Number)
      }
    }
  }, [comptask, taskid, props.res.rVal1]);

  useEffect(() => {
    var filteredModules = []
    var namechart = props.heading;
    if (namechart === "SYLLABUS QUESTION RESULTS") {
      filteredModules = props.modules.filter((option) => option.task_Type === "SYL");
    } else if (namechart === "UPK QUESTION RESULTS") {
      filteredModules = props.modules.filter((option) => option.task_Type === "TSK");
    } else {
      filteredModules = props.modules;
    }
    setcomptask(filteredModules);

  }, [props.modules, setcomptask, props.heading]);

  const [chartdata1, setchartdata1] = useState({
    columndata: [],
    linedata: [],
    piedata: [],
  });

  return (
    <div>
      {toreports ? getchartdata() : ""}
      {props.updatechart ? personaldashboard() : ""}
      <div className="chartborder">
        <Row>
          <Col xs="9">
            {(() => {
              let reportType;
              let chartID;
              let chartLabel;
              let detail = "GROUP BY PERSON"
              switch (chartname.toUpperCase()) {
                case "COMPLIANCE ANALYSIS":
                  reportType = "compliancereport";
                  chartID = "T_COMP";
                  chartLabel = t("Homepage.Compliance Analysis");
                  break;
                case "DAT PART 1 (TRN006)":
                  reportType = "evaluationreport";
                  chartID = "Q_DAT";
                  chartLabel = t("Homepage.Driver Assessment Part 1")
                  break;
                case "DAT PART 2 (TRN007)":
                  reportType = "evaluationreport";
                  chartID = "DAT2";
                  chartLabel = t("Homepage.Driver Assessment Part 2")
                  break;
                case "UPK QUESTION RESULTS":
                  reportType = "evaluationreport";
                  chartID = "Q_UPK";
                  chartLabel = t("Homepage.UPK Question Results");
                  break;
                case "COMPETENCY DISTRIBUTION":
                  reportType = "evaluationreport";
                  chartID = "COMP_LVL";
                  chartLabel = t("Homepage.Competency Distribution");
                  break;
                case "SYLLABUS QUESTION RESULTS":
                  reportType = "evaluationreport";
                  chartID = "Q_CAS";
                  chartLabel = t("Homepage.Syllabus Question Results");
                  break;
                case "ASSESSMENT EXPIRY":
                  reportType = "compliancereport";
                  chartID = "ASS_EXP";
                  chartLabel = t("Homepage.Assessment Expiry");
                  break;
                default:
                  break;
              }
              return (
                <div className="chartheading">

                  <span
                    className="bheading mb-0 pb-0"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      setToreports(true);
                      navigate(`/${reportType}?reportname=${chartID}&detail=${detail}&module=${taskid}`);
                    }}
                  >
                    {chartLabel}
                    {props.favourite === true ? <img src={Star} alt="" height="16px" /> : ""}{" "}
                  </span>
                </div>
              );
            })()}



            <span
              style={{ fontSize: "12px", marginLeft: "10px", height: "20px" }}
              className="d-flex justify-content-start mb-2 mt-n4"
            >
              <div>
                {showtask && taskid.length > 0 && (

                  <span
                    style={{
                      color: "black",
                      backgroundColor: "whitesmoke",
                      padding: "4px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsFavChartDataEmpty(false);
                      setshowtask(false);
                      calltask("");
                      setTaskid("");
                      setisloadingchartfav(true);
                    }}
                  >
                    &nbsp; {taskNumber} &nbsp;&nbsp;
                    <Remove16 />
                  </span>
                )
                }</div>

            </span>

          </Col>
          <Col className="text-end pt-3">
            <UncontrolledDropdown isOpen={isopen} toggle={Toggle}>
              <DropdownToggle className="kebabdropdown btnpadding pe-4">
                {isopen ? (
                  <Remove24 className="" style={{ color: "#111" }} />
                ) : (
                  <Kebab24 className="" style={{ color: "#111" }} />
                )}
              </DropdownToggle>
              <DropdownMenu end>
                <UncontrolledDropdown direction="right" tag="li">
                  <DropdownToggle tag="button" caret className="dropdown-item">
                    <Trans i18nKey="Homepage.Charts">Charts</Trans>{" "}
                    <div className="dropdown-suffix x5-me-n1">
                      <Right24 />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem
                      key="column"
                      value="column"
                      onClick={() => {
                        setchart1("column");
                        setisopen(false);
                        updatedashboard({ charttype: "column", task: "" });
                      }}
                    >
                      <Trans i18nKey="Homepage.Column">Column</Trans>
                    </DropdownItem>
                    <DropdownItem
                      key="line"
                      value="line"
                      onClick={() => {
                        setchart1("line");
                        setisopen(false);
                        updatedashboard({ charttype: "line", task: "" });
                      }}
                    >
                      <Trans i18nKey="Homepage.Line">Line</Trans>
                    </DropdownItem>
                    <DropdownItem
                      key="pie"
                      value="pie"
                      onClick={() => {
                        setchart1("pie");
                        setisopen(false);
                        updatedashboard({ charttype: "pie", task: "" });
                      }}
                    >
                      <Trans i18nKey="Homepage.Pie">Pie</Trans>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                {chartname.includes("COMPLIANCE") ||
                  chartname.includes("ASSESSMENT") ||
                  chartname.includes("UPK") ||
                  chartname.includes("SYLLABUS") ? (
                  <UncontrolledDropdown direction="end" tag="li">
                    <DropdownToggle tag="button" caret className="dropdown-item">
                      <Trans i18nKey="Homepage.Module">Module</Trans>{" "}
                      <div className="dropdown-suffix x5-me-n1">
                        <Right24 />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-scroll">
                      <DropdownItem
                        value=""
                        key=""
                        onClick={() => {
                          setIsFavChartDataEmpty(false);
                          setisloadingchartfav(true);
                          calltask("");
                          settaskNumber("");
                          setTaskid("");
                          setshowtask(false);
                          setisopen(false);
                        }}
                      >
                        <Trans i18nKey="Homepage.Reset">Reset</Trans>
                      </DropdownItem>
                      {comptask.map((val, index) => {
                        return (
                          <DropdownItem
                            key={val.taskID}
                            onClick={() => {
                              setIsFavChartDataEmpty(false);
                              setisloadingchartfav(true);
                              calltask(val.taskID);
                              // settaskname(val.task_Name);
                              setTaskid(val.taskID);
                              settaskNumber(val.task_Number);
                              setshowtask(true);
                              setisopen(false);
                            }}
                          >
                            <div style={{ overflowX: "hidden" }}>
                              {val.task_Number}&nbsp;&nbsp;-&nbsp;&nbsp;{val.task_Name}
                            </div>
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  ""
                )}

                {props.favourite === false ? <>
                  <UncontrolledDropdown direction="right" tag="li">
                    <DropdownToggle tag="button" caret className="dropdown-item">
                      <Trans i18nKey="Homepage.Add to Favourites">Add to Favourites</Trans>{" "}
                      <div className="dropdown-suffix x5-me-n1">
                        <Right24 />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem
                        key="favourite1"
                        value="favourite1"
                        onClick={() => {
                          updatefavourites(
                            chart1,
                            props.heading,
                            "1",
                            taskid,
                          );
                          setisopen(false);
                        }}
                      >
                        <Trans i18nKey="Homepage.Add to Favourite 1">
                          Add to Favourite 1
                        </Trans>
                      </DropdownItem>
                      <DropdownItem
                        key="favourite2"
                        value="favourite2"
                        onClick={() => {
                          updatefavourites(
                            chart1,
                            props.heading,
                            "2",
                            taskid
                          );
                          setisopen(false);
                        }}
                      >
                        <Trans i18nKey="Homepage.Add to Favourite 2">
                          Add to Favourite 2
                        </Trans>
                      </DropdownItem>
                      <DropdownItem
                        key="favourite3"
                        value="favourite3"
                        onClick={() => {
                          updatefavourites(
                            chart1,
                            props.heading,
                            "3",
                            taskid
                          );
                          setisopen(false);
                        }}
                      >
                        <Trans i18nKey="Homepage.Add to Favourite 3">
                          Add to Favourite 3
                        </Trans>
                      </DropdownItem>
                    </DropdownMenu>

                  </UncontrolledDropdown></> : ""}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col >
        </Row >
        {/* )
        } */}
        {
          !isFavChartDataEmpty && chart1 === "column" ? (
            <LoadingOverlay
              active={isloadingchartfav}
              spinner={<Spinner style={{ color: "#111" }} />}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(0, 0, 0, 0.15)",
                }),

              }}

            >
              {!isloadingchartfav ?
                <AgChartsReact
                  options={{
                    data: chartdata1.columndata,
                    series: [
                      {
                        type: "column",
                        fills:
                          chartname === "COMPLIANCE ANALYSIS"
                            ? ["#000099", "#edac1a", "#007f00", "#660099"]
                            : chartname === "DAT PART 1 (TRN006)"
                              ? ["#007f00", "#edac1a", "#000099", "#660099"]
                              : chartname === "DAT PART 2 (TRN007)" ||
                                chartname === "UPK QUESTION RESULTS"
                                ? ["#edac1a", "#000099", "#007f00", "#660099"]
                                : chartname === "SYLLABUS QUESTION RESULTS"
                                  ? ["#007f00", "#edac1a", "#000099", "#660099"]
                                  : chartname === "COMPETENCY DISTRIBUTION" ||
                                    chartname === "COMPLIANCE ANALYSIS"
                                    ? ["#007f00", "#000099", "#edac1a", "#660099"]
                                    : ["#edac1a", "#000099", "#660099", "#007f00"],
                        xKey: "label",
                        yKeys: ["Q1", "Q2", "Q3", "Q4"],

                        listeners: {
                          nodeClick: (event) => {
                            if (
                              chartname === "ASSESSMENT EXPIRY" ||
                              chartNameGlobal === "ASSESSMENT EXPIRY"
                            ) {
                              //On click of data label of 'Assessment Expiry' chart report with below filters will beloaded
                              var datum = event.datum;

                              if (datum[event.xKey] === "Overdue") {
                                setAssessmentExpLabelName("Overdue");
                              }
                              if (datum[event.xKey] === "Expired") {
                                setAssessmentExpLabelName("Expired");
                              }
                              if (datum[event.xKey] === "0-30 days") {
                                setAssessmentExpLabelName("0-30 days");
                              }
                              if (datum[event.xKey] === "   31-60 days") {
                                setAssessmentExpLabelName("31-60 days");
                              }
                            }
                          },
                        },
                        cursor: chartname === "ASSESSMENT EXPIRY" && "pointer",
                        highlightStyle: {
                          item: {
                            fill: "#FFE600",
                          },
                        },
                        tooltip: {
                          renderer: function (params) {
                            if (chartname === "COMPLIANCE ANALYSIS") {
                              params.yValue = params.yValue * 100;
                            }
                            return {
                              content: params.yValue.toFixed(0),

                            };
                          },
                        },
                      },
                    ],
                    legend: {
                      enabled: false,
                      position: "bottom",
                    },
                    listeners: {
                      seriesNodeClick: function (event) {
                        chartname === "ASSESSMENT EXPIRY"
                          && setToreports(false);

                      },
                    },
                  }}
                />
                :
                <div style={{ height: "300px" }} />
              }
            </LoadingOverlay>
          ) : (
            ""
          )
        }
        {
          !isFavChartDataEmpty && chart1 === "line" ? (
            <LoadingOverlay
              active={isloadingchartfav}
              spinner={<Spinner style={{ color: "#111" }} />}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(0, 0, 0, 0.15)",
                }),

              }}
            >
              {!isloadingchartfav ?
                <AgChartsReact
                  options={{
                    data: chartdata1.linedata,
                    series: [
                      {
                        fills: ["#edac1a", "#000099", "#007f00", "#660099"],
                      },
                      {
                        xKey: "label",
                        yKey: "l1",
                        cursor: "pointer",
                        highlightStyle: {
                          item: {
                            fill: "#FFE600",
                          },
                        },
                        tooltip: {
                          renderer: function (params) {
                            return {
                              title: params.xValue,
                              content: params.yValue.toFixed(0),
                            };
                          },
                        },
                      },
                      {
                        xKey: "label",
                        yKey: "l3",
                        listeners: {
                          nodeClick: (event) => {
                            if (
                              chartname === "ASSESSMENT EXPIRY" ||
                              chartNameGlobal === "ASSESSMENT EXPIRY"
                            ) {
                              //On click of data label of 'Assessment Expiry' chart report with below filters will beloaded
                              var datum = event.datum;
                              if (datum[event.xKey] === "Overdue") {
                                setAssessmentExpLabelName("Overdue");
                              }
                              if (datum[event.xKey] === "Expired") {
                                setAssessmentExpLabelName("Expired");
                              }
                              if (datum[event.xKey] === "0-30 days") {
                                setAssessmentExpLabelName("0-30 days");
                              }
                              if (datum[event.xKey] === "   31-60 days") {
                                setAssessmentExpLabelName("31-60 days");
                              }
                            }
                          },
                        },
                        cursor: chartname === "ASSESSMENT EXPIRY" && "pointer",
                        tooltip: {
                          renderer: function (params) {
                            if (chartname === "COMPLIANCE ANALYSIS") {
                              params.yValue = params.yValue * 100;
                            }
                            return {
                              content: params.yValue.toFixed(0),
                            };
                          },
                        },
                        stroke: "#660099",
                        marker: {
                          stroke: "#660099",
                          fill: "#660099",
                          strokewidth: "10",
                        },
                      },
                    ],
                    legend: {
                      enabled: false,
                    },
                    listeners: {
                      seriesNodeClick: function (event) {
                        chartname === "ASSESSMENT EXPIRY"
                          && setToreports(false)
                      },
                    },
                  }}
                />
                :
                <div style={{ height: "300px" }} />
              }
            </LoadingOverlay>
          ) : (
            ""
          )
        }
        {
          !isFavChartDataEmpty && chart1 === "pie" ? (
            <LoadingOverlay
              active={isloadingchartfav}
              spinner={<Spinner style={{ color: "#111" }} />}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(0, 0, 0, 0.15)",
                }),

              }}
            >
              {!isloadingchartfav ?

                <AgChartsReact
                  options={{
                    data: chartdata1.piedata,

                    series: [
                      {
                        type: "pie",
                        fills:
                          chartname === "COMPLIANCE ANALYSIS"
                            ? ["#000099", "#edac1a", "#007f00", "#660099"]
                            : chartname === "DAT PART 1 (TRN006)"
                              ? ["#007f00", "#edac1a", "#000099", "#660099"]
                              : chartname === "DAT PART 2 (TRN007)" ||
                                chartname === "UPK QUESTION RESULTS"
                                ? ["#edac1a", "#000099", "#007f00", "#660099"]
                                : chartname === "SYLLABUS QUESTION RESULTS"
                                  ? ["#007f00", "#edac1a", "#000099", "#660099"]
                                  : chartname === "COMPETENCY DISTRIBUTION"
                                    ? [
                                      "#007f00",
                                      "#000099",
                                      "#edac1a",
                                      "#660099",
                                      "#009ee0",
                                      "#ff6600",
                                      "#FBDB65",
                                      "#666666",
                                      "#BEDBBF",
                                      "#99CC00",
                                      "#CCFF00",
                                    ]
                                    : ["#edac1a", "#000099", "#660099", "#007f00"],
                        angleKey: "value",
                        labelKey: "label",
                        listeners: {
                          nodeClick: (event) => {
                            //On click of data label of 'Assessment Expiry' chart report with below filters will beloaded
                            if (
                              chartname === "ASSESSMENT EXPIRY" ||
                              chartNameGlobal === "ASSESSMENT EXPIRY"
                            ) {
                              var datum = event.datum;
                              if (datum[event.labelKey] === "Overdue") {
                                setAssessmentExpLabelName("Overdue");
                              }
                              if (datum[event.labelKey] === "Expired") {
                                setAssessmentExpLabelName("Expired");
                              }
                              if (datum[event.labelKey] === "0-30 days") {
                                setAssessmentExpLabelName("0-30 days");
                              }
                              if (datum[event.labelKey] === "31-60 days") {
                                setAssessmentExpLabelName("31-60 days");
                              }
                            }
                          },
                        },
                        label: { enabled: false },
                        cursor: chartname === "ASSESSMENT EXPIRY" && "pointer",
                        highlightStyle: {
                          item: {
                            fill: undefined,
                          },
                        },
                        tooltip: {
                          renderer: function (params) {
                            if (chartname === "COMPLIANCE ANALYSIS") {
                              params.angleValue = params.angleValue * 100;
                            }
                            return {
                              content: params.angleValue.toFixed(0),
                            };
                          },
                        },
                      },
                    ],
                    legend: {
                      position: "bottom",
                    },
                    listeners: {
                      seriesNodeClick: function (event) {
                        chartname === "ASSESSMENT EXPIRY"
                          && setToreports(false)
                      },
                    },
                  }}
                />
                :
                <div style={{ height: "300px" }} />
              }
            </LoadingOverlay>
          ) : (
            ""
          )
        }

        {isFavChartDataEmpty && <EmptyChartTextComponent />}
      </div >
    </div >
  );
}

export default withRouter(Favouritechart);