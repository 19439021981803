import React from "react";
import "../../Content/stylesheet/common.css";
import Cadopslogo from "../../Content/Images/Logo/cadopslogo.svg";
import "../../Content/stylesheet/layout.css";
import TitleBanner from "./Layout/TitleBanner";
const Error500 = () => {
    return (
        <>

            <div className="navbar-new">
                <div className="ms-5 " style={{ margin: "18px 0px" }}>
                    <img
                        alt="CADOPS Logo"
                        src={Cadopslogo}
                        width="105px"
                    ></img>
                </div>
                <TitleBanner
                    heading={"Error"}
                    subheading={""}
                    infoText={""}
                    breadcrumbArray={[]}
                    showButton1={false}
                    showButton2={false}
                    showButton3={false}
                />
                <div className="marginleft5" style={{ marginTop: "15px" }}>
                    <p>
                        An unexpected error has occured.
                        <br /><br />
                        If the issues persists, please contact support.
                    </p>
                    <p>Thank you</p>
                    <p>Air bp CADOPS Support Team</p>
                </div>
            </div>


        </>
    );
}

export default Error500;
