import { Spinner } from "reactstrap"
import "../../../Content/stylesheet/layout.css";


const LoadingView = () => {

    return (
        <>
            <div className="loading-view" style={{ position: "fixed", zIndex: 999999 }}>
            </div>
            <Spinner className="loading-spinner" style={{ position: "fixed", zIndex: 999999 }} />
        </>
    )
}

export default LoadingView


