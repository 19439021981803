import React, { useState, useEffect } from "react";
import ConfirmDeleteToast from "../Toast/ConfirmDeleteToast";
import { Row, Col, DropdownItem } from "reactstrap";
import base from "../../Services/BaseService";
import Cookies from "universal-cookie";
import translations from '../../i18n/locales/en/translations.json'
import Select from "react-select";
import { components } from "react-select";
import { Trans, useTranslation } from "react-i18next";
import LoadingView from "../Shared/Layout/LoadingView";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';

function CreateAssessment(props) {
  const typesData = translations.types;
  const { t, i18n } = useTranslation();
  var cookies = new Cookies();
  const Option = (props) => {
    return (
      <div id="empMulti ">
        <components.Option {...props}>
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    if (props.mode === "edit") {
      getsinglerecord();
      iseditable();
      setDescription(props.selectedval.description);
      settask(props.selectedval.task_Number);
      setTaskid(props.selectedval.taskID);
      var name = convertUnicode(props.selectedval.task_Name);
      var item = {};
      item.value =
        props.selectedval.task_Number +
        "&/&" +
        props.selectedval.global_TaskID +
        "&/&" +
        props.selectedval.taskID;
      item.label = props.selectedval.task_Number + " - " + name;
      setModule({ label: item.label, value: item.value })
      setYear(props.selectedval.xYear);
      setQuarter(props.selectedval.xMonth);
      setassessment(props.selectedval.assessment_Type);

      let assessType = assessmenttypeoptions.filter(x => x.value === props.selectedval.assessment_Type)
      setassessmenttype({ value: assessType[0].value, label: assessType[0].label })
    }

    function getsinglerecord() {
      base.get(`assessment/Get/${props.selectedval.assessmentID}`, i18n.language.toLowerCase()).then((res) => {
        setisTrained(res.xTrained < 0 ? true : false);
        setId(res.id);
      });
    }

    function iseditable() {
      base.get("resultcompetency/Qry", props.selectedval.assessmentID).then((res) => {
        if (res.length > 0) {
          setAsseCompleteEableCheckbox(true);
        }
        if (res.length === 0) {
          setAsseCompleteEableCheckbox(false);
        }
      });
    }

    var yearops = [];
    let currentMoth = new Date().getMonth();
    let curreYear = new Date().getFullYear();
    let previousYear = curreYear - 1;
    let prePrevYear = curreYear - 2;

    setCurrentYear(curreYear.toString());
    let today = new Date();
    let quarter = Math.floor((today.getMonth() + 3) / 3);
    setCurrentQuarter("Q" + quarter);

    let res = [];

    if (currentMoth === 0 || currentMoth === 1 || currentMoth === 2)
      res.push(prePrevYear.toString());
    res.push(previousYear.toString());
    res.push(curreYear.toString());
    for (let i = 0; i < res.length; i++) {
      yearops.push(
        <DropdownItem
          onClick={() => {
            setYear(res[i]);
          }}
        >
          {res[i]}
        </DropdownItem>
      );
    }
    // eslint-disable-next-line
  }, []);

  const [assessmenttype, setassessmenttype] = useState("");
  const [task, settask] = useState("");
  const [module, setModule] = useState({ label: "", value: "" });
  const [year, setYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [description, setDescription] = useState("");
  const [assessment, setassessment] = useState("");
  const [isTrained, setisTrained] = useState(false);
  const [isCompleteAsschecked, setisCompleteAsschecked] = useState(false);
  const [ischeckboxChanged, setischeckboxChanged] = useState(false);
  const [taskid, setTaskid] = useState("");
  const [iscancel, setIscancel] = useState(false);
  const [id, setId] = useState(0);
  const [asseCompleteEableCheckbox, setAsseCompleteEableCheckbox] = useState(false);
  const [taskoptions, settaskoptions] = useState([]);
  const [typeerror, settypeerror] = useState(false);
  const [errtask, setErrtask] = useState(false);
  const [lengtherr, setLengtherr] = useState(false);
  const [globaltask, setglobaltask] = useState("");
  const [isLoadingEditAssess, setisLoadingEditAssess] = useState(false);
  const [currentYear, setCurrentYear] = useState("");
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [disabled, setDisabled] = useState(false);

  const assessmenttypeoptions = Object.keys(typesData).map((key) => ({
    label: t(`types.${key}`),
    value: key,
  }));

  function closemodal(val) {
    props.closed();
  }

  function convertUnicode(input) {
    return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
    });
  }

  const getModuleTasks = (params) => {
    var MTask = [];
    const filteredOptions = props.moduletasks.filter((option) => option.task_Type === params);
    filteredOptions.map((option) => {
      var item = {};
      item.value = option.taskID;
      item.label = option.task_Number;
      item.text = convertUnicode(option.task_Name);
      item.allData = option;
      MTask.push(item);
      return null;
    }
    );
    settaskoptions(MTask);
  }

  function modulechange(value) {
    setErrtask(false);
    settask(value.label);
    setModule({ label: value.label, value: "" });
    setglobaltask(value.allData.global_TaskID);
    setTaskid(value.value);
  }

  const handleChange = e => {
    setassessmenttype(e);
    setModule({ label: "", value: "" });
    settypeerror(false);
    setassessment(e.value);
    settask("");
    setTaskid("");
    getModuleTasks(e.value === "CAS"
      ? "SYL"
      : e.value === "TAR"
        ? "TSK"
        : e.value);
  }

  function addAssessment() {
    setDescription(description.trim());
    var data = {
      AssessmentID: "",
      Description: description.trim(),
      xYear: currentYear,
      global_TaskID: globaltask,
      fk_TaskID: taskid,
      Assessment_Type: assessment === "TAR2" ? "TAR" : assessment,
      xMonth: currentQuarter,
      fk_UserID: cookies.get("email"),
      Period: currentYear + "." + currentQuarter,
      xTrained: isTrained.toString(),
    };
    base.add("/assessment/ADD", data).then((res) => {
      if (
        res.data === "Not adequate questions assigned for this module!"
      ) {
        props.noquestion(res.data);
        setisLoadingEditAssess(false);
        closemodal();
      } else {
        props.update();
        props.success();
        setisLoadingEditAssess(false);
        closemodal();
      }
    })
      .catch((err) => {
        setisLoadingEditAssess(false);
        closemodal();
        props.error();
      });
  }

  function getquestions() {
    var proceed = checkdetails();
    if (proceed) {
    } else {
      if (
        taskid === "GBL10057" ||
        assessment === "NON" ||
        assessment === "EXT"
      ) {
        setisLoadingEditAssess(true);
        addAssessment();
      } else {
        setisLoadingEditAssess(true);
        base.get("questiontask/qry", taskid).then((res) => {
          if (res.length > 0) {
            // setToastconfirm(true);
            addAssessment();
          } else {
            closemodal();
            props.noquestion("");
          }
        });
      }
    }
  }

  function checkdetails() {
    var buf = false;
    if (assessmenttype.length < 1) {
      settypeerror(true);
      setDisabled(false);
      buf = true;
    }
    if (task.length < 1) {
      setErrtask(true);
      setDisabled(false);
      buf = true;
    }
    if (description.trim().length > 125) {
      setLengtherr(true);
      setDisabled(false);
      buf = true;
    } else {
      setLengtherr(false);
    }
    if (!description.trim().length) {
      setDescription("");
    }
    return buf;
  }

  function updateAssessment() {
    setDescription(description.trim());

    var proceed = checkdetails();

    var data = {
      AssessmentID: props.selectedval.assessmentID,
      Description: description.trim(),
      xYear: year,
      fk_TaskID: taskid,
      Assessment_Type: assessment === "TAR2" ? "TAR" : assessment,
      xMonth: quarter,
      fk_UserID: cookies.get("email"),
      Period: year + "." + quarter,
      xTrained: isTrained.toString(),
      id: id,
    };

    if (proceed) {
    } else {
      setisLoadingEditAssess(true);
      base.update("/assessment/UPD", data).then((res) => {
        if (isCompleteAsschecked) {
          //if marke assessment checked then marked assesssment to complete
          base
            .update("assessment/Upd1/" + data.AssessmentID)
            .then(() => {
              props.update();
              closemodal();
              props.success();
              setisLoadingEditAssess(false);
            });
        } else {
          props.update();
          closemodal();
          props.success();
          setisLoadingEditAssess(false);
        }
      });
    }
  }

  function ischanged() {
    if (props.mode === "edit") {
      if (props.selectedval.description !== description) {
        return true;
      }
      if (props.selectedval.xMonth !== quarter) {
        return true;
      }
      if (props.selectedval.xYear !== year) {
        return true;
      }
      if (ischeckboxChanged) return true;
    } else {
      if (
        assessmenttype !== "" ||
        taskid !== "" ||
        description !== "" ||
        quarter !== "" ||
        year !== ""
      ) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {isLoadingEditAssess && <LoadingView />}
      <Modal isOpen={true} className="modal-dialog-centered modal-70">
        {iscancel ? (
          <ConfirmDeleteToast
            show={true}
            style={{ top: "20%" }}
            Message={t("ErrorMessages.CancelSure")}
            handleCancel={() => {
              setIscancel(false);
            }}
            handleDelete={() => {
              closemodal();
            }}
          ></ConfirmDeleteToast>
        ) : (
          ""
        )}
        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={() => {
            ischanged() ? setIscancel(true) : closemodal();
          }}>
          <Label className="modalHeaderGlobal m-4">
            {props.mode === "edit" ? (
              <Trans i18nKey="assessment.Edit Assessment">
                "Edit Assessment"{" "}
              </Trans>
            ) : (
              <Trans i18nKey="assessment.Create Assessment">
                "Create Assessment"{" "}
              </Trans>
            )}
          </Label>
        </Modal.Header>
        <Modal.Body id="scrolltop" className="ps-6 pe-6">
          <Row>
            <Col xs="12" lg="6" className="pt-3">
              <Label>
                <Trans i18nKey="assessment.Assessment Type">
                  Assessment Type
                </Trans>
                <span>*</span>
              </Label>
              <Select
                id=""
                className={errtask ? "errorborder" : ""}
                placeholder={""}
                value={assessmenttype}
                options={assessmenttypeoptions}
                onChange={handleChange}
                components={{
                  Option,
                  IndicatorSeparator: () => null,
                }}
                classNamePrefix="react-select"
                isDisabled={props.mode === "create" ? false : true}
              ></Select>
              {typeerror ? (
                <span className="assessmenterror">
                  <Trans i18nKey="ErrorMessages.Required">
                    Oops! Looks like you haven’t filled this field
                  </Trans>
                </span>
              ) : (
                ""
              )}
            </Col>
            <Col xs="12" lg="6" className="pt-3">
              <Label>
                <Trans i18nKey="Common.Module">Module</Trans>{" "}
                <span>*</span>
              </Label>
              <Select
                id=""
                className={errtask ? "errorborder" : ""}
                placeholder={""}
                value={module}
                options={taskoptions}
                onChange={modulechange}
                classNamePrefix="react-select"
                isDisabled={props.mode === "create" ? false : true}
                components={{
                  Option: (props) => (
                    <div id=" " >
                      <components.Option {...props}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "19% 81%",
                            width: "100%",
                          }}
                        >
                          <span> {props.data.allData.task_Number}</span>
                          <span> {props.data.allData.task_Name}</span>
                        </div>
                      </components.Option>
                    </div>
                  ),
                  IndicatorSeparator: () => null,
                }}
              ></Select>

              {errtask ? (
                <span className="assessmenterror">
                  <Trans i18nKey="ErrorMessages.Required">
                    Oops! Looks like you haven’t filled this field
                  </Trans>
                </span>
              ) : (
                ""
              )}
            </Col>

            <Col xs="12" className="pt-3">
              <Label>
                {" "}
                <Trans i18nKey="assessment.Description">Description</Trans>
              </Label>
              <Input type="textarea" className={lengtherr ? "errorborder" : ""} onChange={(event) => { setDescription(event.target.value); }} value={description} maxLength={125} />
              <Col xs="12" className="text-end desclength">
                {description.length}/125
              </Col>
            </Col>
            <Col xs="12" >
              {props.mode === "edit" ? (
                <>
                  <Input
                    id="complete"
                    type="checkbox"
                    className=""
                    disabled={asseCompleteEableCheckbox ? false : true}
                    checked={isCompleteAsschecked}
                    onChange={() => {
                      setischeckboxChanged(true);
                      setisCompleteAsschecked(!isCompleteAsschecked);
                    }}
                  ></Input>

                  <Label
                    className="ps-4 pt-3"
                    for="complete"
                    style={{
                      opacity: !asseCompleteEableCheckbox ? "0.7" : "1",
                    }}
                  >
                    <Trans i18nKey="assessment.Mark assessment complete">
                      Mark assessment complete
                    </Trans>
                  </Label>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded-pill btn-style"
            size="sm"
            color="standard-secondary"
            onClick={() => {
              ischanged() ? setIscancel(true) : closemodal();
            }}
          >
            <Trans i18nKey="Common.Cancel">Cancel</Trans>
          </Button>
          <Button
            className="rounded-pill btn-style"
            size="sm"
            color="standard-primary"

            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              setDescription(description.trim());
              props.mode === "edit" ? updateAssessment() : getquestions();
            }}
          >
            <Trans i18nKey="Common.Save">Save</Trans>
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default CreateAssessment;