import React, { useEffect, useState, useMemo } from "react";
import base from "../../Services/BaseService";
import "../../App.css";
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import { Download32 } from "@bphxd/ds-core-react/lib/icons";
import PrivacyContent from "./PrivacyContent";
import SuccessToastNew from "../Toast/SuccessToastNew";
import { Navigate } from "react-router-dom-v5-compat";
import { useTranslation } from "react-i18next";
import "../../Content/stylesheet/common.css";
import base64data from "./datapdf.json";
import Cookies from "universal-cookie";
import { Button, Label, Modal } from '@bphxd/ds-core-react';

function PrivacyStatement({ seterror, loginsucess, fetchUser, logOut }) {
  const { t } = useTranslation();
  const [modal, setmodal] = useState(true);
  const [modal1, setmodal1] = useState(false);
  const [privacyaccepted, setprivacyaccepted] = useState(true);
  const [success, setsuccess] = useState(false);
  const [err, seterr] = useState(false);

  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    base
      .getAll("Auth/Get")
      .then((res) => {
        if (res === "User not recognised" || res === "User not enabled") {
          seterr(true);
          seterror(true);
        } else if (res.privacy_Indicator === "N") {
          setprivacyaccepted(false);
        } else {
          cookies.set("rolelevel", res.userLevel);
          cookies.set("accessLevel", res.accessLevel);
          cookies.set("sitemanager", res.siteManager);
          loginsucess();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cookies, loginsucess, seterror]);

  function Logout() {
    // sessionStorage.removeItem("loggedin");
    cookies.remove("loggedin");
    cookies.set("loggedout", "yes");
    base.update("Auth/User/Logoff", "").then((res) => {
      closemodal(false)
      logOut()
    });
  }

  function closemodal(val) {
    setmodal(val);
  }

  function Successshow() {
    setsuccess(true);

    document.addEventListener("mousedown", DataSavingToastonClick);
  }

  function DataSavingToastonClick() {
    setsuccess(false);
  }

  function base64toPDF() {
    var data = base64data.text;
    var bufferArray = base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = "Privacy Statement";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    Successshow();
  }

  function base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function scrolltotop() {
    var myDiv = document.getElementById("scrolltop");
    myDiv.scrollTop = 0;
  }

  return (
    <div>
      {err && <Navigate to={"/contactus"} replace />}

      {privacyaccepted === false ? (
        <Modal isOpen={modal} className="modal-dialog-centered modal-70">
          <Modal.Header
            className="comm-header-bgPosition"
            onToggleOpen={() => setmodal1(true)}
          >
            <Label className="modalHeaderGlobal m-4">
              {t("Privacy.Privacy Statement")}
            </Label>
            <Download32
              className="download"
              onClick={() => {
                base64toPDF();
              }}
            ></Download32>
            <SuccessToastNew
              show={success}
              Message={t("Privacy.PrivacyDownloadSuccess")}
              form={true}

            />
          </Modal.Header>
          <Modal.Body
            id="scrolltop"
            style={{
              textAlign: "justify",
              padding: "1rem 2rem 4rem",
              maxHeight: "72vh",
              overflowY: "scroll",
            }}
          >
            <div className="bd-example-modal">
              <Modal
                container=".bd-example-modal"
                isOpen={modal1}
                size="sm"
                backdrop="static"
                // tabIndex="5"
                centered
              // className="modal-70"
              >
                <Modal.Body style={{ textAlign: "center" }}>
                  <p className="" >
                    Agreeing to the privacy statement is mandatory to access
                    CADOPS.
                  </p>
                  <p>
                    Are you sure you want to exit ?
                  </p>

                </Modal.Body>
                <Modal.Footer
                >
                  <Button
                    color="standard-secondary"
                    size="sm"
                    className="rounded-pill btn-style"
                    onClick={() => {
                      Logout();
                    }}
                  >
                    Exit
                  </Button>{" "}
                  <Button
                    color="standard-primary"
                    size="sm"
                    className="rounded-pill btn-style"
                    onClick={() => {
                      setmodal1(false);
                    }}
                  >
                    Stay
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            <PrivacyContent
              close={closemodal}
              mandatory={true}
              scrolltotop={scrolltotop}
              success={loginsucess}
              fetchUser={fetchUser}
            ></PrivacyContent>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default PrivacyStatement;
