import { Modal, ModalFooter, Button, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

const Infobox = (props) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    props.close();
  };

  return (


    <Modal
      id="cancel"
      size="md"
      isOpen={props.show}
      fade={false}
      backdrop="static"
      wrapClassName="cancel"
      centered

    >
      <ModalBody className="text-center"
        style={{ borderBottom: "1px solid #dedede" }}

      >
        <div className="">{props.message}</div>
      </ModalBody>
      <ModalFooter
        style={{ alignSelf: "center", borderTop: "none" }}
      >
        <Button
          color="standard-secondary"
          onClick={handleChange}
          className="rounded-pill btn-style"
          size="sm"
        >
          {t("assessment.Ok")}
        </Button>{" "}
      </ModalFooter>
    </Modal>



  );
};

export default Infobox;
