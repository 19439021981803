import React, { useState, useEffect } from "react";
import "./home.css";
import MyFavourites from "../Homescreen/MyFavourites";
import Support from "../Homescreen/Support"
import Newsfeed from "../Homescreen/Newsfeed"
import { Row, Col } from "reactstrap";
import base from "../../Services/BaseService";
import Favouritechart from "./favouritechart";
import "../../Content/stylesheet/common.css";
import { withRouter } from "react-router-dom";
import EntireDashboard from "../Homescreen/EntireDashboard"
import ComplianceData from "../Homescreen/ComplianceData"
import { useTranslation } from "react-i18next";
import TitleBanner from "../Shared/Layout/TitleBanner";

export const EmptyChartTextComponent = (params) => {
  const { t } = useTranslation();

  return (
    <div className="emptychart_text">
      <div className="emptychart_textheading">
        {t("Homepage.No records found")}
      </div>
    </div>
  );
};

function HomeScreen() {
  const { t } = useTranslation();
  const [favouritesData, setFavouritesData] = useState([
    {
      "chartLevel": "",
      "chartName": "",
      "reportType": "",
      "chartReport": "",
      "chartType": "",
      "chartPos": "",
      "rVal1": "",
      "rVal2": "",
      "rVal3": null,
      "rVal4": null,
      "rVal5": null,
      "rVal6": null,
      "rVal7": null,
      "rVal8": null,
      "rVal9": null,
      "dt_sDate": 44080.4514699,
      "dt_eDate": 45175.4514699,
      "dt_up_date": 45342.4091898,
      "up_user": "3318WP",
      "rel_rec": "1",
      "sysid": 1,
      "id": 1814,
      "fk_UserID": "3318WP"
    },
    {
      "chartLevel": "",
      "chartName": "",
      "reportType": "",
      "chartReport": "",
      "chartType": "",
      "chartPos": "",
      "rVal1": "",
      "rVal2": "",
      "rVal3": null,
      "rVal4": null,
      "rVal5": null,
      "rVal6": null,
      "rVal7": null,
      "rVal8": null,
      "rVal9": null,
      "dt_sDate": null,
      "dt_eDate": 42971.4190277,
      "dt_up_date": 45342.4082638,
      "up_user": "3318WP",
      "rel_rec": "1",
      "sysid": 1,
      "id": 1813,
      "fk_UserID": "3318WP"
    },
    {
      "chartLevel": "",
      "chartName": "",
      "reportType": "",
      "chartReport": "",
      "chartType": "",
      "chartPos": "",
      "rVal1": "",
      "rVal2": "",
      "rVal3": null,
      "rVal4": null,
      "rVal5": null,
      "rVal6": null,
      "rVal7": null,
      "rVal8": null,
      "rVal9": null,
      "dt_sDate": null,
      "dt_eDate": 45175.4592939,
      "dt_up_date": 45342.4045601,
      "up_user": "3318WP",
      "rel_rec": "1",
      "sysid": 1,
      "id": 1815,
      "fk_UserID": "3318WP"
    }
  ])
  const [dataTrigger, setDataTrigger] = useState("")
  const [favouriteChart, setFavouriteChart] = useState(0);
  const [refreshCharts, setRefreshCharts] = useState(0);
  const [updatechart, setupdatechart] = useState(false);
  const [modules, setModules] = useState([])

  useEffect(() => {
    base.get("dashboard/qry", "personal").then((res) => {
      setFavouritesData(res);
      var millisecond = new Date().getMilliseconds()
      setRefreshCharts(millisecond);
    });

  }, [dataTrigger, favouriteChart, setRefreshCharts]);

  useEffect(() => {
    base.get(`Task/qry/NULL/NULL/Y/NULL`, "assessment").then((res) => {
      setModules(res)
    });
  }, []);

  function postupdate() {
    setupdatechart(false);
  }

  return (
    <div>
      <TitleBanner
        heading={"CADOPS"}
        subheading={""}
        infoText={""}
        breadcrumbArray={[t("Common.Home"), t("Homepage.Dashboard")]}
        showButton1={false}
        showButton2={false}
        showButton3={false}
      />


      <div className="container-fluid homebg">
        <div>
          <Row className=" pt-2 pb-0 ps-4 pe-4 ms-1 me-1 mb-0">
            <Col xl="8" lg="12" xs="12">
              <Row className="" >
                <Col md="6" className="p-2">

                  <Favouritechart
                    res={ComplianceData[0]}
                    heading={ComplianceData[0].rVal2}
                    position={ComplianceData[0].chartPos}
                    updatechart={updatechart}
                    setDataTrigger={setDataTrigger}
                    postupdate={postupdate}
                    favourite={ComplianceData[0].favourite}
                    setFavouriteChart={setFavouriteChart}
                    favouriteChart={favouriteChart}
                    dataTrigger={dataTrigger}
                    modules={modules}
                  ></Favouritechart>
                </Col>
                {favouritesData[0] !== undefined &&
                  (
                    <Col md="6" className="p-2">
                      <Favouritechart
                        res={favouritesData[0]}
                        heading={favouritesData[0]?.rVal2}
                        position={1}
                        updatechart={updatechart}
                        postupdate={postupdate}
                        favourite={true}
                        setFavouriteChart={setFavouriteChart}
                        favouriteChart={favouriteChart}
                        refreshCharts={refreshCharts}
                        // fetchedData={fetchedData}
                        modules={modules}

                      ></Favouritechart>
                    </Col>
                  )
                }
                {favouritesData[1] !== undefined &&
                  (
                    <Col md="6" className="p-2 ">
                      <Favouritechart
                        res={favouritesData[1]}
                        heading={favouritesData[1]?.rVal2}
                        position={2}
                        updatechart={updatechart}
                        postupdate={postupdate}
                        favourite={true}
                        setFavouriteChart={setFavouriteChart}
                        favouriteChart={favouriteChart}
                        refreshCharts={refreshCharts}
                        modules={modules}

                      ></Favouritechart>
                    </Col>
                  )
                }
                {favouritesData[2] !== undefined &&
                  (
                    <Col md="6" className="p-2">
                      <Favouritechart
                        res={favouritesData[2]}
                        heading={favouritesData[2]?.rVal2}
                        position={3}
                        updatechart={updatechart}
                        postupdate={postupdate}
                        favourite={true}
                        setFavouriteChart={setFavouriteChart}
                        favouriteChart={favouriteChart}
                        refreshCharts={refreshCharts}
                        modules={modules}
                      ></Favouritechart>
                    </Col>
                  )
                }
              </Row>
            </Col>
            <Col xl="4" lg="12" md="12" xs="12" >
              <Row className="">
                <Col xl="12" md="6" xs="12" className="p-2">
                  <Newsfeed />
                </Col>
                <Col xxl="6" xl="12" md="6" xs="12" className="p-2">
                  <MyFavourites />
                </Col>
                <Col xxl="6" xl="12" md="6" xs="12" className="p-2">
                  <Support />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className=" pt-0 ps-4 pe-4 pb-4 ms-1 me-1 ">
            {EntireDashboard.map((dash, index) => {
              return (

                <Col xl="4" md="6" xs="12" className="p-2" key={index}>
                  <Favouritechart
                    res={dash}
                    heading={dash.rVal2}
                    position={dash.chartPos}
                    updatechart={updatechart}
                    setDataTrigger={setDataTrigger}
                    postupdate={postupdate}
                    favourite={dash.favourite}
                    setFavouriteChart={setFavouriteChart}
                    favouriteChart={favouriteChart}
                    dataTrigger={dataTrigger}
                    modules={modules}
                  ></Favouritechart>
                </Col>
              );
            })}

          </Row>
        </div>
      </div >
    </div >
  );
};

export default withRouter(HomeScreen);