import React, { useState, useEffect, useMemo, useCallback } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { Spinner } from "reactstrap";
import "../../../App.css";
import AppRoutes from "./AppRoutes";
import ContactUs from "../../ContactUs/ContactUs";
import PrivacyStatement from "../../Privacy Statement/PrivacyStatement";
import { Service } from "../../../Service";
import base from "../../../Services/BaseService";
import Maintenance from "../Maintenance";
import Error500 from "../Error500";

const MainSAML = (props) => {

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userEnabled, setUserEnabled] = useState(true);
  const [enterpriseID, setEnterpriseID] = useState("");
  const [loggedOut, setLoggedOut] = useState(false);
  const [maintenanceData, setMaintenanceData] = useState(false);
  const [error, setError] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState("");
  const cookies = useMemo(() => new Cookies(), []);
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const BASE_AUTH_URL = process.env.REACT_APP_BASE_AUTH_URL;

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      if (process.env.REACT_APP_LOCALTOKEN === 'true') {
        cookies.set("authheader", `Bearer ${process.env.REACT_APP_TOKEN}`);
      }
    }
  }, [cookies]);

  const handleUserNotEnabled = useCallback((res) => {
    setUserEnabled(false);
    setIsPrivacyAccepted("X")
    setUserData(null)
    setLoading(false);
    setEnterpriseID(res.userID);
  }, [])

  Service.getMessage().subscribe(async (res) => {
    if (res.text?.message === "User is not enabled") {
      handleUserNotEnabled(res.text);
    } else if (
      res.text.message === "unauth" ||
      res.text.message === "update user" ||
      res.text.message === "request timed out"
    ) {
      redirectToLogin();
    }
  });

  const redirectToLogin = useCallback(() => {
    const relayState =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : process.env.REACT_APP_REDIRECTURI;

    const loginURL = `${BASE_AUTH_URL}Auth/Login?returnurl=${relayState}`;
    window.location.href = loginURL;
  }, [BASE_AUTH_URL])

  const fetchUser = useCallback(() => {

    axios({
      method: "GET",
      url: `${BASE_API_URL}maintenance/status`,
    })
      .then((response) => {
        setMaintenanceData(response.data);
        if (response.data.isEnabled) {
          setLoading(false);
        }
        else {
          axios({
            method: "GET",
            url: `${BASE_API_URL}user/get`,
            withCredentials: true,
            headers: {
              Authorization: cookies.get("authheader"),
            },
          })
            .then((response) => {
              setUserData(response.data);
              setIsPrivacyAccepted(response.data.privacy_Indicator)
              cookies.set("email", response.data.userID);
              cookies.set("username", response.data.userDisplayName);
              setLoading(false);
            })
            .catch((error) => {
              if (error.response.status === 406) handleUserNotEnabled(error.response.data);
              else redirectToLogin();
            });
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);

      });

  }, [BASE_API_URL, cookies, handleUserNotEnabled, redirectToLogin])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token !== null) {
      cookies.set("authheader", `Bearer ${token}`); // use MSAL token (from dev) for other API calls


      urlParams.delete("token");
      const currentLocation = window.location;
      const updatedUrl = `${currentLocation.pathname}`;
      window.history.replaceState(null, "", updatedUrl);

      fetchUser();
    } else {
      if (cookies.get("authheader")) fetchUser();
      else redirectToLogin();
    }
  }, [cookies, fetchUser, redirectToLogin]);


  const logOut = () => {
    base
      .update("Auth/User/Logoff", "")
      .then((res) => {
        cookies.remove("authheader");
        setLoggedOut(true);
        setUserData(null);
        setUserEnabled(true);
      })
      .catch((error) => console.log("Failed to log out!"));
  }

  if (loading) {
    return (
      <div className="mainpage">
        <Spinner></Spinner>
      </div>
    );
  }

  if (loggedOut) {
    return (
      <div className="mainpage">
        <h2>You have logged out successfully</h2>
      </div>
    );
  }

  return (
    <>
      {!userEnabled && <ContactUs enterpriseID={enterpriseID} />}
      {isPrivacyAccepted === "N" && (
        <PrivacyStatement fetchUser={fetchUser} logOut={logOut} />
      )}
      {userData && isPrivacyAccepted === "Y" && (
        <AppRoutes userData={userData} logOut={logOut} />
      )}
      {maintenanceData.isEnabled && (
        <Maintenance maintenanceData={maintenanceData} />
      )
      }
      {error && (
        <Error500 />
      )
      }
    </>
  );
};

export default MainSAML;
