import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import "../../Content/stylesheet/common.css";
import "../../Content/stylesheet/tooltip.css";
import "../../Content/stylesheet/createquestion.css";
import { Label, Modal, Button } from '@bphxd/ds-core-react';

const LanguageSelectComponent = (props) => {
  const { t } = useTranslation();
  const { languages, currentLanguage } = props;

  const defaultLanguage = languages.find(
    (language) => language.languageID === "en-GB"
  );

  const selectedLanguage = currentLanguage ? currentLanguage : defaultLanguage;

  function toggleModal() {
    props.closed();
  }

  function updateLanguage(incomingLang) {
    if (selectedLanguage.languageID === incomingLang.languageID) return;

    props.updateCurrentLanguage(incomingLang);
    toggleModal();
  }

  const LanguageItem = ({ language }) => {
    const { languageID, language_Name } = language;

    return (
      <Col xs="12" lg="6" xl="4" className="pb-3">
        <Button
          size="md"
          color={
            selectedLanguage.languageID === languageID
              ? "standard-primary"
              : "standard-tertiary"
          }
          active={selectedLanguage.languageID === languageID ? true : false}
          onClick={() => updateLanguage(language)}
          className="rounded-pill w-100"
        >
          {language_Name}
        </Button>
      </Col>
    );
  };

  return (
    <>
      <Modal backdrop={"static"} isOpen={true} centered className="modal-50">
        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={() => toggleModal()}
        >
          <Label className="modalHeaderGlobal m-4">
            {t("Common.Select your language")}
          </Label>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {languages.map((language) => (
              <LanguageItem key={language.languageID} language={language} />
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LanguageSelectComponent;
