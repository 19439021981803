import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import base from "../../../Services/BaseService";
import ErrorToastNew from "../../Toast/ErrorToastNew";
import { useValidation } from "../../Shared/ValidateForm";
import { Row, Col } from "reactstrap";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import "../../../Content/stylesheet/createquestion.css";
import "../../../Content/stylesheet/tooltip.css";
import "../../../Content/stylesheet/Location.css"
import "../../../Content/stylesheet/createnewsfeed.css";
import "../../../Content/stylesheet/assignModuleToEmployee.css";
import { Input, Label, Modal, Button } from '@bphxd/ds-core-react';
import Select from "react-select";

const AssignSiteManagerPopUp = (props) => {

  const { t } = useTranslation();
  var feed = {
    fk_UserID: props.AssignSiteManagerFeed.fk_UserID,
    fk_LocationID: props.AssignSiteManagerFeed.fk_LocationID,
    assign_Indicator: "MANAGER",
    text01: "",
    text02: "",
    text03: "",
    text04: null,
    rel_rec: 1,
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "146px",
    }),
  };

  const AssignSiteManagerFeed = feed;
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [register] = useValidation();
  const [isChanged, setIsChanged] = useState(false);
  const [ddlReqByVal, setReqByVal] = useState(null);
  const [ddlReqBySelected, setReqBySelected] = useState(null);
  const [invalidRequestedBy, setInvalidRequestedBy] = useState(false)
  const [text03Remaining, settext03Remaining] = useState(
    AssignSiteManagerFeed.text03.length
  );
  const [reqConfirmChkerr, setreqConfirmChkerr] = useState(false);

  //Setting up of Requested By Drop Down
  const setDropdownData = () => {

    let reqBy = [];
    for (let i = 0; i < props.ReqByList.length; i++) {
      reqBy.push({
        value: props.ReqByList[i].pValue1,
        label: props.ReqByList[i].pValue1,
      });
    }
    setReqByVal(reqBy);
  };

  useEffect(() => {
    setDropdownData();
    // eslint-disable-next-line
  }, []);

  const handleReqByDDL = (params) => {
    setInvalidRequestedBy(false)
    setIsChanged(true);
    setReqBySelected(params.value);
  };

  const removeCheckboxRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.remove("redOutline");
      document.getElementById(id).classList.remove("redBorderRequired");
    }
  };

  const ADDCheckboxRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.add("redOutline");
      document.getElementById(id).classList.add("redBorderRequired");
    }
  };

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const handleDiscard = () => {
    props.getAllUser();
    props.close(false);
  };

  const handletext03 = (e) => {
    setIsChanged(true);
    if (e.target.name === "text03") {
      settext03Remaining(e.target.value.length);
    }
  };

  //Function invoked when user clicks on Save button
  const AssignManagerUser = () => {
    settext03Remaining(document.getElementById("text03").value.trim().length);

    var save = false;
    if (document.getElementById("confirmCopy").checked !== true) {
      save = false;
      setreqConfirmChkerr(true);
      ADDCheckboxRedBorderDynamically("confirmCopy");
    }
    if (
      ddlReqBySelected === null ||
      ddlReqBySelected === "null" ||
      ddlReqBySelected === "" ||
      ddlReqBySelected === undefined
    ) {
      setInvalidRequestedBy(true)
      save = false;
    }

    var confirm = document.getElementById("confirmCopy").checked
      ? "True"
      : "False";
    var assignManager = {
      fk_UserID: props.AssignSiteManagerFeed.fk_UserID,
      fk_LocationID: props.AssignSiteManagerFeed.fk_LocationID,
      assign_Indicator: "MANAGER",
      text01: ddlReqBySelected,
      text02: confirm,
      text03: document.getElementById("text03").value.trim(),
      text04: null,
      rel_rec: 1,
    };

    if (document.getElementById("confirmCopy").checked !== true) {
      save = false;
      setreqConfirmChkerr(true);
    } else {
      if (
        ddlReqBySelected !== null &&
        ddlReqBySelected !== "null" &&
        ddlReqBySelected !== "" &&
        ddlReqBySelected !== undefined
      )
        save = true;
    }

    //validate();
    if (save) {
      base
        .add(process.env.REACT_APP_ASSIGNMANAGER_ADD, assignManager)
        .then((response) => {
          props.getAllUser();
          props.saveSuccess();
          handleDiscard();
        })
        .catch((error) => {
          handleDiscard();
        });
    } else {
      if (
        ddlReqBySelected !== null &&
        ddlReqBySelected !== "null" &&
        ddlReqBySelected !== "" &&
        ddlReqBySelected !== undefined
      ) {
        setInvalidRequestedBy(false)
      }
      if (document.getElementById("confirmCopy").checked === true) {
        setreqConfirmChkerr(false);
        removeCheckboxRedBorderDynamically("confirmCopy");
      }
    }
  };

  const handleConfirmChk = (e) => {
    if (document.getElementById("confirmCopy").checked !== true) {
      setreqConfirmChkerr(true);
      ADDCheckboxRedBorderDynamically("confirmCopy");
    } else if (document.getElementById("confirmCopy").checked === true) {
      setreqConfirmChkerr(false);
      removeCheckboxRedBorderDynamically("confirmCopy");
    }
  };

  return (
    <>

      <Modal
        isOpen={props.isopen}
        size="md"
        centered
        className="modal-60"
      >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
          />
        )}

        <Modal.Header
          className="comm-header-bgPosition modalPopupBGColour "
          onToggleOpen={cancelHandler}

        >
          <div className="row">
            <div className="col ">
              <label className="modalHeaderGlobal ms-5">
                {t("Regionpage.Site Manager Assignment")}
              </label>
            </div>
          </div>

          {/* Toast messages */}
          {showToast.Name === "wrongDate" && (
            <ErrorToastNew
              show={false}
              Message={t("Regionpage.WrongDate")}
            />
          )}
          {/* Toast messages */}
        </Modal.Header>
        <Modal.Body style={{ padding: "1rem 2rem" }}>
          <Row>
            <Col lg={6} md={12} className="mt-2">
              <Label htmlFor="Hazard_Description">
                {t("Regionpage.Requested By")}
                <span className="text-danger">*</span>
              </Label>
              <br />
              <div className="">
                <div className="w-100">
                  <Select
                    id="ReqBy"
                    invalid={invalidRequestedBy}
                    placeholder={ddlReqBySelected}
                    value={ddlReqBySelected}
                    options={ddlReqByVal}
                    onChange={handleReqByDDL}
                    styles={customStyles}
                    classNamePrefix="react-select"
                  ></Select>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="" className="mt-3 mb-2" >
              <Input
                type="checkbox"
                id="confirmCopy"
                name="confirmCopy"
                onChange={handleConfirmChk}
              />

              <Label className="pt-3 ms-3"
                htmlFor="confirmCopy"
              >
                {t("Regionpage.Confirm")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
            <div className="">
              {reqConfirmChkerr ? (
                <span className=" assessmenterror">{t("ErrorMessages.Checked")}</span>
              ) : (
                ""
              )}
            </div>
          </Row>
          <Row className="mt-2">
            <Col xs="12">
              <Label className="">
                {t("Regionpage.Additional Details")}
              </Label>
              <Input
                type="textarea"
                id="text03"
                name="text03"
                maxLength={100}
                onChange={handletext03}
                {...register({
                  fieldName: "text03",
                })}

              ></Input>
            </Col>
            <Col className="text-end desclength" xs="12">
              {text03Remaining}/100
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <Button color="standard-secondary" onClick={cancelHandler} size="sm" className="rounded-pill btn-style">
              {t("Common.Cancel")}
            </Button>{" "}
            <Button
              className="ms-2 rounded-pill btn-style"
              color="standard-primary"
              size="sm"
              onClick={() => AssignManagerUser()}
            >
              {t("Common.Save")}
            </Button>{" "}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignSiteManagerPopUp;
