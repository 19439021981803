import { useState } from "react";

export const useValidation = () => {
  const fieldsToValidate = [];
  const [errors, setErrors] = useState({ isValid: true });
  var isValid = true;
  const register = (val) => {
    if (!fieldsToValidate.some((e) => e.fieldName === val.fieldName)) {
      fieldsToValidate.push(val);
    }
  };

  const validate = () => {
    const error = { isValid: true };
    fieldsToValidate.forEach((fieldToValidate) => {
      error[fieldToValidate.fieldName] = {};
      errors[fieldToValidate.fieldName] = {};
      errors[fieldToValidate.fieldName].isValid = true;
      error[fieldToValidate.fieldName].isValid = true;
      if (
        document.getElementById(fieldToValidate.fieldName) !== null &&
        document.getElementById(fieldToValidate.fieldName) !== undefined
      ) {
        document
          .getElementById(fieldToValidate.fieldName)
          .classList.remove("redBorder");
        document.getElementById(fieldToValidate.fieldName).style.border =
          "1px solid #ced4da";
      }

      if (
        fieldToValidate.required !== undefined &&
        fieldToValidate.required === true &&
        document.getElementById(fieldToValidate.fieldName) !== null &&
        document.getElementById(fieldToValidate.fieldName) !== undefined &&
        document.getElementById(fieldToValidate.fieldName).value === ""
      ) {
        error[fieldToValidate.fieldName].type = "required";
        error[fieldToValidate.fieldName].Message =
          "Oops! Looks like you haven't filled this field";
        // document.getElementById(fieldToValidate.fieldName).style.border =
        //   "1px solid red !Important";
        if (
          document.getElementById(fieldToValidate.fieldName) !== null &&
          document.getElementById(fieldToValidate.fieldName) !== undefined
        ) {
          document
            .getElementById(fieldToValidate.fieldName)
            .classList.add("redBorder");
        }

        errors[fieldToValidate.fieldName].isValid = false;
        error[fieldToValidate.fieldName].isValid = false;
      } else if (
        fieldToValidate.minLength !== undefined &&
        fieldToValidate.minLength > 0 &&
        document.getElementById(fieldToValidate.fieldName) !== null &&
        document.getElementById(fieldToValidate.fieldName) !== undefined &&
        document.getElementById(fieldToValidate.fieldName).value.length <
        fieldToValidate.minLength
      ) {
        error[fieldToValidate.fieldName].type = "minLenght";
        if (
          document.getElementById(fieldToValidate.fieldName) !== null &&
          document.getElementById(fieldToValidate.fieldName) !== undefined
        ) {
          document.getElementById(fieldToValidate.fieldName).style.border =
            "1px solid red";
        }
        errors[fieldToValidate.fieldName].isValid = false;
        error[fieldToValidate.fieldName].isValid = false;
      } else if (
        fieldToValidate.maxLength !== undefined &&
        fieldToValidate.maxLength > 0 &&
        document.getElementById(fieldToValidate.fieldName) !== null &&
        document.getElementById(fieldToValidate.fieldName) !== undefined &&
        document.getElementById(fieldToValidate.fieldName).value.length >
        fieldToValidate.maxLength
      ) {
        error[fieldToValidate.fieldName].type = "maxLength";
        error[fieldToValidate.fieldName].Message =
          "Oops! Looks like you have exceeded the limit. Max " +
          fieldToValidate.maxLength +
          " characters";
        if (
          document.getElementById(fieldToValidate.fieldName) !== null &&
          document.getElementById(fieldToValidate.fieldName) !== undefined
        ) {
          document.getElementById(fieldToValidate.fieldName).style.border =
            "1px solid red";
        }
        errors[fieldToValidate.fieldName].isValid = false;
        error[fieldToValidate.fieldName].isValid = false;
      }
    });
    setErrors(error);
  };
  return [register, validate, errors, isValid];
};
