import React, { useEffect, useState, useRef } from 'react';
import '../../../Content/stylesheet/record-results.css'
import base from "../../../Services/BaseService";
import { Label, Table, Button } from 'reactstrap';
import { Modal } from '@bphxd/ds-core-react';
import { Trans, useTranslation } from "react-i18next";
import ConfirmToast from "../../Toast/ConfirmrtrToast";
import DismissToast from "../../Toast/DismissToast";
import TableHeaders from './tableHeaders'
import AssessmentPart1 from './assessmentPart1';
import AssessmentPart2 from './assessmentPart2';
import _ from 'lodash';
import { useUser } from '../../Shared/userContext';

const MatrixModal = (props) => {
    const { assessmentID, assessment_Type, isMCQ, taskID, task_Number, task_Name, task_Critical } = props.assessmentNode;
    const isDATPart2 = taskID === "GBL10057" ? true : false;
    const isNonAssessed = assessment_Type === "NON" ? true : false;
    const isEXT = assessment_Type === "EXT" ? true : false;
    const offlineTAR = ['L1', 'L2', 'L3']
    const offlineDAT1 = ['Yes', 'No']
    const offlineDAT2 = ['L1', 'L2', 'L3', 'NATO']
    const [showPartialSave, setShowPartialSave] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [assessment, setAssessment] = useState([]);
    const [showDismissConfirmation, setShowDismissConfirmation] = useState(false);
    const [employeeData, setEmployeeData] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [showResetConfirm, setShowResetConfirm] = useState(false);
    const [showNATOFail, setShowNATOFail] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [stepData, setStepData] = useState([]);
    const { t } = useTranslation();
    const [invalidDates, setInvalidDates] = useState([]);
    const [invalidQuestions, setInvalidQuestions] = useState([]);
    const [invalidConfirm, setInvalidConfirm] = useState([]);
    const [invalidActivity, setInvalidActivity] = useState([]);
    const [clearCalled, setClearCalled] = useState("");
    const [formChanged, setFormChanged] = useState(false);
    const [saveDisbaled, setSaveDisbaled] = useState(true);
    const invalidDatesRef = useRef([]);
    const invalidQuestionRef = useRef([]);
    const invalidConfirmRef = useRef([]);
    const invalidActivityRef = useRef([]);
    const invalidNATORef = useRef([]);
    const commitAssessments = useRef([]);
    const part1Ref = useRef([]);
    const part2Ref = useRef([]);
    const dateRef = useRef([]);
    const assessmentRef = useRef([]);
    const commitEmployees = useRef([]);
    const scrollRef = useRef(null);
    const { languagePreference } = useUser();

    const actionClear = () => {
        let millisecond = new Date().getMilliseconds();
        setInvalidDates([]);
        setInvalidQuestions([]);
        setInvalidConfirm([]);
        setInvalidActivity([]);
        setClearCalled(millisecond);
        setFormChanged(false);
        setSaveDisbaled(true);
    }


    const handleDateUpdate = (updatedValue) => {
        dateRef.current = updatedValue;
    };
    const handlePart1Update = (updatedValue) => {
        part1Ref.current = updatedValue;
    };
    const handlePart2Update = (updatedValue) => {
        part2Ref.current = updatedValue;
    };

    useEffect(() => {
        const promises = [
            base.getAll(`assessmentemployee/QRY1/${assessmentID}`),
            base.getAll(`assessmentdata/Qry/${assessmentID}/question/${languagePreference}`),
            base.getAll(`assessmentdata/Qry/${assessmentID}/step/${languagePreference}`)
        ];
        Promise.all(promises)
            .then(([employeeRes, questionRes, stepRes]) => {
                setEmployeeData(employeeRes);
                if (isEXT) {
                    const questionObject = {
                        questionID: "NA",
                        fk_ID: "NA",
                        question_Description: "Overall competency score",
                        answer: null,
                        isCriticalQuestion: false,
                        employeeResult: ""
                    };
                    questionRes.push(questionObject);
                }
                setQuestionData(questionRes);
                setStepData(stepRes);
                setShowModal(true);
                const resultArray = [];
                const employeeQuestions = [];
                const employeeActivities = [];
                questionRes.forEach((question) => {
                    const questionObject = {
                        questionID: question.fk_ID,
                        answer: question.answer,
                        isCriticalQuestion: question.isCriticalQuestion,
                        ssrt: question.ssrt,
                        employeeResult: ""
                    };
                    employeeQuestions.push(questionObject);
                });
                if (isDATPart2) {
                    stepRes.forEach((step) => {
                        if (step.step_Level !== '1') {
                            const activity = {
                                stepID: step.fk_ID,
                                employeeResult: ""
                            };
                            employeeActivities.push(activity);
                        }
                    });
                }
                employeeRes.forEach((employee) => {
                    var onlineResult = ""
                    if (employee.questionResult === undefined) {
                        onlineResult = [];
                    } else {
                        onlineResult = employee.questionResult;
                    }
                    var resultObject = {
                        fk_EmployeeID: employee.fk_EmployeeID,
                        emp_DisplayName: employee.emp_DisplayName,
                        fk_LocationID: employee.fk_LocationID,
                        isPart1Completed: employee.isPart1Completed,
                        part1Score: employee.part1Score,
                        pStart: employee.pStart,
                        questionResult: onlineResult,
                        isOnlineEmployee: employee.isOnlineEmployee
                    };
                    resultObject.assessmentResults =
                    {
                        questions: employeeQuestions,
                        activities: employeeActivities,
                    };
                    resultArray.push(resultObject);
                });
                const assessmentObject = {
                    assessment: props.assessmentNode,
                    results: resultArray
                }
                assessmentRef.current = assessmentObject;
                setAssessment(assessmentObject)
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, [assessmentID, isDATPart2, isEXT, props.assessmentNode, languagePreference]);

    const validateForm = () => {
        var validEmployees = [];
        var invalidD = [];
        var invalidQ = [];
        var invalidC = [];
        var invalid2 = [];
        var invalidN = [];
        assessmentRef.current.results.forEach((employee) => {
            var valid = true;
            if (!employee.isPart1Completed && employee.pStart === null) {
                valid = false;
                invalidD.push(`${employee.fk_EmployeeID}_datecontrol`);
            }
            if (!employee.isPart1Completed && !isNonAssessed) {
                employee.assessmentResults.questions.forEach((question) => {
                    if (question.employeeResult === "") {
                        valid = false;
                        invalidQ.push(`${employee.fk_EmployeeID}_${question.questionID}`);
                    }
                });
            }
            if (isDATPart2) {
                employee.assessmentResults.activities.forEach((step) => {
                    if (step.employeeResult === "") {
                        valid = false;
                        invalid2.push(`${employee.fk_EmployeeID}_${step.stepID}`);
                    }
                });
                if (valid) {
                    let resultNATO = employee.assessmentResults.activities.filter(d => d.employeeResult === "NATO");
                    let totalSteps = employee.assessmentResults.activities.length;
                    if (resultNATO.length / totalSteps > 0.50) {
                        valid = false;
                        invalidN.push(`${employee.emp_DisplayName}`);
                    }
                }
            }

            if (!employee.confirmEntries && assessment_Type === "TAR") {
                valid = false;
                invalidC.push(`${employee.fk_EmployeeID}_confirm`);
            }
            if (valid) {
                validEmployees.push({ "fk_EmployeeID": employee.fk_EmployeeID });
            }
        });
        invalidDatesRef.current = invalidD;
        invalidQuestionRef.current = invalidQ;
        invalidConfirmRef.current = invalidC;
        invalidActivityRef.current = invalid2;
        invalidNATORef.current = invalidN;
        return validEmployees;
    }

    const commitSave = () => {
        base.add("result/record", commitAssessments.current).then(() => {
            base.update("result/upd/competencyScore", commitEmployees.current)
            props.handleClose();
            setShowModal(false);
        })
    }

    const mergeObject = () => {
        const mergeObject = _.cloneDeep(assessmentRef.current);
        mergeObject.results.forEach((employee, index) => {
            const { fk_EmployeeID } = employee;
            const p1Employee = part1Ref.current.results.find((emp) => emp.fk_EmployeeID === fk_EmployeeID);
            const p2Employee = part2Ref.current.results.find((emp) => emp.fk_EmployeeID === fk_EmployeeID);
            const dEmployee = dateRef.current.results.find((emp) => emp.fk_EmployeeID === fk_EmployeeID);
            if (p1Employee) {
                mergeObject.results[index].assessmentResults.questions = p1Employee.assessmentResults.questions;
            }
            if (p2Employee) {
                mergeObject.results[index].assessmentResults.activities = p2Employee.assessmentResults.activities;
                mergeObject.results[index].confirmEntries = p2Employee.confirmEntries;
            }
            if (dEmployee) {
                mergeObject.results[index].pStart = dEmployee.pStart;
            }
        });

        assessmentRef.current = mergeObject;
    };


    const handleSave = () => {
        setSaveDisbaled(true);
        mergeObject();
        const completeEmployees = validateForm();
        commitEmployees.current = completeEmployees;
        if (invalidNATORef.current.length === 0) {
            const validAssessments = {
                ...assessmentRef.current,
                results: assessmentRef.current.results?.filter(result =>
                    completeEmployees.some(employee => employee.fk_EmployeeID === result.fk_EmployeeID)
                ) || [],
            };
            if (validAssessments.results.length > 0) {
                if (assessment.results.length === completeEmployees.length) {
                    props.setIsLoading(true);
                    commitAssessments.current = validAssessments;
                    commitSave();
                } else {
                    commitAssessments.current = validAssessments;
                    setShowPartialSave(true);
                }
            } else {
                if (invalidDatesRef.current.length === 0
                    && invalidQuestionRef.current.length === 0
                    && invalidActivityRef.current.length === 0
                    && invalidConfirmRef.current.length !== 0) {
                    if (scrollRef.current) {
                        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                    }
                }
                setInvalidDates(invalidDatesRef.current);
                setInvalidQuestions(invalidQuestionRef.current)
                setInvalidConfirm(invalidConfirmRef.current);
                setInvalidActivity(invalidActivityRef.current);
                setSaveDisbaled(false);
            }
        } else {
            let message = "";
            invalidNATORef.current.forEach((employee, index) => {
                if (index !== 0) {
                    message += ', '
                }
                message += employee
            })
            message += ':\n\n' + t("assessment.DAT2infoMassage") + '\n\n' + t("assessment.DAT2infoMassage1");
            setToastMessage(message);
            setShowNATOFail(true);
        }
    }

    const handleCancel = () => {
        if (formChanged) {
            setShowDismissConfirmation(true);
        } else {
            props.setShowNewModal(false);
        }

    }
    return (
        <>
            <Modal isOpen={showModal} className="modal-90">
                <Modal.Header
                    className="comm-header-bgPosition"
                    onToggleOpen={() => { handleCancel() }}
                >
                    <Label className="modalHeaderGlobal m-4">
                        {task_Number}&nbsp;&nbsp;&nbsp;{task_Name}
                    </Label>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div style={{ overflowX: 'auto', overflowY: 'hidden' }} >
                        <div style={{ overflow: 'scroll' }} >
                            <div id="wrapper" ref={scrollRef} >
                                <Table className="matrix-table">
                                    <thead>
                                        <tr>
                                            <TableHeaders
                                                invalidDates={invalidDates}
                                                assessment={assessment}
                                                clearCalled={clearCalled}
                                                onUpdate={handleDateUpdate}
                                                employeeData={employeeData}
                                                setFormChanged={setFormChanged}
                                                setSaveDisbaled={setSaveDisbaled}
                                                formChanged={formChanged}
                                                setShowResetConfirm={setShowResetConfirm}
                                                task_Critical={task_Critical}
                                                setInvalidDates={setInvalidDates}
                                            />
                                        </tr>
                                    </thead>
                                    <tbody key={"table_body"}>
                                        <AssessmentPart1
                                            employeeData={employeeData}
                                            questionData={questionData}
                                            assessment_Type={assessment_Type}
                                            offlineDAT1={offlineDAT1}
                                            offlineTAR={offlineTAR}
                                            isDATPart2={isDATPart2}
                                            invalidQuestions={invalidQuestions}
                                            isMCQ={isMCQ}
                                            isNonAssessed={isNonAssessed}
                                            isEXT={isEXT}
                                            assessment={assessment}
                                            setInvalidQuestions={setInvalidQuestions}
                                            invalidActivity={invalidActivity}
                                            setInvalidActivity={setInvalidActivity}
                                            assessmentID={assessmentID}
                                            clearCalled={clearCalled}
                                            onUpdate={handlePart1Update}
                                            setFormChanged={setFormChanged}
                                            setSaveDisbaled={setSaveDisbaled}
                                            task_Number={task_Number}
                                            taskID={taskID}

                                        />
                                        {!isDATPart2 && (
                                            <tr className="border-0 p-0">
                                                <td className="border-0  p-0">&nbsp;</td>
                                            </tr>
                                        )}
                                        <AssessmentPart2
                                            employeeData={employeeData}
                                            questionData={questionData}
                                            assessment_Type={assessment_Type}
                                            offlineDAT1={offlineDAT1}
                                            offlineTAR={offlineTAR}
                                            isDATPart2={isDATPart2}
                                            invalidQuestions={invalidQuestions}
                                            isMCQ={isMCQ}
                                            isNonAssessed={isNonAssessed}
                                            isEXT={isEXT}
                                            assessment={assessment}
                                            setInvalidQuestions={setInvalidQuestions}
                                            invalidActivity={invalidActivity}
                                            setInvalidActivity={setInvalidActivity}
                                            assessmentID={assessmentID}
                                            stepData={stepData}
                                            offlineDAT2={offlineDAT2}
                                            invalidConfirm={invalidConfirm}
                                            setInvalidConfirm={setInvalidConfirm}
                                            clearCalled={clearCalled}
                                            onUpdate={handlePart2Update}
                                            setFormChanged={setFormChanged}
                                            setSaveDisbaled={setSaveDisbaled}


                                        />
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        size="sm"
                        color="standard-secondary"
                        className="rounded-pill btn-style"
                        onClick={() => handleCancel()}
                    >
                        <Trans i18nKey="Common.Cancel">Cancel</Trans>
                    </Button>
                    <Button
                        size="sm"
                        color="standard-primary"
                        className="rounded-pill btn-style"
                        onClick={() => handleSave()}
                        disabled={saveDisbaled}
                    >
                        <Trans i18nKey="Common.Save">Save</Trans>
                    </Button>

                </Modal.Footer>
            </Modal >
            <ConfirmToast
                show={showPartialSave}
                Message={t("ErrorMessages.SkipIncomplete")}
                handleCancel={() => {
                    setInvalidDates(invalidDatesRef.current);
                    setInvalidQuestions(invalidQuestionRef.current)
                    setInvalidConfirm(invalidConfirmRef.current);
                    setInvalidActivity(invalidActivityRef.current);
                    setShowPartialSave(false);
                    setSaveDisbaled(false);
                }}
                handleDelete={() => {
                    props.setIsLoading(true);
                    setShowPartialSave(false);
                    commitSave();
                }}
            />
            <ConfirmToast
                show={showDismissConfirmation}
                Message={t("ErrorMessages.CancelSure")}
                handleCancel={() => {
                    setShowDismissConfirmation(false);
                }}
                handleDelete={() => {
                    setShowDismissConfirmation(false);
                    props.setShowNewModal(false);
                }}
            />
            <ConfirmToast
                show={showResetConfirm}
                Message={"Are you sure you would like to reset all fields on the form?"}
                handleCancel={() => {
                    setShowResetConfirm(false);
                }}
                handleDelete={() => {
                    setShowResetConfirm(false);
                    actionClear();
                }}
            />
            <DismissToast
                show={showNATOFail}
                Message={toastMessage}
                handleCancel={() => {
                    setShowNATOFail(false);
                }}
            />
        </>
    );
}
export default MatrixModal;