import { Routes, Route } from "react-router-dom-v5-compat";
import TrainingsLandingPage from "./TrainingsLandingPage";
import TraineeAssessment from "./TraineeAssessmentLandingPage";
// import LoadingView from "../Shared/Layout/LoadingView";
import { useMyTrainingContext } from '../Shared/myTrainingContext'

const MyTraining = () => {
  const { onlineAssessments } = useMyTrainingContext();
  return (
    <>
      {/* {isLoading && <LoadingView />} */}
      <Routes>
        <Route
          index
          element={<TrainingsLandingPage onlineAssessments={onlineAssessments} />}
        />
        <Route
          path=":assessmentID"
          element={<TraineeAssessment onlineAssessments={onlineAssessments} />}
        />
      </Routes>
    </>
  );
}

export default MyTraining;
