import { useLocation } from "react-router-dom-v5-compat";
import { useUser } from "../Components/Shared/userContext";

const useAuthorize = () => {
  const { userData } = useUser();
  const { userLevel: rolelevel, siteManager: isSiteManager } = userData;
  const location = useLocation();
  const currentPathLowerCase = location.pathname.toLowerCase();

  if (rolelevel === "10" || rolelevel === "15" || rolelevel === "20") {
    //10 - SysAdmin //15 - Global admin //20 - Global user

    if (isSiteManager === "Y" && currentPathLowerCase === "/manageassessment") { return true; }
    else if (currentPathLowerCase === "/manageassessment") { return false; }

    if (currentPathLowerCase === "/managenewsfeed" && rolelevel === "20") { return false; }

    return true;
  } else if (rolelevel === "30" || rolelevel === "40") {
    //30 - PU Admin //40 - User

    if (currentPathLowerCase === "/managenewsfeed") { return false; }

    return true;

  } else { return false; }
};

export default useAuthorize;
