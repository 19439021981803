import React, { useState, useEffect } from 'react';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { Check32, Down32, DownloadBasic32, Remove32, SendBasic32, Up32 } from '@bphxd/ds-core-react/lib/icons';
import base from "../../../Services/BaseService";
import fileDownload from "js-file-download";
import _ from 'lodash';

const AssessmentPart1 = (props) => {
    const { clearCalled, setFormChanged, task_Number, taskID, setSaveDisbaled, onUpdate, employeeData, questionData, assessment_Type, offlineDAT1, offlineTAR, isDATPart2, invalidQuestions, isMCQ, isNonAssessed, isEXT, assessment, setInvalidQuestions, assessmentID, invalidActivity, setInvalidActivity } = props
    const [hidePart1, setHidePart1] = useState(false);
    const [assessmentCopy, setAssessmentCopy] = useState(assessment);
    function handleDownloadScoreBreakdown(employeeID) {
        let scoreBreakdownEndpoint = `Report_ScoreBreakdown/qry_TARpart1/${employeeID}/${assessmentID}`;
        base.getReport(scoreBreakdownEndpoint).then(({ responseData, fileName }) => {
            fileDownload(responseData, fileName);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        if (invalidQuestions.length !== 0) {
            setHidePart1(false);
        }
    }, [invalidQuestions]);

    useEffect(() => {
        const updatedAssessment = assessmentCopy;
        onUpdate(updatedAssessment);
    }, [onUpdate, assessmentCopy]);

    const togglePart1 = () => {
        setHidePart1(!hidePart1)
    }

    useEffect(() => {
        setAssessmentCopy(assessment);
    }, [clearCalled, assessment]);

    const handleCheckboxClick = (key) => {
        setFormChanged(true);
        setSaveDisbaled(false);
        const keyArray = key.split('_');
        const updatedAssessment = _.cloneDeep(assessmentCopy);
        const employeeIndex = updatedAssessment.results.findIndex((employee) => employee.fk_EmployeeID === keyArray[0]);
        if (isDATPart2) {
            const stepKey = `${keyArray[0]}_${keyArray[1]}`
            const isInvalid = invalidActivity.includes(stepKey);
            if (isInvalid) {
                setInvalidActivity((prev) => {
                    return prev.filter((d) => d !== stepKey);
                });
            }
            const stepIndex = updatedAssessment.results[employeeIndex].assessmentResults.activities.findIndex(
                (step) => step.stepID === keyArray[1]
            );
            const isChecked = document.getElementById(key).checked;
            let newResponse = "";

            if (isChecked) {
                newResponse = keyArray[2];
            }
            if (employeeIndex !== -1 && stepIndex !== -1) {
                updatedAssessment.results[employeeIndex].assessmentResults.activities[stepIndex].employeeResult = newResponse;
            }
        } else {
            const questionKey = `${keyArray[0]}_${keyArray[1]}`
            const isInvalid = invalidQuestions.includes(questionKey);
            const itemChecked = document.getElementById(key).checked;
            if (isInvalid) {
                setInvalidQuestions((prev) => {
                    return prev.filter((d) => d !== questionKey);
                });
            }
            const questionIndex = updatedAssessment.results[employeeIndex].assessmentResults.questions.findIndex(
                (question) => question.questionID === keyArray[1]
            );
            var newResponse = "";
            var prevResponse = "";
            if (isMCQ === 1) {
                prevResponse = updatedAssessment.results[employeeIndex].assessmentResults.questions[questionIndex].employeeResult;
            }
            if (itemChecked) {
                newResponse = keyArray[2];
                if (prevResponse !== "") {
                    const originalArray = prevResponse.split(';');
                    originalArray.push(newResponse);
                    const sortedArray = originalArray.sort();
                    const sortedString = sortedArray.join(';');
                    newResponse = sortedString;
                }
            } else {
                newResponse = prevResponse
                    .split(';')
                    .filter(item => item !== keyArray[2])
                    .join(';');
            }
            if (employeeIndex !== -1 && questionIndex !== -1) {
                const assessmentResultsCopy = JSON.parse(JSON.stringify(updatedAssessment.results[employeeIndex].assessmentResults));
                assessmentResultsCopy.questions[questionIndex].employeeResult = newResponse;
                updatedAssessment.results[employeeIndex].assessmentResults = assessmentResultsCopy;
            }
        }
        setAssessmentCopy(updatedAssessment);
    };

    function handleSendEmail(employeeID) {
        var taskNumber = encodeURIComponent(task_Number);
        base.get("Email/SendEmailPart1", `${employeeID}/${assessment_Type}/${taskNumber}/${false}/${taskID}/${assessmentID}`)
    }

    return (
        <>
            {(!isDATPart2 && !isNonAssessed) && (
                <tr className="custom-heading-height p-2" >
                    <td onClick={() => togglePart1()} className="text-start col-heading  p-2" style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white', paddingRight: '17px' }}>
                        <Row className="header-content">
                            <Col xs="9">
                                {assessment_Type === "TAR" && "Part 1 - "}Assessment
                            </Col>
                            <Col xs="3" className="text-end">
                                {!hidePart1 ?
                                    <Up32 />
                                    :
                                    <Down32 />
                                }
                            </Col>
                        </Row>
                    </td>
                    {employeeData.map((rowData, rowIndex) => (

                        <td className="colspan-border  p-2" key={rowIndex} >
                            {rowData.isOnlineEmployee && !rowData.isPart1Completed && isMCQ === 1 && (
                                <SendBasic32 style={{ cursor: "pointer" }} onClick={() => handleSendEmail(rowData.fk_EmployeeID)} />
                            )
                            }
                            {rowData.isPart1Completed && (
                                <DownloadBasic32 style={{ cursor: "pointer" }} onClick={() => handleDownloadScoreBreakdown(rowData.fk_EmployeeID)} />
                            )
                            }


                        </td>
                    ))}

                </tr>
            )}
            {questionData.map((rowData, rowIndex) => {
                return (
                    <>
                        {!hidePart1 && (
                            <tr key={`tr_${rowIndex}`} className="custom-row-height">
                                <td className="border-question-row p-2" style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white', paddingRight: '17px' }}>
                                    <div className="d-flex" style={{ fontSize: "14px" }}>
                                        <div className="d-flex" style={{ fontSize: "14px" }}>
                                            {!isEXT && (
                                                <div style={{ flex: "0 0 50px" }} className={`text-start ${rowData.isCriticalQuestion && "critical-step"}`}>
                                                    Q{rowIndex + 1}.
                                                </div>
                                            )}
                                            <div style={{ flex: "0 0 1" }} className={`cell-content text-start ${rowData.isCriticalQuestion && "critical-step"}`}>
                                                {rowData.question_Description}
                                            </div>

                                        </div>
                                    </div>
                                </td>
                                {employeeData.map((employee, employeeIndex) => (
                                    employee.isPart1Completed ? (
                                        <td className="border-question p-2 text-center" key={employeeIndex}>
                                            {employee.questionResult[rowIndex].employeeResult === employee.questionResult[rowIndex].modelAnswer ?
                                                <Check32 />
                                                :
                                                <Remove32 />
                                            }
                                        </td>
                                    ) : (
                                        <td className="border-question p-2" key={employeeIndex}>
                                            {(() => {
                                                const checkboxes = [];
                                                const assessmentVersion = `${assessment_Type}${isMCQ}`
                                                let employeeInfo = assessmentCopy.results.filter(d => d.fk_EmployeeID === employee.fk_EmployeeID);
                                                let questionInfo = employeeInfo[0].assessmentResults.questions.filter(d => d.questionID === rowData.fk_ID);
                                                switch (assessmentVersion) {
                                                    case "TAR1":
                                                    case "CAS1":
                                                        const opts = parseInt(rowData.opts, 10);
                                                        for (let innerIndex = 0; innerIndex < opts; innerIndex++) {
                                                            const labelValue = String.fromCharCode(65 + innerIndex);
                                                            const part1Key = `${employee.fk_EmployeeID}_${rowData.fk_ID}_${labelValue}`
                                                            checkboxes.push(
                                                                <div key={part1Key} className="d-inline-block pe-2">
                                                                    <div className="text-center w-100">
                                                                        <Label className="ms-3 pe-3 option-label" for={part1Key} >
                                                                            {labelValue}
                                                                        </Label>
                                                                    </div>
                                                                    <div>
                                                                        <Input className="mt-n1" id={part1Key} type="checkbox" name={part1Key}
                                                                            onChange={() => handleCheckboxClick(part1Key)}
                                                                            invalid={invalidQuestions.includes(`${employee.fk_EmployeeID}_${rowData.fk_ID}`)}
                                                                            checked={questionInfo[0].employeeResult.split(';').includes(labelValue)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );

                                                        }
                                                        return checkboxes;
                                                    case "TAR0":
                                                    case "EXT0":
                                                        for (let i = 0; i < offlineTAR.length; i++) {
                                                            const labelValue = offlineTAR[i];
                                                            const part1Key = `${employee.fk_EmployeeID}_${rowData.fk_ID}_${labelValue}`
                                                            checkboxes.push(
                                                                <div key={part1Key} className="d-inline-block pe-2">
                                                                    <div className="text-center w-100">
                                                                        <Label className="ms-3 pe-3 option-label" for={part1Key}>
                                                                            {labelValue}
                                                                        </Label>
                                                                    </div>
                                                                    <div>
                                                                        <Input className="mt-n1" id={part1Key} name={`${employee.fk_EmployeeID}_${rowData.fk_ID}`} type="radio"
                                                                            onChange={() => handleCheckboxClick(part1Key)}
                                                                            invalid={invalidQuestions.includes(`${employee.fk_EmployeeID}_${rowData.fk_ID}`)}
                                                                            checked={questionInfo[0].employeeResult.split(';').includes(labelValue)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return (
                                                            <FormGroup>
                                                                {checkboxes}
                                                            </FormGroup>
                                                        );
                                                    case "DAT0":
                                                        for (let i = 0; i < offlineDAT1.length; i++) {
                                                            const labelValue = offlineDAT1[i];
                                                            const part1Key = `${employee.fk_EmployeeID}_${rowData.fk_ID}_${labelValue}`
                                                            checkboxes.push(

                                                                <div key={part1Key} className="d-inline-block pe-2">
                                                                    <div className="text-center w-100">
                                                                        <Label className="ms-3 pe-3 option-label" for={part1Key}>
                                                                            {labelValue}
                                                                        </Label>
                                                                    </div>
                                                                    <div>
                                                                        <Input className="mt-n1" id={part1Key} name={`${employee.fk_EmployeeID}_${rowData.fk_ID}`} type="radio"
                                                                            onChange={() => handleCheckboxClick(part1Key)}
                                                                            invalid={invalidQuestions.includes(`${employee.fk_EmployeeID}_${rowData.fk_ID}`)}
                                                                            checked={questionInfo[0].employeeResult.split(';').includes(labelValue)}

                                                                        />

                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return (
                                                            <FormGroup>
                                                                {checkboxes}
                                                            </FormGroup>
                                                        );
                                                    default:
                                                        break;
                                                }
                                            })()}
                                        </td>
                                    )
                                ))}
                            </tr>
                        )}
                        {assessment_Type === "TAR" &&

                            rowIndex === questionData.length - 1 && (
                                <tr key={rowIndex + 1} className="custom-heading-height">
                                    <td className="border-question-row col-subheading text-end p-2" style={{ position: 'sticky', left: 0, zIndex: 1, backgroundColor: 'white', paddingRight: '17px' }}>
                                        Part 1 score
                                    </td>
                                    {employeeData.map((rowData, rowIndex) => (
                                        <>
                                            {rowData.isPart1Completed ? (
                                                <td className="border-question p-2" key={rowIndex}>
                                                    <div className="competency-score">
                                                        {rowData.part1Score}
                                                    </div>
                                                </td>
                                            ) : (
                                                <td className="border-question p-2" key={rowIndex}>
                                                    <div className="not-assessed">
                                                        Not assessed
                                                    </div>
                                                </td>
                                            )}
                                        </>
                                    ))}
                                </tr>
                            )

                        }
                    </>
                );
            })}

        </>
    );
}

export default AssessmentPart1;
