//import "bootstrap/dist/css/bootstrap.css";
import "../../Content/stylesheet/toast.css";
import { Button, Modal, ModalFooter, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
const ConfirmDeleteToast = (props) => {
  const { t } = useTranslation();
  return (
    <>
      {props.show && (
        <div>
          <Modal
            wrapClassName="deleteToast"
            // className={customClassName}
            centered
            backdrop="static"
            fade={false}
            isOpen={props.show}
            size="md"
            keyboard={false}
          >
            <ModalBody
              className="text-center popup-text"
              style={{ borderBottom: "1px solid #68686740" }}
            >
              {props.Message}
            </ModalBody>

            <ModalFooter style={{ alignSelf: "center", borderTop: "none" }}>
              {/* {' '} */}
              <Button
                onClick={props.handleCancel}
                className="rounded-pill btn-style"
                //className="toast-popup-yesbutton-dim"
                color="standard-secondary"
                value="Cancel"
                id="cancel"
                size="sm"
              >
                {t("assessment.No")}
              </Button>
              <Button
                onClick={props.handleDelete}
                //className="toast-popup-nobutton-dim"
                // style={{ width: "68px" }}
                className="rounded-pill btn-style"
                color="standard-primary"
                value="Delete"
                id="delete"
                size="sm"

              >
                {t("assessment.Yes")}
              </Button>
            </ModalFooter>
          </Modal>

          {/* </div>
            </div> */}
        </div>
      )}
    </>
  );
};

export default ConfirmDeleteToast;
