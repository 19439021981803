import { useRef, useEffect } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom-v5-compat";
import base from "../../Services/BaseService";
import { Col, ListGroup, ListGroupItem } from "reactstrap";
import { Favourite24 } from "@bphxd/ds-core-react/lib/icons";

const MyFavourites = () => {
    const endIndex = 3;
    const navigate = useNavigate();
    const favouriteReports = useRef([]);

    useEffect(() => {
        base.get("reportfavorite", "qry").then((res) => {
            favouriteReports.current = res;
        });
    }, []);

    function RedirectofavReports(reportid) {
        if (reportid.length > 0) {
            navigate("/MyFavouriteReports?reportid=" + encodeURIComponent(reportid));
        } else {
            navigate("/MyFavouriteReports");
        }
    }

    return (
        <>
            <Col xs="12">
                <div className="chartborder " style={{ minHeight: "362px" }}>
                    <div className="listgroup-bt p-3">
                        <p className="mb-3 d-flex align-center">
                            <Favourite24 className="me-3 mt-2" />
                            <span className="mt-2 pt-1">My favourites</span>
                        </p>
                    </div>
                    <ListGroup className="pt-1" flush tag="div" key={"favouriteReports_Listgroup"} >
                        {favouriteReports.current.length > 0 ? (
                            <>
                                {favouriteReports.current.slice(0, endIndex).map((report, index) => {
                                    return (
                                        <ListGroupItem
                                            className="custom-listgroup-item"
                                            action
                                            style={{ cursor: "pointer" }}
                                            key={`favouriteReport-${index}`}
                                            onClick={() => {
                                                RedirectofavReports(
                                                    report.report_Description
                                                );
                                            }}
                                        >
                                            <Col xs="12">
                                                <div>
                                                    <span style={{ fontFamily: "Roboto" }}>
                                                        {report.report_Description}
                                                    </span>
                                                </div>
                                            </Col>
                                        </ListGroupItem>

                                    );
                                })
                                }
                                {favouriteReports.current.length > endIndex &&
                                    <ListGroupItem className="border-0 custom-listgroup-item">
                                        <Col xs="12" style={{ marginBottom: "0px", textAlign: "right", textDecoration: "underline", color: "blue" }}>
                                            <span style={{ cursor: "pointer" }} onClick={() => { RedirectofavReports(""); }} >
                                                <Trans i18nKey="Homepage.More">More</Trans>
                                            </span>
                                        </Col>
                                    </ListGroupItem>
                                }
                            </>
                        ) : (
                            <ListGroupItem className="custom-listgroup-item">
                                <Col xs="12" className="lireports" style={{ cursor: "unset" }}>
                                    No reports found
                                </Col>
                            </ListGroupItem>
                        )}
                    </ListGroup>
                </div>
            </Col>
        </>
    )

}

export default MyFavourites;