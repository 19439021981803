import React, { useEffect, useState, useRef, useMemo } from "react";
import { Label } from "reactstrap";
import Select, { components } from "react-select";
import base from "../../Services/BaseService";

function UserParameters({ selectData, valueData, setReportValueData, resetTrigger, page }) {

    const reportPage = useRef(page);

    const [locationOptions, setLocationOptions] = useState([])
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [originalEmployeeOptions, setOriginalEmployeeOptions] = useState([])
    const [puOptions, setPUOptions] = useState([])
    const [valueOptions, setValueOptions] = useState([])
    const [roleOptions, setRoleOptions] = useState([])
    const [moduleOptions, setModuleOptions] = useState([])
    const [originModuleOptions, setOriginModuleOptions] = useState([])
    const [outputOptions, setOutputOptions] = useState([])
    const [moduleTypeOptions, setModuleTypeOptions] = useState([])
    const [originalModuleTypeOptions, setOriginalModuleTypeOptions] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const [dataOptions, setDataOptions] = useState([])
    const [failureRateOptions, setFailureRateOptions] = useState([])
    const [documentOptions, setDocumentOptions] = useState([])
    const [assessmentOptions, setAssessmentOptions] = useState([])

    const labelChangedRef = useRef("");

    function locationoptions() {
        base.get("location", "qry").then((res) => {
            let locArr = [];
            for (let i = 0; i < res.length; i++) {
                locArr.push({
                    label: res[i].locationID,
                    desc: res[i].location_Description !== null && res[i].location_Description,
                    value: res[i].locationID,
                });
            }
            setLocationOptions(locArr);
        });
    }

    function countryoptions() {
        base.get("country", "qry").then((res) => {
            let countryArr = [];
            for (let i = 0; i < res.length; i++) {
                countryArr.push({
                    desc: res[i].country_Description,
                    label: res[i].countryID,
                    value: res[i].countryID,
                });
            }
            setCountryOptions(countryArr);
        });
    }

    function reportoutputoptions(reportID) {
        let outputepoint = "drop/frm/PDF_CHART";
        if (reportID === "T_REQ" || reportID === "T_DEL") {
            outputepoint = "drop/frm/PDF_CHART:3";
        } else if (reportID === "COMP_LVL") {
            outputepoint = "drop/frm/PDF_CHART:2";
        } else if (reportID === "OPS_FLOW") {
            outputepoint = "drop/frm/EDITABLE:1";
        } else if (reportID === "OPS_CHK") {
            outputepoint = "drop/frm/EDITABLE";
        }

        base.getAll(outputepoint).then((res) => {
            let outputArr = [];
            for (let i = 0; i < res.length; i++) {
                var newLabel
                (res[i].pValue2 === "DATA DUMP") ?
                    newLabel = "Excel output" : newLabel = res[i].pValue2

                outputArr.push({
                    value: res[i].pValue1,
                    label: newLabel,
                });
            }
            setOutputOptions(outputArr);
        });
    }

    function personoptions() {
        if (reportPage.current === "Evaluation") {
            base.getAll("employee/qry/EVALUATION").then((res) => {
                let personArr = [];
                for (let i = 0; i < res.length; i++) {
                    personArr.push({
                        value: res[i].employeeID,
                        label: res[i].employeeName,
                        locationID: res[i].fk_LocationID,
                    });
                }
                setEmployeeOptions(personArr);
                setOriginalEmployeeOptions(personArr);
            });
        } else {
            base.getAll("employee/qry/COMPLIANCE").then((res) => {
                let personArr = [];
                for (let i = 0; i < res.length; i++) {
                    personArr.push({
                        value: res[i].employeeID,
                        label: res[i].emp_DisplayName,
                        locationID: res[i].fk_LocationID,
                    });
                }
                setEmployeeOptions(personArr);
                setOriginalEmployeeOptions(personArr);
            });
        }
    }

    function valuesoptions() {
        base.get("drop/frm", "R_VALUES").then((res) => {
            let valuesArr = [];
            for (let i = 0; i < res.length; i++) {
                valuesArr.push({
                    value: res[i].pValue1,
                    label: res[i].pValue2,
                });
            }
            setValueOptions(valuesArr);
        });
    }

    function roletypeoptions() {
        base.get("drop/frm", "ROLE").then((res) => {
            let roleTypeArr = [];
            for (let i = 0; i < res.length; i++) {
                roleTypeArr.push({
                    value: res[i].pValue1,
                    label: res[i].pValue1,
                });
            }
            setRoleOptions(roleTypeArr);

        });
    }

    function puoptions() {
        base.get("performanceunit", "qry").then((res) => {
            let puArr = [];
            for (let i = 0; i < res.length; i++) {
                puArr.push({
                    desc: res[i].pU_Description,
                    label: res[i].puid,
                    value: res[i].puid,
                });
            }
            setPUOptions(puArr);
        });
    }

    function tasktypeoptions() {
        base.getAll("drop/frm/SITE_DRP").then((res) => {

            let taskTypeArr = [];
            for (let i = 0; i < res.length; i++) {
                taskTypeArr.push({
                    value: res[i].pValue1,
                    label: res[i].pValue2,
                });
            }
            setModuleTypeOptions(taskTypeArr);
            setOriginalModuleTypeOptions(taskTypeArr)

        });
    }

    function dataoptions(option) {
        var dataArr = [];
        base.getAll(`drop/frm/` + option).then((res) => {
            for (let i = 0; i < res.length; i++) {
                var item = {};
                item.value = res[i].pValue1;
                item.label = res[i].pValue1;
                item.pValue1 = res[i].pValue1;
                dataArr.push(item);
            }
            setDataOptions(dataArr);
        });
    }

    const failurerateoptions = () => {
        base.get("drop/frm", "F_RATE").then((res) => {
            let failureRateArr = [];
            for (let i = 0; i < res.length; i++) {
                failureRateArr.push({
                    value: res[i].pValue1,
                    label: res[i].pValue2
                });
            }
            setFailureRateOptions(failureRateArr);
            // if (page === "My favourites") {
            //     if (defaultSelected === "") {
            //         setSelected("")
            //     } else {
            //         var failureRate = failureRateArr.filter(f => f.value === defaultSelected)
            //         setSelected(failureRate[0].value)
            //     }
            // }
        });
    }

    function documentoptions(reportid) {
        var documentArr = [];
        if (reportid === "MN_DAT1" || reportid === "MN_DAT2") {
            documentArr.push({
                value: "TPO",
                label: "Test paper output",
            });
            documentArr.push({
                value: "TTB",
                label: "Trainers task breakdown",
            });
        }
        if (reportid === "MN_TP") {
            documentArr.push({
                value: "TTB",
                label: "Trainers task breakdown (TTB)",
            });
            documentArr.push({
                value: "OTF",
                label: "Operators task flow (OTF)",
            });
            documentArr.push({
                value: "TAR",
                label: "Task assessment record (TAR)",
            });
            documentArr.push({
                value: "MAS",
                label: "Model answer sheet (MAS)",
            });
        }
        setDocumentOptions(documentArr);
    }
    function assessmentoptions(employee) {
        var assArr = [];
        base.getAll("resultcompetencyviewresults/qry/scorebreakdown/employee/" + employee).then((res) => {
            for (let i = 0; i < res.length; i++) {
                var item = {};
                item.value = res[i].fk_AssessmentID;
                item.desc = res[i].task_Type;
                item.period = res[i].period;
                item.assessmentID = res[i].fk_AssessmentID
                item.label = res[i].task_Number;
                item.pStart = res[i].pStart.substring(0, 10)
                item.description = res[i].description
                assArr.push(item);
            }
            setAssessmentOptions(assArr);
        });
    }

    function taskoptions(moduleValue) {
        base.getAll("task/qry/null/null/Y/null/assessment").then((res) => {

            let moduleFilter;
            switch (moduleValue) {
                case "TASK":
                    moduleFilter = ['TSK'];
                    break;
                case "SYLLABUS":
                    moduleFilter = ['SYL'];
                    break;
                case "TASKOPS":
                    moduleFilter = ['TSK', 'DAT'];
                    break;
                case "MODULE":
                    moduleFilter = [];
                    break;
                default:
                    moduleFilter = [];
                    break;
            }


            const filteredArray = moduleFilter.length === 0 ? res : res.filter(d => moduleFilter.includes(d.task_Type));

            let taskArr = [];
            for (let i = 0; i < filteredArray.length; i++) {
                taskArr.push({
                    label: filteredArray[i].task_Number,
                    pValue: filteredArray[i].task_Number,
                    value: filteredArray[i].taskID,
                    taskID: filteredArray[i].taskID,
                    taskType: filteredArray[i].task_Type,
                    tskName: filteredArray[i].task_Name,
                    task_Site: filteredArray[i].task_Site,
                });
            }
            setModuleOptions(taskArr);
            setOriginModuleOptions(taskArr);
        });
    }

    function employeeOptionsDeleted(locationId) {
        var emp = [];
        var url = "employee/qry/" + locationId + "/deleted/EMPLOYEES";
        base.getAll(url).then((res) => {
            if (res !== undefined) {
                for (let i = 0; i < res.length; i++) {
                    emp.push({
                        value: res[i].employeeID,
                        label: res[i].employeeName,
                    });
                }
                setEmployeeOptions(emp);
            }
        });
    }

    const taskDataArray = useMemo(() => [
        { value: "INCLUDE", label: "Include site specific modules", desc: "Include site specific modules", },
        { value: "EXCLUDE", label: "Exclude site specific modules", desc: "Exclude site specific modules", }
    ], []);

    const criticalArray = useMemo(() => [
        { value: "Y", label: "Critical (tier 1)" },
        { value: "P", label: "Critical (tier 2)" },
        { value: "N", label: "Non-critical" }
    ], []);

    const criticalQuestionArray = useMemo(() => [
        { value: "All", label: "All" },
        { value: "Y", label: "Critical" },
        { value: "N", label: "Non-critical" },
    ], []);

    const versionArray = useMemo(() => [
        { value: "GLOBAL", label: "Global generic task", pValue1: "GLOBAL", pValue2: "GLOBAL GENERIC TASK" },
        { value: "SITE", label: "Site specific task", pValue1: "SITE", pValue2: "SITE SPECIFIC TASK" },
    ], []);


    useEffect(() => {
        for (let i = 0; i < selectData.length; i++) {
            const reportID = selectData[i].fk_ReportID
            switch (selectData[i].label_Name) {
                case "LOCATION":
                    locationoptions()
                    break;
                case "EMPLOYEE":
                case "PERSON":
                case "EMPLOYEE:1":
                    personoptions()
                    break;
                case "PU":
                    puoptions();
                    break;
                case "TASK":
                case "MODULE":
                case "TASKOPS":
                case "SYLLABUS":
                    taskoptions(selectData[i].label_Name);
                    break;
                case "OUTPUT":
                    reportoutputoptions(reportID);
                    break;
                case "VALUES":
                    valuesoptions();
                    break;
                case "MODULE TYPE":
                    tasktypeoptions();
                    break;
                case "ROLE TYPE":
                    roletypeoptions();
                    break;
                case "COUNTRY":
                    countryoptions();
                    break;
                case "DATA":
                    if (reportID === "TP_DEL") {
                        dataoptions("DEL_TP");
                    } else if (reportID === "TP_CERT") {
                        dataoptions("TPDATA")
                    }
                    break;
                case "FAILURE RATE":
                    failurerateoptions();
                    break;
                case "DOCUMENT":
                    if (reportID === "MN_DAT1" || reportID === "MN_DAT2") {
                        documentoptions(reportID);
                    } else if (reportID === "MN_TP") {
                        documentoptions(reportID)
                    }
                    break;

                default:
                    break;
            }
        };

    }, [selectData]);


    const filterOptions = (selectValue, elementName) => {
        for (let i = 0; i < selectData.length; i++) {
            if (selectData[i].report_Dependant === elementName) {
                switch (selectData[i].report_Dependant) {
                    case "LOCATION":
                        switch (selectData[i].label_Name) {
                            case "EMPLOYEE:1":
                            case "EMPLOYEE":
                                if (selectData[i].fk_ReportID === "TP_DEL") {
                                    employeeOptionsDeleted(selectValue)
                                } else {
                                    if (selectValue) {
                                        setEmployeeOptions(originalEmployeeOptions.filter(d => selectValue.includes(d.locationID)));
                                    } else {
                                        setEmployeeOptions(originalEmployeeOptions);
                                    }
                                }
                                break;
                            case "PERSON":
                                if (selectValue) {
                                    setEmployeeOptions(originalEmployeeOptions.filter(d => selectValue.includes(d.locationID)));
                                } else {
                                    setEmployeeOptions(originalEmployeeOptions);
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case "EMPLOYEE":
                        assessmentoptions(selectValue)
                        break;
                    case "VERSION":
                        setModuleOptions(originModuleOptions.filter(d => d.task_Site === selectValue));
                        break;
                    case "TASK":
                    case "MODULE":
                    case "TASKOPS":
                    case "SYLLABUS":
                        if (selectValue) {
                            const selectedTask = moduleOptions.find(item => item.taskID === selectValue);
                            setModuleTypeOptions(originalModuleTypeOptions.filter(d => d.value === selectedTask.taskType))
                        } else {
                            setModuleTypeOptions(originalModuleTypeOptions);
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const handleChange = (selectValue, meta) => {

        labelChangedRef.current = meta.name;
        let newValue = "";
        let newValue1 = "";
        if (Array.isArray(selectValue)) {
            let arrayValues
            for (let i = 0; i < selectValue.length; i++) {
                if (i === 0) {
                    arrayValues = `${selectValue[i].value}`
                } else {
                    arrayValues += `,${selectValue[i].value}`
                }
            }
            newValue = arrayValues

        } else {
            if (selectValue) {
                newValue = selectValue.value
            }
        }

        filterOptions(newValue, meta.name);

        setReportValueData((prevValueData) => {
            const updatedValueData = prevValueData.map(item => {
                if (item.label_Name === meta.name) {
                    return {
                        ...item,
                        value: newValue,
                        value1: newValue1
                    };
                }
                if (item.label_Depedency === meta.name) {
                    return {
                        ...item,
                        value: ""
                    };
                }
                return item;
            });

            return updatedValueData;
        });

    }

    useEffect(() => {

        setReportValueData(prevReportValueData => {
            const updatedReportValueData = prevReportValueData.map(module => ({
                ...module,
                "value": "",
            }));
            return updatedReportValueData;
        });

    }, [resetTrigger, setReportValueData]);


    return (

        selectData.map((detail, index) => {
            let options = [];
            let isMulti = false;
            let valueObject = valueData.filter(d => d.label_Name === detail.label_Name)
            let selected = valueObject[0]?.value;
            isMulti = detail.field_Multi;
            switch (detail.label_Name) {
                case "LOCATION":
                    options = locationOptions;
                    break;
                case "EMPLOYEE:1":
                case "EMPLOYEE":
                case "PERSON":
                    options = employeeOptions;
                    break;
                case "PU":
                    options = puOptions;
                    break;
                case "MODULE":
                case "SYLLABUS":
                case "TASK":
                case "TASKOPS":
                    options = moduleOptions;
                    break;
                case "OUTPUT":
                    options = outputOptions;
                    break;
                case "VALUES":
                    options = valueOptions;
                    break;
                case "MODULE TYPE":
                    options = moduleTypeOptions;
                    break;
                case "COUNTRY":
                    options = countryOptions;
                    break;
                case "ROLE TYPE":
                    options = roleOptions;
                    break;
                case "ACTIVITY DATA":
                case "TASK DATA":
                    options = taskDataArray;
                    break;
                case "CRITICALQUESTION":
                    options = criticalQuestionArray;
                    break;
                case "CRITICAL":
                    options = criticalArray;
                    break;
                case "DATA":
                    options = dataOptions;
                    break;
                case "FAILURE RATE":
                    options = failureRateOptions;
                    break;
                case "DOCUMENT":
                    options = documentOptions;
                    break;
                case "VERSION":
                    options = versionArray;
                    break;
                case "ASSESSMENT":
                    options = assessmentOptions;
                    break;
                default:
                    break;
            }

            return (
                <div key={index} className={page === "Preformatted" ? "" : "ms-5"}>
                    <Label>
                        {detail.label_Description}
                        {detail.field_Mandatory && (<span className="text-danger"> *</span>)}
                    </Label>
                    <div className="d-flex">
                        <div className="reportsdropdownitem">
                            <Select
                                key={index}
                                id={detail.label_Name}
                                name={detail.label_Name}
                                placeholder=""
                                value={(selected === "" || selected === undefined) ? "" : options.filter(option => selected.includes(option.value))}
                                isMulti={isMulti}
                                options={options}
                                onChange={handleChange}
                                styles={{
                                    menuList: (base) => ({
                                        ...base,
                                        maxHeight: "146px",
                                    }),
                                }}
                                isClearable
                                classNamePrefix="react-select"
                                components={{
                                    Option: (props) => (
                                        <div id="">
                                            <components.Option {...props}>
                                                <div
                                                    style={{
                                                        display: "grid",
                                                        gridTemplateColumns: detail.label_Name === "ASSESSMENT" ? "25% 25% 25% 25%" : "25% 70% 5%",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {(() => {
                                                        switch (detail.label_Name) {
                                                            case "PU":
                                                            case "ACTIVITY DATA":
                                                            case "LOCATION":
                                                            case "COUNTRY":
                                                                return (
                                                                    <>
                                                                        <span>{props.value}</span>
                                                                        <span>{props.data.desc}</span>
                                                                    </>
                                                                );
                                                            case "MODULE":
                                                            case "SYLLABUS":
                                                            case "TASK":
                                                            case "TASKOPS":
                                                                return (
                                                                    <>
                                                                        <span>{props.data.pValue}</span>
                                                                        <span>{props.data.tskName}</span>
                                                                    </>
                                                                );
                                                            case "ASSESSMENT":
                                                                return (
                                                                    <>
                                                                        <span>{props.label}</span>
                                                                        <span>{props.data.desc}</span>
                                                                        <span>{props.data.pStart}</span>
                                                                        <span>{props.data.description}</span>
                                                                    </>
                                                                );
                                                            default:
                                                                return (
                                                                    <>
                                                                        <span>{props.label}</span>
                                                                    </>
                                                                );
                                                        }
                                                    })()}
                                                </div>
                                            </components.Option>
                                        </div>
                                    ),
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div >
                    </div >

                </div>
            );
        })
    );


}

export default UserParameters;