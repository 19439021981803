import React, { useState, useEffect } from "react";
import { Row, Col, UncontrolledPopover, PopoverBody } from "reactstrap";
import { Info24 } from "@bphxd/ds-core-react/lib/icons";
import "../../../Content/stylesheet/systemuser.css"
import base from "../../../Services/BaseService";
import Select from "react-select";
import Infobox from "../../Toast/Infobox";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import { Service } from "../../../Service";
import Cookies from "universal-cookie";
import { useUser } from "../../Shared/userContext";
import { useTranslation } from "react-i18next";
import LoadingView from "../../Shared/Layout/LoadingView";
import { useAssessmentContext } from "../../Shared/assessmentContext";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';

function Createsystemuser(props) {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const { languages, userData } = useUser();
  const languageOptions = languages.map((language) => {
    const { languageID, language_Name } = language;
    return {
      value: languageID,
      label: language_Name,
    };
  });

  const { updateAssessmentCount } = useAssessmentContext();
  const isLanguagePreferenceDropdownEnabled = false;
  const [emailid, setEmailid] = useState("");
  const [username, setUsername] = useState("");
  const [ulevel, setUlevel] = useState([]);
  const [enterpriseID, setEnterpriseID] = useState("");
  const [enterpriseExists, setEnterpriseExists] = useState("");
  const [enabled, setEnabled] = useState("");
  const enabledOptions = [{ "value": "Y", "label": t("SystemUserpage.Yes") }, { "value": "N", "label": t("SystemUserpage.No") }]
  const [userlevel, setUserlevel] = useState("");
  const [userlevelLabel, setuserlevelLabel] = useState("");
  const [RegionLabel, setRegionLabel] = useState("Region");
  const [isRegionFieldVisible, setIsRegionFieldVisible] = useState(false);
  const [isOnlineAssessment, setIsOnlineAssessment] = useState(false);
  const [accesslevel, setAccesslevel] = useState("");
  const [locations, setLocations] = useState([]);
  const [emailerr, setEmailerr] = useState(false);
  const [enterpriseError, setEnterpriseError] = useState(false);
  // const [alevelerr, setAlevelerr] = useState(false);
  const [ulevelerr, setUlevelerr] = useState(false);
  const [regionerr, setRegionerr] = useState(false);
  const [emailerrmsg, setemailerrmsg] = useState("");
  const [enterprisemsg, setenterprisemsg] = useState("");
  const [selectedloc, setSelectedloc] = useState([]);
  const [selectedLocationOfEdit, setselectedLocationOfEdit] = useState([]);
  const [forenameexist, setForenameexist] = useState(false);
  const [surnameexist, setSurnameexist] = useState(false);
  const [isEnabled, setIsEnabled] = useState("");
  const [languageError, setlanguageError] = useState(false);
  const [selectedlang, setselectedlang] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [showinfo, setShowinfo] = useState(false);
  const [iscancel, setIscancel] = useState(false);
  const [ischanged, setIschanged] = useState(false);
  const [removevalues, setRemovevalues] = useState([]);
  const [locationsincreate, setlocationsincreate] = useState([]);
  //new
  const [forenameRemaining, setforenameRemaining] = useState(0);
  const [surnameRemaining, setSurnameRemaining] = useState(0);
  const [forenameerr, setforenameerr] = useState(false);
  const [forename, setforename] = useState("");
  const [surnameerr, setsurnameerr] = useState(false);
  const [surname, setsurname] = useState("");
  const [jobtitleerr, setjobtitleerr] = useState(false);
  const [jobtitle, setjobtitle] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const [eID, setEID] = useState("");
  const [jobtitleOptions, setjobtitleOptions] = useState([]);
  const [selectedtraineeLocation, setselectedtraineeLocation] = useState([]);
  const [traineeLocations, settraineeLocations] = useState([]);
  const [traineeLocationerr, settraineeLocationerr] = useState("");
  const [isLongTermAb, setIsLongTermAb] = useState(false);
  const [isTrainee, setIsTrainee] = useState(false);
  const [disbaledEmp, setdisbaledEmp] = useState(false);
  const [isInvalidUL, setIsInvalidUL] = useState(false);
  var proceed = 0;
  var selectedvalues = [];

  const enabledHandleChange = (e) => {
    setIsEnabled(false);
    setIschanged(true);
  }

  function closeModal() {
    if (ischanged) {

      setIscancel(true);
    } else {
      props.close();
    }
  }

  function updateuserdata() {
    Service.sendMessage({ message: "update user" });
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
    }),
  };

  useEffect(() => {
    if (props.mode === "edit") {
      setIsloading(true);
      getuserdetails(props.userdata.id);
      setEmailid(props.userdata.email);
      setEnterpriseID(props.userdata.userID)

      if (
        props.userdata.userLevel === "10" ||
        props.userdata.userLevel === "15" ||
        props.userdata.userLevel === "20"
      ) {
        setIsRegionFieldVisible(false);
        if (props.userdata.isTrainee) {
          setdisbaledEmp(false);
        };
      } else {
        setIsRegionFieldVisible(true);
        if (props.userdata.userLevel === "50") {
          setIsTrainee(true); //will check
          setdisbaledEmp(true);
        }
      }
    } else {
      setEnabled("Y");
    }

    getData();
    getlocationsForTrainee();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    base.getAll("drop/frm/ULEVEL").then((res) => {
      var ulvl = [];
      for (let i = 0; i < res.length; i++) {
        var item = {};
        item.value = res[i].pValue1;
        item.label = res[i].pValue2;
        if (
          cookies.get("rolelevel") === "30" &&
          cookies.get("accessLevel") === "CTY" &&
          res[i].pValue2 === "PU Manager"
        ) { }
        else {
          ulvl.push(item);
        }
      }
      setUlevel(ulvl);
    });

    base.getAll("drop/frm/ALEVEL").then((res) => {
      if (props.mode === "edit") {
        renderal();
      }
    });



    base.getAll("drop/frm/ROLE").then((res) => {
      var jobs = [];
      for (let i = 0; i < res.length; i++) {
        var item = {};
        item.value = res[i].pValue1;
        item.label = res[i].pValue2;
        jobs.push(item);
      }
      setjobtitleOptions(jobs);
    });
  };



  const handleLevelChange = (res) => {
    setUserlevel(res.value);
    setuserlevelLabel(res.label);
    setUlevelerr(false);
    setIschanged(true);
    renderal(res);
    setselectedtraineeLocation("");
    setjobtitle("");
    setSelectedloc("");
    setIsLongTermAb(false);
    if (res.value === "10" || res.value === "15" || res.value === "20") {
      setAccesslevel("GLOBAL");
      // setAlevelerr(false);
      setRegionerr(false);
      setLocations([]);
      setSelectedloc([]);
      selectedvalues = [];
      setRegionLabel("Region");
      setdisbaledEmp(false);
      setIsTrainee(false);
      setIsRegionFieldVisible(false);
    } else {
      setLocations([]);
      setSelectedloc([]);
      selectedvalues = [];
      setdisbaledEmp(false);
      setIsTrainee(false);
      setIsRegionFieldVisible(true);
      if (res.label.toLowerCase() === "pu manager") {
        setRegionLabel("User Performance Unit");
        setAccesslevel("PU");
      }

      if (res.label.toLowerCase() === "country manager") {
        setRegionLabel("User Country");
        setAccesslevel("CTY");
      }

      if (res.label.toLowerCase() === "site manager") {
        setRegionLabel("User Location");
        setAccesslevel("LOCN");
      }

      if (res.label.toLowerCase() === "employee") {
        setIsTrainee(true);
        setdisbaledEmp(true);
        setEmailerr(false);
      }
    }

    if (props.mode === "edit") {
      getEmployeeDetails(props.userdata.id, res.label.toLowerCase());
    }
  };

  function getlocations(val) {
    var location = [];
    let url = "";
    if (props.mode !== "edit") {
      if (val.toLowerCase() === "pu manager") url = "performanceunit";
      if (val.toLowerCase() === "country manager") url = "COUNTRY";
      if (val.toLowerCase() === "site manager") url = "LOCATION";
    } else {
      url = val;
      if (val.toLowerCase() === "pu manager") url = "performanceunit";
      if (val.toLowerCase() === "country manager") url = "COUNTRY";
      if (val.toLowerCase() === "site manager") url = "LOCATION";
    }
    if (val !== "Employee") {

      base.getAll(url + "/qry").then((res) => {
        for (let i = 0; i < res.length; i++) {
          if (url === "performanceunit" && res[i].puid !== undefined)
            location.push({
              value: res[i].puid,
              label: res[i].puid + " - " + res[i].pU_Description,
            });
          if (url === "COUNTRY" && res[i].countryID !== undefined)
            location.push({
              value: res[i].countryID,
              label: res[i].countryID + " - " + res[i].country_Description,
            });
          if (url === "LOCATION" && res[i].locationID !== undefined)
            location.push({
              value: res[i].locationID,
              label:
                res[i].locationID + " - " + res[i].location_Description,
            });
        }

        setLocations(location);
      });
    }
  }
  function getlocationsForTrainee() {
    var location = [];


    base.getAll("Location/Qry").then((res) => {
      for (let i = 0; i < res.length; i++) {
        var itemLOC = {};
        itemLOC.value = res[i].location_Description;
        itemLOC.label =
          res[i].locationID + " - " + res[i].location_Description;

        itemLOC.id = res[i].locationID;
        location.push(itemLOC);
      }

      settraineeLocations(location);
    });
  }

  function validateEmail(email) {
    var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (emailid && emailid?.length > 0) {
      if (reg.test(email)) {
        proceed = 0;
        return true;
      } else {
        proceed = 1;
        setEmailerr(true);
        setemailerrmsg(t("SystemUserpage.EmailIdFormatIncorrectError"));
      }
    } else {
      if (userlevel !== "50") {
        proceed = 1;
        setEmailerr(true);
        setemailerrmsg(t("ErrorMessages.Required"));
      } else {
        proceed = 0;
        return true;
      }
    }
    return false;
  }

  function getSelectedlocations(val, frmmEdit) {
    let url = "";
    let mail = ";";
    if (props.mode !== "edit") {
      if (val.toLowerCase() === "pu manager") url = "PU";
      if (val.toLowerCase() === "country manager") url = "COUNTRY";
      if (val.toLowerCase() === "site manager") url = "LOCATION";
      mail = document.getElementById("userenterprise").value;
    } else {
      url = val;
      if (val.toLowerCase() === "pu manager") url = "PU";
      if (val.toLowerCase() === "country manager") url = "COUNTRY";
      if (val.toLowerCase() === "site manager") url = "LOCATION";
      mail =
        document.getElementById("userenterprise") === null ||
          document.getElementById("userenterprise") === undefined
          ? props.userdata.email
          : document.getElementById("userenterprise").value;
    }

    var selectedval = [];

    if (val !== "Employee") {
      base.get(`userlocation/qry/${mail}`, url).then((res) => {

        if (url === "LOCATION") {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].fk_LocationID != null &&
              res[i].location_Description != null
            ) {
              selectedval.push({
                value: res[i].fk_LocationID,
                label:
                  res[i].fk_LocationID +
                  " - " +
                  res[i].location_Description,
              });
            }
          }
        } else if (url === "COUNTRY") {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].fk_CountryID != null &&
              res[i].country_Description != null
            ) {
              selectedval.push({
                value: res[i].fk_CountryID,
                label:
                  res[i].fk_CountryID + " - " + res[i].country_Description,
              });
            }
          }
        } else {
          for (let i = 0; i < res.length; i++) {
            if (res[i].fk_PUID != null && res[i].pU_Description != null) {
              selectedval.push({
                value: res[i].fk_PUID,
                label: res[i].fk_PUID + " - " + res[i].pU_Description,
              });
            }
          }
        }
        setSelectedloc(selectedval);
        selectedvalues.push([...selectedval]);
        if (frmmEdit) {
          setselectedLocationOfEdit(selectedval);
        }

      });
    }
  }

  function renderal(res) {
    if (!res) return;
    setSelectedloc([]);
    selectedvalues = [];
    setLocations([]);
    if (res.value !== "10" && res.value !== "15" && res.value !== "20") {
      getlocations(
        res.label
      );
      setSelectedloc([]);
      selectedvalues = [];
      if (props.mode === "edit") {
        getSelectedlocations(
          res.label
        );
      }
    }

  }

  function getuserdetails(val) {

    base.get("user/frmUser", val).then((res) => {
      if (res.length > 0) {
        res = res[0];
        setEmailid(res.email);
        setUsername(res.userDisplayName);
        setUserlevel(res.userLevel); //need to check
        setselectedlang(res.fk_languageID)
        // ulevellabel = res.userLevel;
        setAccesslevel(res.accessLevel);
        setEnabled(res.enabled);
        setforename(res.emp_Forename);
        setIsOnlineAssessment(res.isOnlineAssessment)
        var fLen =
          res.emp_Forename !== null && res.emp_Forename !== ""
            ? res.emp_Forename.length
            : 0;
        setforenameRemaining(fLen);

        setsurname(res.emp_Surname);
        var sLen =
          res.emp_Surname !== null && res.emp_Surname !== ""
            ? res.emp_Surname.length
            : 0;
        setSurnameRemaining(sLen);

        if (res.isTrainee === 1) setIsTrainee(true);
        else setIsTrainee(false);

        if (res.locationID !== "") {
          let s = {
            id: res.locationID,
            value: res.locationID,
            label: res.locationID,
          };
          setselectedtraineeLocation(s);
        }

        setEID(res.eid);
        setEmployeeID(res.employeeID);
        setjobtitle(res.jobTitle);
        // setisTrainer(res.trainer);
        setIsLongTermAb(res.emp_Absent);
        if (res.accessLevel === "LOCN" && res.userLevel === "40") {
          setRegionLabel("User Location");
          setuserlevelLabel("Site Manager");
        }
        if (res.accessLevel === "PU" && res.userLevel === "30") {
          setuserlevelLabel("PU Manager");
          setRegionLabel("User Performance Unit");
        }
        if (res.accessLevel === "CTY" && res.userLevel === "30") {
          setRegionLabel("User Country");
          setuserlevelLabel("Country Manager");
        }
        if (res.userLevel === "10")
          setuserlevelLabel("System Administrator");
        if (res.userLevel === "15")
          setuserlevelLabel("Global Administrator");
        if (res.userLevel === "20") setuserlevelLabel("Global User");
        if (res.userLevel === "50") setuserlevelLabel("Employee");

        if (
          res.userLevel !== "10" &&
          res.userLevel !== "15" &&
          res.userLevel !== "20" &&
          res.userLevel !== "50"
        ) {
          getSelectedlocations(
            res.accessLevel === "LOCN"
              ? "LOCATION"
              : res.accessLevel === "CTY"
                ? "COUNTRY"
                : res.accessLevel,
            true
          );
          getlocations(
            res.accessLevel === "LOCN"
              ? "LOCATION"
              : res.accessLevel === "CTY"
                ? "COUNTRY"
                : res.accessLevel === "PU"
                  ? "performanceunit"
                  : res.accessLevel
          );
        }

        setTimeout(() => {
          setIsloading(false);
        }, 1000);
      }
    });
  }

  const getEmployeeDetails = (val, level) => {

    base.get("user/frmUser", val).then((res) => {
      if (res.length > 0) {
        res = res[0];
        if (res.isTrainee === 1) {
          setIsTrainee(true);
        } else {

        }

        if (res.locationID !== "") {
          let s = {
            id: res.locationID,
            value: res.locationID,
            label: res.locationID,
          };
          setselectedtraineeLocation(s);
        }

        setEID(res.eid);
        setEmployeeID(res.employeeID);
        setjobtitle(res.jobTitle);

        setTimeout(() => {
          setIsloading(false);
        }, 1000);
      }
    });
  };

  function checkValid(value) {
    if (value) return true;
    return false;
  }

  function updateuser() {
    var data = {
      UserID: enterpriseID,
      UserDisplayName: forename.trim() + " " + surname.trim(),
      Enabled: enabled,
      Email: checkValid(emailid) ? emailid.trim() : emailid,
      UserLevel: userlevel,
      fk_languageID: selectedlang,
      AccessControl: "",
      AccessLevel: accesslevel,
      Prefix: "",
      DT_LastLogon: 0,
      Variance: "",
      ID: props.userdata.id,
      IsTrainee: isTrainee === false ? 0 : 1,
      IsOnlineAssessment: isOnlineAssessment === false ? 0 : 1,

    };
    var jobTitle = userlevel === "50" ? jobtitle : userlevelLabel;
    var emp = {
      employee: {
        emp_DisplayName: forename.trim() + " " + surname.trim(),
        jobTitle: jobTitle,
        fk_LocationID:
          selectedtraineeLocation === "" ? "" : selectedtraineeLocation.id,
        emp_SurName: surname.trim(),
        emp_ForeName: forename.trim(),
        emp_Absent: isLongTermAb,
        trainer: userlevel === "30" || userlevel === "40" ? "Y" : "N",
        id: eID,
        EmployeeID: employeeID.toString(),
      },
      user: data,
    };

    base.update("user/updUser", emp).then((res) => {
      updateAssessmentCount();
      removedata();
      if (userlevel === "40") {
        let locations = selectedloc; //selectedLocationOfEdit
        let AssignedSiteMAgerArray = locations.filter(
          ({ value, label }) =>
            !selectedLocationOfEdit.some(
              (exclude) =>
                exclude.value === value && exclude.label === label
            )
        );
        if (AssignedSiteMAgerArray.length > 0) {
          for (let i = 0; i < AssignedSiteMAgerArray.length; i++) {
            assignSiteManager(AssignedSiteMAgerArray[i]);
          }
        }
        let unAssignedSiteMAgerArray = selectedLocationOfEdit.filter(
          ({ value, label }) =>
            !locations.some(
              (exclude) =>
                exclude.value === value && exclude.label === label
            )
        );
        if (unAssignedSiteMAgerArray.length > 0) {
          for (let i = 0; i < unAssignedSiteMAgerArray.length; i++) {
            unassignSiteManager(unAssignedSiteMAgerArray[i]);
          }
        }
      }
      if (selectedloc.length > 0) {
        addlocationstoexistingusers();
      } else {
        if (emailid === cookies.get("upn")) {
          updateuserdata();
        }
        props.reload();
        props.success();
        props.update();
        props.close();
      }
    })
      .catch((err) => {
        setIsloading(false);
        props.error();
      });
  }

  const unassignSiteManager = (location) => {
    var assignManager = {
      fk_UserID: checkValid(emailid) ? emailid.trim() : emailid,
      fk_LocationID: location.value,
      assign_Indicator: "MANAGER",
      text01: "SITE MANAGER",
      text02: "",
      text03: "",
      text04: null,
      rel_rec: 0,
    };


    base
      .add(process.env.REACT_APP_DEANONYMISE_ADD, assignManager)
      .then((response) => { })
      .catch((error) => { });
  };

  function assignSiteManager(locations) {
    var assignManager = {
      fk_UserID: checkValid(emailid) ? emailid.trim() : emailid,
      fk_LocationID: locations.value,
      assign_Indicator: "MANAGER",
      text01: "SITE MANAGER",
      text02: "True",
      text03: "",
      text04: null,
      rel_rec: 1,
    };


    base
      .add(process.env.REACT_APP_ASSIGNMANAGER_ADD, assignManager)
      .then((response) => { })
      .catch((error) => { });
  }

  function createuser() {
    var data = {
      // UserID: checkValid(emailid) ? emailid.trim() : emailid,
      UserID: enterpriseID,
      UserDisplayName: forename.trim() + " " + surname.trim(),
      Enabled: enabled,
      Email: checkValid(emailid) ? emailid.trim() : emailid,
      UserLevel: userlevel,
      fk_languageID: selectedlang,
      AccessControl: "",
      AccessLevel: accesslevel,
      Prefix: "",
      DT_LastLogon: 0,
      Variance: "",
      ID: 0,
      IsTrainee: isTrainee === false ? 0 : 1,
      IsOnlineAssessment: isOnlineAssessment === false ? 0 : 1

    };
    var emp = {
      employee: {
        emp_DisplayName: forename.trim() + " " + surname.trim(),
        jobTitle: jobtitle === "" ? userlevelLabel : jobtitle,
        fk_LocationID:
          selectedtraineeLocation === "" ? "" : selectedtraineeLocation.id,
        emp_SurName: surname.trim(),
        emp_ForeName: forename.trim(),
        emp_Absent: isLongTermAb,
        trainer: userlevel === "30" || userlevel === "40" ? "Y" : "N",
        id: 0,
      },
      user: data,
    };



    base
      .add("user/addUser", emp)
      .then((res) => {
        if (userlevel === "40") {
          let locations = locationsincreate;
          for (let i = 0; i < locations.length; i++) {
            assignSiteManager(locations[i]);
          }
        }
        if (locationsincreate.length > 0) {
          addlocationstonewusers();
        } else {
          props.reload();
          props.update();
          props.success();
          props.close();
        }
      })
      .catch((err) => {
        setIsloading(false);
        props.error();
      });
  }

  function checkvalidation() {
    validateEmail(emailid);
    // if (username.trim().length < 1) {
    //   proceed = 1;
    //   setUsernameerr(true);
    // }

    if (userlevel !== "50" && enterpriseID.length < 1) {
      proceed = 1;
      setEnterpriseError(true);
      setenterprisemsg(t("ErrorMessages.Required"));
    }
    if (userlevel !== "50" && accesslevel.length < 1) {
      proceed = 1;
      // setAlevelerr(true);
    }
    if (userlevel.length < 1) {
      proceed = 1;
      setUlevelerr(true);
      setIsInvalidUL(true)
    }
    if (userlevel === "50" && (jobtitle?.length < 1 || jobtitle === null)) {
      proceed = 1;
      setjobtitleerr(true);
    }
    if (enabled.length < 1) {
      proceed = 1;
      setIsEnabled(true);
    }
    if (forename.trim().length < 1) {
      proceed = 1;
      setforenameerr(true);
      setforenameRemaining(0);
    } else {
      setforenameRemaining(forename.trim().length);
      setforename(forename.trim());
    }

    if (surname.trim().length < 1) {
      proceed = 1;
      setsurnameerr(true);
      setSurnameRemaining(0);
    } else {
      setSurnameRemaining(surname.trim().length);
      setsurname(surname.trim());
    }
    if (selectedlang?.length < 1 || selectedlang === null) {
      proceed = 1;
      setlanguageError(true)

    }
    // if (trainerval.length < 1) {
    //   proceed = 1;
    //   setisTrainer(true);
    // }

    if (isTrainee && selectedtraineeLocation.length < 1) {
      proceed = 1;
      settraineeLocationerr(true);
    }
    if (isLanguagePreferenceDropdownEnabled && selectedlang.length < 1) {
      proceed = 1;
      setlanguageError(true);
    }

    if (props.mode === "edit") {
      if (
        selectedloc.length < 1 &&
        (userlevel === "30" || userlevel === "40")
      ) {
        proceed = 1;
        setRegionerr(true);
      }
    } else {

      if (
        locationsincreate.length < 1 &&
        (userlevel === "30" || userlevel === "40")
      ) {
        proceed = 1;
        setRegionerr(true);
      }
    }
    if (proceed === 0) {
      setIsloading(true);
      props.mode === "edit" ? updateuser() : checkuser();
    }
  }

  function clearspaces() {
    setEmailid(checkValid(emailid) ? emailid.trim() : emailid);
    setUsername(username.trim());
    setforename(forename.trim());
    setsurname(surname.trim());
  }

  function checkuser() {
    var fname = forename.trim();
    var sname = surname.trim();
    var fullname = fname + " " + sname;
    var userLoc =
      selectedtraineeLocation === "" ? null : selectedtraineeLocation.id;
    var userID = enterpriseID.trim() !== "" ? enterpriseID.trim() : null;
    var edpoint = `${fname}/${sname}/${userLoc}/${userID}`;

    base.get("user/get1", edpoint).then((res) => {
      if (res === "User not recognised") {
        createuser();
      } else {
        setIsloading(false);
        if (!res.user?.userID < 1) {
          if (res.user?.userID.toUpperCase() === enterpriseID) {
            setEnterpriseExists(true);
            setEnterpriseError(true);
          }
          if (res.user?.userDisplayName.toUpperCase() === fullname.toUpperCase()) {
            setForenameexist(true);
            setSurnameexist(true);
            setforenameerr(true);
            setsurnameerr(true);
          } else {
            setForenameexist(false);
            setSurnameexist(false);
            setforenameerr(false);
            setsurnameerr(false);
          }
        } else {
          if (res.employee?.emp_Forename.toUpperCase() + " " + res.employee?.emp_Surname.toUpperCase() === fullname.toUpperCase()) {
            setForenameexist(true);
            setSurnameexist(true);
            setforenameerr(true);
            setsurnameerr(true);
          } else {
            setForenameexist(false);
            setSurnameexist(false);
            setforenameerr(false);
            setsurnameerr(false);
          }
        }
      }
    });
  }

  function addlocationstonewusers() {
    for (let i = 0; i < locationsincreate.length; i++) {
      addvalues(locationsincreate[i]);
    }
    props.reload();
    props.update();
    props.success();
    props.close();
  }

  function addlocationstoexistingusers() {
    for (let i = 0; i < selectedloc.length; i++) {
      addvalues(selectedloc[i]);
    }
    if (emailid === cookies.get("upn")) {
      updateuserdata();
    }
    props.reload();
    props.success();
    props.update();
    props.close();
  }

  function removedata() {
    for (let i = 0; i < removevalues.length; i++) {
      if (!selectedvalues.includes(removevalues[i])) {
        removevalue(removevalues[i]);
      }
    }
  }

  function handlechange(val) {
    setIschanged(true);
    if (val > selectedloc) {
      selectedvalues = val;
      setSelectedloc(val);
    } else if (val < selectedloc) {
      if (val.length === 0 && selectedloc.length > 0) {
        setSelectedloc([]);
        setRemovevalues([...removevalues, ...selectedloc]);
      } else {
        var extrabuf = selectedloc.filter(function (value, index, arr) {
          return val.includes(value) ? false : true;
        });

        setRemovevalues([...removevalues, extrabuf[extrabuf.length - 1]]);

        setSelectedloc(val);
        selectedvalues = val;
      }
    }
    if (props.mode !== "edit") {
      setlocationsincreate(val);
    }

  }


  function addvalues(val) {
    setIsloading(true);
    var region = "";
    var data = {
      fk_CountryID: null,
      fk_LocationID: null,
      fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
      fk_PUID: null,
      rel_rec: 1,
    };
    if (accesslevel === "LOCN") {
      region = "location";
      data = {
        fk_CountryID: null,
        fk_LocationID: val.value,
        fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
        fk_PUID: null,
        rel_rec: 1,
      };
    } else if (accesslevel === "CTY") {
      region = "country";
      data = {
        fk_CountryID: val.value,
        fk_LocationID: null,
        fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
        fk_PUID: null,
        rel_rec: 1,
      };
    } else if (accesslevel === "PU") {
      region = "PU";
      data = {
        fk_CountryID: null,
        fk_LocationID: null,
        fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
        fk_PUID: val.value,
        rel_rec: 1,
      };
    }


    base.add(`userlocation/Add/${region}`, data).then((res) => {
      setIsloading(false);
    });
  }

  function removevalue(val) {
    setIsloading(true);

    var region = "";
    var data = {
      fk_CountryID: null,
      fk_LocationID: null,
      fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
      fk_PUID: null,
      rel_rec: 0,
    };
    if (accesslevel === "LOCN") {
      region = "location";
      data = {
        fk_CountryID: null,
        fk_LocationID: val.value,
        fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
        fk_PUID: null,
        rel_rec: 0,
      };
    } else if (accesslevel === "CTY") {
      region = "country";
      data = {
        fk_CountryID: val.value,
        fk_LocationID: null,
        fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
        fk_PUID: null,
        rel_rec: 0,
      };
    } else if (accesslevel === "PU") {
      region = "PU";
      data = {
        fk_CountryID: null,
        fk_LocationID: null,
        fk_UserID: checkValid(enterpriseID) && enterpriseID.trim(),
        fk_PUID: val.value,
        rel_rec: 0,
      };
    }


    base.update(`userlocation/Upd/${region}`, data).then((res) => {
      setIsloading(false);
    });
  }

  return (
    <>
      {isloading && <LoadingView />}

      <Modal isOpen={true} centered className="modal-70">
        {/* <LoadingOverlay
          active={isloading}
          spinner={<Spinner className="spinnerstyle" />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.5)",
              height: "100%",
            }),
          }}
          fadeSpeed={100}
        > */}
        {iscancel ? (
          <ConfirmDeleteToast
            show={true}
            //className="top20"
            Message={t("ErrorMessages.CancelSure")}
            handleCancel={() => {
              setIscancel(false);
            }}
            handleDelete={() => {
              props.close();
            }}
          ></ConfirmDeleteToast>
        ) : (
          ""
        )}



        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={() => { closeModal() }}
        >
          <Label className="modalHeaderGlobal m-4">
            {props.mode === "edit"
              ? t("SystemUserpage.Edit User")
              : t("SystemUserpage.Create User")}
          </Label>
          <Infobox
            message={t("SystemUserpage.EmailIdExistToast")}
            show={showinfo}
            close={() => {
              setShowinfo(false);
              closeModal();
            }}
          ></Infobox>
        </Modal.Header>
        <Modal.Body id="scrolltop">
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12}>
                  <Label className="textcolor ">
                    {t("SystemUserpage.Forename")}{" "}
                    <span className="redstar">*</span>
                  </Label>
                  <Input
                    className={
                      forenameerr || forename.length > 30
                        ? "errorborder inputfield"
                        : "inputfield"
                    }
                    value={forename}
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setforenameerr(false);
                      }
                      setIschanged(true);
                      setforename(event.target.value);
                      setforenameRemaining(event.target.value.length);
                      setForenameexist(false);
                    }}
                    maxLength={30}
                  ></Input>
                </Col>
                <Col xs={12}>
                  <span className="desclength text-end mb-1 float-end">{forenameRemaining}/30</span>
                  {forenameerr && forename.trim().length < 1 && (
                    <span className="desclength errMsgoops">
                      {t("ErrorMessages.Required")}
                    </span>
                  )}
                  {forename.length > 30 && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.NameLenExceededError")}
                    </span>
                  )}
                  {forenameexist && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.ForenameExist")}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12}>
                  <Label className="textcolor ">
                    {t("SystemUserpage.Surname")}{" "}
                    <span className="redstar">*</span>
                  </Label>
                  <Input
                    className={
                      surnameerr || surname.length > 30
                        ? "errorborder inputfield"
                        : "inputfield"
                    }
                    value={surname}
                    onChange={(event) => {
                      if (event.target.value.length > 0) {
                        setsurnameerr(false);
                      }
                      setIschanged(true);
                      setsurname(event.target.value);
                      setSurnameRemaining(event.target.value.length);
                      setSurnameexist(false);
                    }}
                    maxLength={30}
                  ></Input>
                </Col>
                <Col xs={12}>
                  <span className="desclength text-end mb-1 float-end">{surnameRemaining}/30</span>
                  {surnameerr && surname.trim().length < 1 && (
                    <span className="desclength errMsgoops">
                      {t("ErrorMessages.Required")}
                    </span>
                  )}
                  {surname.length > 30 && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.NameLenExceededError")}
                    </span>
                  )}
                  {surnameexist && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.SurnameExist")}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="">
            <Col xs={12} md={6} className="">
              <Row>
                <Col xs={12}>
                  <Label className="textcolor">
                    {t("SystemUserpage.User Level")}{" "}
                    <span className="redstar">*</span>
                  </Label>
                  <Select
                    id="userLevel"
                    name="userLevel"
                    placeholder={userlevelLabel}
                    // value={ulevel.find((option) => option.value === userlevel)}
                    value={ulevel.find((option) => option.value === userlevel && option.label === userlevelLabel)}
                    options={ulevel}
                    invalid={isInvalidUL}
                    onChange={(e) => {
                      setUserlevel(e.value);
                      handleLevelChange(e);
                      setIsInvalidUL(false)
                    }}
                    styles={customStyles}
                    classNamePrefix="react-select"
                  >
                  </Select>
                  {ulevelerr && (
                    <span className="desclength errMsgoops">
                      {t("ErrorMessages.Required")}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="mb-3 enabled-select">
              <Row>
                <Col xs={12} md={12} >
                  <Label className="mb5 textcolor">
                    {t("SystemUserpage.Enabled")}{" "}
                    <span className="redstar">*</span>
                  </Label>
                  <Select
                    id="enabled"
                    name="enabled"
                    placeholder={""}
                    value={enabledOptions.find((option) => option.value === enabled)}
                    options={enabledOptions}
                    onChange={(e) => {
                      setEnabled(e.value);
                      enabledHandleChange(e.value);
                    }}
                    classNamePrefix="react-select"
                  >
                  </Select>
                  {isEnabled && (
                    <span className="desclength errMsgoops">
                      {t("ErrorMessages.Required")}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Row>
                <Col xs={12} md={12}>
                  <Label className="textcolor">
                    {t("SystemUserpage.User Enterprise ID")}
                    {userlevel !== "50" && (
                      <span className="redstar"> *</span>
                    )}
                  </Label>
                  <Input
                    id="userenterprise"
                    className={
                      enterpriseError || enterpriseID?.length > 25
                        ? "errorborder"
                        : ""
                    }
                    value={enterpriseID}
                    onChange={(event) => {
                      // setEmailexist(false);
                      if (event.target.value.length > 0) {
                        setEnterpriseError(false);
                        setenterprisemsg("");
                      } else {
                        if (userlevel !== "50") {

                          setenterprisemsg(t("ErrorMessages.Required"));
                        } else {
                          setEnterpriseError(false);
                          setenterprisemsg("");
                        }
                      }
                      setEnterpriseExists(false);

                      setIschanged(true);
                      setEnterpriseID(event.target.value.trim().toUpperCase());
                      // email = event.target.value.trim();
                    }}
                    maxLength={25}
                  ></Input>
                </Col>
                <Col xs={12} md={12}>
                  <span className="desclength text-end mb-1 float-end">
                    {enterpriseID ? enterpriseID.length : "0"}/25
                  </span>
                  {enterpriseError && (
                    <span className="desclength errMsgoops">{enterprisemsg}</span>
                  )}
                  {enterpriseExists && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.EnterpriseIDExists")}
                    </span>
                  )}
                  {!enterpriseError && enterpriseID?.length > 25 && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.EnterpiseLenExceededError")}
                    </span>
                  )}
                </Col>

              </Row>
            </Col>

            <Col xs={12} md={6} className="">
              <Row>
                <Col xs={12}>
                  <Label className="textcolor">
                    {t("SystemUserpage.Email ID")}
                    {userlevel !== "50" && (
                      <span className="redstar"> *</span>
                    )}
                  </Label>
                  <Input
                    id="useremail"
                    className={
                      emailerr || emailid?.length > 50
                        ? "errorborder"
                        : ""
                    }
                    value={emailid}
                    onChange={(event) => {
                      // setEmailexist(false);
                      if (event.target.value.length > 0) {
                        setEmailerr(false);
                        setemailerrmsg("");
                      } else {
                        if (userlevel !== "50") {
                          setemailerrmsg(t("ErrorMessages.Required"));
                        } else {
                          setEmailerr(false);
                          setemailerrmsg("");
                        }
                      }
                      setIschanged(true);
                      setEmailid(event.target.value.trim());
                    }}
                    maxLength={50}
                  ></Input>
                </Col>
                <Col xs={12}>
                  <span className="desclength text-end mb-1 float-end">
                    {emailid ? emailid.length : "0"}/50
                  </span>
                  {emailerr && (
                    <span className="desclength errMsgoops">{emailerrmsg}</span>
                  )}
                  {/* {emailexist && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.EmailIdExistToast")}
                    </span>
                  )} */}
                  {!emailerr && emailid?.length > 50 && (
                    <span className="desclength errMsgoops">
                      {t("SystemUserpage.EmailLenExceededError")}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            < Col xs={12} md={6} className="mb-5">
              <Row>
                <Col xs={12}>
                  <Label className="textcolor">
                    {t("SystemUserpage.Language Preference")}{" "}
                    <span className="redstar">*</span>
                  </Label>
                  <Select
                    id="language"
                    name="language"
                    placeholder={""}
                    value={languageOptions.find((option) => option.value === selectedlang)}
                    options={languageOptions}
                    onChange={(e) => {
                      if (e.value.length > 0) {
                        setlanguageError(false);
                        setselectedlang(e.value);
                        setIschanged(true)
                      } else {
                        setlanguageError(true)
                      }
                    }}
                    invalid={languageError}
                    styles={customStyles}
                    classNamePrefix="react-select"
                  >
                  </Select>
                  {languageError && (
                    <span className="desclength errMsgoops">
                      {t("ErrorMessages.Required")}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              {isRegionFieldVisible && userlevel !== "50" && (
                <Col xs={12} md={12} className="mb-3">
                  <Row>
                    <Col xs={12}>

                      <Label
                        className="textcolor"
                      >
                        {RegionLabel} <span className="redstar">*</span>
                      </Label>
                      <Select
                        options={locations}

                        isMulti
                        isDisabled={
                          accesslevel === "GLOBAL" || accesslevel === ""
                            ? true
                            : false
                        }

                        value={selectedloc}
                        onChange={(option) => {
                          setRegionerr(false);
                          props.mode === "edit"
                            ? handlechange(option)
                            : handlechange(option);
                        }}
                        invalid={regionerr}
                        styles={customStyles}
                        classNamePrefix="react-select"
                      ></Select>
                      {regionerr && (
                        <span className="desclength errMsgoops">
                          {t("ErrorMessages.Required")}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}

              {userlevel === "50" && (

                <Col xs={12} md={12}>
                  <Row>
                    <Col xs={12}>
                      <Label className="textcolor">
                        {t("SystemUserpage.Job Title")}{" "}
                        <span className="redstar">*</span>
                      </Label>
                      <Select

                        placeholder=""
                        value={jobtitleOptions.find((option) => option.value === jobtitle)}
                        options={jobtitleOptions}
                        onChange={(e) => {
                          setjobtitle(e.value)
                          setjobtitleerr(false);
                          // ddlHandleChange(e.value);   
                        }}
                        classNamePrefix="react-select"
                        styles={customStyles}
                        invalid={jobtitleerr}
                      >
                      </Select>
                      {jobtitleerr && (
                        <span className="desclength errMsgoops">
                          {t("ErrorMessages.Required")}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
            </Col>

          </Row>

          <Row className="">
            {(userlevel === "50" || userlevel === "40" || userlevel === "30" || isTrainee) && (
              <>
                <Col xs={6} md={3} className="mb-4 ">
                  <Row>
                    <Col xs={12}>
                      <Input
                        disabled={disbaledEmp}
                        id="istranie"
                        type="checkbox"
                        checked={isTrainee}
                        onChange={() => {
                          setIschanged(true);
                          setIsTrainee(!isTrainee);
                        }}
                      // value={empFeed.emp_Absent}
                      />
                      <Label
                        className="mt-2 ms-2"
                        htmlFor="istranie"
                      >
                        {t("SystemUserpage.Employee")}
                        <Info24
                          id="tskRetInfo"
                        ></Info24>
                        <UncontrolledPopover
                          placement="right"
                          target="tskRetInfo"
                          trigger="hover focus"
                          popperClassName="popover"
                        >
                          <PopoverBody className="tooltip-msg">
                            {t("SystemUserpage.EmployeeCheckboxInfoMsg")}
                          </PopoverBody>
                        </UncontrolledPopover>
                      </Label>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
            {isTrainee && (
              <>
                <Col xs={6} md={3} className="mb-4">
                  {/* <div className="mt-4 approved-flexcontainer"> */}
                  <Input
                    id="longab"
                    type="checkbox"
                    checked={isLongTermAb}
                    onChange={() => {
                      setIschanged(true);
                      setIsLongTermAb(!isLongTermAb);
                    }}
                  // value={empFeed.emp_Absent}
                  />
                  <Label
                    className="ms-2 mt-3"
                    htmlFor="longab"
                  >
                    {t("SystemUserpage.Long Term Absentee")}
                  </Label>
                  {/* </div> */}
                </Col>
                <Col sm={12} md={6}>
                  <Label
                    className="mt-2 ms-2 mb-2"
                  >
                    {t("SystemUserpage.Employee Training Location")}{" "}
                    <span className="redstar">*</span>
                  </Label>
                  <Select
                    options={traineeLocations}
                    classNamePrefix="react-select"

                    onChange={(option) => {
                      setselectedtraineeLocation(option);
                      setIschanged(true);
                      settraineeLocationerr(false);
                    }}
                    styles={customStyles}
                    invalid={traineeLocationerr}
                    defaultValue={selectedtraineeLocation}
                    value={selectedtraineeLocation}
                  ></Select>
                  {traineeLocationerr && (
                    <span className="desclength errMsgoops">
                      {t("ErrorMessages.Required")}
                    </span>
                  )}
                </Col>
              </>
            )}
          </Row>
          {userData.userLevel === "10" & userlevel !== "50" ?

            <Row>
              <Col>
                <Input
                  id="longab"
                  type="checkbox"
                  checked={isOnlineAssessment}
                  onChange={() => {
                    setIschanged(true);
                    setIsOnlineAssessment(!isOnlineAssessment);
                  }}
                // value={empFeed.emp_Absent}
                />
                <Label
                  className="ms-2 mt-3"
                  htmlFor="longab"
                >
                  {t("SystemUserpage.OnlineAssessment")}
                </Label>
              </Col>
            </Row>
            :
            <></>
          }
        </Modal.Body >
        <Modal.Footer>
          <Button
            size="sm"
            color="standard-secondary"
            className="rounded-pill btn-style"
            onClick={() => {
              closeModal();
            }}
          >
            {t("Common.Cancel")}
          </Button>
          <Button
            size="sm"
            color="standard-primary"
            className="rounded-pill btn-style"
            onClick={() => {
              checkvalidation();
              clearspaces();

            }}
          >
            {t("Common.Save")}
          </Button>
        </Modal.Footer>
        {/* </LoadingOverlay> */}
      </Modal >
    </>
  );
}

export default Createsystemuser;
