import React, { useState } from "react";
import base from "../../../Services/BaseService";
import { Col, Row } from "reactstrap";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import "../../../Content/stylesheet/ManageData.css";
import { useTranslation } from "react-i18next";
import { Input, Label, Modal, Button } from '@bphxd/ds-core-react';

const CreateNewsFeed = (props) => {

  const { t } = useTranslation();
  var feed = {
    news_Headline: "",
    news_Desc: "",
    news_Priority: "false",
    text01: "N",
    dT_Posted_Date: 0,
    id: 0,
  };
  if (props.create === false) {
    feed = {
      news_Headline: props.newsFeed.news_Headline,
      news_Desc: props.newsFeed.news_Desc,
      news_Priority: props.newsFeed.news_Priority.toString(),
      text01: props.newsFeed.text01,
      dT_Posted_Date: 0,
      id: props.newsFeed.id,
    };
  }
  const [newsFeed, setNewsFeed] = useState(feed);
  const [headlineRemaining, setHandleRemaining] = useState(
    newsFeed.news_Headline.length
  );
  const [descRemaining, setDescriptionRemaining] = useState(
    newsFeed.news_Desc.length
  );
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [isChanged, setIsChanged] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [headlineError, setHeadlineError] = useState(false)
  const [storyError, setStoryError] = useState(false)

  const handleChange = (e) => {
    let elementValue = e.target.value;
    if (e.target.name === "news_Headline") {
      setHeadlineError(false)
      setHandleRemaining(elementValue.length)
    } else if (e.target.name === "news_Desc") {
      setStoryError(false);
      setDescriptionRemaining(elementValue.length)
    }
    setIsChanged(true);
    setSaveDisabled(false);
    setNewsFeed({ ...newsFeed, [e.target.name]: elementValue });
  };

  const handleDiscard = () => {
    setNewsFeed(feed);
    props.close(false);
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const saveNewsFeed = () => {
    newsFeed.news_Desc = newsFeed.news_Desc.trim();
    newsFeed.news_Headline = newsFeed.news_Headline.trim();

    let isValid = true;
    if (newsFeed.news_Headline === "") {
      setHeadlineError(true);
      isValid = false;
    }
    if (newsFeed.news_Desc === "") {
      setStoryError(true);
      isValid = false;
    }

    if (isValid) {
      if (props.create === false) {
        base.update(process.env.REACT_APP_NEWSFEED_UPDATE, newsFeed).then((response) => {
          props.getAll();
          handleDiscard();
          setNewsFeed({
            ...response.data,
            news_Priority: response.data.news_Priority.toString(),
          });
          setIsChanged(false);
          props.saveSuccess();
        })
          .catch((error) => {
            handleDiscard();
            props.saveerror();
          });
      } else {
        base.add(process.env.REACT_APP_NEWSFEED_ADD, newsFeed).then((response) => {
          props.getAll();
          handleDiscard();
          setNewsFeed({
            ...response.data,
            news_Priority: response.data.news_Priority.toString(),
          });
          setIsChanged(false);
          props.createSuccess();
        })
          .catch((error) => {
            handleDiscard();
            props.createerror();
          });
      }
    }

  };

  return (
    <>
      <Modal
        isOpen={props.isopen}
        centered
        className="modal-90"
      >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
            handleNo={saveNewsFeed}
          />
        )}
        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={cancelHandler}

        >
          <span className="modalHeaderGlobal ms-5">
            {props.create
              ? t("Newsfeedpage.Create News Feed")
              : t("Newsfeedpage.Edit News Feed")}
          </span>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col >
              <Label
                className=""
                htmlFor="news_Headline"
              >
                {t("Newsfeedpage.Headlines")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col >
              <Input
                id="news_Headline"
                name="news_Headline"
                className=""
                onChange={handleChange}
                maxLength={50}
                value={newsFeed.news_Headline}
                invalid={headlineError}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="desclength text-end" >
              {headlineRemaining}/50
            </Col>
          </Row>

          <Row>
            <Col >
              <Label
                className=""
                htmlFor="news_Desc"
              >
                {t("Newsfeedpage.Message")}{" "}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col >
              <Input
                type="textarea"
                id="news_Desc"
                name="news_Desc"
                className=""
                style={{ height: "350px" }}
                value={newsFeed.news_Desc}
                onChange={handleChange}
                maxLength={3000}
                invalid={storyError}
              ></Input>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="desclength text-end" >
              {descRemaining}/3000
            </Col>
          </Row>
          <Row>
            <Col xs="4" >
              <div className="form-group">
                <Label
                  htmlFor="priority"
                  className=""
                >
                  {t("Newsfeedpage.Priority")}
                </Label>
                <div className="">
                  <Input
                    type="radio"
                    name="news_Priority"
                    checked={newsFeed.news_Priority === "false"}
                    onChange={handleChange}
                    id="news_Priority"
                    value="false"
                  ></Input>
                  <Label
                    check
                    className="ps-3"
                    for="news_Priority"
                  >
                    {t("Newsfeedpage.Low")}
                  </Label>
                </div>
                <div className="">
                  <Input
                    type="radio"
                    name="news_Priority"
                    checked={newsFeed.news_Priority === "true"}
                    onChange={handleChange}
                    id="news_Priority"
                    value="true"
                  ></Input>
                  <Label
                    className="ps-3"
                    for="news_Priority"
                  >
                    {t("Newsfeedpage.High")}
                  </Label>
                </div>
              </div>
            </Col>
            <Col xs="8">
              <div className="form-group">
                <Label
                  htmlFor="text01"
                  className=""
                >
                  {t("Newsfeedpage.Display")}
                  <span className="text-danger">*</span>
                </Label>
                <div className="">
                  <Input
                    type="radio"
                    name="text01"
                    checked={newsFeed.text01 === "N"}
                    onChange={handleChange}
                    id="text01"
                    value="N"
                  ></Input>
                  <Label
                    check
                    className="ps-3"
                    htmlFor="text01"
                  >
                    {t("Newsfeedpage.No")}
                  </Label>
                </div>
                <div className="">
                  <Input
                    type="radio"
                    name="text01"
                    checked={newsFeed.text01 === "Y"}
                    onChange={handleChange}
                    id="text01"
                    value="Y"
                  ></Input>
                  <Label
                    check
                    className="ps-3"
                    for="text01"
                  >
                    {t("Newsfeedpage.Yes")}
                  </Label>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button color="standard-secondary" className="rounded-pill btn-style" size="sm" onClick={cancelHandler}>
            {t("Common.Cancel")}
          </Button>{" "}
          <Button
            size="sm"
            disabled={saveDisabled}
            color="standard-primary"
            onClick={() => {
              setSaveDisabled(true);
              saveNewsFeed();
            }
            }
            className="rounded-pill btn-style"
          >
            {t("Common.Save")}
          </Button>{" "}
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default CreateNewsFeed;
