import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useModuleContext } from "../../../Shared/moduleContext";
import Select from "react-select";
import { Trash32 } from "@bphxd/ds-core-react/lib/icons";
import { Button, Input, Label } from '@bphxd/ds-core-react';

const TTBNotesTab = ({ setIsChangedTTB, invalid, setInvalid }) => {
    const { t } = useTranslation();
    const { moduleData, moduleLanguagePreference, setModuleData, globalUser } = useModuleContext();
    const [TTBImageSize, setTTBImageSize] = useState(moduleData.find(item => item.fk_LanguageID === "en-GB")?.s_IMAGE);
    const [TTB1ImageSize, setTTB1ImageSize] = useState(moduleData.find(item => item.fk_LanguageID === "en-GB")?.s_IMAGE1);
    const [TTB2ImageSize, setTTB2ImageSize] = useState(moduleData.find(item => item.fk_LanguageID === "en-GB")?.s_IMAGE2);
    const [previousImage, setPreviousImage] = useState(moduleData.find(item => item.fk_LanguageID === "en-GB")?.n_IMAGE)
    const [previousImage1, setPreviousImage1] = useState(moduleData.find(item => item.fk_LanguageID === "en-GB")?.n_IMAGE1)
    const [previousImage2, setPreviousImage2] = useState(moduleData.find(item => item.fk_LanguageID === "en-GB")?.n_IMAGE2)
    const [disabled, setDisabled] = useState([]);
    const [theInputKey, setTheInputKey] = useState("")
    const [theInputKey1, setTheInputKey1] = useState("")
    const [theInputKey2, setTheInputKey2] = useState("")
    const [imageErrorMessage, setImageErrorMessage] = useState("");
    const [image1ErrorMessage, setImage1ErrorMessage] = useState("");
    const [image2ErrorMessage, setImage2ErrorMessage] = useState("");
    const imageSizes = [
        {
            id: 1,
            label: "Medium",
            value: "M",
        },
        {
            id: 2,
            label: "Large",
            value: "L",
        }
    ]

    function convertUnicode(input) {
        if (input != null) {
            return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
            });
        }
    }

    useEffect(() => {
        let disabledFields = [];

        if (globalUser === "RADMIN" && moduleData[0].task_Site === "GLOBAL" && moduleLanguagePreference !== "en-GB") {
            disabledFields.push("file-upload", "imageTTB", "file-upload1", "imageTTB1", "file-upload2", "imageTTB2", "trash", "trash1", "trash2")
        }
        if ((globalUser === "RADMIN" && moduleData[0].task_Site === "GLOBAL" && moduleLanguagePreference === "en-GB") || (globalUser === "RUSER" && moduleData[0].task_Site === "GLOBAL")) {
            disabledFields.push("aim", "trash", "trash1", "trash2", "learning_Outcomes", "notes", "file-upload", "imageTTB", "file-upload1", "imageTTB1", "file-upload2", "imageTTB2")
        }
        if (moduleData[0].task_Site === "SITE" && moduleData[0].taskID !== moduleData[0].global_TaskID) {
            disabledFields.push("file-upload", "imageTTB", "file-upload1", "imageTTB1", "file-upload2", "imageTTB2", "trash", "trash1", "trash2")
        }
        if ((moduleData[0].taskID === moduleData[0].global_TaskID)) {



            if (!previousImage) {
                disabledFields.push("file-upload1", "imageTTB1", "file-upload2", "imageTTB2", "trash1", "trash2", "imageTTB", "trash")
            }
            if (!previousImage1) {
                disabledFields.push("file-upload2", "imageTTB2", "imageTTB1", "trash2", "trash1")
            } else {
                disabledFields.push("trash")
            }

            if (!previousImage2) {
                disabledFields.push("imageTTB2", "trash2")
            } else {
                disabledFields.push("trash1")
            }
        }
        setDisabled(disabledFields);
    }, [moduleLanguagePreference, moduleData, globalUser, previousImage, previousImage1, previousImage2]);

    const handleChangeTTB = (id, value) => {
        setIsChangedTTB(true)
        setModuleData((prevModuleData) => {
            const updatedLanguageID = moduleLanguagePreference;
            var updatedItemIndex
            if (id === "s_IMAGE" || id === "s_IMAGE1" || id === "s_IMAGE2") {
                updatedItemIndex = prevModuleData.findIndex(item => item.fk_LanguageID === "en-GB");
            } else {
                updatedItemIndex = prevModuleData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
            }
            if (updatedItemIndex !== -1) {
                const updatedItem = {
                    ...prevModuleData[updatedItemIndex],
                    [id]: convertUnicode(value)
                };
                const updatedQuestionData = [
                    ...prevModuleData.slice(0, updatedItemIndex),
                    updatedItem,
                    ...prevModuleData.slice(updatedItemIndex + 1)
                ];
                return updatedQuestionData;
            }
            return prevModuleData;
        });
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(base64ToHex(reader.result));
        };
        reader.onerror = function (error) {
        };
    };

    const base64ToHex = (str) => {
        str = str.replace("data:image/jpeg;base64,", "");
        const raw = atob(str);
        let result = "";
        for (let i = 0; i < raw.length; i++) {
            const hex = raw.charCodeAt(i).toString(16);
            result += hex.length === 2 ? hex : "0" + hex;
        }
        return "D=" + result.toUpperCase();
    };

    const handleFileUpload = (e, imageNo) => {
        setIsChangedTTB(true)
        var selectedFile = e.target.files[0];
        var idxDot = selectedFile.name.lastIndexOf(".") + 1;
        var extFile = selectedFile.name
            .substr(idxDot, selectedFile.name.length)
            .toLowerCase();
        let bLabel;
        let nLabel;
        let disabledFields = disabled
        if (e.target.files.length && (extFile === "jpg" || extFile === "jpeg")) {
            var imgZiseInKB = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (imgZiseInKB <= 150) {
                getBase64(e.target.files[0], (result) => {
                    if (imageNo === "") {
                        // if (previousImage) {
                        //     setPreviousImage("")
                        //     setTTBImageSize("")
                        // }
                        setPreviousImage(selectedFile.name);
                        bLabel = "b_IMAGE"
                        nLabel = "n_IMAGE"
                        document.getElementById("imageerror").style.display = "none";
                        disabledFields = disabledFields.filter((element) => element !== "file-upload1")
                        disabledFields = disabledFields.filter((element) => element !== "imageTTB")

                    }
                    if (imageNo === "1") {
                        setPreviousImage1(selectedFile.name);
                        bLabel = "b_IMAGE1"
                        nLabel = "n_IMAGE1"
                        document.getElementById("image1error").style.display = "none";
                        disabledFields = disabledFields.filter((element) => element !== "file-upload2")
                        disabledFields = disabledFields.filter((element) => element !== "imageTTB1")
                    }
                    if (imageNo === "2") {

                        setPreviousImage2(selectedFile.name);
                        bLabel = "b_IMAGE2"
                        nLabel = "n_IMAGE2"
                        document.getElementById("image2error").style.display = "none";
                        disabledFields = disabledFields.filter(element => element !== "imageTTB2")
                    }
                    setDisabled(disabledFields)
                    setModuleData((prevModuleData) => {
                        const updatedLanguageID = "en-GB"
                        const updatedItemIndex = prevModuleData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
                        if (updatedItemIndex !== -1) {
                            const updatedItem = {
                                ...prevModuleData[updatedItemIndex],
                                [bLabel]: result,
                                [nLabel]: selectedFile.name
                            };
                            const updatedQuestionData = [
                                ...prevModuleData.slice(0, updatedItemIndex),
                                updatedItem,
                                ...prevModuleData.slice(updatedItemIndex + 1)
                            ];
                            return updatedQuestionData;
                        }
                        return prevModuleData;
                    });
                });
            } else {
                if (imageNo === "") {
                    setImageErrorMessage(
                        t("Curriculumpage.ImageSizeExceeded")
                    );
                    document.getElementById("imageerror").style.display = "";
                }
                if (imageNo === "1") {
                    setImage1ErrorMessage(
                        t("Curriculumpage.ImageSizeExceeded")
                    );
                    document.getElementById("image1error").style.display = "";
                }
                if (imageNo === "2") {
                    setImage2ErrorMessage(
                        t("Curriculumpage.ImageSizeExceeded")
                    );
                    document.getElementById("image2error").style.display = "";
                }
            }
        } else {
            if (extFile !== "jpg" || extFile !== "jpeg") {
                if (imageNo === "") {
                    setImageErrorMessage(t("Curriculumpage.JpegFilesAllowed"));
                    document.getElementById("imageerror").style.display = "";
                }
                if (imageNo === "1") {
                    setImage1ErrorMessage(t("Curriculumpage.JpegFilesAllowed"));
                    document.getElementById("image1error").style.display = "";
                }
                if (imageNo === "2") {
                    setImage2ErrorMessage(t("Curriculumpage.JpegFilesAllowed"));
                    document.getElementById("image2error").style.display = "";
                }
            }
        }
    };

    const removeImageClick = (imageNo) => {
        let nimg = "n_IMAGE" + imageNo
        let bimg = "b_IMAGE" + imageNo
        let simg = "s_IMAGE" + imageNo

        handleChangeTTB(nimg, null)
        handleChangeTTB(bimg, null)
        handleChangeTTB(simg, null)

        let randomString = Math.random().toString(36);
        setTheInputKey(randomString)
        let disabledFields = disabled;
        switch (imageNo) {
            case "":
                // if (previousImage) {
                setPreviousImage("")
                // }
                setTTBImageSize("")
                setTheInputKey(randomString)
                setImageErrorMessage("");
                // disabledFields.push("imageTTB", "file-upload1")
                break;
            case "1":
                // if (previousImage1) {
                setPreviousImage1("")
                // }
                setTTB1ImageSize("")
                setTheInputKey1(randomString)
                setImage1ErrorMessage("");

                // disabledFields.push("imageTTB1", "file-upload2")
                break;
            case "2":
                // if (previousImage2) {
                setPreviousImage2("")
                // }
                setTTB2ImageSize("")
                setTheInputKey2(randomString)
                setImage2ErrorMessage("");
                // disabledFields.push("imageTTB2")
                break;
            default:
                break;
        }
        setDisabled(disabledFields)
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
    };

    return (
        <>
            <div className="m-3 mt-0">
                <div className="text-end mb-2 me-4 mt-2">
                    <div className="p-2" />
                </div>
                <Row className="">
                    <Col lg="12">
                        <Label >
                            {t("Curriculumpage.Aim")}
                        </Label>
                        <Input type="textarea"
                            id="aim"
                            name="Aim"
                            style={{ height: "100px" }}
                            maxLength={1200}
                            onChange={(e) => handleChangeTTB("aim", e.target.value)}
                            value={moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.aim || ''}
                            disabled={disabled.includes("aim")}
                        >
                        </Input>
                        <label className="float-end char-limit h6 mt-1 ">
                            {(moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.aim || "").length || 0}/1200
                        </label>
                    </Col>
                    <Col lg="12" className="mt-3">
                        <Label >
                            {t("Curriculumpage.Learning_Outcomes")}
                        </Label>
                        <Input
                            type="textarea"
                            id="learning_Outcomes"
                            name="Learning_Outcomes"
                            style={{ height: "100px" }}
                            maxLength={1500}
                            disabled={disabled.includes("learning_Outcomes")}
                            onChange={(e) => handleChangeTTB("learning_Outcomes", e.target.value)}
                            value={moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.learning_Outcomes || ''}
                        >
                        </Input>
                        <label className="float-end char-limit h6 mt-1">
                            {(moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.learning_Outcomes || "").length || 0}/1500
                        </label>
                    </Col>
                    <Col lg="12" className="mt-3">
                        <Label >
                            {t("Curriculumpage.Additional_Notes")}
                        </Label>
                        <Input type="textarea"
                            id="notes"
                            name="Notes"
                            style={{ height: "100px" }}
                            maxLength={3000}
                            disabled={disabled.includes("notes")}
                            onChange={(e) => handleChangeTTB("notes", e.target.value)}
                            value={moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.notes || ''}
                        >
                        </Input>
                        <label className="float-end char-limit h6 mt-1">
                            {(moduleData.find(item => item.fk_LanguageID === moduleLanguagePreference)?.notes || "").length || 0}/3000
                        </label>
                    </Col>
                </Row>
                <Row className="">
                    <Col lg="7" >
                        <Label className="">
                            {" "}
                            {t("Curriculumpage.Appendix_Uploads")}
                        </Label>
                        <Row className="mt-3 mb-3">
                            <Col lg="8">
                                {previousImage ?
                                    <Input type="text" disabled value={previousImage} />
                                    :
                                    <Input
                                        id="file-upload"
                                        accept="image/jpeg"
                                        type="file"
                                        onChange={(e) => {
                                            handleFileUpload(e, "");
                                        }}
                                        key={theInputKey || ''}
                                        disabled={disabled.includes("file-upload")}
                                    />
                                }

                                <div
                                    className="  text-danger h7 "
                                    id="imageerror"
                                    style={{
                                        display: "none",
                                        color: "red"
                                    }}
                                >
                                    {imageErrorMessage}
                                </div>
                            </Col>
                            <Col lg="3">
                                <Select
                                    id="imageTTB"
                                    name="imageTTB"
                                    placeholder={"Size"}
                                    className=''
                                    key={`my_unique_select_key__${TTBImageSize}`}
                                    isDisabled={disabled.includes("imageTTB")}
                                    value={imageSizes.find((option) => option.value === TTBImageSize)}
                                    options={imageSizes}
                                    onChange={(e) => {
                                        setTTBImageSize(e.value)
                                        handleChangeTTB("s_IMAGE", e.value)
                                        setInvalid(prevInvalid =>
                                            prevInvalid.filter(element => element !== "imageTTB")
                                        );
                                    }}
                                    classNamePrefix="react-select"
                                    invalid={invalid.includes('imageTTB')}
                                    styles={customStyles}
                                />
                            </Col>
                            <Col lg="1">
                                <Button
                                    className={"removebtn pointerCursor m-0 p-0"}
                                    style={{ backgroundColor: "#fff", borderColor: "#fff" }}
                                    disabled={disabled.includes("trash")}

                                >
                                    <Trash32
                                        className={"removebtn pointerCursor"}
                                        onClick={() => {
                                            removeImageClick("");
                                            setInvalid(prevInvalid =>
                                                prevInvalid.filter(element => element !== "imageTTB")
                                            );
                                        }}
                                    />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col lg="8">
                                {previousImage1 ?
                                    <Input type="text" disabled value={previousImage1} />
                                    :
                                    <Input
                                        id="file-upload1"
                                        accept="image/jpeg"
                                        type="file"
                                        onChange={(e) => {
                                            handleFileUpload(e, "1");
                                        }}
                                        disabled={disabled.includes("file-upload1")}
                                        key={theInputKey1 || ''}
                                        className=""
                                    />
                                }
                                <span
                                    className="  text-danger h7 mb-1 "
                                    id="image1error"
                                    style={{
                                        display: "none",
                                        color: "red"
                                    }}
                                >
                                    {image1ErrorMessage}
                                </span>
                            </Col>
                            <Col lg="3">
                                <Select
                                    id="imageTTB1"
                                    name="imageTTB1"
                                    placeholder={"Size"}
                                    key={`my_unique_select_key__${TTB1ImageSize}`}
                                    value={imageSizes.find((option) => option.value === TTB1ImageSize)}
                                    options={imageSizes}
                                    isDisabled={disabled.includes("imageTTB1")}
                                    onChange={(e) => {
                                        setTTB1ImageSize(e.value)
                                        handleChangeTTB("s_IMAGE1", e.value)
                                        setInvalid(prevInvalid =>
                                            prevInvalid.filter(element => element !== "imageTTB1")
                                        );
                                    }}
                                    className=''
                                    classNamePrefix="react-select"
                                    invalid={invalid.includes('imageTTB1')}
                                    styles={customStyles}
                                />
                            </Col>
                            <Col lg="1">
                                <Button
                                    disabled={disabled.includes("trash1")}
                                    className={"m-0 p-0"}
                                    style={{ backgroundColor: "#fff", borderColor: "#fff" }}
                                >
                                    <Trash32
                                        onClick={() => {
                                            removeImageClick("1");
                                            setInvalid(prevInvalid =>
                                                prevInvalid.filter(element => element !== "imageTTB1")
                                            );
                                        }}
                                    />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col lg="8">
                                {previousImage2 ?
                                    <Input type="text" disabled value={previousImage2} />
                                    :
                                    <Input
                                        id="file-upload2"
                                        accept="image/jpeg"
                                        type="file"
                                        onChange={(e) => {
                                            handleFileUpload(e, "2");
                                        }}
                                        disabled={disabled.includes("file-upload2")}
                                        key={theInputKey2 || ''}
                                        className=""
                                    />
                                }
                                <div
                                    className="  text-danger h7 "
                                    id="image2error"
                                    style={{
                                        display: "none",
                                        color: "red"
                                    }}
                                >
                                    {image2ErrorMessage}
                                </div>
                            </Col>
                            <Col lg="3">
                                <Select
                                    id="imageTTB2"
                                    name="imageTTB2"
                                    placeholder={"Size"}
                                    key={`my_unique_select_key__${TTB2ImageSize}`}
                                    value={imageSizes.find((option) => option.value === TTB2ImageSize)}
                                    options={imageSizes}
                                    onChange={(e) => {
                                        setTTB2ImageSize(e.value)
                                        handleChangeTTB("s_IMAGE2", e.value)
                                        setInvalid(prevInvalid =>
                                            prevInvalid.filter(element => element !== "imageTTB2")
                                        );
                                    }}
                                    isDisabled={disabled.includes("imageTTB2")}
                                    classNamePrefix="react-select"
                                    className=''
                                    invalid={invalid.includes('imageTTB2')}
                                    styles={customStyles}
                                />
                            </Col>
                            <Col lg="1">
                                <Button
                                    disabled={disabled.includes("trash2")}
                                    className={"removebtn pointerCursor m-0 p-0"} //imgupload__removeitem
                                    style={{ backgroundColor: "#fff", borderColor: "#fff" }}
                                >
                                    <Trash32
                                        className={"removebtn pointerCursor"}
                                        onClick={() => {
                                            removeImageClick("2");
                                            setInvalid(prevInvalid =>
                                                prevInvalid.filter(element => element !== "imageTTB2")
                                            );
                                        }}
                                    />
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="5">
                        <div className="mb-4">
                            <Label>
                                {t("Curriculumpage.Sample_Images")}
                                {":"}
                            </Label>
                            <div style={{ display: "flex", marginTop: "2%" }}>
                                <div>
                                    <img
                                        src={
                                            require("../../../../Content/Images/airbpImages/AIR BP EDITED-17.png")
                                        }
                                        alt={"imageval"}
                                        style={{ width: "50%", height: "90%" }}
                                    ></img>
                                    <br />
                                    <span className={"imgSize"}>
                                        <span>{t("Curriculumpage.Medium")} (5.6cm x 13cm)</span>
                                    </span>
                                </div>
                                <div style={{ marginLeft: "-30%" }}>
                                    <img
                                        src={
                                            require("../../../../Content/Images/airbpImages/AIR BP EDITED-17.png")
                                        }
                                        alt={"imageval"}
                                        style={{ width: "50%", height: "115%" }}
                                    ></img>
                                    <br />
                                    <span className={"imgSize"}>
                                        <span>{t("Curriculumpage.Large")} (8.4cm x 13cm)</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row >
            </div >
        </>
    );
}

export default TTBNotesTab;
