const EntireDashboard = [
    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 3",
        "reportType": null,
        "chartReport": "T_COMP1",
        "chartType": "51",
        "chartPos": 1,
        "rVal1": null,
        "rVal2": "ASSESSMENT EXPIRY",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": null,
        "dt_eDate": 45175.4592939,
        "dt_up_date": 45299.6378125,
        "up_user": "3318WP",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1815,
        "fk_UserID": "3318WP",
        "favourite": false,
    },
    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 1",
        "reportType": "EVALUATION",
        "chartReport": "T_COMP1",
        "chartType": "4",
        "chartPos": 2,
        "rVal1": null,
        "rVal2": "COMPETENCY DISTRIBUTION",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": 44080.4514699,
        "dt_eDate": 45175.4514699,
        "dt_up_date": 45175.6062731,
        "up_user": "3318wp",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1814,
        "fk_UserID": "3318WP",
        "favourite": false,
    },
    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 2",
        "reportType": null,
        "chartReport": "T_COMP1",
        "chartType": "51",
        "chartPos": 3,
        "rVal1": null,
        "rVal2": "DAT PART 1 (TRN006)",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": null,
        "dt_eDate": 42971.4190277,
        "dt_up_date": 45299.6411574,
        "up_user": "3318WP",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1813,
        "fk_UserID": "3318WP",
        "favourite": false,
    },

    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 1",
        "reportType": "EVALUATION",
        "chartReport": "T_COMP1",
        "chartType": "51",
        "chartPos": 4,
        "rVal1": null,
        "rVal2": "DAT PART 2 (TRN007)",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": 44080.4514699,
        "dt_eDate": 45175.4514699,
        "dt_up_date": 45299.7059143,
        "up_user": "3318WP",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1814,
        "fk_UserID": "3318WP",
        "favourite": false,
    },
    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 2",
        "reportType": "EVALUATION",
        "chartReport": "T_COMP1",
        "chartType": "5",
        "chartPos": 5,
        "rVal1": null,
        "rVal2": "SYLLABUS QUESTION RESULTS",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": null,
        "dt_eDate": 42971.4190277,
        "dt_up_date": 45299.7060185,
        "up_user": "3318WP",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1813,
        "fk_UserID": "3318WP",
        "favourite": false,
    },
    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 3",
        "reportType": "EVALUATION",
        "chartReport": "T_COMP1",
        "chartType": "51",
        "chartPos": 6,
        "rVal1": null,
        "rVal2": "UPK QUESTION RESULTS",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": null,
        "dt_eDate": 45175.4592939,
        "dt_up_date": 45299.7060648,
        "up_user": "3318WP",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1815,
        "fk_UserID": "3318WP",
        "favourite": false,
    }

]

export default EntireDashboard;