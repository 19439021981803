import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom-v5-compat";
import "../../Content/stylesheet/common.css";
import "../../Content/stylesheet/traineeassessment.css";
import CriticalIcon from "../../Content/Images/Icons/critical-hazard.png";
import base from "../../Services/BaseService";
import GiveAssessment from "./GiveAssessment";
import LoadingView from "../Shared/Layout/LoadingView";
import { Button, CardBody, Card, CardHeader, CardFooter } from "reactstrap";
import { BulletList24, Clock24 } from "@bphxd/ds-core-react/lib/icons";
import { useTranslation } from "react-i18next";
import TitleBanner from "../Shared/Layout/TitleBanner";

const TraineeAssessmentLandingPage = (props) => {
  const { t, i18n } = useTranslation();
  const { onlineAssessments } = props;
  const { assessmentID } = useParams();
  const [onlineAssessment] = onlineAssessments.filter((assessment) => assessment.assessmentID === assessmentID);
  const [initialOnlineAssessment, setInitialOnlineAssessment] = useState(null);

  const { questionCount, employeeId, taskNumber, assessmentType, assessmentTime, trainerName } = initialOnlineAssessment || {};

  useEffect(() => {
    if (!initialOnlineAssessment) {
      setInitialOnlineAssessment(onlineAssessment);
    }
  }, [initialOnlineAssessment, onlineAssessment]);

  const assessmentTypeHeading = assessmentType === "TAR" ? "TAR" : "Classroom";
  const TOTAL_TIME = { timeInMinutes: assessmentTime, timeInSeconds: 0, };
  const [questionsData, setQuestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  function getQuestionData() {
    base.getAll("assessmentdata/Qry/" + assessmentID + "/question/" + i18n.language).then((queData) => {
      for (let i = 0; i < queData.length; i++) {
        queData[i].selectedAnswers = "";
      }
      setQuestionsData(queData);
      handleLoadingOverlay(false);
    });
  }

  function handleStartTest() {
    handleLoadingOverlay(true);
    getQuestionData();
    document.getElementById("nav-wrapper").classList.add("nav-wrapper-pointer");
    document.getElementById("main-navbar-containerID").classList.add("disable-containerClick");
  }

  function handleLoadingOverlay(toggleState) {
    setIsLoading(toggleState)
  }

  const InstructionsComponent = () => {
    return (
      <>

        <div className="container-fluid" >
          <div className="container trainee-cardcontainer" style={{ paddingBlock: "12px" }}>
            <Card className="trainee-card">
              <CardHeader className="pt-4">
                <div className="online-assessment__heading">
                  {t("MyTrainingpage.Instructions")}
                </div>
              </CardHeader>
              <CardBody style={{ paddingBlock: "26px" }}>
                <div className="cardPadding">
                  <div className="instructions-keyitemcontainer">
                    <div className="instructions__keyitem">
                      <BulletList24 />
                      <div>
                        {`${questionCount} `}
                        {t("MyTrainingpage.multiple choice questions")}
                      </div>
                    </div>
                    <div className="instructions__keyitem">
                      <Clock24 />
                      <div>
                        {`${TOTAL_TIME.timeInMinutes}`}{" "}
                        {t("MyTrainingpage.minutes")}
                        {TOTAL_TIME.timeInSeconds > 0
                          ? ` ${TOTAL_TIME.timeInSeconds} seconds `
                          : ""}
                      </div>
                    </div>
                  </div>

                  <div className="subText py-3 instructions__headingfont pt-4">
                    {t("MyTrainingpage.Before you start")}:
                  </div>
                  <div className="">
                    <ul className="instructions__li-container">
                      <li className="instruction_item">
                        {t("MyTrainingpage.Instuction1")}

                      </li>
                      <li className="instruction_item">
                        {t("MyTrainingpage.Instuction2")}
                      </li>
                      <li className="instruction_item">
                        {t("MyTrainingpage.Instuction3")}

                      </li>
                      <li className="instruction_item">
                        <div className="">
                          <div className="pb-3">
                            {t("MyTrainingpage.Instuction4")}
                          </div>

                          <div className="score-table">
                            <table className="instruction_item" style={{ width: "500px" }} >
                              <thead>
                                <tr>
                                  <th className="" >
                                    {t("MyTrainingpage.Score Achieved")}
                                  </th>
                                  <th className="" >
                                    {t("MyTrainingpage.Equivalent Level")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{"100"}</td>
                                  <td>L3</td>
                                </tr>
                                <tr>
                                  <td>{"75 - 99"}</td>
                                  <td>L2</td>
                                </tr>
                                <tr>
                                  <td>{"≤ 74"}</td>
                                  <td>L1</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </li>
                      <li className="instruction_item">

                        {t("MyTrainingpage.Instuction5")}
                        <ul style={{ listStyleType: "disc" }}>
                          <li className="instruction_item">

                            {t("MyTrainingpage.Instuction6")}
                            <span>
                              <img
                                src={CriticalIcon}
                                alt="Critical Question"
                                className="instructions__critical-icon"
                              />
                            </span>

                          </li>
                          <li className="instruction_item">
                            {t("MyTrainingpage.Instuction7")}

                          </li>
                        </ul>


                      </li>
                      <li className="instruction_item">
                        {t("MyTrainingpage.Instuction8")}

                      </li>
                    </ul>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <div className="instructions__btn-container">
                  <Button
                    className="rounded-pill btn-style"
                    color="standard-primary"
                    size="sm"
                    onClick={() => handleStartTest()}
                  >
                    {t("MyTrainingpage.Start")}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>

      </>
    );
  };

  return (
    <>
      {isLoading && <LoadingView />}
      <div className="">
        <TitleBanner
          heading={taskNumber}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("MyTrainingpage.MyTraining"), assessmentTypeHeading === "TAR"
            ? t(`MyTrainingpage.Task Assessment Record`)
            : t(`MyTrainingpage.${assessmentTypeHeading} Assessment`)]}
          showButton1={false} y

          showButton2={false}
          showButton3={false}
        />
        <main>
          {questionsData.length === 0 && <InstructionsComponent />}
          {questionsData.length > 0 && (
            <GiveAssessment
              questionsData={questionsData}
              assessmentID={assessmentID}
              empID={employeeId}
              TOTAL_TIME={TOTAL_TIME}
              assessmentType={assessmentType}
              taskNumber={taskNumber}
              trainerName={trainerName}
              toggleLoadingOverlay={handleLoadingOverlay}
            />
          )}
        </main>
      </div>
    </>
  );
};

export default TraineeAssessmentLandingPage;
