import { useEffect, useState, useCallback, useRef } from "react";
import base from "../../../Services/BaseService";
import { Input } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import AssignEmployees from "../AssignEmployeesToTask/AssignEmployeesNew";
import "../../../Content/stylesheet/manageemployees.css";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/assignModuleToEmployee.css";
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { AddUser32 } from "@bphxd/ds-core-react/lib/icons";
import LoadingView from "../../Shared/Layout/LoadingView";
import { createRoot } from "react-dom/client";

const AssignEmployeesToModuleLanding = (props) => {
  const [isloader, setIsloader] = useState(false);
  const { t } = useTranslation();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridData, setGridData] = useState();
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [assignEmpPopup, setAssignEmpPopup] = useState(false);
  const [taskID, setTaskID] = useState("");
  const [assignTaskData, setassignTaskData] = useState({});
  const [locationID, setLocationID] = useState("");
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllTasks();
    initGridVar();
    // eslint-disable-next-line
  }, []);

  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  // modify pageSize
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: true,
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("AssignEmppage.LandingGridHeader.MODULE"),
        headerTooltip: t("AssignEmppage.LandingGridHeader.MODULE"),
        field: "task_Number",
        resizable: false,
        suppressMovable: true,
        minWidth: 200,
        maxWidth: 200,
        sort: "asc",
        comparator: customComparator,
      },
      {
        headerName: t("AssignEmppage.LandingGridHeader.NAME"),
        headerTooltip: t("AssignEmppage.LandingGridHeader.NAME"),
        field: "task_Name",
        minWidth: 0,
        maxWidth: 900,
        resizable: false,
        headerClass: 'sm-hide-header', cellClass: 'sm-hide-cell',
        suppressMovable: true,
      },

      {
        headerName: t("AssignEmppage.LandingGridHeader.ASSIGN_EMPLOYEE"),
        headerTooltip: t("AssignEmppage.LandingGridHeader.ASSIGN_EMPLOYEE"),
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 200,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressMenu: true,
        cellRenderer: PeopleIconCellRenderer,
      },
    ]);
  };
  class PeopleIconCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <AddUser32
            className="pointer"
            onClick={() => AssignEmployeesModuleClick(params.node.data)}
          />

        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  const getAllTasks = () => {
    let url = "task/qry/null/null/Y";

    base.getAll(url).then((res) => {
      var data = res.filter((x) => x.rel_rec !== 0);
      setGridData(data);
    });

  };

  const AssignEmployeesModuleClick = (params) => {

    setTimeout(() => {
      base.get("task/get", params.taskID).then((response) => {
        if (response !== undefined) {
          var locationID = "";
          if (response.fk_CountryID !== "" && response.fk_CountryID != null)
            locationID = response.fk_CountryID;
          if (
            response.fk_LocationID !== "" &&
            response.fk_LocationID != null
          )
            locationID = response.fk_LocationID;
          if (response.fk_PUID !== "" && response.fk_PUID != null)
            locationID = response.fk_PUID;

          setLocationID(locationID);
          setassignTaskData(response);
          setTaskID(params.taskID);
          setAssignEmpPopup("true");
        }
      });
    }, 300);
  };

  const closeAssignEmployee = () => {
    setAssignEmpPopup(false);
  };

  return (
    <>
      <div>
        {isloader &&
          <LoadingView />
        }

        <TitleBanner
          heading={t("AssignEmppage.Assign_Employees_to_Module")}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("AssignEmppage.Manage Data"), t("AssignEmppage.Assign_Employees_to_Module")]}
          showButton1={false}
          showButton2={false}
          showButton3={false}
        />
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="notifications col-lg-3" style={{ top: "30%" }}>
              {/* col-lg-3 */}
              <div style={{ marginLeft: "-100%", marginRight: "-53%" }}></div>
            </div>
          </div>
        </div>

        <div className="ps-7 pe-7 pb-3 pt-4">
          <div className="gridDiv">

            <div
              className="ag-theme-material gridHtWd "
              style={{ position: 'relative' }}
            >
              <div
                className="rowDataInput"
              >
                {gridData ? (
                  <Input type="select" onChange={onPageSizeChanged} id="page-size" defaultValue={10} >
                    <option value="10" >10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                  </Input>
                ) : (
                  ""
                )}

              </div>
              <AgGridReact
                ref={gridRef}
                columnDefs={gridColDefs}
                rowData={gridData}
                suppressMenuHide={true}
                overlayNoRowsTemplate="No records found"
                defaultColDef={gridDefaultColDef}
                pagination={true}
                paginationPageSize={10}
                suppressScrollOnNewData={true}
                suppressHorizontalScroll={false}
                enableRangeSelection={true}
                onFilterChanged={onFilterChanged}
                postProcessPopup={postProcessPopup}
              ></AgGridReact>

            </div>
          </div>
        </div>
        <div className="col-lg-12 mb-3 p1-0 pr-1 " style={{ width: "98.9%" }}>
          <div className="mt-8"></div>
          {assignEmpPopup ? (
            <AssignEmployees close={closeAssignEmployee} isopen={true} taskID={taskID} locationID={locationID} empModel={assignTaskData} reload={() => { setIsloader(true); }} reloadDone={() => { setIsloader(false); }} />
          ) : (
            ""
          )}
        </div>
      </div>
      {/* </LoadingOverlay> */}
    </>
  );
};
export default AssignEmployeesToModuleLanding;
