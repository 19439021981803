const ComplianceData = [
    {
        "chartLevel": "PERSONAL",
        "chartName": "Chart 3",
        "reportType": null,
        "chartReport": "T_COMP1",
        "chartType": "5",
        "chartPos": 1,
        "rVal1": null,
        "rVal2": "COMPLIANCE ANALYSIS",
        "rVal3": null,
        "rVal4": null,
        "rVal5": null,
        "rVal6": null,
        "rVal7": null,
        "rVal8": null,
        "rVal9": null,
        "dt_sDate": null,
        "dt_eDate": 45175.4592939,
        "dt_up_date": 45299.6378125,
        "up_user": "3318WP",
        "rel_rec": "1",
        "sysid": 1,
        "id": 1815,
        "fk_UserID": "3318WP",
        "favourite": null,
    }
]

export default ComplianceData;