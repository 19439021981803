import { useEffect, useState, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { DropdownItem, DropdownMenu } from "reactstrap";
import base from "../../../Services/BaseService";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew1 from "../../Toast/ErrorToastNew1";
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/common.css";
import { Input } from "reactstrap";
import React from "react";
import CreateQuestion from "./CreateQuestion";
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { Trash32, Edit32 } from "@bphxd/ds-core-react/lib/icons";
import { createRoot } from "react-dom/client";
import { useUser } from "../../Shared/userContext";


const ManageQuestion = (props) => {
  const { languagePreference, globalUser } = useUser();
  const { t } = useTranslation();
  const [gridData, setGridData] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [showToast, setShowToast] = useState({ Name: "", show: true });
  const [questionType, setQuestionType] = useState({ Name: "", show: false });
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [editQuestionUPK, setEditQuestionUPK] = useState({});
  const [editModalUPK, setEditModalUPK] = useState(false);
  const [createModalUPK, setCreateModalUPK] = useState(false);
  const [deleteQuestion, setDeleteQuestion] = useState({});
  const [showError, setShowError] = useState(false);
  const [deleteQuestionConfirmToast, setDeleteQuestionConfirmToast] = useState(false);
  const [ddlPUVal, setddlPUVal] = useState([]);
  const [ddlLocationVal, setddlLocationVal] = useState([]);
  const [ddlCountryVal, setddlCountryVal] = useState([]);
  const [ddlAnswerVal, setddlAnswerVal] = useState([]);
  const [ddlVal, setddlVal] = useState([]);
  const [ddlDrvCompVal, setddlDrvCompVal] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initGridVar();
    getAPIData();
    // eslint-disable-next-line
  }, []);

  const getAPIData = () => {
    base.getAll("performanceunit/Qry").then((res) => {
      setddlPUVal(res);
    });
    base.getAll("Location/Qry").then((res) => {
      setddlLocationVal(res);
    });
    base.getAll("Country/Qry").then((res) => {
      setddlCountryVal(res);
    });
    base.getAll("drop/frm/ANS").then((res) => {
      setddlAnswerVal(res);
    });
    base.getAll("drop/frm/GBL_QTN").then((res) => {
      setddlVal(res);
    });
    base.getAll("drop/frm/D_COMP").then((res) => {
      setddlDrvCompVal(res);
    });
  };

  const dataSaved = () => {
    setShowToast({ Name: "update", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataNotSaved = () => {
    setShowToast({ Name: "error", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  function DataSavingToastonClick() {
    setShowToast(false);
  }

  function handleSuccessClickOutside() {
    setDeleteSuccess(false);
  }

  function handleErrorClickOutside() {
    setShowError(false);
  }

  const handleDeleteNewFeed = () => {
    if (!!deleteQuestion) {

      try {

        setTimeout(() => {
          base
            .delete(
              process.env.REACT_APP_QUESTION_DELETE,
              deleteQuestion.questionID
            )
            .then((response) => {
              if (response === 200) {
                setDeleteSuccess(true);
                getAllQuestions();
                // setTimeout(() => setDeleteSuccess(false), 3000);
                document.addEventListener(
                  "mousedown",
                  handleSuccessClickOutside
                );
              }
            });
        }, 300);
        // });
      } catch (err) {
        setShowError(true);
        // setTimeout(() => setShowError(false), 3000);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeleteQuestionConfirmToast(false);
      }


    }
  };

  const cancelDelete = () => {
    setDeleteQuestionConfirmToast(false);
    setDeleteQuestion({});
  };

  // modify pageSize
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }
    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      // minWidth: 50,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortingOrder: ["asc", "desc"],
      // Enterprise changes
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("Questionpage.LandingGridHeader.QUESTIONS"),
        headerTooltip: t("Questionpage.LandingGridHeader.QUESTIONS"),
        field: "question_Description",
        minWidth: 800,
        resizable: false,
        suppressMovable: true,
      },
      {
        headerName: t("Questionpage.LandingGridHeader.QUESTION_TYPE"),
        headerTooltip: t("Questionpage.LandingGridHeader.QUESTION_TYPE"),
        field: "question_Type",
        suppressMovable: true,
        resizable: false,
      },
      {
        headerName: t("Questionpage.LandingGridHeader.VERSION"),
        headerTooltip: t("Questionpage.LandingGridHeader.VERSION"),
        field: "site",
        sort: "asc",
        suppressMovable: true,
        resizable: false,
      },
      {
        headerName: t("Questionpage.LandingGridHeader.MULTIPLE_CHOICE"),
        headerTooltip: t("Questionpage.LandingGridHeader.MULTIPLE_CHOICE"),
        field: "multiple_Choice",
        suppressMovable: true,
        resizable: false,
      },
      {
        headerName: t("Questionpage.LandingGridHeader.CRITICAL"),
        headerTooltip: t("Questionpage.LandingGridHeader.CRITICAL"),
        field: "isCriticalQuestion",
        suppressMovable: true,
        // minWidth: 170,
        resizable: false,
      },
      {
        headerName: t("Questionpage.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("Questionpage.LandingGridHeader.EDIT_DELETE"),
        resizable: false,
        suppressMovable: true,
        minWidth: 237,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressMenu: true,
        cellRenderer: QuestionEditCellRenderer,
      },
    ]);
  };

  const editQuestionClick = (Question) => {
    base.getAll(`Question/frm/${Question.questionID}`).then((response) => {
      if (response !== undefined) {
        setQuestionType(response[0].question_Type)
        setEditQuestionUPK(response);
        setEditModalUPK(true);
      }
    });
  };

  const deleteQuestionClick = (params) => {
    if (globalUser === "RADMIN" && params.site === "GLOBAL") {
      setShowToast({ Name: "delete", show: true });
      document.addEventListener("mousedown", DataSavingToastonClick);
    } else {
      setDeleteQuestionConfirmToast(true);
      setDeleteQuestion(params);
    }
  };

  class QuestionEditCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;

      // var isvisible = false;
      // if (userRole !== "") {
      //   (userRole === "30" || userRole === "40") && params.data.site === "GLOBAL"
      //     ? (isvisible = false)
      //     : (isvisible = true);
      // } else {
      //   (role === "30" || role === "40") && params.data.site === "GLOBAL"
      //     ? (isvisible = false)
      //     : (isvisible = true);
      // }

      const root = createRoot(divElement);

      root.render(
        <>
          <Edit32
            onClick={() => editQuestionClick(params.node.data)}

            className="pointer"
          />
          <Trash32
            onClick={() => deleteQuestionClick(params.node.data)}
            className="pointer trashOnSmallerScreen ms-2"
          />

        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  const closeCreateManageQuestion = (val) => {
    setCreateModalUPK(val);
    setEditModalUPK(val);
  };
  function convertUnicode(input) {
    if (input != null) {
      return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
      });
    }
  }

  const getAllQuestions = useCallback(() => {

    base.getAll("question/qry").then((res) => {
      var data = res.filter(
        (x) =>
          x.rel_rec !== 0 &&
          x.question_Description !== "" &&
          x.question_Description != null
      );
      for (let i = 0; i < data.length; i++) {
        data[i].question_Description = convertUnicode(
          data[i].question_Description
        );
        data[i].isCriticalQuestion = data[i].isCriticalQuestion
          ? "Y"
          : "N";
      }

      setGridData(data);

      // document
      //   .getElementById("createButtonDDL")
      //   .classList.remove("btnDisabled");
    });
  }, []);

  useEffect(() => {
    getAllQuestions()
  }, [languagePreference, getAllQuestions]);

  return (
    <div>
      <div >
        <TitleBanner
          heading={t("Questionpage.Questions")}
          // subheading={<Trans i18nKey="Homepage.Title">Competency Assessment Database for Operations Staff</Trans>}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("Common.Manage Data"), t("Questionpage.Questions")]}
          showButton1={false}
          // functionButton2={}
          showButton2={false}
          showButton3={true}
          textButton3={t("Questionpage.Create Question")}
          dropdownMenu={
            <DropdownMenu>
              <div>
                <DropdownItem
                  onClick={() => {
                    setQuestionType("CAS");
                    setCreateModalUPK(true);
                  }}
                >
                  {t("Questionpage.Classroom Assessment (CAS)")}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setQuestionType("DAT");
                    setCreateModalUPK(true);
                  }}
                >
                  {t("Questionpage.Driver Assessment Test (DAT)")}
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setQuestionType("UPK");
                    setCreateModalUPK(true);
                  }}
                >
                  {t("Questionpage.Underpinning Knowledge (UPK)")}
                </DropdownItem>
              </div>
            </DropdownMenu>

          }

          buttonHeading={""}
        />


        {/* Toast messages */}
        < ConfirmDeleteToast
          show={deleteQuestionConfirmToast}
          handleCancel={cancelDelete}
          Message={t("ErrorMessages.DeleteSure")}
          handleDelete={handleDeleteNewFeed}
        />
        {
          showToast.Name === "success" && (
            <SuccessToastNew
              show={showToast.show}
              Message={t("Questionpage.QuestionCreatedToast")}
            />
          )
        }
        {
          showToast.Name === "update" && (
            <SuccessToastNew
              show={showToast.show}
              Message={t("Questionpage.QuestionUpdatedToast")}
            />
          )
        }
        {
          showToast.Name === "error" && (
            <ErrorToastNew1
              show={showToast.show}
              Message={t("Questionpage.DataSaveError")}
            />
          )
        }
        {
          showToast.Name === "delete" && (
            <ErrorToastNew1
              show={showToast.show}
              Message={t("ErrorMessages.UserLevelDelete")}
            />
          )
        }
        <SuccessToastNew
          show={deleteSuccess}
          Message={t("Questionpage.QuestionDeletedToast")}
        />
        {
          showError &&
          <ErrorToastNew1
            show={showError}
            Message={t("Questionpage.DataDeleteError")}
          />
        }
        {/* Toast messages */}
      </div >

      <div className="ps-7 pe-7 pb-5 pt-4">
        <div className="gridDiv">

          <div
            className="ag-theme-material gridHtWd "
            style={{ width: "100%", position: 'relative' }}

          >
            {/* <div
              style={{
                position: 'absolute',
                bottom: '1.5%',
                right: '450px',
                zIndex: '1',
                backgroundColor: 'white',
              }}
            > */}
            <div
              className="rowDataInput"
            >
              {gridData ? (
                <Input type="select"
                  onChange={onPageSizeChanged}
                  id="page-size"
                  // className="ag-select-pagesize-manageassessment"
                  defaultValue={10}

                >
                  <option value="10" >
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </Input>
              ) : (
                ""
              )}
            </div>
            {/* </div> */}
            <AgGridReact
              ref={gridRef}
              columnDefs={gridColDefs}
              rowData={gridData}
              suppressMenuHide={true}
              // overlayLoadingTemplate={"testing..."}
              overlayNoRowsTemplate="No records found"
              defaultColDef={gridDefaultColDef}
              pagination={true}
              paginationPageSize={10}
              suppressScrollOnNewData={true}
              suppressHorizontalScroll={false}

              enableRangeSelection={true}
              onFilterChanged={onFilterChanged}
              postProcessPopup={postProcessPopup}
            // getRowHeight={getRowHeight}
            ></AgGridReact>

          </div>
        </div>

      </div>


      {
        createModalUPK &&
        <CreateQuestion
          questionType={questionType}
          modalOpen={createModalUPK}
          DrivingCompList={ddlDrvCompVal}
          fromtask={false}
          PUList={ddlPUVal}
          LocationList={ddlLocationVal}
          CountryList={ddlCountryVal}
          AnswerList={ddlAnswerVal}
          GLList={ddlVal}
          create={true}
          saveSuccess={dataSaved}
          error={dataNotSaved}
          getAll={getAllQuestions}
          close={closeCreateManageQuestion}
          questionData={[]}
          isSite={false}
        />
      }


      {
        editModalUPK &&
        <CreateQuestion
          questionType={questionType}
          modalOpen={editModalUPK}
          DrivingCompList={ddlDrvCompVal}
          fromtask={false}
          PUList={ddlPUVal}
          LocationList={ddlLocationVal}
          CountryList={ddlCountryVal}
          AnswerList={ddlAnswerVal}
          GLList={ddlVal}
          create={false}
          saveSuccess={dataSaved}
          error={dataNotSaved}
          getAll={getAllQuestions}
          close={closeCreateManageQuestion}
          questionData={editQuestionUPK} />
      }


      {/* <Modal
        isOpen={editModalUPKOLD}
        centered
        className="modal-dialog-centered modal-90"

      >
        <CreateQuestionsUPKOLD
          fromtask={false}
          PUList={ddlPUVal}
          LocationList={ddlLocationVal}
          CountryList={ddlCountryVal}
          GLList={ddlVal}
          YesNoList={ddlYNVal}
          create={false}
          saveSuccess={dataUpdated}
          error={dataNotSaved}
          Question={editQuestionUPK}
          getAll={getAllQuestions}
          close={closeCreateManageQuestion}
        ></CreateQuestionsUPKOLD>
      </Modal> */}
    </div >
  );
};
export default ManageQuestion;
