import React, { useState, useEffect, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";

import base from "../../Services/BaseService";

const UserContext = createContext(null);

export const UserProvider = ({ children, userData, logOut }) => {
  const { fk_languageID: preferredLanguageID } = userData;

  const { i18n } = useTranslation();

  const [languages, setLanguages] = useState([]);
  const [isPreferredLanguageConfigured, setIsPreferredLanguageConfigured] = useState(false);
  const [languagePreference, setLanguagePreference] = useState(preferredLanguageID);

  useEffect(() => {
    base.getAll("Language/qry").then((languages) => setLanguages(languages));
  }, []);

  const globalUser = (userData.userLevel === "10" || userData.userLevel === "15" || userData.userLevel === "20") ? "GADMIN" : userData.userLevel === "30" ? "RADMIN" : "RUSER";

  useEffect(() => {
    if (preferredLanguageID) {
      i18n
        .changeLanguage(preferredLanguageID)
        .then(() => setIsPreferredLanguageConfigured(true))
        .catch((err) => console.log(err));
    } else {
      setIsPreferredLanguageConfigured(true);
    }

  }, [preferredLanguageID, i18n]);

  if (!isPreferredLanguageConfigured || languages.length === 0) {
    return null;
  }

  const updateLanguagePreference = (chosenLanguage) => {

    base
      .update(`user/upd/language/${chosenLanguage.languageID}`).then(() => {
        setLanguagePreference(chosenLanguage.languageID)
      })
      .catch((err) => console.log(err));
  }

  return (
    <UserContext.Provider value={{ userData, logOut, languages, languagePreference, updateLanguagePreference, globalUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const { userData, logOut, languages, languagePreference, updateLanguagePreference, globalUser } = useContext(UserContext);


  return { userData, logOut, languages, languagePreference, updateLanguagePreference, globalUser };
};
