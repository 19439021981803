import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useValidation } from "../../Shared/ValidateForm";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import "../../../Content/stylesheet/Location.css"
import Select from "react-select";
import { components } from "react-select";
import base from "../../../Services/BaseService";
import { Row, Col } from "reactstrap";
import LoadingView from "../../Shared/Layout/LoadingView";
import { Input, Label, Modal, Button } from '@bphxd/ds-core-react';

const Country = (props) => {
  const { t } = useTranslation();
  var feed = {
    id: 0,
    fk_PUID: "",
    countryID: "",
    country_Description: "",
    text01: null,
    text02: null,
    text03: null,
  };
  if (props.create === false) {
    feed = {
      id: props.countryFeed.country_ID,
      fk_PUID: props.countryFeed.puid,
      countryID: props.countryFeed.countryID,
      country_Description: props.countryFeed.country_Description,
      text01: null,
      text02: null,
      text03: null,
    };
  }
  const [isCreate, setIsCreate] = useState(props.create);
  const [countryFeed, setcountryFeed] = useState(feed);
  const [isLoadingSteps, setisLoadingSteps] = useState(false);

  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [isChanged, setIsChanged] = useState(false);
  const [register, validate, errors] = useValidation();
  const [lengthcountryIDerr, setlengthcountryIDerr] = useState(false);
  const [reqcountryIDerr, setreqcountryIDerr] = useState(false);
  const [lengthcountry_Descriptionerr, setlengthcountry_Descriptionerr] =
    useState(false);
  const [reqcountry_Descriptionerr, setreqcountry_Descriptionerr] =
    useState(false);
  const [reqpuiderr, setreqpuiderr] = useState(false);
  const [countryIDRemaining, setcountryIDRemaining] = useState(
    countryFeed.countryID.length
  );
  const [country_DescriptionRemaining, setcountry_DescriptionRemaining] =
    useState(countryFeed.country_Description.length);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [ddlPUSelected, setddlPUSelected] = useState(null);
  const [DuplicateAlert, setDuplicateAlert] = useState(false);

  const [ddlPUVal, setddlPUVal] = useState(null);

  useEffect(() => {
    setDropdownData();
    // eslint-disable-next-line
  }, []);

  //Loading data for Performance Unit drop down
  const setDropdownData = () => {
    if (props.create === false) {
      setddlPUSelected({
        value: props.countryFeed.puid,
        label: props.countryFeed.puid,
      });
    }

    var pu = [];
    for (let i = 0; i < props.PUList.length; i++) {
      var itemPU = {};
      itemPU.value = props.PUList[i].pU_Description;
      itemPU.label = props.PUList[i].puid;
      pu.push(itemPU);
    }
    setddlPUVal(pu);
  };

  const handlePUDDL = (params) => {
    removeRedBorderDynamically("puid");
    setreqpuiderr(false);
    setddlPUSelected(params);
    setIsChanged(true);
  };

  //Function invoked when values are changed in text boxes
  const handleChange = (e) => {
    if (e.target.name === "countryID") {
      setDuplicateAlert(false);
      setreqcountryIDerr(false);
      removeRedBorderDynamically("countryID");
      setcountryIDRemaining(e.target.value.length);
      if (e.target.value.length > 5) {
        setlengthcountryIDerr(true);
        ADDRedBorderDynamically("countryID");
      } else {
        removeRedBorderDynamically("countryID");
        setlengthcountryIDerr(false);
      }
    }
    if (e.target.name === "country_Description") {
      setreqcountry_Descriptionerr(false);
      removeRedBorderDynamically("country_Description");
      setcountry_DescriptionRemaining(e.target.value.length);
      if (e.target.value.length > 50) {
        setlengthcountry_Descriptionerr(true);
        ADDRedBorderDynamically("country_Description");
      } else {
        removeRedBorderDynamically("country_Description");
        setlengthcountry_Descriptionerr(false);
      }
    }
    setIsChanged(true);
    setcountryFeed({ ...countryFeed, [e.target.name]: e.target.value });
  };

  const ADDRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.add("redBorder");
      //document.getElementById(id).classList.add("redBorderRequired");
    }
  };
  const removeRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.remove("redBorder");
    }
  };

  const Option = (props) => {
    return (
      <div id="empMulti ">
        <components.Option {...props}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "30% 70%",
              width: "100%",
            }}
          >
            <span> {props.label}</span>
            <span> {props.value}</span>
          </div>
        </components.Option>
      </div>
    );
  };

  //Function invoked when user clicks on Save button

  const saveCountry = () => {
    countryFeed.country_Description = countryFeed.country_Description.trim();
    countryFeed.countryID = countryFeed.countryID.trim();
    document.getElementById("country_Description").value =
      countryFeed.country_Description.trim();
    document.getElementById("countryID").value = countryFeed.countryID.trim();
    if (ddlPUSelected !== "" && ddlPUSelected !== null)
      countryFeed.fk_PUID = ddlPUSelected.label;

    setcountryIDRemaining(countryFeed.countryID.trim().length);
    if (countryFeed.countryID.trim().length > 5) {
      setlengthcountryIDerr(true);
      setSaveDisabled(false);
    } else {
      setlengthcountryIDerr(false);
    }

    setcountry_DescriptionRemaining(
      countryFeed.country_Description.trim().length
    );
    if (countryFeed.country_Description.trim().length > 50) {
      setlengthcountry_Descriptionerr(true);
      setSaveDisabled(false);
    } else {
      setlengthcountry_Descriptionerr(false);
    }

    validate();
    if (document.getElementById("countryID").value.trim() !== "") {
      setreqcountryIDerr(false);
      if (document.getElementById("country_Description").value.trim() !== "") {
        setreqcountry_Descriptionerr(false);
        if (
          ddlPUSelected &&
          (ddlPUSelected.label !== null ||
            ddlPUSelected.label !== "null" ||
            ddlPUSelected.label !== "")
        ) {
          setreqpuiderr(false);
          removeRedBorderDynamically("puid");
          if (
            errors.countryID.isValid &&
            errors.country_Description.isValid &&
            reqpuiderr !== true
          ) {
            if (isCreate === false) {
              if (
                props.countryFeed.countryID.toString() ===
                document.getElementById("countryID").value
              ) {

                setTimeout(() => {
                  base
                    .update(
                      process.env.REACT_APP_COUNTRY_UPDATE,
                      countryFeed
                    )
                    .then((response) => {
                      props.getAll();
                      handleDiscard();
                      setIsCreate(true);
                      setIsChanged(false);
                      props.saveSuccess();
                      setisLoadingSteps(false);
                    })
                    .catch((error) => {
                      handleDiscard();
                      props.error();
                      setisLoadingSteps(false);
                    });
                }, 300);
                //normal update
              } else {

                setTimeout(() => {
                  base
                    .getAll(
                      "country/get/" +
                      document.getElementById("countryID").value.trim()
                    )
                    .then((resCty) => {
                      if (resCty !== null) {
                        ADDRedBorderDynamically("countryID");
                        setDuplicateAlert(true);
                        setSaveDisabled(false);
                      } else {
                        removeRedBorderDynamically("countryID");
                        setDuplicateAlert(false);

                        setTimeout(() => {
                          base
                            .update(
                              process.env.REACT_APP_COUNTRY_UPDATE,
                              countryFeed
                            )
                            .then((response) => {
                              props.getAll();
                              handleDiscard();
                              setIsCreate(true);
                              setIsChanged(false);
                              props.saveSuccess();
                              setisLoadingSteps(false);
                            })
                            .catch((error) => {
                              handleDiscard();
                              props.error();
                              setisLoadingSteps(false);
                            });
                        }, 300);
                      }
                    });
                }, 300);
              }
            } else {

              // setTimeout(() => {
              base
                .getAll(
                  "country/get/" +
                  document.getElementById("countryID").value.trim()
                )
                .then((resCty) => {
                  if (resCty !== null) {
                    ADDRedBorderDynamically("countryID");
                    setDuplicateAlert(true);
                    setSaveDisabled(false);
                  } else {
                    removeRedBorderDynamically("countryID");
                    setDuplicateAlert(false);

                    // setTimeout(() => {
                    base
                      .add(
                        process.env.REACT_APP_COUNTRY_ADD,
                        countryFeed
                      )
                      .then((response) => {
                        props.getAll();
                        handleDiscard();
                        setIsCreate(false);
                        setIsChanged(false);
                        props.saveSuccess();
                        setisLoadingSteps(false);
                      })
                      .catch((error) => {
                        handleDiscard();
                        props.error();
                        setisLoadingSteps(false);
                      });
                    // }, 300);
                  }
                });
              // }, 300);
            }
          } else {
            if (document.getElementById("countryID").value.trim() === "") {
              setcountryIDRemaining(0);
              setreqcountryIDerr(true);
              setlengthcountryIDerr(false);
              setSaveDisabled(false);
            }
            if (
              document.getElementById("country_Description").value.trim() === ""
            ) {
              setcountry_DescriptionRemaining(0);
              setreqcountry_Descriptionerr(true);
              setlengthcountry_Descriptionerr(false);
              setSaveDisabled(false);
            }
            if (
              !ddlPUSelected ||
              (ddlPUSelected &&
                (ddlPUSelected.label === null ||
                  ddlPUSelected.label === "null" ||
                  ddlPUSelected.label === ""))
            ) {
              setreqpuiderr(true);
              setSaveDisabled(false);
              ADDRedBorderDynamically("puid");
            }
          }
        } else {
          if (document.getElementById("countryID").value.trim() === "") {
            setcountryIDRemaining(0);
            setreqcountryIDerr(true);
            setSaveDisabled(false);
            setlengthcountryIDerr(false);
          }
          if (
            document.getElementById("country_Description").value.trim() === ""
          ) {
            setcountry_DescriptionRemaining(0);
            setreqcountry_Descriptionerr(true);
            setSaveDisabled(false);
            setlengthcountry_Descriptionerr(false);
          }
          if (
            !ddlPUSelected ||
            (ddlPUSelected &&
              (ddlPUSelected.label === null ||
                ddlPUSelected.label === "null" ||
                ddlPUSelected.label === ""))
          ) {
            setreqpuiderr(true);
            setSaveDisabled(false);
            ADDRedBorderDynamically("puid");
          }
        }
      } else {
        if (document.getElementById("countryID").value.trim() === "") {
          setcountryIDRemaining(0);
          setreqcountryIDerr(true);
          setSaveDisabled(false);
          setlengthcountryIDerr(false);
        }
        if (
          document.getElementById("country_Description").value.trim() === ""
        ) {
          setcountry_DescriptionRemaining(0);
          setreqcountry_Descriptionerr(true);
          setSaveDisabled(false);
          setlengthcountry_Descriptionerr(false);
        }
        if (
          !ddlPUSelected ||
          (ddlPUSelected &&
            (ddlPUSelected.label === null ||
              ddlPUSelected.label === "null" ||
              ddlPUSelected.label === ""))
        ) {
          setreqpuiderr(true);
          setSaveDisabled(false);
          ADDRedBorderDynamically("puid");
        }
      }
    } else {
      if (document.getElementById("countryID").value.trim() === "") {
        setcountryIDRemaining(0);
        setreqcountryIDerr(true);
        setSaveDisabled(false);
        setlengthcountryIDerr(false);
      }
      if (document.getElementById("country_Description").value.trim() === "") {
        setcountry_DescriptionRemaining(0);
        setreqcountry_Descriptionerr(true);
        setSaveDisabled(false);
        setlengthcountry_Descriptionerr(false);
      }
      if (
        !ddlPUSelected ||
        (ddlPUSelected &&
          (ddlPUSelected.label === null ||
            ddlPUSelected.label === "null" ||
            ddlPUSelected.label === ""))
      ) {
        setreqpuiderr(true);
        setSaveDisabled(false);
        ADDRedBorderDynamically("puid");
      }
    }
  };

  const handleDiscard = () => {
    setcountryFeed(feed);
    props.close(false);
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  return (
    <>

      <Modal

        isOpen={props.isopen}
        className="manageRegionCtyLocCreateEdit modal-dialog-centered modal-50"
      >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
            handleNo={saveCountry}
          />
        )}
        {isLoadingSteps && <LoadingView />}
        <Modal.Header
          className="modalPopupBGColour"
          onToggleOpen={cancelHandler}
        >
          <div className="row">
            <div className="col">
              <Label className="modalHeaderGlobal ms-5">
                {props.create
                  ? t("Regionpage.Create Country")
                  : t("Regionpage.Edit Country")}
              </Label>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="ps-6">
              <Label className="" htmlFor="countryID">
                {t("Regionpage.Country Code")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6">
              <Input
                id="countryID"
                name="countryID"
                onChange={handleChange}
                maxLength={5}

                value={countryFeed.countryID}
                {...register({
                  fieldName: "countryID",
                  required: true,
                  maxLength: 5,
                })}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col xs="6" className="ps-6">

              {errors.countryID && errors.countryID.Message !== undefined && (
                <div className="col" id="countryID_Error">
                </div>
              )}
              {lengthcountryIDerr ? (
                <span className="assessmenterror">{t("ErrorMessages.Max5")}</span>
              ) : (
                ""
              )}
              {DuplicateAlert ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.CountryCodeExists")}
                </span>
              ) : (
                ""
              )}
              {reqcountryIDerr ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.Required")}
                </span>
              ) : (
                ""
              )}
              <div
                // className="col float-end p-0"
                className={
                  errors.countryID && errors.countryID.Message !== undefined
                    ? "thWordLmtErr col float-end p-0"
                    : "thWordLmtNoErr col float-end p-0"
                }
              ></div>
            </Col>
            <Col xs="6" className={`text-end desclength ${errors.countryID && errors.countryID.Message !== undefined}`}>
              <span>
                {countryIDRemaining}/5
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6">
              <Label className="" htmlFor="country_Description">
                {t("Regionpage.Country")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6">
              <Input
                id="country_Description"
                name="country_Description"
                className=" "
                onChange={handleChange}
                maxLength={50}

                value={countryFeed.country_Description}
                {...register({
                  fieldName: "country_Description",
                  required: true,
                  maxLength: 50,
                })}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col xs="8" className="ps-6">
              {errors.country_Description &&
                errors.country_Description.Message !== undefined && (
                  <div className="col" id="country_Description_Error">
                    {/* <label className="float-start text-danger h7">
                        {errors.Hazard_Description.Message}
                      </label> */}
                  </div>
                )}
              {lengthcountry_Descriptionerr ? (
                <span className="assessmenterror">{t("ErrorMessages.Max50")}</span>
              ) : (
                ""
              )}
              {reqcountry_Descriptionerr ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.Required")}
                </span>
              ) : (
                ""
              )}
              <div
                // className="col float-end p-0"
                className={
                  errors.country_Description &&
                    errors.country_Description.Message !== undefined
                    ? "thWordLmtErr col float-end p-0"
                    : "thWordLmtNoErr col float-end p-0"
                }
              ></div>
            </Col>
            <Col xs="4" className={`text-end desclength ${errors.country_Description && errors.country_Description.Message !== undefined}`} >
              <span>
                {country_DescriptionRemaining}/50
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6">
              <Label className="" htmlFor="pU_Description">
                {t("Regionpage.Performance Unit")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>

          <Row>
            <Col className="ps-6">
              <Select
                id="puid"
                placeholder={""}
                defaultValue={ddlPUSelected}
                value={ddlPUSelected}
                options={ddlPUVal}
                onChange={handlePUDDL}
                components={{
                  Option,
                  IndicatorSeparator: () => null,
                }}
                classNamePrefix="react-select"
              ></Select>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6">
              {(errors.puid && errors.puid.Message !== undefined) ||
                (reqpuiderr && (
                  <div className="col" id="puid_Error" style={{ marginLeft: 0 }} >
                    {reqpuiderr ? (
                      <span className=" assessmenterror">
                        {t("ErrorMessages.Required")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button size="sm" color="standard-secondary" className="rounded-pill btn-style" onClick={cancelHandler}>
              {t("Common.Cancel")}
            </Button>{" "}
            <Button disabled={saveDisabled} size="sm" color="standard-primary" className="rounded-pill btn-style" onClick={() => { setSaveDisabled(true); saveCountry(); }} >
              {t("Common.Save")}
            </Button>{" "}
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default Country;
