import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import "../../Content/stylesheet/ag-grid-style.css";
import { Input, Label } from "reactstrap";

export default forwardRef((props, ref) => {
  const [filterState, setFilterState] = useState("option-1-all");

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterState !== "option-1-all";
      },
      doesFilterPass(params) {
        const didFilterPass =
          (params.data.isAssigned === "true" &&
            filterState === "option-2-selected") ||
          (params.data.isAssigned === "false" &&
            filterState === "option-3-unselected");
        return didFilterPass;
      },
      getModel() {
        return undefined;
      },
      setModel() { },
    };
  });

  function updateFilterState(filterValueReceived) {
    setFilterState(filterValueReceived);
  }

  useEffect(() => props.filterChangedCallback(), [filterState, props]);

  return (
    <>
      <div className="chkboxfilter-flexcontainer">
        <Input
          type="radio"
          id="option-1-all"
          name="customcheckboxfilter"
          value="option-1-all"
          className="ms-4"
          checked={filterState === "option-1-all"}
          onChange={() => updateFilterState("option-1-all")}
        />
        <Label for="option-1-all" className="ms-4"
        >All</Label>
      </div>
      <div className="chkboxfilter-flexcontainer">
        <Input
          type="radio"
          id="option-2-selected"
          name="customcheckboxfilter"
          className="ms-4"

          value="option-2-selected"
          checked={filterState === "option-2-selected"}
          onChange={() => updateFilterState("option-2-selected")}
        />
        <Label for="option-2-selected" className="ms-4"
        >Assigned</Label>
      </div>
      <div className="chkboxfilter-flexcontainer">
        <Input
          type="radio"
          id="option-3-unselected"
          name="customcheckboxfilter"
          className="ms-4"

          value="option-3-unselected"
          checked={filterState === "option-3-unselected"}
          onChange={() => updateFilterState("option-3-unselected")}
        />
        <Label for="option-3-unselected" className="ms-4"
        >Unassigned</Label>
      </div>
    </>
  );
});
