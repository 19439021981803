import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../App.css";
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import { Download32 } from "@bphxd/ds-core-react/lib/icons";
import PrivacyContent from "./PrivacyContent";
import SuccessToastNew from "../Toast/SuccessToastNew";
import "../../Content/stylesheet/common.css";
import base64data from "./datapdf.json";
import { Label, Modal } from '@bphxd/ds-core-react';

function PrivacyStatement(props) {
  const { t } = useTranslation();
  const [success, setsuccess] = useState(false);

  function Successshow() {
    setsuccess(true);

    document.addEventListener("mousedown", DataSavingToastonClick);
  }

  function DataSavingToastonClick() {
    setsuccess(false);
  }

  function closemodal(val) {
    props.closed();
  }

  function base64toPDF() {
    var data = base64data.text;
    var bufferArray = base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blobStore);
      return;
    }
    data = window.URL.createObjectURL(blobStore);
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.href = data;
    link.download = "Privacy Statement";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
    Successshow();
  }

  function base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
      var ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function scrolltotop() {
    var myDiv = document.getElementById("scrolltop");
    myDiv.scrollTop = 0;
  }

  return (
    <div>
      <Modal isOpen={true} backdrop="static" className="modal-dialog-centered modal-70 privacyStatementmodal" >
        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={() => closemodal()}
        >
          <Label className="modalHeaderGlobal m-4">
            {t("Privacy.Privacy Statement")}
          </Label>
          <Download32
            className="download"
            onClick={() => {
              base64toPDF();
            }}
          />
          <SuccessToastNew
            show={success}
            form={true}
            Message={t("Privacy.PrivacyDownloadSuccess")}
          />
        </Modal.Header>
        <Modal.Body
          id="scrolltop"
          style={{
            textAlign: "justify",
            maxHeight: "72vh",
            overflowY: "scroll",
          }}
        >
          <PrivacyContent
            close={closemodal}
            mandatory={false}
            scrolltotop={scrolltotop}
          ></PrivacyContent>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PrivacyStatement;
