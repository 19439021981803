import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom-v5-compat";
import { Info32 } from "@bphxd/ds-core-react/lib/icons";
import { CardBody, Card } from "reactstrap";
import moment from "moment";
import "../../Content/stylesheet/traineeassessment.css";
import base from "../../Services/BaseService";
import QueAnsComponent from "./QueAnsComponent";
import ReviewQuestions from "./ReviewQuestions";
import { useMyTrainingContext } from '../Shared/myTrainingContext'
import { useAssessmentContext } from '../Shared/assessmentContext'
import { useTranslation } from "react-i18next";

const GiveAssessment = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { questionsData, TOTAL_TIME, assessmentType, assessmentID, empID } = props;
  const [questionIndex, setquestionIndex] = useState(0);
  const [checkedAnswers, setCheckedAnswers] = useState({ isCheckedA: false, isCheckedB: false, isCheckedC: false, isCheckedD: false, });
  const [toggleResultScreen, setToggleResultScreen] = useState({ isVisible: false, score: null, });
  const [isReviewScreenEnabled, setIsReviewScreenEnabled] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({ minutes: TOTAL_TIME.timeInMinutes, seconds: TOTAL_TIME.timeInSeconds, });
  const [allQuestionsVisited, setAllQuestionsVisited] = useState(false);
  const { updateAssessmentCount } = useAssessmentContext();
  const { updateMyTrainingCount } = useMyTrainingContext()

  function handleCheckedAnswers(currQuestion) {
    const checkedA = currQuestion.selectedAnswers.includes("A");
    const checkedB = currQuestion.selectedAnswers.includes("B");
    const checkedC = currQuestion.selectedAnswers.includes("C");
    const checkedD = currQuestion.selectedAnswers.includes("D");

    setCheckedAnswers({
      isCheckedA: checkedA,
      isCheckedB: checkedB,
      isCheckedC: checkedC,
      isCheckedD: checkedD,
    });
  }

  const navigateToMyTraining = () => {
    history.push('/mytraining');
  };

  const previousClick = () => {
    let index = questionIndex - 1;
    setquestionIndex(index);

    handleCheckedAnswers(questionsData[index]);
  };

  const nextClick = () => {
    let index = questionIndex + 1;
    setquestionIndex(index);
    if (index === questionsData.length - 1) setAllQuestionsVisited(true);

    handleCheckedAnswers(questionsData[index]);
  };

  const onReviewClick = () => {
    setIsReviewScreenEnabled(true);
  };

  function setselectedAnswer(params) {
    let q = questionsData[questionIndex];

    let ansA =
      document.getElementById(q.fk_ID + "A") !== null
        ? document.getElementById(q.fk_ID + "A").checked
        : false;
    let ansB =
      document.getElementById(q.fk_ID + "B") !== null
        ? document.getElementById(q.fk_ID + "B").checked
        : false;
    let ansC =
      document.getElementById(q.fk_ID + "C") !== null
        ? document.getElementById(q.fk_ID + "C").checked
        : false;
    let ansD =
      document.getElementById(q.fk_ID + "D") !== null
        ? document.getElementById(q.fk_ID + "D").checked
        : false;

    let selectedans = "";

    if (ansA) {
      selectedans = selectedans.length > 0 ? selectedans + ";A" : "A";
    }
    if (ansB) {
      selectedans = selectedans.length > 0 ? selectedans + ";B" : "B";
    }
    if (ansC) {
      selectedans = selectedans.length > 0 ? selectedans + ";C" : "C";
    }

    if (ansD) {
      selectedans = selectedans.length > 0 ? selectedans + ";D" : "D";
    }

    q.selectedAnswers = selectedans;

    questionsData[questionIndex] = q;

    handleCheckedAnswers(q);
  }

  const SubmitTARAssessment = () => {
    var questionarr = [];
    var questionjson = {
      fk_AssessmentID: "",
      fk_EmployeeID: "",
      fk_TaskID: "",
      fk_QuestionID: "",
      ModelAnswer: "",
      Ssrt: "",
      EmployeeResult: "",
      IsCriticalQuestion: "",

    };
    let correctAnswerCount = 0;
    for (let i = 0; i < questionsData.length; i++) {
      let selectedans = questionsData[i].selectedAnswers
        .toString()
        .replaceAll(";", "")
        .replace(/ /g, "");

      let CorrectAnswer =
        questionsData[i].answer == null
          ? ""
          : questionsData[i].answer
            .toString()
            .replaceAll(";", "")
            .replace(/ /g, "");

      if (selectedans === CorrectAnswer) {
        correctAnswerCount = correctAnswerCount + 1;
      }

      questionjson = {
        fk_AssessmentID: props.assessmentID,
        fk_EmployeeID: props.empID,
        fk_TaskID: questionsData[i].taskID,
        fk_QuestionID: questionsData[i].fk_ID,
        ModelAnswer: questionsData[i].answer,
        Ssrt: questionsData[i].ssrt,
        EmployeeResult: questionsData[i].selectedAnswers,
        IsCriticalQuestion: questionsData[i].isCriticalQuestion,
      };
      questionarr.push(questionjson);
    }

    let data = {
      competency: {
        fk_AssessmentID: props.assessmentID,
        fk_EmployeeID: props.empID,
        fk_TaskID: questionsData[0].taskID,
        SC01: correctAnswerCount,
        SC02: questionsData.length,
        SC03: "",
        SC10: "",
        pStart: moment(new Date()).format("L"),
        Status: "C",
      },
      part1tar: {
        fk_AssessmentID: props.assessmentID,
        fk_EmployeeID: props.empID,
        fk_TaskID: questionsData[0].taskID,
        SC01: "",
        SC03: "",
        Part1Score: "",
        pStart: moment(new Date()).format("L"),
        Status: "C",
      },
      questions: questionarr,
    };

    let url = "result/add1";
    base.add(url, JSON.stringify(data)).then((res) => {
      updateAssessment();
    });
  };

  const submitAssessment = useCallback(() => {
    // enable Navbar click after submitting the assessment
    document
      .getElementById("main-navbar-containerID")
      .classList.remove("disable-containerClick");

    document
      .getElementById("nav-wrapper")
      .classList.remove("nav-wrapper-pointer");

    props.toggleLoadingOverlay(true);

    if (assessmentType === "TAR") {
      SubmitTARAssessment();
    } else {
      let url = "";
      if (assessmentType === "CAS") url = "result/add";

      var questionarr = [];
      var questionjson = {
        fk_AssessmentID: "",
        fk_EmployeeID: "",
        fk_TaskID: "",
        fk_QuestionID: "",
        ModelAnswer: "",
        Ssrt: "",
        EmployeeResult: "",
        IsCriticalQuestion: ""
      };

      let correctAnswerCount = 0;
      for (let i = 0; i < questionsData.length; i++) {
        let selectedans = questionsData[i].selectedAnswers
          .toString()
          .replaceAll(";", "")
          .replace(/ /g, "");
        let CorrectAnswer = questionsData[i].answer
          .toString()
          .replaceAll(";", "")
          .replace(/ /g, "");

        if (selectedans === CorrectAnswer) {
          correctAnswerCount = correctAnswerCount + 1;
        }

        questionjson = {
          fk_AssessmentID: props.assessmentID,
          fk_EmployeeID: props.empID,
          fk_TaskID: questionsData[i].taskID,
          fk_QuestionID: questionsData[i].fk_ID,
          ModelAnswer: questionsData[i].answer,
          Ssrt: questionsData[i].ssrt,
          EmployeeResult: questionsData[i].selectedAnswers,
          IsCriticalQuestion: questionsData[i].isCriticalQuestion,
        };
        questionarr.push(questionjson);
      }

      let data = {
        competency: {
          fk_AssessmentID: props.assessmentID,
          fk_EmployeeID: props.empID,
          fk_TaskID: questionsData[0].taskID,
          SC01: correctAnswerCount,
          SC02: questionsData.length,
          SC03: "",
          SC10: "",
          pStart: moment(new Date()).format("L"),
          Status: "C",
        },
        questions: questionarr,
      };

      base.add(url, JSON.stringify(data)).then((res) => {
        updateAssessment();
      });
    }
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    let intervalID = setInterval(() => {
      setTimeRemaining((currTimeRem) => {
        const { minutes, seconds } = currTimeRem;

        if (seconds > 0) return { ...currTimeRem, seconds: seconds - 1 };
        else if (seconds === 0) {
          if (minutes === 0) {
            submitAssessment();
            clearInterval(intervalID);
            return currTimeRem;
          } else if (minutes > 0)
            return {
              ...currTimeRem,
              minutes: minutes - 1,
              seconds: 59,
            };
        }
      });
    }, 1000);

    // Logic to handle browser's refresh or close of Tab/window
    const beforeUnloadListener = (event) => {
      return (event.returnValue = "Are you sure you want to exit?");
    };
    window.addEventListener("beforeunload", beforeUnloadListener);

    // Logic to block the backward/forward navigation from a specific route (here- once assessment started navigation will be blocked)
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        return false;
      }
    });

    return () => {
      clearInterval(intervalID);
      window.removeEventListener("beforeunload", beforeUnloadListener);
      unblock();
    };
  }, [submitAssessment, history]);

  function updateAssessment() {

    base.get("result/qry", props.assessmentID + "/N").then((res) => {
      base
        .getAll(
          `result/GetScore/${assessmentID}/${empID}/${assessmentType}`
        )
        .then((res) => {
          updateAssessmentCount();
          updateMyTrainingCount();
          setToggleResultScreen({ isVisible: true, score: res });
          props.toggleLoadingOverlay(false);
          if (res === "L1") {
            sendEmailToTrainer();
          }
        });
    });
  }
  function sendEmailToTrainer() {
    var tskNum = encodeURIComponent(props.taskNumber);


    setTimeout(() => {
      base
        .get(
          "Email/SendEmailForLevelL1",
          `${props.empID}/${props.trainerName}/${props.assessmentType}/${tskNum}
                `
        )
        .then((res) => { });
    }, 300);
  }
  const handleChangeResponse = (queIndex) => {
    handleCheckedAnswers(questionsData[queIndex]);

    setquestionIndex(queIndex);
    setIsReviewScreenEnabled(false);
  };

  if (toggleResultScreen.isVisible) {
    return (
      <div className="result-screen-parentcontainer">
        <div className="result-section-flexcontainer">
          {/* <div className="result-section__heading">Congratulations! You have passed.</div> */}
          <div className="result-section__body">
            <Card>
              <CardBody className="card-resultbody bg-light-gray-100">
                <div className="result-body-container">
                  <div className="result-body__text">
                    {toggleResultScreen.score === "L1" && (
                      <>
                        <div className="result-body__text">
                          {t("MyTrainingpage.fail")}
                        </div>
                        <br />
                        <div className="result-body__text">
                          {t("MyTrainingpage.Competency score")}
                        </div>
                      </>
                    )}
                    {(toggleResultScreen.score === "L2" ||
                      toggleResultScreen.score === "L3") && (
                        <>
                          <div className="result-body__text">
                            {t("MyTrainingpage.pass")}
                          </div>
                          <br />
                          <div className="result-body__subtext">
                            {t("MyTrainingpage.Competency score achieved")}
                            {assessmentType === "TAR" && (
                              <>
                                <Info32
                                  id="tskRetInfo"
                                  className={"taskRetInfoIcon mt-n3"}
                                ></Info32>
                                <div id="divInfo" className={"hide info-boxR"}>
                                  <br />
                                  <span className={"h5-2 info-textR"}>
                                    <p>
                                      {t("MyTrainingpage.Part2")}
                                    </p>
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    <div className="result-body__score ">
                      {toggleResultScreen.score}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="result-section__footer">
            <span className="me-2">{t("MyTrainingpage.Go to")}</span>
            <Link to="/MyTraining">
              <span
                style={{ color: "blue" }}
                onClick={() => navigateToMyTraining()}
              >
                {t("MyTrainingpage.MyTraining")}
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    if (isReviewScreenEnabled)
      return (
        <ReviewQuestions
          timeRemaining={timeRemaining}
          questionsData={questionsData}
          assessmentType={assessmentType}
          onClickResponse={handleChangeResponse}
          submitAssessment={submitAssessment}
        />
      );

    return (
      <QueAnsComponent
        timeRemaining={timeRemaining}
        questionsData={questionsData}
        questionIndex={questionIndex}
        checkedAnswers={checkedAnswers}
        allQuestionsVisited={allQuestionsVisited}
        assessmentType={assessmentType}
        onChangeAnswer={setselectedAnswer}
        previousClick={previousClick}
        nextClick={nextClick}
        onReviewClick={onReviewClick}
      />
    );
  }
};

export default GiveAssessment;
