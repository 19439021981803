import { Trans } from "react-i18next";
import base64data from "../Privacy Statement/datapdf.json";
import { Col, ListGroup, ListGroupItem } from "reactstrap";
import { Support24 } from "@bphxd/ds-core-react/lib/icons";

const Support = () => {
    function base64toPDF(name) {
        var data =
            name === "breach" ? base64data.breachguide : base64data.userguide;

        var bufferArray = base64ToArrayBuffer(data);
        var blobStore = new Blob([bufferArray], { type: "application/pdf" });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blobStore);
            return;
        }
        data = window.URL.createObjectURL(blobStore);
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = data;
        link.download =
            name === "breach" ? "CADOPS Breach Guide" : "CADOPS User Guide";
        link.click();
        window.URL.revokeObjectURL(data);
        link.remove();
    }

    function base64ToArrayBuffer(data) {
        var bString = window.atob(data);
        var bLength = bString.length;
        var bytes = new Uint8Array(bLength);
        for (var i = 0; i < bLength; i++) {
            var ascii = bString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    return (
        <>
            <Col xs="12">
                <div className="chartborder " style={{ minHeight: "362px" }}>
                    <div className="listgroup-bt p-3">
                        <p className="mb-3 d-flex align-center">
                            <Support24 className="me-3 mt-2" />
                            <span className="mt-2 pt-1">Support</span>
                        </p>
                    </div>
                    <ListGroup className="pt-1" flush tag="div" style={{ maxWidth: "100px !important" }}>
                        <ListGroupItem className="custom-listgroup-item">

                            <Col xs="12" >
                                <div style={{ fontFamily: "Roboto" }}>
                                    <Trans i18nKey="Homepage.supporttext">
                                        For day to day support, refer
                                    </Trans>
                                    <span className="anchortag" onClick={() => { base64toPDF("User guide"); }} >
                                        <Trans i18nKey="Homepage.supporttextA">
                                            User Guide
                                        </Trans>
                                    </span>{" "}
                                    <Trans i18nKey="Homepage.supporttextB">and</Trans>{" "}
                                    <span className="anchortag" onClick={() => window.open("https://airbptraining.instilled.com/containers/2170224506830853899")} >
                                        <Trans i18nKey="Homepage.supporttextC">
                                            User Assist Videos
                                        </Trans>
                                    </span>
                                    .
                                </div>
                            </Col>


                        </ListGroupItem>
                        <ListGroupItem className="custom-listgroup-item">

                            <Col xs="12" >
                                <div style={{ fontFamily: "Roboto" }}>
                                    <Trans i18nKey="Homepage.supporttext1">
                                        For Technical Support, create a ticket
                                    </Trans>
                                    <span className="anchortag" onClick={() => { window.open("https://myit.bpglobal.com/myit?id=myit_ticket_submission_form&ci_id=aeec6c930fd5db40ef9a4bfce1050e68"); }} >
                                        <Trans i18nKey="Homepage.supporttext1A">here</Trans>
                                    </span>
                                    .
                                </div>
                            </Col>


                        </ListGroupItem>

                        <ListGroupItem className="custom-listgroup-item">

                            <Col xs="12" >
                                <div style={{ fontFamily: "Roboto" }}>
                                    <Trans i18nKey="Homepage.supporttext2">
                                        In the event of a data breach, refer
                                    </Trans>
                                    <span className="anchortag" onClick={() => { base64toPDF("breach") }} >
                                        <Trans i18nKey="Homepage.supporttext2A">
                                            Breach Guide
                                        </Trans>
                                    </span>
                                    .
                                </div>
                            </Col>


                        </ListGroupItem>
                    </ListGroup>
                </div>
            </Col >
        </>
    )
}

export default Support;