import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import Moment from "moment";
import base from "../../Services/BaseService";
import Viewnewsfeeddata from "./viewnewsfeed";
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Alert32, ChartUp24 } from "@bphxd/ds-core-react/lib/icons";

const Newsfeed = () => {
    const [newsfeed, setnewsfeed] = useState([]);
    const [feeddata, setfeeddata] = useState("");
    const [showfeed, setshowfeed] = useState(false);

    useEffect(() => {
        base.get("NewsFeed", "all").then((data) => {
            var res = data.filter((x) => x.rel_rec !== 0);
            var newsfeedbuf = [];
            var prioritybuf = [];
            for (let i = 0; i < res.length; i++) {
                if (res[i].news_Priority === true && res[i].text01 === "Y") {
                    prioritybuf.push(res[i]);
                } else if (res[i].text01 === "Y") {
                    newsfeedbuf.push(res[i]);
                }
            }
            prioritybuf.sort(function (a, b) {
                return new Date(b.up_date) - new Date(a.up_date);
            });
            newsfeedbuf.sort(function (a, b) {
                return new Date(b.up_date) - new Date(a.up_date);
            });
            if (prioritybuf.length < 4) {
                prioritybuf.push(...newsfeedbuf);
            }
            prioritybuf = prioritybuf.slice(0, 4);
            setnewsfeed(prioritybuf);
        });
    }, []);

    return (
        <>
            {showfeed ? (
                <Viewnewsfeeddata
                    data={feeddata}
                    closed={() => {
                        setshowfeed(false);
                    }}
                ></Viewnewsfeeddata>
            ) : (
                ""
            )}

            <Col xs="12">
                <div className="chartborder" style={{ minHeight: "362px" }}>
                    <div className="listgroup-bt p-3">
                        <p className="mb-3 d-flex align-center">
                            <ChartUp24 className="me-3 mt-2" />
                            <span className="mt-2 pt-1">News feed </span>
                        </p>
                    </div>
                    <ListGroup className="pt-1" flush tag="div" >
                        {newsfeed.map((feed, index) => {
                            return (
                                <ListGroupItem className="standard-listgroup-item" action key={`newsfeed-${index}`} onClick={() => { setfeeddata(feed); setshowfeed(true); }} style={{ cursor: "pointer" }} >
                                    <Col lg="9" xs="9">
                                        <div>
                                            <span style={{ fontFamily: "Roboto" }}>
                                                {feed.news_Headline}
                                            </span>
                                            <div className="list-group-item-subtitle">
                                                {Moment(feed.up_date).format("DD MMM YYYY HH:SS")}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="3" xs="3" className="text-end">
                                        {feed.news_Priority && (<Alert32 />)}
                                    </Col>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </div>
            </Col>
        </>
    )
}

export default Newsfeed;