import axios from "axios";
import { Service } from "../Service";
import Cookies from "universal-cookie";

var axiosinstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    Authorization: "testing",
    "Content-Type": "application/json",
  },
});

const cookies = new Cookies();

axiosinstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: cookies.get("authheader"),
      "Content-Type": "application/json",
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosinstance.interceptors.response.use(
  function (successRes) {
    return successRes;
  },
  function (error) {
    if (error.toString().includes("Network Error")) {
      return "";
    } else if (error.response.status) {
      if (error.response.status === 401) {
        Service.sendMessage({ message: "unauth" });
        return Promise.reject("unauthourized");
      }
      if (error.response.status === 406) {
        Service.sendMessage(error.response.data);
        return Promise.reject(error.response);
      }
      if (error.response.status === 508) {
        Service.sendMessage({ message: "request timed out" });
        return Promise.reject(error.response);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosinstance;
