import { useEffect, useState, useRef, useCallback } from "react";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import PerformanceUnit from "./PerformanceUnit";
import Location from "./Location";
import Country from "./Country";
import DeAnonymiseLocation from "./DeanonymizeLocation";
import AssignSiteManager from "./AssignSiteManager";
import { Input } from "reactstrap";
import "../../../Content/stylesheet/Location.css"
import "../../../Content/stylesheet/ag-grid-style.css";
import base from "../../../Services/BaseService";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew from "../../Toast/ErrorToastNew";
import { Messages } from "../../../Constants";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { UncontrolledDropdown } from "reactstrap";
import { DropdownToggle } from "reactstrap";
import { AddUser32, Edit32, Trash32, UserSettingsGear32 } from "@bphxd/ds-core-react/lib/icons";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../Content/stylesheet/ag-grid-style.css";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { createRoot } from "react-dom/client";

const ManageRegion = (props) => {
  const { t } = useTranslation();
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [gridData, setGridData] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [deletePUFeed, setDeletePUFeed] = useState({});
  const [deleteLOCFeed, setDeleteLOCFeed] = useState({});
  const [deleteCountryFeed, setDeleteCountryFeed] = useState({});
  const [showPUError, setShowPUError] = useState(false);
  const [showLOCError, setShowLOCError] = useState(false);
  const [showCTYError, setShowCTYError] = useState(false);
  const [deletePUSuccess, setDeletePUSuccess] = useState(false);
  const [deleteLOCSuccess, setDeleteLOCSuccess] = useState(false);
  const [deleteCTYSuccess, setDeleteCTYSuccess] = useState(false);
  const [selectedpuFeed, setselectedpuFeed] = useState({});
  const [selectedLOCFeed, setselectedLOCFeed] = useState({});
  const [selectedCountryFeed, setselectedCountryFeed] = useState({});
  const [ddlCountryVal, setddlCountryVal] = useState([]);
  const [ddlPUVal, setddlPUVal] = useState([]);
  const [createpuModule, setcreatepuModule] = useState(false);
  const [createlocModule, setcreatelocModule] = useState(false);
  const [createcountryModule, setcreatecountryModule] = useState(false);
  const [editpuModule, seteditpuModule] = useState(false);
  const [editlocModule, seteditlocModule] = useState(false);
  const [editcountryModule, seteditcountryModule] = useState(false);
  const [deletePUFeedConfirmToast, setDeletePUFeedConfirmToast] = useState(false);
  const [deleteLOCFeedConfirmToast, setDeleteLOCFeedConfirmToast] = useState(false);
  const [deleteCountryFeedConfirmToast, setDeleteCountryFeedConfirmToast] = useState(false);
  const [deAnonymiseModule, setDeAnonymiseModule] = useState(false);
  const [deAnonymiseFeed, setDeAnonymiseFeed] = useState({});
  const [assignManagerModule, setassignManagerModule] = useState(false);
  const [assignManagerFeed, setassignManagerFeed] = useState({});
  const [role, setRole] = useState();
  const [isSiteManager, setisSiteManager] = useState();
  const [isPUVisible, setisPUvisible] = useState(false);
  const [isCountryVisible, setisCountryVisible] = useState(false);
  const [isLocationVisible, setisLocationVisible] = useState(false);
  const [isDisabledCreate, setisDisabledCreate] = useState(false);
  const [accessLevel, setaccessLevel] = useState(false);
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserRole();
    initGridVar();
    getAPIData();
    // eslint-disable-next-line
  }, []);

  var userRole = "";
  var userAccessLevel = "";
  var SiteManager = "";

  //Fetching User Role
  const getUserRole = () => {

    setTimeout(() => {
      base.getAll("Auth/Get").then((res) => {
        setRole(res.userLevel);
        userRole = res.userLevel;
        userAccessLevel = res.accessLevel;
        setaccessLevel(res.accessLevel);

        SiteManager = res.siteManager;
        setisSiteManager(res.siteManager);

        //LOCN CTY PU
        if (userAccessLevel != null) {
          if (userAccessLevel.toUpperCase() === "LOCN") {
            setisPUvisible(false);
            setisCountryVisible(false);
            setisLocationVisible(false);
            setisDisabledCreate(true);
          }
          if (userAccessLevel.toUpperCase() === "CTY") {
            setisPUvisible(false);
            setisCountryVisible(false);
            setisLocationVisible(true);
            setisDisabledCreate(false);
          }
          if (userAccessLevel.toUpperCase() === "PU") {
            setisPUvisible(false);
            setisCountryVisible(true);
            setisLocationVisible(true);
            setisDisabledCreate(false);
          }

          if (userAccessLevel.toUpperCase() === "GLOBAL") {
            setisPUvisible(true);
            setisCountryVisible(true);
            setisLocationVisible(true);
            setisDisabledCreate(false);
          }
        }

        getAllLocation();
      });
    }, 300);
  };

  function handleSuccessClickOutside() {
    setDeletePUSuccess(false);
    setDeleteLOCSuccess(false);
    setDeleteCTYSuccess(false);
  }

  function handleErrorClickOutside() {
    setShowPUError(false);
    setShowLOCError(false);
    setShowCTYError(false);
  }

  //Function to delete a Performance Unit
  const handleDeletePUFeed = () => {
    if (!!deletePUFeed) {
      try {
        setTimeout(() => {
          base
            .delete(process.env.REACT_APP_PU_DELETE, deletePUFeed.pU_ID)
            .then((response) => {
              if (response === 200) {
                setDeletePUSuccess(true);
                getAllLocation();
                document.addEventListener(
                  "mousedown",
                  handleSuccessClickOutside
                );
              }
            });
        }, 300);
      } catch (err) {
        setShowPUError(true);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeletePUFeedConfirmToast(false);
      }
    }
  };

  //Function to delete a Location
  const handleDeleteLOCFeed = () => {
    if (!!deleteLOCFeed) {
      try {

        setTimeout(() => {
          base
            .delete(
              process.env.REACT_APP_LOCATION_DELETE,
              deleteLOCFeed.location_ID
            )
            .then((response) => {
              if (response === 200) {
                setDeleteLOCSuccess(true);
                getAllLocation();
                document.addEventListener(
                  "mousedown",
                  handleSuccessClickOutside
                );
              }
            });
        }, 300);
      } catch (err) {
        setShowLOCError(true);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeleteLOCFeedConfirmToast(false);
      }
    }
  };

  //Function to delete a Country
  const handleDeleteCountryFeed = () => {
    if (!!deleteCountryFeed) {
      try {

        setTimeout(() => {
          base
            .delete(
              process.env.REACT_APP_COUNTRY_DELETE,
              deleteCountryFeed.country_ID
            )
            .then((response) => {
              if (response === 200) {
                setDeleteCTYSuccess(true);
                getAllLocation();
                document.addEventListener(
                  "mousedown",
                  handleSuccessClickOutside
                );
              }
            });
        }, 300);
      } catch (err) {
        setShowCTYError(true);
        document.addEventListener("mousedown", handleErrorClickOutside);
      } finally {
        setDeleteCountryFeedConfirmToast(false);
      }
    }
  };

  const cancelPUDelete = () => {
    setDeletePUFeedConfirmToast(false);
    setDeletePUFeed({});
  };
  const cancelLOCDelete = () => {
    setDeleteLOCFeedConfirmToast(false);
    setDeleteLOCFeed({});
  };
  const cancelCountryDelete = () => {
    setDeleteCountryFeedConfirmToast(false);
    setDeleteCountryFeed({});
  };

  // modify pageSize
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  //Setting up of AG Grid
  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,

      autoHeight: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: t("Regionpage.LandingGridHeader.PU CODE"),
        headerTooltip: t("Regionpage.LandingGridHeader.PU CODE"),
        field: "puid",
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 200,
        sort: "asc",
      },
      {
        headerName: t("Regionpage.LandingGridHeader.PU"),
        headerTooltip: t("Regionpage.LandingGridHeader.PU"),
        field: "pU_Description",
        resizable: false,
        suppressMovable: true,
        headerClass: 'md-hide-header', cellClass: 'md-hide-cell',

        minWidth: 0,
        maxWidth: 200,
        sort: "asc",
      },
      {
        headerName: t("Regionpage.LandingGridHeader.COUNTRY CODE"),
        headerTooltip: t("Regionpage.LandingGridHeader.COUNTRY CODE"),
        field: "countryID",
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 200,

      },
      {
        headerName: t("Regionpage.LandingGridHeader.COUNTRY"),
        headerTooltip: t("Regionpage.LandingGridHeader.COUNTRY"),
        field: "country_Description",
        resizable: false,
        suppressMovable: true,
        headerClass: 'md-hide-header', cellClass: 'md-hide-cell',

        minWidth: 0,
        maxWidth: 300,
      },
      {
        headerName: t("Regionpage.LandingGridHeader.LOCATION CODE"),
        headerTooltip: t("Regionpage.LandingGridHeader.LOCATION CODE"),
        field: "locationID",
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 200,
      },
      {
        headerName: t("Regionpage.LandingGridHeader.LOCATION"),
        headerTooltip: t("Regionpage.LandingGridHeader.LOCATION"),
        field: "location_Description",
        resizable: false,
        suppressMovable: true,
        headerClass: 'md-hide-header', cellClass: 'md-hide-cell',

        minWidth: 0,
        maxWidth: 300,
      },

      {
        headerName: t("Regionpage.LandingGridHeader.ASSIGN SITE MANAGER"),
        headerTooltip: t("Regionpage.LandingGridHeader.ASSIGN SITE MANAGER"),
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 200,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressMenu: true,
        cellRenderer: AssignSiteManagerRenderer,
      },
      {
        headerName: t("Regionpage.LandingGridHeader.DEANONYMISE LOCATION"),
        headerTooltip: t("Regionpage.LandingGridHeader.DEANONYMISE LOCATION"),
        resizable: false,
        suppressMovable: true,
        minWidth: 150,
        maxWidth: 200,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressMenu: true,
        cellRenderer: DeAnonymiseRenderer,
      },
      {
        headerName: t("Regionpage.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("Regionpage.LandingGridHeader.EDIT_DELETE"),
        resizable: false,
        suppressMovable: true,
        minWidth: 200,
        maxWidth: 200,
        sortable: false,
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        suppressMenu: true,
        cellRenderer: EditDeleteIconRenderer,
      },
    ]);
  };

  //Setting up of Edit and Delete cell Renderer
  class EditDeleteIconRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      var isLocationDisabled = false;
      var isCountryDisabled = false;
      var isPUDisabled = false;
      if (userAccessLevel !== "") {
        if (userAccessLevel === "LOCN") {
          isLocationDisabled = true;
          isCountryDisabled = true;
          isPUDisabled = true;
        }
      } else {
        if (accessLevel === undefined) {
          isLocationDisabled = true;
          isCountryDisabled = true;
          isPUDisabled = true;
        } else if (accessLevel === "LOCN") {
          isLocationDisabled = true;
          isCountryDisabled = true;
          isPUDisabled = true;
        }
      }

      if (userAccessLevel !== "") {
        if (userAccessLevel === "CTY") {
          isLocationDisabled = false;
          isCountryDisabled = true;
          isPUDisabled = true;
        }
      } else {
        if (accessLevel === undefined) {
          isLocationDisabled = true;
          isCountryDisabled = true;
          isPUDisabled = true;
        } else if (accessLevel === "CTY") {
          isLocationDisabled = false;
          isCountryDisabled = true;
          isPUDisabled = true;
        }
      }

      if (userAccessLevel !== "") {
        if (userAccessLevel === "PU") {
          isLocationDisabled = false;
          isCountryDisabled = false;
          isPUDisabled = true;
        }
      } else {
        if (accessLevel === undefined) {
          isLocationDisabled = true;
          isCountryDisabled = true;
          isPUDisabled = true;
        } else if (accessLevel === "PU") {
          isLocationDisabled = false;
          isCountryDisabled = false;
          isPUDisabled = true;
        }
      }

      if (userAccessLevel !== "") {
        if (userAccessLevel === "GLOBAL") {
          isLocationDisabled = false;
          isCountryDisabled = false;
          isPUDisabled = false;
        }
      } else {
        if (accessLevel === undefined) {
          isLocationDisabled = true;
          isCountryDisabled = true;
          isPUDisabled = true;
        } else if (accessLevel === "GLOBAL") {
          isLocationDisabled = false;
          isCountryDisabled = false;
          isPUDisabled = false;
        }
      }

      var isDisabled =
        isLocationDisabled === true &&
          isCountryDisabled === true &&
          isPUDisabled === true
          ? true
          : false;



      const root = createRoot(divElement);
      root.render(<>
        <UncontrolledDropdown
          disabled={isDisabled}
          direction="down"
          className="regEditIcon"
        >
          <DropdownToggle className="kebabdropdown btnpadding">
            <Edit32
              className="pointer"
            />
          </DropdownToggle>
          <DropdownMenu className="edit-delete-dropdown-region" container="body">
            {isPUDisabled === false ? (
              <DropdownItem
                onClick={() => editPUFeedClick(params.node.data)}
                style={
                  params.node.data.puid === null ||
                    params.node.data.puid === "null" ||
                    params.node.data.puid === "" ||
                    params.node.data.pU_Description === null ||
                    params.node.data.pU_Description === "null" ||
                    params.node.data.pU_Description === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                Performance Unit (PU)
              </DropdownItem>
            ) : (
              ""
            )}

            {isCountryDisabled === false ? (
              <DropdownItem
                onClick={() => editCountryFeedClick(params.node.data)}
                style={
                  params.node.data.countryID === null ||
                    params.node.data.countryID === "null" ||
                    params.node.data.countryID === "" ||
                    params.node.data.country_Description === null ||
                    params.node.data.country_Description === "null" ||
                    params.node.data.country_Description === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                Country{" "}
              </DropdownItem>
            ) : (
              ""
            )}

            {isLocationDisabled === false ? (
              <DropdownItem
                onClick={() => editLOCFeedClick(params.node.data)}
                style={
                  params.node.data.locationID === null ||
                    params.node.data.locationID === "null" ||
                    params.node.data.locationID === "" ||
                    params.node.data.location_Description === null ||
                    params.node.data.location_Description === "null" ||
                    params.node.data.location_Description === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                Location{" "}
              </DropdownItem>
            ) : (
              ""
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown
          disabled={isDisabled}
          direction="down"
          className="regDelIcon"
        >
          <DropdownToggle className="kebabdropdown btnpadding">
            <Trash32
              className="pointer trashOnSmallerScreen ms-2"
            />
          </DropdownToggle>

          <DropdownMenu className="" container="body">
            {isPUDisabled === false ? (
              <DropdownItem
                onClick={() => deletePUFeedClick(params.node.data)}
                style={
                  params.node.data.puid === null ||
                    params.node.data.puid === "null" ||
                    params.node.data.puid === "" ||
                    params.node.data.pU_Description === null ||
                    params.node.data.pU_Description === "null" ||
                    params.node.data.pU_Description === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                Performance Unit (PU)
              </DropdownItem>
            ) : (
              ""
            )}
            {isCountryDisabled === false ? (
              <DropdownItem
                onClick={() => deleteCountryFeedClick(params.node.data)}
                style={
                  params.node.data.countryID === null ||
                    params.node.data.countryID === "null" ||
                    params.node.data.countryID === "" ||
                    params.node.data.country_Description === null ||
                    params.node.data.country_Description === "null" ||
                    params.node.data.country_Description === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                Country{" "}
              </DropdownItem>
            ) : (
              ""
            )}
            {isLocationDisabled === false ? (
              <DropdownItem
                onClick={() => deleteLOCFeedClick(params.node.data)}
                style={
                  params.node.data.locationID === null ||
                    params.node.data.locationID === "null" ||
                    params.node.data.locationID === "" ||
                    params.node.data.location_Description === null ||
                    params.node.data.location_Description === "null" ||
                    params.node.data.location_Description === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                Location{" "}
              </DropdownItem>
            ) : (
              ""
            )}
          </DropdownMenu>
        </UncontrolledDropdown >
      </>
      );
    }
    getGui() {
      return this.eGui;
    }
  }

  const editPUFeedClick = (selectedpuFeed) => {
    setselectedpuFeed(selectedpuFeed);
    seteditpuModule(true);
  };

  const editLOCFeedClick = (selectedLOCFeed) => {
    setselectedLOCFeed(selectedLOCFeed);
    seteditlocModule(true);
  };

  const editCountryFeedClick = (selectedCountryFeed) => {
    setselectedCountryFeed(selectedCountryFeed);
    seteditcountryModule(true);
  };

  const deletePUFeedClick = (params) => {
    setDeletePUFeedConfirmToast(true);
    setDeletePUFeed(params);
  };
  const deleteLOCFeedClick = (params) => {
    setDeleteLOCFeedConfirmToast(true);
    setDeleteLOCFeed(params);
  };
  const deleteCountryFeedClick = (params) => {
    setDeleteCountryFeedConfirmToast(true);
    setDeleteCountryFeed(params);
  };

  //Setting up of Assign Site Manager cell renderer
  class AssignSiteManagerRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      var isAssignSM = false;

      if (userRole !== "") {
        userRole === "10" || userRole === "15"
          ? (isAssignSM = true)
          : (isAssignSM = false);
      } else {
        if (role === undefined) isAssignSM = false;
        else {
          role === "10" || role === "15"
            ? (isAssignSM = true)
            : (isAssignSM = false);
        }
      }

      const root = createRoot(divElement);
      root.render(
        <>
          {isAssignSM === true ? (
            <AddUser32
              onClick={() => callassignManager(params.node.data)}
              style={
                params.node.data.locationID === null ||
                  params.node.data.locationID === "null" ||
                  params.node.data.locationID === "" ||
                  params.node.data.location_Description === null ||
                  params.node.data.location_Description === "null" ||
                  params.node.data.location_Description === ""
                  ? { display: "none" }
                  : { display: "inline", cursor: "pointer" }
              } />
          ) : (
            ""
          )}
        </>,
        divElement
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  //Setting up of Deanonymise cell renderer
  class DeAnonymiseRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      var isDeanonymise = false;
      if (SiteManager !== "" || userRole !== "") {
        (SiteManager === "Y" &&
          (userRole === "30" || userRole === "40" || userRole === "20")) ||
          userRole === "10" ||
          userRole === "15"
          ? (isDeanonymise = true)
          : (isDeanonymise = false);
      } else {
        if (isSiteManager !== undefined || role !== undefined) {
          (isSiteManager === "Y" &&
            (role === "30" || role === "40" || role === "20")) ||
            role === "10" ||
            role === "15"
            ? (isDeanonymise = true)
            : (isDeanonymise = false);
        } else isDeanonymise = false;
      }

      const root = createRoot(divElement);
      root.render(
        <>
          {isDeanonymise === true ? (
            <UserSettingsGear32

              style={
                params.node.data.locationID === null ||
                  params.node.data.locationID === "null" ||
                  params.node.data.locationID === "" ||
                  params.node.data.location_Description === null ||
                  params.node.data.location_Description === "null" ||
                  params.node.data.location_Description === ""
                  ? { display: "none" }
                  : { display: "inline", cursor: "pointer" }
              }
              onClick={() => callDeAnonymise(params.node.data)}
            />
          ) : (
            ""
          )}
        </>,
        divElement
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  const callDeAnonymise = (deanonymiseFeed) => {
    setDeAnonymiseModule("true");
    setDeAnonymiseFeed(deanonymiseFeed);
  };
  const callassignManager = (assignManagerFeed) => {
    setassignManagerModule("true");
    setassignManagerFeed(assignManagerFeed);
  };

  //Function invoked to load Country and Performance Unit data
  const getAPIData = () => {

    setTimeout(() => {
      base.getAll("Country/Qry").then((res) => {
        var data = res.filter(
          (x) => x.rel_rec !== 0 && x.countryID != null && x.fk_PUID != null
        );
        setddlCountryVal(data);
      });
    }, 300);


    setTimeout(() => {
      base.getAll("performanceunit/Qry").then((res) => {
        var data = res.filter(
          (x) => x.rel_rec !== 0 && x.puid != null
        );
        setddlPUVal(data);
      });
    }, 300);
  };

  const dataPUSaved = () => {
    setShowToast({ Name: "successPU", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataPUNotSaved = () => {
    setShowToast({ Name: "errorPU", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataLOCSaved = () => {
    setShowToast({ Name: "successLOC", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataLOCNotSaved = () => {
    setShowToast({ Name: "errorLOC", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataCTYSaved = () => {
    setShowToast({ Name: "successCTY", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const dataCTYNotSaved = () => {
    setShowToast({ Name: "errorCTY", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const closeCreateManagePU = (val) => {
    setcreatepuModule(val);
    seteditpuModule(val);
  };

  const closeCreateManageLoc = (val) => {
    setcreatelocModule(val);
    seteditlocModule(val);
  };
  const closeCreateManageCountry = (val) => {
    setcreatecountryModule(val);
    seteditcountryModule(val);
  };
  const closeDeAnonymiseModule = (val) => {
    setDeAnonymiseModule(val);
  };
  const closeassignManagerModule = (val) => {
    setassignManagerModule(val);
  };

  function DataSavingToastonClick() {
    setShowToast(false);
  }

  //Function invoked to load all data in AG Grid
  const getAllLocation = () => {

    setTimeout(() => {
      base.getAll("performanceunit/get/location").then((res) => {
        var data = res.filter((x) => x.rel_rec !== 0);
        setGridData(data);
      });
    }, 300);


    setTimeout(() => {
      base.getAll("Country/Qry").then((res1) => {
        var data = res1.filter(
          (x) => x.rel_rec !== 0 && x.countryID != null && x.fk_PUID != null
        );
        setddlCountryVal(data);
      });
    }, 300);


    setTimeout(() => {
      base.getAll("performanceunit/Qry").then((res2) => {
        var data = res2.filter(
          (x) => x.rel_rec !== 0 && x.puid != null
        );
        setddlPUVal(data);
      });
    }, 300);
  };

  return (
    <>
      <div>

        <TitleBanner
          heading={t("Regionpage.Region")}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("Common.Manage Data"), t("Regionpage.Region")]}
          showButton1={false}
          showButton2={false}
          showButton3={true}
          textButton3={t("Regionpage.Create Region")}
          dropdownMenu={<DropdownMenu >
            {isPUVisible ? (
              <DropdownItem onClick={() => setcreatepuModule(true)} > {t("Regionpage.PerformanceUnit")} </DropdownItem>
            ) : (
              ""
            )}
            {isCountryVisible ? (
              <DropdownItem
                onClick={() => setcreatecountryModule(true)}
              >
                {t("Regionpage.Country")}
              </DropdownItem>
            ) : (
              ""
            )}
            {isLocationVisible ? (
              <DropdownItem
                onClick={() => setcreatelocModule(true)}
              >
                {t("Regionpage.Location")}
              </DropdownItem>
            ) : (
              ""
            )}
          </DropdownMenu>}
          buttonHeading={""}
          isDisabledCreate={isDisabledCreate}
        />

        <ConfirmDeleteToast
          show={deletePUFeedConfirmToast}
          handleCancel={cancelPUDelete}
          Message={Messages.manageRegion.PUDeleteConfirm}
          handleDelete={handleDeletePUFeed}
        />
        <ConfirmDeleteToast
          show={deleteLOCFeedConfirmToast}
          handleCancel={cancelLOCDelete}
          Message={Messages.manageRegion.LOCDeleteConfirm}
          handleDelete={handleDeleteLOCFeed}
        />
        <ConfirmDeleteToast
          show={deleteCountryFeedConfirmToast}
          handleCancel={cancelCountryDelete}
          Message={Messages.manageRegion.CTYDeleteConfirm}
          handleDelete={handleDeleteCountryFeed}
        />
        <SuccessToastNew
          show={deletePUSuccess}
          Message={t("Regionpage.PUDeleteSuccess")}
        //
        />
        <ErrorToastNew
          show={showPUError}
          Message={t("Regionpage.PUDeleteFailure")}
        />
        <SuccessToastNew
          show={deleteLOCSuccess}
          Message={t("Regionpage.LOCDeleteSuccess")}
        />
        <ErrorToastNew
          show={showLOCError}
          Message={t("Regionpage.LOCDeleteFailure")}
        />
        <SuccessToastNew
          show={deleteCTYSuccess}
          Message={t("Regionpage.CTYDeleteSuccess")}
        />
        <ErrorToastNew
          show={showCTYError}
          Message={t("Regionpage.CTYDeleteFailure")}
        />
        {showToast.Name === "successPU" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Regionpage.PUSaveSuccess")}
          />
        )}

        {showToast.Name === "errorPU" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Regionpage.PUSaveFailure")}
          />
        )}
        {showToast.Name === "successLOC" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Regionpage.LOCSaveSuccess")}
          />
        )}

        {showToast.Name === "errorLOC" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Regionpage.LOCSaveFailure")}
          />
        )}
        {showToast.Name === "successCTY" && (
          <SuccessToastNew
            show={showToast.show}
            Message={t("Regionpage.CTYSaveSuccess")}
          />
        )}

        {showToast.Name === "errorCTY" && (
          <ErrorToastNew
            show={showToast.show}
            Message={t("Regionpage.CTYSaveFailure")}
          />
        )}
      </div>

      <div>

      </div>

      <div className="ps-7 pe-7 pb-5 pt-4">
        <div className="gridDiv">

          <div
            className="ag-theme-material gridHtWd "
            style={{ position: 'relative' }}
          >
            <div
              className="rowDataInput"
            >
              {gridData ? (
                <Input type="select" onChange={onPageSizeChanged} id="page-size" defaultValue={10} >
                  <option value="10">10</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </Input>
              ) : (
                ""
              )}
            </div>
            <AgGridReact
              ref={gridRef}
              columnDefs={gridColDefs}
              rowData={gridData}
              suppressMenuHide={true}
              overlayNoRowsTemplate="No records found"
              defaultColDef={gridDefaultColDef}
              pagination={true}
              paginationPageSize={10}
              suppressScrollOnNewData={true}
              suppressHorizontalScroll={false}
              enableRangeSelection={true}
              onFilterChanged={onFilterChanged}
              postProcessPopup={postProcessPopup}
            ></AgGridReact>

          </div>
        </div>
      </div>
      <div className="col-lg-12 mb-3 p1-0 pr-1 " style={{ width: "98.9%" }}>
        {createpuModule ? (
          <PerformanceUnit
            create={true}
            close={closeCreateManagePU}
            saveSuccess={dataPUSaved}
            error={dataPUNotSaved}
            getAll={getAllLocation}
            isopen={createpuModule}
          ></PerformanceUnit>
        ) : (
          ""
        )}

        {editpuModule ? (
          <PerformanceUnit
            create={false}
            puFeed={selectedpuFeed}
            close={closeCreateManagePU}
            saveSuccess={dataPUSaved}
            error={dataPUNotSaved}
            getAll={getAllLocation}
            isopen={editpuModule}
          ></PerformanceUnit>
        ) : (
          ""
        )}

        {createlocModule ? (
          <Location
            create={true}
            close={closeCreateManageLoc}
            CountryList={ddlCountryVal}
            saveSuccess={dataLOCSaved}
            error={dataLOCNotSaved}
            getAll={getAllLocation}
            isopen={createlocModule}
          ></Location>
        ) : (
          ""
        )}

        {editlocModule ? (
          <Location
            create={false}
            close={closeCreateManageLoc}
            locFeed={selectedLOCFeed}
            CountryList={ddlCountryVal}
            saveSuccess={dataLOCSaved}
            error={dataLOCNotSaved}
            getAll={getAllLocation}
            isopen={editlocModule}
          ></Location>
        ) : (
          ""
        )}

        {createcountryModule ? (
          <Country
            create={true}
            close={closeCreateManageCountry}
            PUList={ddlPUVal}
            saveSuccess={dataCTYSaved}
            error={dataCTYNotSaved}
            getAll={getAllLocation}
            isopen={createcountryModule}
          ></Country>
        ) : (
          ""
        )}

        {editcountryModule ? (
          <Country
            create={false}
            close={closeCreateManageCountry}
            countryFeed={selectedCountryFeed}
            PUList={ddlPUVal}
            saveSuccess={dataCTYSaved}
            error={dataCTYNotSaved}
            getAll={getAllLocation}
            isopen={editcountryModule}
          ></Country>
        ) : (
          ""
        )}

        {deAnonymiseModule ? (
          <DeAnonymiseLocation
            close={closeDeAnonymiseModule}
            deAnonymiseFeed={deAnonymiseFeed}
            isopen={deAnonymiseModule}
          ></DeAnonymiseLocation>
        ) : (
          ""
        )}

        {assignManagerModule ? (
          <AssignSiteManager
            close={closeassignManagerModule}
            assignManagerFeed={assignManagerFeed}
            isopen={assignManagerModule}
          ></AssignSiteManager>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default ManageRegion;
