import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { Datepicker } from '@bphxd/ds-core-react';
import _ from 'lodash';

const EmployeeHeader = (props) => {
    const { setFormChanged, setSaveDisbaled, setShowResetConfirm, formChanged, assessment, setInvalidDates, invalidDates, clearCalled, onUpdate, employeeData, task_Critical } = props
    const [assessmentCopy, setAssessmentCopy] = useState(assessment);
    const [selectedDate, setSelectedDate] = useState(null);

    const maxRetrain = task_Critical === "Y" ? 183 : task_Critical === "P" ? 365 : 730
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - maxRetrain);
    useEffect(() => {
        setAssessmentCopy(assessment);
        setSelectedDate(null)
    }, [clearCalled, assessment]);

    useEffect(() => {
        const updatedAssessment = assessmentCopy;
        onUpdate(updatedAssessment);
    }, [onUpdate, assessmentCopy]);

    const handleDateChange = (selectedDates, dateStr, instance) => {
        setFormChanged(true);
        setSaveDisbaled(false);
        var key = instance.element.id;
        const isInvalid = invalidDates.includes(key);
        if (isInvalid) {
            setInvalidDates((prev) => {
                return prev.filter((d) => d !== key);
            });
        }
        const keyArray = key.split('_')
        var date
        var dbDate
        if (selectedDates.length === 0) {
            date = null;
        } else {
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

            dbDate = new Date(Date.parse(selectedDates) - tzoffset).toISOString();
            date = new Date(selectedDates).toISOString();
        }
        const updatedAssessment = _.cloneDeep(assessmentCopy);
        const employeeIndex = updatedAssessment.results.findIndex(
            (employee) => employee.fk_EmployeeID === keyArray[0]
        );
        const assessmentResultsCopy = JSON.parse(JSON.stringify(updatedAssessment.results[employeeIndex]));
        assessmentResultsCopy.pStart = dbDate;
        assessmentResultsCopy.pStartShow = date;
        updatedAssessment.results[employeeIndex] = assessmentResultsCopy;
        setAssessmentCopy(updatedAssessment);
    };

    const handleAllDateChange = (selectedDates, dateStr, instance) => {

        setInvalidDates([]);
        setFormChanged(true);
        setSaveDisbaled(false);

        var date
        var dbDate
        if (selectedDates.length === 0) {
            date = null;
        } else {
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

            dbDate = new Date(Date.parse(selectedDates) - tzoffset).toISOString();
            date = new Date(selectedDates).toISOString();
        }
        const updatedAssessment = _.cloneDeep(assessmentCopy);

        updatedAssessment.results.forEach((employee, employeeIndex) => {
            const assessmentResultsCopy = JSON.parse(JSON.stringify(updatedAssessment.results[employeeIndex]));
            assessmentResultsCopy.pStart = dbDate;
            assessmentResultsCopy.pStartShow = date;
            updatedAssessment.results[employeeIndex] = assessmentResultsCopy;
        })
        setAssessmentCopy(updatedAssessment);
        setSelectedDate(dateStr);
    };

    const dateoptionsAll = {
        onChange: (selectedDates, dateStr, instance) => handleAllDateChange(selectedDates, dateStr, instance),
        allowInput: false,
        minDate: minDate,
        maxDate: "today"
    };

    const handleClear = () => {
        if (formChanged) {
            setShowResetConfirm(true);
        }
    }

    return (
        <>
            <th rowSpan={3} className="first-cell border-0 pt-0 pb-2 ps-1 pe-1" >
                <>
                    <Row>
                        <Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end me-4">
                            <Button
                                size="sm"
                                color="standard-tertiary"
                                className="rounded-pill btn-style"
                                onClick={() => handleClear()}
                            >
                                Reset all
                            </Button>
                        </Col>
                    </Row>
                    <Row >
                        <Col className="text-end me-4" >
                            <div style={{ width: "180px", marginLeft: "auto" }}>
                                <Datepicker
                                    id={`all_datecontrol`}
                                    className="mt-2"
                                    bsSize='sm'
                                    placeholder="Assign date to all"
                                    options={dateoptionsAll}
                                    value={selectedDate}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            </th>
            {employeeData.map((employee, index) => {
                const employeeInfo = assessmentCopy.results.filter(d => d.fk_EmployeeID === employee.fk_EmployeeID);
                const minDate2 = new Date(employee.pStart);
                const dateoptions = {

                    onChange: (selectedDates, dateStr, instance) => handleDateChange(selectedDates, dateStr, instance),
                    allowInput: false,
                    minDate: employee.pStart ? minDate2 : minDate,
                    maxDate: new Date()
                };

                return (
                    <th key={index} className="border-0 other-cells pt-0 pb-2 ps-1 pe-1">
                        <Row>
                            <Col>
                                <Input className="mt-2" bsSize="sm" value={employee.emp_DisplayName} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input className="mt-2" bsSize="sm" value={employee.fk_LocationID} disabled />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Datepicker
                                    id={`${employee.fk_EmployeeID}_datecontrol`}
                                    className="mt-2"
                                    bsSize='sm'
                                    placeholder="Select date"
                                    options={dateoptions}
                                    invalid={props.invalidDates.includes(`${employee.fk_EmployeeID}_datecontrol`)}
                                    value={employeeInfo.length > 0 ? employeeInfo[0].pStart : null}
                                />
                            </Col>
                        </Row>
                    </th>)
            }
            )
            }
        </>
    );
}

export default EmployeeHeader;
