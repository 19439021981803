import axios from "./axiosInstance";

const base = {
  getAll: async (url) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.get(url, { withCredentials: true });
        resolve(response.data);
      } catch (error) {
        console.error("An error occurred while making the GETALL request:", error);
        reject(error);
      }
    });
    return promise;
  },

  getReport: async (url) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.get(url, {
          withCredentials: true,
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        });
        let responseStringResult = "";
        if (response.headers["content-type"] === "application/json; charset=utf-8") {
          let resdata = response.data;
          let decstr = String.fromCharCode.apply(null, new Uint8Array(resdata));
          responseStringResult = JSON.parse(decstr);
        }

        let fileName = "";
        if (responseStringResult === "" && response.status === 200) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .split(";")[0];

          resolve({
            responseData: response.data,
            fileName: fileName.replace(/['"]+/g, ""),
          });
        } else if (
          responseStringResult !== "" &&
          (
            responseStringResult === "Task Count exceeds maximum allow for this report type." ||
            responseStringResult === "There are no recorded results for DAT Part 1" ||
            responseStringResult === "Not adequate questions assigned for this module!"
          )
        ) {
          resolve({
            responseData: responseStringResult,
            fileName: fileName,
          });
        } else if (responseStringResult === "Incorrect task type") {
          reject({
            responseData: response.data,
            response: {
              status: response.status,
              message: responseStringResult
            },
          });
        }
      } catch (error) {
        console.error("An error occurred while making the REPORT request:", error);
        reject(error);
      }
    });
    return promise;
  },


  get: async (url, id) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.get(url + "/" + id, {
          withCredentials: true,
        });
        resolve(response.data);
      } catch (error) {
        console.error("An error occurred while making the GET request:", error);
        reject(error);
      }
    });
    return promise;
  },

  add: async (url, data) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.post(url, data, {
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
        });
        resolve(response);
      } catch (error) {
        console.error("An error occurred while making the ADD request:", error);
        reject(error);
      }
    });
    return promise;
  },

  update: async (url, data) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.put(url, data, { withCredentials: true });
        resolve(response);
      } catch (error) {
        console.error("An error occurred while making the UPDATE request:", error);
        reject(error);
      }
    });
    return promise;
  },

  updateHazardsToStep: async (url, data) => {
    const response = await axios.put(url, data, { withCredentials: true });
    return response;
  },

  delete: async (url, id) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.put(url + "/" + id, null, {
          withCredentials: true,
        });
        resolve(response.status);
      } catch (error) {
        console.error("An error occurred while making the DELETE request:", error);
        reject(error);
      }
    });
    return promise;
  },

  remove: async (url) => {
    var promise = new Promise(async function (resolve, reject) {
      try {
        const response = await axios.delete(url, null, {
          withCredentials: true,
        });
        resolve(response.status);
      } catch (error) {
        console.error("An error occurred while making the REMOVE request:", error);
        reject(error);
      }
    });
    return promise;
  },
};

export default base;
