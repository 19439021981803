import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { RemoveSmall16, } from "@bphxd/ds-core-react/lib/icons";
import { Row, Col, CardBody, Card, CardHeader, Badge } from 'reactstrap'
import "../../Content/stylesheet/common.css";
import "../../Content/stylesheet/traineeassessment.css";


import OnlineAssessmentItem from "./OnlineAssessmentItem";

import { useTranslation } from "react-i18next";
import { useUser } from "../Shared/userContext";
import TitleBanner from "../Shared/Layout/TitleBanner";

const TrainingsLandingPage = (props) => {
  const { t } = useTranslation();

  const onlineAssessments =
    props.onlineAssessments === "loading" ? null : props.onlineAssessments;

  const navigate = useNavigate();

  const { userData } = useUser();
  const userDisplayName = userData.userDisplayName;

  function getActiveFilterClassName(activeFilter) {
    switch (activeFilter) {
      case "EXPIRED":
        return "danger";
      case "OVERDUE":
        return "warning";
      default:
        return "light";
    }
  }

  const trainingStatusMapping = {
    EXPIRED: "EXPIRED",
    OVERDUE: "OVERDUE",
    DUE: "DUE",
    "NOT YET DUE": "NOT YET DUE",
  };

  const [onlineAssessmentFilterState, setOnlineAssessmentFilterState] =
    useState({ activeFilters: [] });

  let assessmentTrainingStatusCount = useMemo(() => {
    if (!onlineAssessments || onlineAssessments?.length === 0) return null;
    let expiredCount = 0;
    let overdueCount = 0;
    let dueCount = 0;
    let notYetDueCount = 0;

    for (let i = 0; i < onlineAssessments.length; i++) {
      let currTrainingstatus = onlineAssessments[i].dueType;

      if (currTrainingstatus === "EXPIRED") expiredCount++;
      else if (currTrainingstatus === "OVERDUE") overdueCount++;
      else if (currTrainingstatus === "DUE") dueCount++;
      else if (currTrainingstatus === "NOT YET DUE") notYetDueCount++;
    }

    return {
      EXPIRED: expiredCount,
      OVERDUE: overdueCount,
      DUE: dueCount,
      "NOT YET DUE": notYetDueCount,
    };
  }, [onlineAssessments]);

  function handleLaunchAssessment(assessmentInfo) {
    const { assessmentID } = assessmentInfo;

    navigate(`${assessmentID}`); // this will tell React-Router to go to route "/MyTraining/assessmentID" and render the linked Component (TraineeAssessment)
  }

  function onOnlineAssessmentFilterChange(dueType) {
    const currentFilter = dueType;
    setOnlineAssessmentFilterState((prevFilterState) => {
      const prevActiveFilters = prevFilterState.activeFilters;
      let newActiveFilters = [...prevActiveFilters];

      if (!prevActiveFilters.includes(currentFilter))
        newActiveFilters.push(currentFilter);

      return { ...prevFilterState, activeFilters: newActiveFilters };
    });
  }

  function onRemoveFilter(selectedFilter) {
    setOnlineAssessmentFilterState((prevFilterState) => {
      const prevActiveFilters = prevFilterState.activeFilters;
      let newActiveFilters = prevActiveFilters.filter(
        (filterItem) => filterItem !== selectedFilter
      );

      return { ...prevFilterState, activeFilters: newActiveFilters };
    });
  }

  const filteredOnlineAssessments = useMemo(() => {
    if (
      !onlineAssessments ||
      onlineAssessmentFilterState.activeFilters.length === 0
    )
      return onlineAssessments;

    let newOnlineAssessments = onlineAssessments.filter((assessment) => {
      const activeFilters = onlineAssessmentFilterState.activeFilters;

      // New Due Types :- "OVERDUE", "DUE" (DueDate : 0-30 days), "NOT YET DUE"(DueDate : > 30 days)
      // trainingStatus values from DB are :- "OVERDUE", "DUE", "DUE IN < 30 DAYS", "NOT YET DUE"

      let assessmentTrainingStatus = assessment.dueType;

      if (activeFilters.includes(assessmentTrainingStatus)) return true;
      return false;
    });

    return newOnlineAssessments;
  }, [onlineAssessments, onlineAssessmentFilterState]);

  return (
    <>

      <TitleBanner
        heading={t("MyTrainingpage.MyTraining")}
        subheading={""}
        infoText={""}
        breadcrumbArray={[t("MyTrainingpage.MyTraining"), t("MyTrainingpage.Dashboard")]}
        showButton1={false}
        showButton2={false}
        showButton3={false}
      />


      <div className="mytrainingdashboard-bodycontainer">
        <div className="mt-dashboard-body">
          <div className="mt-dashboard-body__heading">
            {t("MyTrainingpage.Welcome,")} {` ${userDisplayName}`}
          </div>

          {filteredOnlineAssessments?.length === 0 && (
            <div className="no-assessment-message">
              <div> {t("MyTrainingpage.No Assessment available")}</div>
            </div>
          )}

          {filteredOnlineAssessments?.length > 0 && (
            <Row className="">
              <Col xxl="5" xl="8" sm="12" >
                <Card className="card-lg border  mt-4">
                  <CardHeader className="online-assessment__heading pt-2 pb-2 ps-4 pe-4">
                    {/* tag="h5" */}
                    <div className="p-0 m-0">
                      {t("MyTrainingpage.TRAINING_STATUS")}
                    </div>
                  </CardHeader>

                  <CardBody>


                    <div>


                      <Row
                      >
                        <Col xl="3" xs="3" className="due-textitem  ">
                          <div
                            className={
                              assessmentTrainingStatusCount.EXPIRED > 0
                                ? "item__due-number due-text-color "
                                : "item__due-number due-pointer-events"
                            }
                            onClick={() => onOnlineAssessmentFilterChange("EXPIRED")}
                          >
                            {assessmentTrainingStatusCount.EXPIRED}
                          </div>
                          <div
                            className={
                              assessmentTrainingStatusCount.EXPIRED > 0
                                ? "txtlink__body"
                                : "txtlink__body due-pointer-events"
                            }
                            id="dueType-OVERDUE"
                            onClick={() => onOnlineAssessmentFilterChange("EXPIRED")}
                          >
                            {t("MyTrainingpage.EXPIRED")}{" "}
                            {/*{trainingStatusMapping.EXPIRED} */}
                          </div>
                        </Col>
                        <Col xl="3" xs="3" className="due-textitem">
                          <div
                            className={
                              assessmentTrainingStatusCount.OVERDUE > 0
                                ? "item__due-number"
                                : "item__due-number due-pointer-events"
                            }
                            onClick={() => onOnlineAssessmentFilterChange("OVERDUE")}
                          >
                            {assessmentTrainingStatusCount.OVERDUE}
                          </div>
                          <div
                            className={
                              assessmentTrainingStatusCount.OVERDUE > 0
                                ? "txtlink__body"
                                : "txtlink__body due-pointer-events"
                            }
                            id="dueType-OVERDUE"
                            onClick={() => onOnlineAssessmentFilterChange("OVERDUE")}
                          >
                            {t("MyTrainingpage.OVERDUE")}
                            {/* {trainingStatusMapping.OVERDUE} */}
                          </div>
                        </Col>
                        <Col xl="3" xs="3" className="due-textitem">
                          <div
                            className={
                              assessmentTrainingStatusCount.DUE > 0
                                ? "item__due-number"
                                : "item__due-number due-pointer-events"
                            }
                            onClick={() => onOnlineAssessmentFilterChange("DUE")}
                          >
                            {assessmentTrainingStatusCount.DUE}
                          </div>
                          <div
                            className={
                              assessmentTrainingStatusCount.DUE > 0
                                ? "txtlink__body"
                                : "txtlink__body due-pointer-events"
                            }
                            id="dueType-DUE_SOON"
                            onClick={() => onOnlineAssessmentFilterChange("DUE")}
                          >
                            {t("MyTrainingpage.DUE")}
                            {/* {trainingStatusMapping.DUE} */}
                          </div>
                        </Col>
                        <Col xl="3" xs="3" className="due-textitem ">
                          <div
                            className={
                              assessmentTrainingStatusCount["NOT YET DUE"] > 0
                                ? "item__due-number"
                                : "item__due-number due-pointer-events"
                            }
                            onClick={() =>
                              onOnlineAssessmentFilterChange("NOT YET DUE")
                            }
                          >
                            {assessmentTrainingStatusCount["NOT YET DUE"]}
                          </div>
                          <div
                            className={
                              assessmentTrainingStatusCount["NOT YET DUE"] > 0
                                ? "txtlink__body"
                                : "txtlink__body due-pointer-events"
                            }
                            id="dueType-ASSIGNED_OR_NO_DUEDATE"
                            onClick={() =>
                              onOnlineAssessmentFilterChange("NOT YET DUE")
                            }
                          >
                            {t("MyTrainingpage.NOT YET DUE")}
                            {/* {trainingStatusMapping["NOT YET DUE"]} */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xxl="7" xl="12" sm="12" className="mt-4">

                <div className="online-assessment__heading ">
                  <Row>
                    <Col md="5" xs="12">
                      <div className="p-0 m-0">
                        {t("MyTrainingpage.Online Assessments")}
                      </div>
                    </Col>
                    <Col md="7" xs="12" className="text-end">
                      {onlineAssessmentFilterState.activeFilters.map(
                        (activeFilter) => {
                          return (
                            <>
                              <Badge
                                key={activeFilter}
                                className="me-2 badge-text"
                                color={`${getActiveFilterClassName(activeFilter)}`}
                                style={{ fontSize: "11px" }}>
                                {t(`MyTrainingpage.${trainingStatusMapping[activeFilter]}`)}
                                <RemoveSmall16
                                  className="m-0 p-0 remove-filter-icon"
                                  onClick={() => onRemoveFilter(activeFilter)}
                                />
                              </Badge>
                            </>
                          );
                        }
                      )}
                    </Col>
                  </Row>


                </div>
                <div className="assessment-item-container" />

                <div className="onlineassessment__list-container">
                  {filteredOnlineAssessments.map((assessmentData) => (
                    <OnlineAssessmentItem
                      key={assessmentData.assessmentID}
                      onLaunchAssessment={handleLaunchAssessment}
                      assessmentData={assessmentData}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};
export default TrainingsLandingPage;
