import React from 'react';
import { useEffect, useState } from "react";
import base from "../../../../Services/BaseService";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Trash32, World24 } from "@bphxd/ds-core-react/lib/icons";
import Select from "react-select";
import { useModuleContext } from "../../../Shared/moduleContext";
import { Trans } from "react-i18next";
import '../../../../Content/stylesheet/LO.css'
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';
import { useUser } from "../../../Shared/userContext";

const LearningOutcomeModal = ({ modalTitle, closeLOModal, saveLOModal, loFeedData, create }) => {
    const { languages } = useUser();
    const { t } = useTranslation();
    const { moduleData, moduleLanguagePreference, globalUser } = useModuleContext();
    const [language, setLanguage] = useState(moduleLanguagePreference)
    const [linkToActivityData, setLinkToActivityData] = useState([])
    const [loActivitySelected, setloActivitySelected] = useState([])
    const [binaryImage, setBinaryImage] = useState("");
    const [invalid, setInvalid] = useState([])
    const [loImageSize, setLoImageSize] = useState("")
    const [previousImage, setPreviousImage] = useState("")
    const [disabled, setDisabled] = useState([])
    const [outcomeData, setOutcomeData] = useState(create
        ? [{
            "outcomeID": "",
            "outcome_Description": "",
            "fk_TaskID": moduleData[0].taskID,
            "Field01": "",
            "Field02": "",
            "Field03": "",
            "Field04": "",
            "Field05": "",
            "N_IMAGE": "",
            "S_IMAGE": "",
            "B_IMAGE": "",
            "fk_LanguageID": moduleLanguagePreference,
        }]
        : loFeedData);

    useEffect(() => {
        let disabledFields = []

        if (create === false && globalUser === "RADMIN" && moduleData[0].task_Site === "GLOBAL" && language === "en-GB") {
            disabledFields.push("remove", "imageLO", "file-upload1", "linkToActivity", "outcome_Description")
        }
        if (create === false && globalUser === "RADMIN" && moduleData[0].task_Site === "GLOBAL" && language !== "en-GB") {
            disabledFields.push("remove", "imageLO", "file-upload1", "linkToActivity")
        }
        if (create === false && moduleData[0].task_Site === "SITE" && (moduleData[0].taskID === moduleData[0].global_TaskID)) {
            disabledFields = []
        }

        if (!previousImage) {
            disabledFields.push("remove", "imageLO")

        }
        setDisabled(disabledFields)

    }, [language, globalUser, moduleData, create, loFeedData, previousImage]);

    useEffect(() => {
        if (Object.keys(loFeedData).length > 0) {
            setBinaryImage(loFeedData.find(item => item.fk_LanguageID === "en-GB")?.b_IMAGE)
            setLoImageSize(loFeedData.find(item => item.fk_LanguageID === "en-GB")?.s_IMAGE)
            setPreviousImage(loFeedData.find(item => item.fk_LanguageID === "en-GB")?.n_IMAGE)
        }
        setLanguage(moduleLanguagePreference);
    }, [moduleLanguagePreference, loFeedData])

    const handleLanguageChange = (event) => {
        let selectedLanguage = event.target.value
        setLanguage(selectedLanguage);
    };

    const handleChange = (e) => {
        let elementValue = e.target.value
        let elementID = e.target.id
        setInvalid(prevInvalid =>
            prevInvalid.filter(element => element !== elementID)
        );

        setOutcomeData((prevOutcomeData) => {
            let updatedLanguageID = language;
            if (elementID.includes("image")) {
                updatedLanguageID = "en-GB"
            }
            const updatedItemIndex = prevOutcomeData.findIndex(item => item.fk_LanguageID === updatedLanguageID);
            if (updatedItemIndex !== -1) {
                const updatedItem = {
                    ...prevOutcomeData[updatedItemIndex],
                    [elementID]: elementValue
                };
                const updatedOutcomeData = [
                    ...prevOutcomeData.slice(0, updatedItemIndex),
                    updatedItem,
                    ...prevOutcomeData.slice(updatedItemIndex + 1)
                ];
                return updatedOutcomeData;
            }
            return prevOutcomeData;
        });
    }

    useEffect(() => {
        var taskID = moduleData[0].taskID
        var linkData = [];
        var unUssignedActi = [];
        let url = "step/Qry/" + taskID + "/null/234/ASSIGN";

        base.getAll(url).then((res) => {
            if (res !== "" && res != null) {
                for (let i = 0; i < res.length; i++) {
                    var item = {};
                    if (res[i].step_Description != null) {
                        item = {};
                        item.value = res[i].stepID;
                        item.label = res[i].step_Label;
                        item.isSelected = false;
                        linkData.push(item);
                        unUssignedActi.push(item);
                    }
                }
            }
        });

        if (Object.keys(loFeedData).length > 0) {

            url = "step/Qry/" + taskID + "/" + loFeedData[0].outcomeID + "/234/ASSIGN";
            base.getAll(url).then((res) => {

                var assignedActi = [];
                for (let i = 0; i < res.length; i++) {
                    if (res !== "" && res != null) {
                        var item = {};
                        if (res[i].step_Description != null) {
                            item = {};
                            item.value = res[i].stepID;
                            item.label = res[i].step_Label;
                            item.isSelected = true;
                            linkData.push(item);
                            assignedActi.push(item);
                        }
                    }
                }

                const sortedRes = linkData
                    .sort((activity1, activity2) => {
                        let act1 = activity1.label;
                        let act2 = activity2.label;
                        if (act1 > act2) return 1;
                        if (act1 < act2) return -1;
                        return 0;
                    });

                setLinkToActivityData(sortedRes);
                setloActivitySelected(assignedActi);

            });
        } else {
            setLinkToActivityData(linkData);
            setloActivitySelected("");
        }
        // eslint-disable-next-line 
    }, [loFeedData]);

    const handleLOActivity = (selected) => {

        setloActivitySelected(selected);
        var activities = [];
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].value !== "*") activities.push(selected[i].value);
        }
        var deSelectedActivities = [];
        var unassign = document.getElementsByClassName("chkAct");
        for (const acti of unassign) {
            if (acti.checked === false) deSelectedActivities.push(acti.id);
        }

    };

    const imageSizes = [
        {
            id: 1,
            label: "Small",
            value: "S",
        },
        {
            id: 2,
            label: "Medium",
            value: "M",
        },
        {
            id: 3,
            label: "Large",
            value: "L",
        }
    ]

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(base64ToHex(reader.result));
        };
        reader.onerror = function (error) {
        };
    };
    const base64ToHex = (str) => {
        str = str.replace("data:image/jpeg;base64,", "");
        const raw = atob(str);
        let result = "";
        for (let i = 0; i < raw.length; i++) {
            const hex = raw.charCodeAt(i).toString(16);
            result += hex.length === 2 ? hex : "0" + hex;
        }
        return "D=" + result.toUpperCase();
    };

    const [imageBoxErrorMessage, setImageBoxErrorMessage] = useState("");

    const handleFileUpload = (e) => {
        var selectedFile = e.target.files[0];
        var idxDot = selectedFile.name.lastIndexOf(".") + 1;
        var extFile = selectedFile.name
            .substr(idxDot, selectedFile.name.length)
            .toLowerCase();
        setPreviousImage(selectedFile.name)
        if (e.target.files.length && (extFile === "jpg" || extFile === "jpeg")) {
            var imgZiseInKB = parseFloat(e.target.files[0].size / 1024).toFixed(2);
            if (imgZiseInKB <= 150) {
                document.getElementById("imgSizeLO").style.display = "none";

                getBase64(e.target.files[0], (result) => {
                    setBinaryImage(result);

                    let nimg = {}
                    nimg.target = { "id": "n_IMAGE", "value": selectedFile.name }
                    handleChange(nimg)

                    let bimg = {}
                    bimg.target = { "id": "b_IMAGE", "value": result }
                    handleChange(bimg)
                });
            } else {
                setImageBoxErrorMessage(
                    t("Curriculumpage.ImageSizeExceeded")
                );
                document.getElementById("imgSizeLO").style.display = "";
            }
        } else {
            if (extFile !== "jpg" || extFile !== "jpeg") {
                setImageBoxErrorMessage(t("Curriculumpage.JpegFilesAllowed"));
                document.getElementById("imgSizeLO").style.display = "";
            }
        }
    };

    const saveLearningOutcome = () => {
        let fail = [];
        if (binaryImage && !loImageSize) {
            document.getElementById("imageLO").style.display = "";
            fail.push("imageLO")
        }
        outcomeData.forEach((outcome) => {
            if (!outcome.outcome_Description) {
                fail.push("outcome_Description")
            }
        })
        if (loActivitySelected.length === 0) {
            fail.push("loActivity")
        }

        setInvalid(fail)
        if (fail.length === 0) {
            let url = "learningoutcome/"
            let promise = [];
            if (create) {
                promise.push(base.add(url + "add", outcomeData[0]))
            } else {
                promise.push(base.update(url + "upd", outcomeData))
            }
            Promise.all(promise).then((res) => {
                outcomeData[0].outcomeID = res[0].data.outcomeID
                linkOutcomeToActivity(outcomeData[0])
            })
        }
    }

    const linkOutcomeToActivity = (loFeed) => {
        //Link to activity
        //get all activities checked fro cuttent task to which this outcome id is saving
        ///this should be all checked activities from link to activity DDL

        let initiallySelected = linkToActivityData.filter((x) => x.isSelected === true);
        let unassigned = initiallySelected.filter(
            (x) => !loActivitySelected.includes(x)
        );

        let initiallyNotSelected = linkToActivityData.filter(
            (x) => x.isSelected === false && loActivitySelected.includes(x)
        );
        let assigned = initiallyNotSelected.filter((x) =>
            loActivitySelected.includes(x)
        );
        var selectedActivities = assigned;
        var DeselectedActivities = unassigned;
        var outComeID = loFeed.outcomeID;
        let promise = [];

        if (selectedActivities.length > 0) {
            for (let i = 0; i < selectedActivities.length; i++) {
                var url = process.env.REACT_APP_STEP_UPD + "/" + selectedActivities[i].value + "/" + outComeID + "/assign/outcome";

                promise.push(base.update(url, [{}]));
            }
        }
        if (DeselectedActivities.length > 0) {
            var deSelectedActivities = DeselectedActivities;
            for (let i = 0; i < deSelectedActivities.length; i++) {
                url =
                    process.env.REACT_APP_STEP_UPD +
                    "/" +
                    deSelectedActivities[i].value +
                    "/NULL/assign/outcome";

                promise.push(base.update(url, [{}]));
            }
        }

        Promise.all(promise).then(() => {
            saveLOModal()
        })

    };

    const [theInputKey, setTheInputKey] = useState("")

    const removeLOImageClick = () => {

        setBinaryImage("");

        let nimg = {}
        nimg.target = { "id": "n_IMAGE", "value": null }
        handleChange(nimg)

        let bimg = {}
        bimg.target = { "id": "b_IMAGE", "value": null }
        handleChange(bimg)

        let simg = {}
        simg.target = { "id": "s_IMAGE", "value": null }
        handleChange(simg)

        let randomString = Math.random().toString(36);
        setTheInputKey(randomString)
        setLoImageSize("")

        setPreviousImage("")
        setImageBoxErrorMessage("");
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
        }),
    };

    return (
        <div className="bd-example-modal">
            <div
            >
                <Modal centered
                    className=" modal-dialog-centered modal-70"
                    isOpen={true}
                >
                    <Modal.Header
                        className="modalPopupBGColour"
                        onToggleOpen={closeLOModal}
                    >
                        <div className="">
                            <div className="modalHeaderGlobal ms-5"
                                style={{ marginLeft: "2%" }}
                            >
                                {modalTitle}
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body >
                        <Row>
                            <Col lg="12">
                                <Label >
                                    {t("Curriculumpage.Learning_Outcomes")}{" "}
                                    <span className="text-danger">*</span>
                                </Label>
                            </Col>
                            <Col xs="12">
                                <Input
                                    type="textarea"
                                    id="outcome_Description"
                                    name="outcome_Description"
                                    resizable="true"
                                    maxLength={5600}
                                    disabled={disabled.includes("outcome_Description")}
                                    onChange={handleChange}
                                    style={{ height: "320px" }}
                                    value={outcomeData.find(item => item.fk_LanguageID === language)?.outcome_Description || ''}
                                    invalid={invalid.includes('outcome_Description')}
                                ></Input>
                            </Col>
                            <Col xs="9">
                            </Col>
                            <Col xs="3"
                                className="text-end"
                            >
                                <span className="desclength">
                                    {(outcomeData.find(item => item.fk_LanguageID === language)?.outcome_Description || '').length}/5600
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Label>
                                    {t("Curriculumpage.Link to Activity")}
                                </Label>
                            </Col>
                            <Col xs="12">
                                <Select
                                    options={linkToActivityData}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    classNamePrefix="react-select"
                                    className='linkToActivity'
                                    onChange={handleLOActivity}
                                    styles={customStyles}
                                    value={loActivitySelected}
                                    defaultValue={loActivitySelected}
                                    isDisabled={disabled.includes("linkToActivity")}
                                    invalid={invalid.includes("loActivity")}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-5">
                            <Col lg="6" >
                                <Label className="mt-2">
                                    {" "}
                                    {t("Curriculumpage.Image Upload")}
                                </Label>
                                <Row className="">
                                    <Col lg="8">
                                        {previousImage ?
                                            <Input type="text" disabled value={previousImage} className="mt-2" />
                                            :
                                            <Input
                                                id="file-upload1"
                                                accept="image/jpeg"
                                                type="file"
                                                onChange={(e) => {
                                                    handleFileUpload(e);
                                                }}
                                                disabled={disabled.includes("file-upload1")}
                                                key={theInputKey || ''}
                                                className="mt-2"
                                            />
                                        }

                                    </Col>
                                    <Col lg="3" className="mt-2 ps-0 pe-0 ">
                                        <Select
                                            id="imageLO"
                                            name="imageLO"
                                            key={`my_unique_select_key__${loImageSize}`}
                                            placeholder={"Size"}
                                            value={imageSizes.find((option) => option.value === loImageSize)}
                                            options={imageSizes}
                                            styles={customStyles}
                                            isDisabled={disabled.includes("imageLO")}
                                            invalid={invalid.includes('imageLO')}
                                            onChange={(e) => {
                                                setLoImageSize(e.value)
                                                let simg = {}
                                                simg.target = { "id": "s_IMAGE", "value": e.value }
                                                handleChange(simg)
                                                setInvalid(prevInvalid =>
                                                    prevInvalid.filter(element => element !== "imageLO")
                                                );
                                            }}
                                            classNamePrefix="react-select"
                                        />
                                    </Col>
                                    <Col lg="1" className='ps-0 mt-1 ms-n2' >
                                        <Button
                                            disabled={disabled.includes("remove")}
                                            style={{ backgroundColor: "#fff", borderColor: "#fff" }}
                                        >
                                            <Trash32
                                                className={"removebtn pointerCursor"}
                                                onClick={() => {
                                                    removeLOImageClick();
                                                    setInvalid(prevInvalid =>
                                                        prevInvalid.filter(element => element !== "imageLO")
                                                    );
                                                }}
                                            />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <div
                                            className="desclength"
                                            id="imgSizeLO"
                                            style={{
                                                display: "none",
                                                color: "red"
                                            }}
                                        >
                                            {imageBoxErrorMessage}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="6" className=''>
                                <Label>
                                    {t("Curriculumpage.Sample_Images")}:
                                </Label>
                                <Row className='pe-0'>
                                    <Col className="currLO-flexitem-img">
                                        <img
                                            src={
                                                require("../../../../Content/Images/airbpImages/AIR BP EDITED-17.png")
                                            }
                                            alt={"imageval"}
                                            style={{ height: "50%" }}
                                        ></img>
                                        <p className="imgsize-text">{t("Curriculumpage.Small")}</p>
                                        <p className="imgdimension-text">
                                            (2.8cm x 13cm)
                                        </p>
                                    </Col>
                                    <Col className="currLO-flexitem-img">
                                        <img
                                            src={
                                                require("../../../../Content/Images/airbpImages/AIR BP EDITED-17.png")
                                            }
                                            alt={"imageval"}
                                            style={{ height: "70%" }}
                                        ></img>
                                        <p className="imgsize-text">{t("Curriculumpage.Medium")}</p>
                                        <p className="imgdimension-text">
                                            (5.6cm x 13cm)
                                        </p>
                                    </Col>
                                    <Col
                                        className="currLO-flexitem-img me-0 pe-0"
                                    >
                                        <img
                                            src={
                                                require("../../../../Content/Images/airbpImages/AIR BP EDITED-17.png")
                                            }
                                            alt={"imageval"}
                                            style={{ height: "90%" }}
                                        ></img>
                                        <p className="imgsize-text">{t("Curriculumpage.Large")}</p>
                                        <p className="imgdimension-text">
                                            (8.4cm x 13cm)
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Row className="w-100">
                            <Col xs="4" className="text-start ">
                                {create === false ?
                                    <div className="d-flex" >
                                        <World24 className="icon-margin" />
                                        <Input type="select" className="border-0 " value={language} onChange={handleLanguageChange}>
                                            {languages.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.languageID} >
                                                        {item.language_Name}
                                                    </option>
                                                )
                                            })
                                            }
                                        </Input>
                                    </div>
                                    : ""
                                }
                            </Col>
                            <Col xs="8" className="text-end">
                                <Button
                                    className="rounded-pill btn-style me-3"
                                    size="sm"
                                    color="standard-secondary"
                                    onClick={closeLOModal}
                                >
                                    <Trans i18nKey="Common.Cancel">Cancel</Trans>
                                </Button>
                                <Button
                                    className="rounded-pill btn-style"
                                    size="sm"
                                    color="standard-primary"
                                    onClick={() => { saveLearningOutcome() }}
                                >
                                    <Trans i18nKey="Common.Save">Save</Trans>
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            </div>
        </div >
    );
}

export default LearningOutcomeModal;
