import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useValidation } from "../../Shared/ValidateForm";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import "../../../Content/stylesheet/Location.css"
import base from "../../../Services/BaseService";
import { Row, Col } from "reactstrap";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import { Messages } from "../../../Constants";
import Select from "react-select";
import { components } from "react-select";
import LoadingView from "../../Shared/Layout/LoadingView";
import { useUser } from "../../Shared/userContext";
import { Input, Label, Modal, Button } from '@bphxd/ds-core-react';

const Location = (props) => {
  const { t } = useTranslation();
  const { userData } = useUser();
  var feed = {
    location_Description: "",
    id: 0,
    locationID: "",
    fk_CountryID: "",
  };
  //for edit
  if (props.create === false) {
    feed = {
      location_Description: props.locFeed.location_Description,
      id: props.locFeed.location_ID,
      locationID: props.locFeed.locationID,
      fk_CountryID: props.locFeed.countryID,
      isTraineeLocation: props.locFeed.isTraineeLocation
    };
  }
  const [isCreate, setIsCreate] = useState(props.create);
  const [locFeed, setlocFeed] = useState(feed);
  const [isLoadingSteps, setisLoadingSteps] = useState(false);
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [isChanged, setIsChanged] = useState(false);
  const [register, validate, errors] = useValidation();
  const [reqlocationIDerr, setreqlocationIDerr] = useState(false);
  const [lengthlocation_Descriptionerr, setlengthlocation_Descriptionerr] = useState(false);
  const [reqlocation_Descriptionerr, setreqlocation_Descriptionerr] = useState(false);
  const [reqcountryIDerr, setreqcountryIDerr] = useState(false);
  const [locationIDRemaining, setlocationIDRemaining] = useState(locFeed.locationID.length);
  const [location_DescriptionRemaining, setlocation_DescriptionRemaining] = useState(locFeed.location_Description.length);
  const [ddlCountrySelected, setddlCountrySelected] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [DuplicateAlert, setDuplicateAlert] = useState(false);
  const [DeletedAlert, setDeletedAlert] = useState(false);
  const [ddlCountryVal, setddlCountryVal] = useState(null);

  useEffect(() => {
    setDropdownData();
    // eslint-disable-next-line
  }, []);

  //Loading data for country drop down
  const setDropdownData = () => {
    if (props.create === false) {
      setddlCountrySelected({
        value: props.locFeed.countryID,
        label: props.locFeed.countryID,
      });
    }

    var countries = [];
    for (let i = 0; i < props.CountryList.length; i++) {
      var itemCTY = {};
      itemCTY.value = props.CountryList[i].country_Description;
      itemCTY.label = props.CountryList[i].countryID;
      countries.push(itemCTY);
    }
    setddlCountryVal(countries);
  };

  const handleCountryDDL = (params) => {
    removeRedBorderDynamically("countryID");
    setreqcountryIDerr(false);
    setddlCountrySelected(params);
    setIsChanged(true);
  };

  //Function invoked when user edits the text boxes
  const handleChange = (e) => {
    var elementValue
    if (e.target.name === "locationID") {
      setreqlocationIDerr(false);
      setDuplicateAlert(false);
      setDeletedAlert(false);
      removeRedBorderDynamically("locationID");
      setlocationIDRemaining(e.target.value.length);

      elementValue = e.target.value;
    }

    if (e.target.name === "isTraineeLocation") {
      elementValue = e.target.checked === true ? 1 : 0
    }

    if (e.target.name === "location_Description") {
      setreqlocation_Descriptionerr(false);
      removeRedBorderDynamically("location_Description");
      setlocation_DescriptionRemaining(e.target.value.length);
      if (e.target.value.length > 50) {
        setlengthlocation_Descriptionerr(true);
        ADDRedBorderDynamically("location_Description");
      } else {
        removeRedBorderDynamically("location_Description");
        setlengthlocation_Descriptionerr(false);
      }
      elementValue = e.target.value;
    }
    setIsChanged(true);
    setlocFeed({ ...locFeed, [e.target.name]: elementValue });
  };

  const ADDRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.add("redBorder");
    }
  };
  const removeRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.remove("redBorder");
    }
  };

  const Option = (props) => {
    return (
      <div id="empMulti ">
        <components.Option {...props}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "30% 70%",
              width: "100%",
            }}
          >
            <span> {props.label}</span>
            <span> {props.value}</span>
          </div>
        </components.Option>
      </div>
    );
  };

  //Function invoked when user clicks on Save button
  const saveLocation = () => {
    locFeed.location_Description = locFeed.location_Description.trim();
    locFeed.locationID = locFeed.locationID.trim();
    document.getElementById("location_Description").value =
      locFeed.location_Description.trim();
    document.getElementById("locationID").value = locFeed.locationID.trim();
    if (ddlCountrySelected !== "" && ddlCountrySelected !== null)
      locFeed.fk_CountryID = ddlCountrySelected.label;

    setlocationIDRemaining(locFeed.locationID.trim().length);


    setlocation_DescriptionRemaining(
      locFeed.location_Description.trim().length
    );
    if (locFeed.location_Description.trim().length > 50) {
      setlengthlocation_Descriptionerr(true);
      setSaveDisabled(false);
    } else {
      setlengthlocation_Descriptionerr(false);
    }
    validate();
    if (document.getElementById("locationID").value.trim() !== "") {
      setreqlocationIDerr(false);
      if (document.getElementById("location_Description").value.trim() !== "") {
        setreqlocation_Descriptionerr(false);
        if (
          ddlCountrySelected &&
          (ddlCountrySelected.label !== null ||
            ddlCountrySelected.label !== "null" ||
            ddlCountrySelected.label !== "")
        ) {
          setreqcountryIDerr(false);
          removeRedBorderDynamically("countryID");
          if (
            errors.locationID.isValid &&
            errors.location_Description.isValid
          ) {
            if (isCreate === false) {
              if (
                props.locFeed.locationID.toString() ===
                document.getElementById("locationID").value
              ) {

                //normal update
                base.update(process.env.REACT_APP_LOCATION_UPDATE, locFeed).then((response) => {
                  props.getAll();
                  handleDiscard();
                  setIsCreate(true);
                  setIsChanged(false);
                  props.saveSuccess();
                  setisLoadingSteps(false);
                })
                  .catch((error) => {
                    handleDiscard();
                    props.error();
                    setisLoadingSteps(false);
                  });
              } else {
                base.getAll("location/get/" + document.getElementById("locationID").value.trim()).then((resLOC) => {
                  if (resLOC !== null) {
                    ADDRedBorderDynamically("locationID");
                    setDuplicateAlert(true);
                    setSaveDisabled(false);
                  } else {
                    removeRedBorderDynamically("locationID");
                    setDuplicateAlert(false);
                    base.getAll("location/get/" + document.getElementById("locationID").value.trim() + "/DEL").then((resLOCDEL) => {
                      if (resLOCDEL !== null) {
                        setDeletedAlert(true);
                      } else {
                        base
                          .update(
                            process.env
                              .REACT_APP_LOCATION_UPDATE,
                            locFeed
                          )
                          .then((response) => {
                            props.getAll();
                            handleDiscard();
                            setIsCreate(true);
                            setIsChanged(false);
                            props.saveSuccess();
                            setisLoadingSteps(false);
                          })
                          .catch((error) => {
                            handleDiscard();
                            props.error();
                            setisLoadingSteps(false);
                          });
                      }
                    });
                  }
                });
              }
            } else {

              setTimeout(() => {
                base
                  .getAll(
                    "location/get/" +
                    document.getElementById("locationID").value.trim()
                  )
                  .then((resLOC) => {
                    if (resLOC !== null) {
                      ADDRedBorderDynamically("locationID");
                      setDuplicateAlert(true);
                      setSaveDisabled(false);
                    } else {
                      removeRedBorderDynamically("locationID");
                      setDuplicateAlert(false);

                      setTimeout(() => {
                        base
                          .getAll(
                            "location/get/" +
                            document
                              .getElementById("locationID")
                              .value.trim() +
                            "/DEL"
                          )
                          .then((resLOCDEL) => {
                            if (resLOCDEL !== null) {
                              setDeletedAlert(true);
                              // setdeletedLocationFeed({
                              //   ...resLOCDEL,
                              //   location_Description:
                              //     resLOCDEL.location_Description.toString(),
                              //   id: resLOCDEL.id,
                              //   locationID:
                              //     resLOCDEL.locationID.toString(),
                              //   fk_CountryID:
                              //     resLOCDEL.fk_CountryID.toString(),
                              //   // rel_rec:1,
                              // });
                            } else {

                              setTimeout(() => {
                                base
                                  .add(
                                    process.env
                                      .REACT_APP_LOCATION_ADD,
                                    locFeed
                                  )
                                  .then((response) => {
                                    props.getAll();
                                    handleDiscard();
                                    // setEditDisabled(false);
                                    // setDisabled(true);
                                    setlocFeed({
                                      ...response.data,
                                      locationID:
                                        response.data.locationID.toString(),
                                      location_Description:
                                        response.data.location_Description.toString(),
                                      fk_CountryID:
                                        ddlCountrySelected.label,
                                    });
                                    setIsCreate(false);
                                    setIsChanged(false);
                                    props.saveSuccess();
                                    setisLoadingSteps(false);
                                  })
                                  .catch((error) => {
                                    handleDiscard();
                                    // setEditDisabled(false);
                                    // setDisabled(true);
                                    props.error();
                                    setisLoadingSteps(false);
                                  });
                              }, 300);
                              // });
                            }
                          });
                      }, 300);
                      // });
                    }
                  });
              }, 300);
              // });
            }
          } else {
            if (document.getElementById("locationID").value.trim() === "") {
              setlocationIDRemaining(0);
              setreqlocationIDerr(true);
              setSaveDisabled(false);
              //setreqThreaterr(true);
            }
            if (
              document.getElementById("location_Description").value.trim() ===
              ""
            ) {
              setlocation_DescriptionRemaining(0);
              setreqlocation_Descriptionerr(true);
              setlengthlocation_Descriptionerr(false);
              setSaveDisabled(false);
              //setreqThreaterr(true);
            }
            if (
              !ddlCountrySelected ||
              (ddlCountrySelected &&
                (ddlCountrySelected.label === null ||
                  ddlCountrySelected.label === "null" ||
                  ddlCountrySelected.label === ""))
            ) {
              setreqcountryIDerr(true);
              setSaveDisabled(false);
              ADDRedBorderDynamically("countryID");
            }
          }
        } else {
          if (document.getElementById("locationID").value.trim() === "") {
            setlocationIDRemaining(0);
            setreqlocationIDerr(true);
            setSaveDisabled(false);
            //setreqThreaterr(true);
          }
          if (
            document.getElementById("location_Description").value.trim() === ""
          ) {
            setlocation_DescriptionRemaining(0);
            setreqlocation_Descriptionerr(true);
            setlengthlocation_Descriptionerr(false);
            setSaveDisabled(false);
            //setreqThreaterr(true);
          }
          if (
            !ddlCountrySelected ||
            (ddlCountrySelected &&
              (ddlCountrySelected.label === null ||
                ddlCountrySelected.label === "null" ||
                ddlCountrySelected.label === ""))
          ) {
            setreqcountryIDerr(true);
            setSaveDisabled(false);
            ADDRedBorderDynamically("countryID");
          }
        }
      } else {
        if (document.getElementById("locationID").value.trim() === "") {
          setlocationIDRemaining(0);
          setreqlocationIDerr(true);
          setSaveDisabled(false);
          //setreqThreaterr(true);
        }
        if (
          document.getElementById("location_Description").value.trim() === ""
        ) {
          setlocation_DescriptionRemaining(0);
          setreqlocation_Descriptionerr(true);
          setSaveDisabled(false);
          setlengthlocation_Descriptionerr(false);
          //setreqThreaterr(true);
        }
        if (
          !ddlCountrySelected ||
          (ddlCountrySelected &&
            (ddlCountrySelected.label === null ||
              ddlCountrySelected.label === "null" ||
              ddlCountrySelected.label === ""))
        ) {
          setreqcountryIDerr(true);
          setSaveDisabled(false);
          ADDRedBorderDynamically("countryID");
        }
      }
    } else {
      if (document.getElementById("locationID").value.trim() === "") {
        setlocationIDRemaining(0);
        setreqlocationIDerr(true);
        setSaveDisabled(false);
        //setreqThreaterr(true);
      }
      if (document.getElementById("location_Description").value.trim() === "") {
        setlocation_DescriptionRemaining(0);
        setreqlocation_Descriptionerr(true);
        setSaveDisabled(false);
        setlengthlocation_Descriptionerr(false);
        //setreqThreaterr(true);
      }
      if (
        !ddlCountrySelected ||
        (ddlCountrySelected &&
          (ddlCountrySelected.label === null ||
            ddlCountrySelected.label === "null" ||
            ddlCountrySelected.label === ""))
      ) {
        setreqcountryIDerr(true);
        setSaveDisabled(false);
        ADDRedBorderDynamically("countryID");
      }
    }
    //}
    // });
    // }

    // });
  };

  const handleDiscard = () => {
    setlocFeed(feed);
    props.close(false);
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const cancelLocationCreate = () => {
    setDeletedAlert(false);
  };

  //Function invoked when user selects to reinstate a deleted location from the alert
  const ActivateLocation = () => {
    var locID = {
      locationID: document.getElementById("locationID").value.trim(),
    };
    var locationID = document.getElementById("locationID").value.trim();

    if (!!locID) {

      setTimeout(() => {
        base
          .update(
            process.env.REACT_APP_LOCATION_REINSTATE + locationID,
            locID
          )
          .then((response) => {
            props.getAll();
            handleDiscard();
            // setEditDisabled(false);
            // setDisabled(true);
            // setlocFeed({
            //   ...response.data,
            //   locationID: response.data.locationID.toString(),
            //   location_Description: response.data.location_Description.toString(),
            //   countryID: ddlCountrySelected,
            // });
            setIsCreate(true);
            setIsChanged(false);
            props.saveSuccess();
            setisLoadingSteps(false);
          })
          .catch((error) => {
            handleDiscard();
            props.error();
            // setEditDisabled(false);
            // setDisabled(true);
            setisLoadingSteps(false);
          });
      }, 300);
      // });
    }
  };

  return (
    <>

      <Modal

        isOpen={props.isopen}
        size="sm"
        className=" modal-dialog-centered modal-50"
      >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
            handleNo={saveLocation}
          />
        )}
        <ConfirmDeleteToast
          show={DeletedAlert}
          handleCancel={cancelLocationCreate}
          Message={Messages.manageRegion.LOCReinstate}
          handleDelete={ActivateLocation}
        />

        {isLoadingSteps && <LoadingView />}
        {/* <LoadingOverlay
          active={isLoadingSteps}
          spinner={<Spinner style={{ color: "#111" }} />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.15)",
            }),content: (base) => ({
                  ...base,
                }),
          }}
        > */}
        <Modal.Header
          className="modalPopupBGColour "
          onToggleOpen={cancelHandler}
        >
          <div className="row">
            <div className="col">
              <Label className="modalHeaderGlobal ms-5">
                {props.create
                  ? t("Regionpage.Create Location")
                  : t("Regionpage.Edit Location")}
              </Label>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col ms-4">
              <Label className="" htmlFor="locationID">
                {t("Regionpage.Location Code")}
                <span className="text-danger">*</span>
              </Label>
            </div>
          </div>
          <div className="row ms-1 me-1">
            <div className="col">
              <Input
                type="text"
                id="locationID"
                name="locationID"
                className=" locTxtField"
                onChange={handleChange}
                maxLength={8}
                value={locFeed.locationID}
                {...register({
                  fieldName: "locationID",
                  required: true,
                  maxLength: 8,
                })}
              ></Input>
            </div>
          </div>
          <div className="row ms-1 me-2">
            <div
              //className="col"
              className=""
            >
              {errors.locationID && errors.locationID.Message !== undefined && (
                <div className="col" id="locationID_Error">
                  {/* <label className="float-start text-danger h7">
                        {errors.Hazard_Description.Message}
                      </label> */}
                </div>
              )}

              {reqlocationIDerr ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.Required")}
                </span>
              ) : (
                ""
              )}
              {DuplicateAlert ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.LocationCodeExists")}
                </span>
              ) : (
                ""
              )}
              <div
                // className="col float-end p-0"
                className={
                  errors.locationID && errors.locationID.Message !== undefined
                    ? "thWordLmtErr col float-end p-0"
                    : "thWordLmtNoErr col float-end p-0"
                }
              ></div>
            </div>
            <div
              className={`col float-end text-end ${errors.locationID && errors.locationID.Message !== undefined
                }`}
            >
              <label
                className="h6"
              // style={
              //   errors.locationID &&
              //   errors.locationID.Message !== undefined
              //     ? {}
              //     : {}
              // }
              >
                {locationIDRemaining}/8
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col ms-4">
              <Label className="" htmlFor="location_Description">
                {t("Regionpage.Location")}
                <span className="text-danger">*</span>
              </Label>
            </div>
          </div>
          <div className="row ms-1 me-1">
            <div className="col">
              <Input
                type="text"
                id="location_Description"
                name="location_Description"
                className=" "
                onChange={handleChange}
                maxLength={50}
                value={locFeed.location_Description}
                {...register({
                  fieldName: "location_Description",
                  required: true,
                  maxLength: 50,
                })}
              ></Input>
            </div>
            {/* {errors.location_Description &&
                errors.location_Description.Message !== undefined && (
                  <div
                    className="col-auto"
                    style={{ padding: "0px !important" }}
                  >
                    <img src={ErrorAlert} alt="error"></img>
                  </div>
                )} */}
          </div>
          <div className="row ms-1 me-2 ">
            <div
              //className="col"
              className="errorLOCDiv"
            >
              {errors.location_Description &&
                errors.location_Description.Message !== undefined && (
                  <div className="col" id="location_Description_Error">
                    {/* <label className="float-start text-danger h7">
                        {errors.Hazard_Description.Message}
                      </label> */}
                  </div>
                )}
              {lengthlocation_Descriptionerr ? (
                <span className="assessmenterror">{t("ErrorMessages.Max50")}</span>
              ) : (
                ""
              )}
              {reqlocation_Descriptionerr ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.Required")}
                </span>
              ) : (
                ""
              )}
              <div
                // className="col float-end p-0"
                className={
                  errors.location_Description &&
                    errors.location_Description.Message !== undefined
                    ? "thWordLmtErr col float-end p-0"
                    : "thWordLmtNoErr col float-end p-0"
                }
              ></div>
            </div>
            <div
              className={`col float-end text-end ${errors.location_Description &&
                errors.location_Description.Message !== undefined
                }`}
            >
              <label
                className="h6"
              // style={
              //   errors.location_Description &&
              //   errors.location_Description.Message !== undefined
              //     ? {}
              //     : {}
              // }
              >
                {location_DescriptionRemaining}/50
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col ms-4">
              <Label className="" htmlFor="country_Description">
                {t("Regionpage.Country")}
                <span className="text-danger">*</span>
              </Label>

            </div>
          </div>
          <div className="row ms-1 me-1">
            <div className="col">
              <Select
                id="countryID"
                placeholder={""}
                //isDisabled={disabledDropdows}
                defaultValue={ddlCountrySelected}
                value={ddlCountrySelected}
                options={ddlCountryVal}
                onChange={handleCountryDDL}
                //menuIsOpen={true} // for styling
                components={{
                  Option,
                  IndicatorSeparator: () => null,
                }}
                classNamePrefix="react-select"
              ></Select>
            </div>
          </div>
          <div className="row p-0" id="countryID_Error">
            {(errors.countryID &&
              errors.countryID.Message !== undefined) ||
              (reqcountryIDerr && (
                <div
                  className="col"
                  id="countryID_Error"
                  style={{ marginLeft: 0 }}
                >
                  {/* <label id="jobError" className="float-start text-danger h7" style={{marginLeft:"-2%"}}>
                          {errors.Job.Message} widthddlinPercent
                        </label> */}
                  {reqcountryIDerr ? (
                    <span className=" assessmenterror">
                      {t("ErrorMessages.Required")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
          {userData.userLevel === "10" ?
            <Row className="ms-1 me-1 mt-3">
              <Col xs="12">
                <Input

                  id="isTraineeLocation"
                  name="isTraineeLocation"
                  type="checkbox"
                  checked={locFeed.isTraineeLocation}
                  onChange={handleChange}

                // value={empFeed.emp_Absent}
                />
                <Label
                  className="ms-2 mt-3"
                  htmlFor="isTraineeLocation"
                >
                  {t("Regionpage.MyTraining")}
                </Label>
              </Col>
            </Row>
            :
            <></>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="me-4">
            <Button size="sm"
              color="standard-secondary"
              className="rounded-pill btn-style" onClick={cancelHandler}>
              {t("Common.Cancel")}
            </Button>{" "}
            <Button
              disabled={saveDisabled}
              size="sm"
              color="standard-primary"
              className="rounded-pill btn-style"
              onClick={() => {
                setSaveDisabled(true);
                saveLocation();
              }}
            >
              {t("Common.Save")}
            </Button>{" "}
          </div>
        </Modal.Footer>
        {/* </LoadingOverlay> */}
      </Modal>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Location;
