import React, { useMemo } from "react";
import { Button, CardBody, Card, CardHeader, CardFooter, Progress, Row, Col, Input } from "reactstrap";
import { Clock24 } from "@bphxd/ds-core-react/lib/icons";
import "../../Content/stylesheet/traineeassessment.css";
import CriticalIcon from "../../Content/Images/Icons/critical-hazard.png";
import { useTranslation } from "react-i18next";

const QueAnsComponent = (props) => {
  const { questionsData, questionIndex, timeRemaining, checkedAnswers, allQuestionsVisited, } = props;

  const progressBarValue = useMemo(() => {
    const currentCount = questionIndex + 1;
    return (currentCount / questionsData.length) * 100;
  }, [questionIndex, questionsData.length]);

  const { t } = useTranslation();

  return (
    <>
      <div className="container-fluid " style={{ marginBlock: "12px" }}>
        <div className="container trainee-cardcontainer">
          <Card className="trainee-card">
            <CardHeader className="pt-4">
              <div className="queans__maincontainer">
                <div className="que-numberindicator">
                  {`${questionIndex + 1}.`}
                </div>
                <div className="que-description">
                  {questionsData[questionIndex].question_Description}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {
                questionsData[questionIndex].answer !== null ? (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "4%" }}></div>
                    {questionsData[questionIndex].answer
                      .toString()
                      .replaceAll(/ /g, "")
                      .split(";").length > 1 ? (
                      <div style={{ height: "55px" }}>
                        {t("MyTrainingpage.Select all that apply")}
                      </div>
                    ) : (
                      <div style={{ height: "55px" }}>
                        {t("MyTrainingpage.Select the correct answer")}
                      </div>
                    )}
                  </div>
                ) : (
                  <div style={{ height: "55px" }}>
                  </div>
                )}
              <div className="cardPadding">
                {questionsData.length > 0 && (
                  <div>
                    <div className="quesectioncontainer">
                      <div style={{ width: "4%" }}></div>
                      <div className="que__answerscontainer">
                        {questionsData[questionIndex].optionA != null &&
                          questionsData[questionIndex].optionA !== "" ? (
                          <div className="answer-container">
                            <div className="answer__checkbox-container">
                              <Input
                                id={questionsData[questionIndex].fk_ID + "A"}
                                className="trainee__commoncheckbox"
                                type="checkbox"
                                checked={checkedAnswers.isCheckedA}
                                onChange={() =>
                                  props.onChangeAnswer(questionIndex)
                                }
                              ></Input>
                            </div>
                            <div >
                              <label className="answer__description"
                                htmlFor={
                                  questionsData[questionIndex].fk_ID + "A"
                                }
                              >
                                {questionsData[questionIndex].optionA}
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {questionsData[questionIndex].optionB != null &&
                          questionsData[questionIndex].optionB !== "" ? (
                          <div className="answer-container">
                            <div className="answer__checkbox-container">
                              <Input
                                id={questionsData[questionIndex].fk_ID + "B"}
                                className="trainee__commoncheckbox"
                                type="checkbox"
                                checked={checkedAnswers.isCheckedB}
                                onChange={() =>
                                  props.onChangeAnswer(questionIndex)
                                }
                              ></Input>
                            </div>
                            <div >
                              <label className="answer__description"
                                htmlFor={
                                  questionsData[questionIndex].fk_ID + "B"
                                }
                              >
                                {questionsData[questionIndex].optionB}
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {questionsData[questionIndex].optionC != null &&
                          questionsData[questionIndex].optionC !== "" ? (
                          <div className="answer-container">
                            <div className="answer__checkbox-container">
                              <Input
                                id={questionsData[questionIndex].fk_ID + "C"}
                                className="trainee__commoncheckbox"
                                type="checkbox"
                                checked={checkedAnswers.isCheckedC}
                                onChange={() =>
                                  props.onChangeAnswer(questionIndex)
                                }
                              ></Input>
                            </div>
                            <div >
                              <label className="answer__description"
                                htmlFor={
                                  questionsData[questionIndex].fk_ID + "C"
                                }
                              >
                                {questionsData[questionIndex].optionC}
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {questionsData[questionIndex].optionD != null &&
                          questionsData[questionIndex].optionD !== "" ? (
                          <div className="answer-container">
                            <div className="answer__checkbox-container">
                              <Input
                                id={questionsData[questionIndex].fk_ID + "D"}
                                className="trainee__commoncheckbox"
                                type="checkbox"
                                checked={checkedAnswers.isCheckedD}
                                onChange={() =>
                                  props.onChangeAnswer(questionIndex)
                                }
                              ></Input>
                            </div>
                            <div >
                              <label className="answer__description"
                                htmlFor={
                                  questionsData[questionIndex].fk_ID + "D"
                                }
                              >
                                {questionsData[questionIndex].optionD}
                              </label>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {
                      questionsData[questionIndex].isCriticalQuestion ? (
                        <div className="critical-que-indicator">
                          <img
                            id="tskRetInfo"
                            src={CriticalIcon}
                            alt="Critical Question"
                            className="critical-icon"
                          />
                          <div id="divInfo" className={"hide critical-info-box"}>
                            <span>
                              {t("MyTrainingpage.InfoMyT")}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div style={{ height: "30px" }}></div>
                      )}
                  </div>
                )}
              </div>
            </CardBody>
            <Progress className="progress-brand" value={progressBarValue} />
            <CardFooter>
              <Row>

                <Col xs="12" lg="6" className="pt-3 pb-2">

                  <span className="qa__answeredCount">
                    {t("MyTrainingpage.Question")} {`${questionIndex + 1}/${questionsData.length}`}
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span >
                    <Clock24 className="timer__icon" />
                    <span className="timer__timetext">
                      {timeRemaining.minutes < 10
                        ? `0${timeRemaining.minutes}`
                        : timeRemaining.minutes}
                      :
                      {timeRemaining.seconds < 10
                        ? `0${timeRemaining.seconds}`
                        : timeRemaining.seconds}
                    </span>
                  </span>
                </Col>
                <Col xs="12" lg="6" className="text-end pt-2 pb-2">
                  {questionIndex !== 0 && (
                    <Button
                      className="rounded-pill btn-style"
                      color="standard-secondary"
                      size="sm"
                      onClick={() => {
                        props.previousClick();
                      }}
                    >
                      {t("MyTrainingpage.Previous")}
                    </Button>
                  )}
                  {questionIndex !== questionsData.length - 1 && (
                    <Button
                      className="rounded-pill btn-style ms-2"
                      color="standard-primary"
                      size="sm"
                      onClick={() => {
                        props.nextClick();
                      }}
                    // disabled={questionIndex !== questionsData.length - 1 ? false : true}

                    >
                      {t("MyTrainingpage.Next")}
                    </Button>
                  )}

                  {allQuestionsVisited && (
                    <Button
                      className="rounded-pill btn-style ms-2"
                      color="standard-primary"
                      size="sm"
                      onClick={() => {
                        props.onReviewClick();
                      }}
                    >
                      {t("MyTrainingpage.Review")}
                    </Button>
                  )}
                </Col>

              </Row>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
};

export default QueAnsComponent;
