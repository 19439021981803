import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../Content/stylesheet/layout.css";
import PrivacyStatement from "../../Privacy Statement/PrivacyStatementdisplay";
import { Row, Col } from 'reactstrap'
const Footer = () => {
  const { t } = useTranslation();
  const [privacy, setprivacy] = useState(false);
  return (
    <>
      {privacy ? (
        <PrivacyStatement
          closed={() => {
            setprivacy(false);
          }}
        ></PrivacyStatement>
      ) : (
        ""
      )}
      <Row className="footer  ps-5 pe-5">
        <Col xs="12" lg="4" className="text-start pt-4">
          <span className="footeratag" onClick={() => { setprivacy(true); }}>
            {t("Footer.Privacy")}
          </span>
          <span className="pe-7 ps-7"></span>
          <a className="footeratag" target="_blank" rel="noreferrer" href="https://myit.bpglobal.com/myit?id=myit_ticket_submission_form&ci_id=aeec6c930fd5db40ef9a4bfce1050e68">{t("Footer.Contact Support")}</a>
        </Col>
        <Col xs="12" lg="8" className="text-end pt-4 pb-4">
          <span className="copyrightText">
            {`© ${t("Footer.footerText")}`}
          </span>
        </Col>

      </Row>
    </>
  );
};

export default Footer;
