// TrainerActionContext.js
import { createContext, useContext, useState } from 'react';
import { changeDueDateFormat } from "../../Services/ConvertDate";
import base from "../../Services/BaseService";
import moment from "moment";

const MyTrainingContext = createContext();

export const useMyTrainingContext = () => useContext(MyTrainingContext);

export const MyTrainingProvider = ({ children, fk_EmployeeID }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [onlineAssessments, setOnlineAssessments] = useState("loading");
  const [myTrainingCount, setMyTrainingCount] = useState(0);
  const dueDateFormat = "YYYY-MM-DD";

  function getDueType(dueDate) {
    let todayDate = moment(new Date()).format(dueDateFormat);
    const dateDiff = moment(todayDate).diff(dueDate, "days");
    if (dateDiff < -30) return "NOT YET DUE";
    if (dateDiff <= -1) return "DUE";
    if (dateDiff <= 30) return "OVERDUE";
    return "EXPIRED";
  }

  const updateMyTrainingCount = () => {
    setIsLoading(true);
    base.getAll(`AssessmentEmployee/get/EmployeeAssessments/${fk_EmployeeID}`).then((res) => {
      if (res) {
        let sortedAssessments = res.map((assessment) => {
          const formattedDueDate = changeDueDateFormat(
            assessment.dueDate - 2,
            dueDateFormat
          );

          const dueType = getDueType(formattedDueDate);

          return {
            ...assessment,
            dueDate: formattedDueDate,
            dueType: dueType,
          };
        });

        sortedAssessments.sort(
          (dateObj1, dateObj2) =>
            moment(dateObj1.dueDate) - moment(dateObj2.dueDate)
        );
        setOnlineAssessments(sortedAssessments);
        setMyTrainingCount(sortedAssessments.length);
        setIsLoading(false);
      }
    });
  };

  return (
    <MyTrainingContext.Provider value={{ myTrainingCount, onlineAssessments, updateMyTrainingCount, isLoading }}>
      {children}
    </MyTrainingContext.Provider>
  );
};
