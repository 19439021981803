import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useValidation } from "../../Shared/ValidateForm";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import "../../../Content/stylesheet/Location.css"
import base from "../../../Services/BaseService";
import { Row, Col } from "reactstrap";
import LoadingView from "../../Shared/Layout/LoadingView";
import { Input, Label, Modal, Button } from '@bphxd/ds-core-react';

const PerformanceUnit = (props) => {
  const { t } = useTranslation();
  var feed = {
    pU_Description: "",
    id: 0,
    puid: "",
    text01: null,
  };

  if (props.create === false) {
    ;
    feed = {
      pU_Description: props.puFeed.pU_Description,
      id: props.puFeed.pU_ID,
      puid: props.puFeed.puid,
      text01: null,
    };
  }
  const [isCreate, setIsCreate] = useState(props.create);
  const [puFeed, setPUFeed] = useState(feed);
  const [isLoadingSteps, setisLoadingSteps] = useState(false);
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [isChanged, setIsChanged] = useState(false);
  const [register, validate, errors] = useValidation();
  const [lengthpuiderr, setlengthpuiderr] = useState(false);
  const [reqpuiderr, setreqpuiderr] = useState(false);
  const [lengthpU_Descriptionerr, setlengthpU_Descriptionerr] = useState(false);
  const [reqpU_Descriptionerr, setreqpU_Descriptionerr] = useState(false);
  const [puidRemaining, setpuidRemaining] = useState(puFeed.puid.length);
  const [pU_DescriptionRemaining, setpU_DescriptionRemaining] = useState(
    puFeed.pU_Description.length
  );
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [DuplicateAlert, setDuplicateAlert] = useState(false);

  //Function invoked when user edits text boxes
  const handleChange = (e) => {
    if (e.target.name === "puid") {
      setDuplicateAlert(false);
      setreqpuiderr(false);
      removeRedBorderDynamically("puid");
      setpuidRemaining(e.target.value.length);
      if (e.target.value.length > 5) {
        setlengthpuiderr(true);
        ADDRedBorderDynamically("puid");
      } else {
        removeRedBorderDynamically("puid");
        setlengthpuiderr(false);
      }
    }
    if (e.target.name === "pU_Description") {
      setreqpU_Descriptionerr(false);
      removeRedBorderDynamically("pU_Description");
      setpU_DescriptionRemaining(e.target.value.length);
      if (e.target.value.length > 50) {
        setlengthpU_Descriptionerr(true);
        ADDRedBorderDynamically("pU_Description");
      } else {
        removeRedBorderDynamically("pU_Description");
        setlengthpU_Descriptionerr(false);
      }
    }
    setIsChanged(true);
    setPUFeed({ ...puFeed, [e.target.name]: e.target.value });
  };

  const ADDRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.add("redBorder");
    }
  };
  const removeRedBorderDynamically = (id) => {
    if (
      document.getElementById(id) != null &&
      document.getElementById(id) !== undefined
    ) {
      document.getElementById(id).classList.remove("redBorder");
    }
  };

  //Function invoked when user clicks on Save button
  const savePU = () => {
    puFeed.pU_Description = puFeed.pU_Description.trim();
    puFeed.puid = puFeed.puid.trim();
    document.getElementById("pU_Description").value =
      puFeed.pU_Description.trim();
    document.getElementById("puid").value = puFeed.puid.trim();

    setpuidRemaining(puFeed.puid.trim().length);
    if (puFeed.puid.trim().length > 5) {
      setlengthpuiderr(true);
      setSaveDisabled(false);

    } else {
      setlengthpuiderr(false);
    }

    setpU_DescriptionRemaining(puFeed.pU_Description.trim().length);
    if (puFeed.pU_Description.trim().length > 50) {
      setlengthpU_Descriptionerr(true);
      setSaveDisabled(false);

    } else {
      setlengthpU_Descriptionerr(false);
    }

    validate();
    if (document.getElementById("puid").value.trim() !== "") {
      setreqpuiderr(false);
      if (document.getElementById("pU_Description").value.trim() !== "") {
        setreqpU_Descriptionerr(false);
        if (errors.puid.isValid && errors.pU_Description.isValid) {
          if (isCreate === false) {
            if (
              props.puFeed.puid.toString() ===
              document.getElementById("puid").value
            ) {

              base
                .update(
                  process.env.REACT_APP_PERFORMANCEUNIT_UPDATE,
                  puFeed
                )
                .then((response) => {
                  props.getAll();
                  handleDiscard();
                  setIsCreate(true);
                  setIsChanged(false);
                  props.saveSuccess();
                  setisLoadingSteps(false);
                })
                .catch((error) => {
                  handleDiscard();
                  props.error();
                  setisLoadingSteps(false);
                });
              // });
            } else {

              base
                .getAll(
                  "performanceunit/get/" +
                  document.getElementById("puid").value.trim()
                )
                .then((resPU) => {
                  if (resPU !== null) {
                    ADDRedBorderDynamically("puid");
                    setDuplicateAlert(true);
                    setSaveDisabled(false);
                  } else {
                    removeRedBorderDynamically("puid");
                    setDuplicateAlert(false);


                    base
                      .update(
                        process.env
                          .REACT_APP_PERFORMANCEUNIT_UPDATE,
                        puFeed
                      )
                      .then((response) => {
                        props.getAll();
                        handleDiscard();
                        // setEditDisabled(false);
                        // setDisabled(true);
                        setIsCreate(true);
                        setIsChanged(false);
                        props.saveSuccess();
                        setisLoadingSteps(false);
                      })
                      .catch((error) => {
                        handleDiscard();
                        props.error();
                        // setEditDisabled(false);
                        // setDisabled(true);
                        setisLoadingSteps(false);
                      });
                  }
                }
                );


            }
          } else {
            base.getAll("performanceunit/get/" + document.getElementById("puid").value.trim()).then((resPU) => {
              if (resPU !== null) {
                ADDRedBorderDynamically("puid");
                setDuplicateAlert(true);
                setSaveDisabled(false);
              } else {
                removeRedBorderDynamically("puid");
                setDuplicateAlert(false);
                base.add(process.env.REACT_APP_PERFORMANCEUNIT_ADD, puFeed).then((response) => {
                  props.getAll();
                  handleDiscard();

                  setIsCreate(false);
                  setIsChanged(false);
                  props.saveSuccess();
                  setisLoadingSteps(false);
                })
                  .catch((error) => {
                    handleDiscard();
                    props.error();
                    setisLoadingSteps(false);
                  });
              }
            });
          }
        } else {
          if (document.getElementById("puid").value.trim() === "") {
            setpuidRemaining(0);
            setreqpuiderr(true);
            setlengthpuiderr(false);
            setSaveDisabled(false);
          }
          if (document.getElementById("pU_Description").value.trim() === "") {
            setpU_DescriptionRemaining(0);
            setreqpU_Descriptionerr(true);
            setlengthpU_Descriptionerr(false);
            setSaveDisabled(false);
          }
        }
      } else {
        if (document.getElementById("puid").value.trim() === "") {
          setpuidRemaining(0);
          setreqpuiderr(true);
          setlengthpuiderr(false);
          setSaveDisabled(false);
        }
        if (document.getElementById("pU_Description").value.trim() === "") {
          setpU_DescriptionRemaining(0);
          setreqpU_Descriptionerr(true);
          setlengthpU_Descriptionerr(false);
          setSaveDisabled(false);
        }
      }
    } else {
      if (document.getElementById("puid").value.trim() === "") {
        setpuidRemaining(0);
        setreqpuiderr(true);
        setlengthpuiderr(false);
        setSaveDisabled(false);
      }
      if (document.getElementById("pU_Description").value.trim() === "") {
        setpU_DescriptionRemaining(0);
        setreqpU_Descriptionerr(true);
        setlengthpU_Descriptionerr(false);
        setSaveDisabled(false);
      }
    }
  };

  const handleDiscard = () => {
    setPUFeed(feed);
    props.close(false);
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  return (
    <>
      <Modal isOpen={props.isopen} className=" modal-dialog-centered modal-50" >
        {showToast.Name === "cancel" && (
          <ConfirmCancel className={"showPopup"} show={showToast.show} close={handleCancelDelete} handleYes={handleDiscard} handleNo={savePU} />
        )}
        {isLoadingSteps && <LoadingView />}

        <Modal.Header
          className="modalPopupBGColour comm-header-bgPosition"
          onToggleOpen={cancelHandler}>
          <div className="row">
            <div className="col manageDataCreateEditHeading">
              <label className="modalHeaderGlobal ms-5">
                {props.create
                  ? t("Regionpage.Create Performance Unit")
                  : t("Regionpage.Edit Performance Unit")}
              </label>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="ps-6">
              <Label className="" htmlFor="puid">
                {t("Regionpage.Performance Unit Code")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6">
              <Input
                id="puid"
                name="puid"
                className=" "
                maxLength={5}
                onChange={handleChange}
                value={puFeed.puid}
                {...register({
                  fieldName: "puid",
                  required: true,
                  maxLength: 5,
                })}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6" xs="8">
              {errors.puid && errors.puid.Message !== undefined && (
                <div className="col" id="puid_Error">
                </div>
              )}
              {lengthpuiderr ? (
                <span className="assessmenterror">{t("ErrorMessages.Max5")}</span>
              ) : (
                ""
              )}
              {reqpuiderr ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.Required")}
                </span>
              ) : (
                ""
              )}
              {DuplicateAlert ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.PerformanceUnitCodeExists")}
                </span>
              ) : (
                ""
              )}
              <div
                className={
                  errors.puid && errors.puid.Message !== undefined
                    ? "thWordLmtErr col float-end p-0"
                    : "thWordLmtNoErr col float-end p-0"
                }
              ></div>
            </Col>
            <Col xs="4" className={`desclength text-end ${errors.puid && errors.puid.Message !== undefined}`}>

              {puidRemaining}/5
            </Col>
          </Row>
          <Row>
            <Col className="ps-6" xs="8">
              <Label className="" htmlFor="pU_Description">
                {t("Regionpage.PerformanceUnit")}
                <span className="text-danger">*</span>
              </Label>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6" >
              <Input
                id="pU_Description"
                name="pU_Description"
                className=" "
                onChange={handleChange}
                value={puFeed.pU_Description}
                maxLength={50}

                {...register({
                  fieldName: "pU_Description",
                  required: true,
                  maxLength: 50,
                })}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col className="ps-6" xs="8">
              {errors.pU_Description &&
                errors.pU_Description.Message !== undefined && (
                  <div className="col" id="pU_Description_Error">
                  </div>
                )}
              {lengthpU_Descriptionerr ? (
                <span className="assessmenterror">{t("ErrorMessages.Max50")}</span>
              ) : (
                ""
              )}
              {reqpU_Descriptionerr ? (
                <span className="assessmenterror">
                  {t("ErrorMessages.Required")}
                </span>
              ) : (
                ""
              )}
              <div className={errors.pU_Description && errors.pU_Description.Message !== undefined ? "thWordLmtErr col float-end p-0" : "thWordLmtNoErr col float-end p-0"} ></div>
            </Col>
            <Col xs="4" className={`desclength text-end ${errors.pU_Description && errors.pU_Description.Message !== undefined}`} >
              {pU_DescriptionRemaining}/50
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="me-3">
            <Button size="sm" color="standard-secondary" className="rounded-pill btn-style" onClick={cancelHandler}>
              {t("Common.Cancel")}
            </Button>{" "}
            <Button disabled={saveDisabled} size="sm" color="standard-primary" className="rounded-pill btn-style ms-2" onClick={() => { setSaveDisabled(true); savePU(); }} >
              {t("Common.Save")}
            </Button>{" "}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PerformanceUnit;
