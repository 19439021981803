import React from 'react';
import { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModuleTab from './Tabs/ModuleTab';
import TTBNotesTab from './Tabs/TTBNotesTab';
import { World24 } from '@bphxd/ds-core-react/lib/icons';
import { useModuleContext } from "../../Shared/moduleContext";
import base from "../../../Services/BaseService";
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import { useTranslation } from "react-i18next";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import QuestionTab from './Tabs/QuestionTab';
import StepTab from './Tabs/StepTab';
import LearningOutcomeTab from './Tabs/LearningOutcomeTab';
import { Trans } from "react-i18next";
import { useUser } from "../../Shared/userContext";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';
import LoadingView from "../../Shared/Layout/LoadingView";
import ErrorToastNew from '../../Toast/ErrorToastNew1';

const CreateModule = ({ saved, create, close, getAll, retrainOptions, globalModules, setIsCreate }) => {
    const { languages } = useUser();
    const { moduleData, updateModuleData, moduleLanguagePreference, updateLanguagePreference, showToast, moduleDataSaved, refetchDataPostAdd } = useModuleContext();
    const [language, setLanguage] = useState(moduleLanguagePreference)
    const [activeTab1, setActiveTab1] = useState(true)
    const [activeTab2, setActiveTab2] = useState(false)
    const [activeTab3, setActiveTab3] = useState(false)
    const [activeTab4, setActiveTab4] = useState(false)
    const [activeTab5, setActiveTab5] = useState(false)
    const [currentActiveTab, setCurrentActiveTab] = useState("tab1")
    const [isChangedTTB, setIsChangedTTB] = useState(false)
    const [isChangedModule, setIsChangedModule] = useState(false)
    const [cancelConfirm, setCancelConfirm] = useState(false);
    const [cancelModuleOrTTB, setcancelModuleOrTTB] = useState("");
    const { t } = useTranslation();
    const [disabledTabs, setDisabledTabs] = useState([])
    const [invalid, setInvalid] = useState([])
    const [issueSelected, setissueSelected] = useState("");
    const [selecteddate, setselecteddate] = useState("");
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    function convertUnicode(input) {
        if (input != null) {
            return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
                return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
            });
        }
    }

    const cancelC = () => {
        setCancelConfirm(false);
    };

    const [stepGridData, setStepGridData] = useState([]);

    const getAllSteps = async (taskID) => {
        base.getAll("step/qrylocal/" + taskID + "/" + moduleLanguagePreference).then((res) => {
            if (res !== undefined) {
                var data = res.filter(
                    (x) =>
                        x.rel_rec !== 0 &&
                        x.step_Description !== "" &&
                        x.step_Description != null
                );
                res = data;
                var s = res.filter((x) => x.step_Level === "1");
                var maxssrtLevel1 = Math.max.apply(
                    Math,
                    s.map((v) => v.ssrt)
                );
                // activity
                var ac1 = res.filter((x) => x.step_Level === "2");
                let group = ac1.reduce((r, a) => {
                    if (a.step_Label != null) {
                        r[a.step_Label.substr(0, 1)] = [
                            ...(r[a.step_Label.substr(0, 1)] || []),
                            a,
                        ];
                    }
                    return r;
                }, {});
                var actAray = [];
                actAray = group;
                var maxssrtActivity = [];
                for (const acti of Object.keys(actAray)) {
                    var maxssrtLevelActivity = Math.max.apply(
                        Math,
                        actAray[acti].map((v) => v.ssrt)
                    );
                    maxssrtActivity.push(maxssrtLevelActivity);
                }
                // subacti
                var sub = res.filter((x) => x.step_Level === "3");
                let groupSub = sub.reduce((r, a) => {
                    if (a.step_Label != null) {
                        r[a.step_Label.substr(0, 3)] = [
                            ...(r[a.step_Label.substr(0, 3)] || []),
                            a,
                        ];
                    }
                    return r;
                }, {});
                var subAray = [];
                subAray = groupSub;
                var maxssrtSubActivity = [];
                for (const acti of Object.keys(subAray)) {
                    var maxssrtSActivity = Math.max.apply(
                        Math,
                        subAray[acti].map((v) => v.ssrt)
                    );
                    maxssrtSubActivity.push(maxssrtSActivity);
                }
                // sub sub
                // subacti
                var subSub = res.filter((x) => x.step_Level === "4");
                let groupsubSub = subSub.reduce((r, a) => {
                    if (a.step_Label != null) {
                        r[a.step_Label.substr(0, 4)] = [
                            ...(r[a.step_Label.substr(0, 4)] || []),
                            a,
                        ];
                    }
                    return r;
                }, {});
                var subSubAray = [];
                subSubAray = groupsubSub;
                var maxssrtSubSubActivity = [];
                for (const acti of Object.keys(subSubAray)) {
                    var maxssrtSsActivity = Math.max.apply(
                        Math,
                        subSubAray[acti].map((v) => v.ssrt)
                    );
                    maxssrtSubSubActivity.push(maxssrtSsActivity);
                }
                for (let i = 0; i < res.length; i++) {
                    if (res[i].ssrt === maxssrtLevel1) res[i].moveDown = true; //&& res[i].moveDown != true
                    var ssrt = parseInt(res[i].ssrt);
                    //dwon disabled for acrivity
                    for (let j = 0; j < maxssrtActivity.length; j++) {
                        if (ssrt === maxssrtActivity[j]) {
                            res[i].moveDown = true;
                        }
                    }
                    //dwon disabled for sub acrivity
                    for (let j = 0; j < maxssrtSubActivity.length; j++) {
                        if (ssrt === maxssrtSubActivity[j]) {
                            res[i].moveDown = true;
                        }
                    }
                    //dwon disabled for sub sub acrivity
                    for (let j = 0; j < maxssrtSubSubActivity.length; j++) {
                        if (ssrt === maxssrtSubSubActivity[j]) {
                            res[i].moveDown = true;
                        }
                    }
                }
                for (let i = 0; i < res.length; i++) {
                    res[i].step_Description = convertUnicode(
                        res[i].step_Description
                    );
                    res[i].taskID = taskID;
                }
                setStepGridData(res);
            }
        });
    };

    const handleLanguageChange = (event) => {
        let selectedLanguage = event.target.value
        setLanguage(selectedLanguage);
        updateLanguagePreference(selectedLanguage)
    };

    const toggle = (tab) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
        switch (tab) {
            case "tab1":
                setActiveTab1(true)
                setActiveTab2(false)
                setActiveTab3(false)
                setActiveTab4(false)
                setActiveTab5(false)
                break;
            case "tab2":
                setActiveTab1(false)
                setActiveTab2(true)
                setActiveTab3(false)
                setActiveTab4(false)
                setActiveTab5(false)
                break;
            case "tab3":
                setActiveTab1(false)
                setActiveTab2(false)
                setActiveTab3(true)
                setActiveTab4(false)
                setActiveTab5(false)
                break;
            case "tab4":
                setActiveTab1(false)
                setActiveTab2(false)
                setActiveTab3(false)
                setActiveTab4(true)
                setActiveTab5(false)
                break;
            case "tab5":
                setActiveTab1(false)
                setActiveTab2(false)
                setActiveTab3(false)
                setActiveTab4(false)
                setActiveTab5(true)
                break;
            default:
                break;
        }
    }
    const closeModalConfirm = () => {
        close();
    }
    function closemodal(val) {
        if (create) close();
        else if (!isChangedModule && !isChangedTTB) close();
        else {
            if (isChangedModule && isChangedTTB) {
                setCancelConfirm(true);
                setcancelModuleOrTTB(
                    t("Curriculumpage.CancelModuleOrTTB")
                );
            } else if (isChangedModule) {
                setCancelConfirm(true);
                setcancelModuleOrTTB(
                    t("Curriculumpage.CancelModule")
                );
            } else if (isChangedTTB) {
                setCancelConfirm(true);
                setcancelModuleOrTTB(
                    t("Curriculumpage.CancelTTB")
                );
            }
        }
    }


    useEffect(() => {
        let disabledArray = [];
        if (create) {
            disabledArray.push('TTB', 'STEP', 'QUESTION', 'LEARNING')
        }
        else {
            switch (moduleData[0].task_Type) {
                case "SYL":
                    // SHOW QUESTIONS
                    disabledArray.push('TTB', 'STEP', 'LEARNING')
                    break;
                case "DAT":
                    // SHOW QUESTIONS
                    if (moduleData[0].taskID === "GBL10057") {
                        disabledArray.push('TTB', 'QUESTION', 'LEARNING')
                    }
                    break;
                case "NON":
                case "EXT":
                    // SHOW NONE
                    disabledArray.push('TTB', 'STEP', 'QUESTION', 'LEARNING')
                    break;
                default:
                    break;
            }
        }
        setDisabledTabs(disabledArray);
    }, [create, moduleData]);

    const saveModule = () => {

        let fail = [];
        moduleData.forEach((item) => {
            if (item.task_Site === "") {
                fail.push("task_Site");
            }
            if (item.task_Type === "") {
                fail.push("task_Type");
            }
            if (item.task_Number === "") {
                fail.push("task_Number");
            }
            if (item.task_Name === "") {
                fail.push("task_Name");
            }
            if (item.task_Critical === "") {
                fail.push("task_Critical");
            }
            if (item.retrainPeriod === "") {
                fail.push("retrainPeriod");
            }
            if (issueSelected === "") {
                fail.push("issueNumber");
            }
            if (selecteddate === "") {
                fail.push("issueDate");
            }
            let region = (item?.fk_PUID || '') + (item.fk_CountryID || '') + (item.fk_LocationID || '')
            if (item.task_Site === "SITE" && region === "") {
                fail.push("RegionalInformation");
            }
            if (item.n_IMAGE && !item.s_IMAGE) {
                fail.push("imageTTB");
            }
            if (item.n_IMAGE1 && !item.s_IMAGE1) {
                fail.push("imageTTB1");
            }
            if (item.n_IMAGE2 && !item.s_IMAGE2) {
                fail.push("imageTTB2");
            }
        });

        setInvalid(fail);

        if (fail.length === 0) {
            setSaveButtonDisabled(true)
            setIsLoading(true)
            if (create) {
                base.add("task/addtask", moduleData[0])
                    .then((response) => {
                        getAll();
                        setIsCreate(false);
                        setIsChangedModule(false);
                        moduleDataSaved("dataSuccess", "Module saved");
                        base.get("task/frm", response.data.taskID).then((savedModule) => {
                            updateModuleData(savedModule);
                            refetchDataPostAdd(new Date().getMilliseconds());
                        });
                        setSaveButtonDisabled(false)
                        setIsLoading(false)

                    })
                    .catch((error) => {
                        error();
                        setSaveButtonDisabled(false)
                        setIsLoading(false)

                    });
            } else {
                setSaveButtonDisabled(true)
                setIsLoading(true)
                base.update("task/updtask", moduleData)
                    .then((response) => {
                        getAll();
                        setIsChangedModule(false);
                        saved();
                        setSaveButtonDisabled(false)
                        setIsLoading(false)

                    })
                    .catch((error) => {
                        console.log(error);
                        error();
                        setSaveButtonDisabled(false)
                        setIsLoading(false)
                    });
            }
        }
    }

    return (
        <div>
            <Modal isOpen={true} className="modal-90" >
                {isLoading && <LoadingView />}
                <Modal.Header
                    className="comm-header-bgPosition"
                    onToggleOpen={() => closemodal()}>
                    <Label className="modalHeaderGlobal m-4">
                        {create === true ? "Create module" : "Edit module"}
                    </Label>
                    {showToast.Name === "dataSuccess" && (
                        <SuccessToastNew
                            show={showToast.show}
                            Message={showToast.Message}
                            form={true}
                        />
                    )}
                    {showToast.Name === "error" && (
                        <ErrorToastNew
                            show={showToast.show}
                            Message={showToast.Message}
                            form={true}
                        />
                    )}
                </Modal.Header>
                <Modal.Body style={{ minHeight: "500px" }}>
                    <div className="">
                        <Nav
                            className="nav-standard"
                            style={{ borderBottom: "0px" }}
                        >
                            <NavItem>
                                <NavLink
                                    active={activeTab1}
                                    className="pointer"
                                    onClick={() => { toggle('tab1'); }}
                                >
                                    Module information
                                </NavLink>
                            </NavItem>
                            {(!create) && (
                                <>
                                    {!disabledTabs.includes('TTB') && (
                                        <NavItem>
                                            <NavLink
                                                active={activeTab2}
                                                className="pointer"
                                                onClick={() => { toggle('tab2'); }}
                                            >
                                                TTB notes
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {!disabledTabs.includes('QUESTION') && (
                                        <NavItem>
                                            <NavLink
                                                active={activeTab3}
                                                className="pointer"
                                                onClick={() => { toggle('tab3'); }}
                                            >
                                                Questions
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    {!disabledTabs.includes('STEP') && (
                                        <NavItem>
                                            <NavLink
                                                // disabled
                                                active={activeTab4}
                                                className="pointer"
                                                onClick={() => { toggle('tab4'); }}
                                            >
                                                Steps and activites
                                            </NavLink>
                                        </NavItem>
                                    )
                                    }
                                    {!disabledTabs.includes('LEARNING') && (
                                        <NavItem>
                                            <NavLink
                                                // disabled
                                                active={activeTab5}
                                                className="pointer"
                                                onClick={() => { toggle('tab5'); }}
                                            >
                                                Learning outcomes
                                            </NavLink>
                                        </NavItem>
                                    )
                                    }
                                </>
                            )
                            }
                        </Nav>
                        <TabContent activeTab={currentActiveTab} className="" >
                            <TabPane tabId="tab1">
                                <ModuleTab retrainOptions={retrainOptions} invalid={invalid} setInvalid={setInvalid} globalModules={globalModules} create={create} setIsChangedModule={setIsChangedModule} issueSelected={issueSelected} setissueSelected={setissueSelected} selecteddate={selecteddate} setselecteddate={setselecteddate} />
                            </TabPane>
                            <TabPane tabId="tab2">
                                <TTBNotesTab setIsChangedTTB={setIsChangedTTB} invalid={invalid} setInvalid={setInvalid} />
                            </TabPane>
                            <TabPane tabId="tab3">
                                <QuestionTab create={create} />
                            </TabPane>
                            <TabPane tabId="tab4">
                                <StepTab getAllSteps={getAllSteps} stepGridData={stepGridData} />
                            </TabPane>
                            <TabPane tabId="tab5">
                                <LearningOutcomeTab />
                            </TabPane>
                        </TabContent>
                    </div>
                    <ConfirmDeleteToast //ModuleConfirm
                        show={cancelConfirm}
                        handleCancel={cancelC}
                        Message={cancelModuleOrTTB}
                        handleDelete={closeModalConfirm}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Row className="w-100">
                        <Col xs="4" className="text-start ">
                            {create === false && (
                                <div className="d-flex" >
                                    <World24 className="icon-margin" />
                                    <Input type="select" className="border-0 " value={language} onChange={handleLanguageChange}>
                                        {languages.map((item, index) => {
                                            return (
                                                <option key={index} value={item.languageID} >
                                                    {item.language_Name}
                                                </option>
                                            )
                                        })
                                        }
                                    </Input>
                                </div>
                            )}
                        </Col>
                        <Col xs="8" className="text-end">
                            <Button
                                className="rounded-pill btn-style me-3"
                                size="sm"
                                color="standard-secondary"
                                onClick={() => closemodal()}
                            >
                                <Trans i18nKey="Common.Cancel">Cancel</Trans>
                            </Button>
                            <Button
                                className="rounded-pill btn-style"
                                size="sm"
                                color="standard-primary"
                                onClick={() => { saveModule(); }}
                                disabled={saveButtonDisabled}
                            >
                                <Trans i18nKey="Common.Save">Save</Trans>
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default CreateModule;
