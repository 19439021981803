import React from "react";
import { Navigate } from "react-router-dom-v5-compat";

import useAuthorize from "../../../Hooks/useAuthorize";

const RequireAuth = ({ children }) => {
  const isAuthorized = useAuthorize();

  return (
    <>{isAuthorized === true ? children : <Navigate to="/home" replace />}</>
  );
};

export default RequireAuth;
