import "../../Content/stylesheet/toast.css";
import { Button, Modal, ModalFooter, ModalBody } from "reactstrap";

const DismissToast = (props) => {

  const formattedMessage = props.Message?.replace(/\n/g, '<br>');

  return (
    <>
      {props.show && (
        <Modal show={props.show.toString()} backdrop="static" fade={false} isOpen size="md" keyboard={false} centered >
          <ModalBody className="text-center" style={{ borderBottom: "1px solid #dedede" }} >
            <div dangerouslySetInnerHTML={{ __html: formattedMessage }} />
          </ModalBody >
          <ModalFooter style={{ alignSelf: "center", borderTop: "none" }}>
            <Button onClick={props.handleCancel} className="rounded-pill btn-style" color="standard-secondary" size="sm" value="Delete" id="delete" >
              Dismiss
            </Button>
          </ModalFooter>
        </Modal >
      )}
    </>
  );
};

export default DismissToast;
