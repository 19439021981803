import { useEffect, useState, useRef, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { Spinner, Col, Row } from "reactstrap";
import base from "../../../Services/BaseService";
import ErrorToastNew from "../../Toast/ErrorToastNew";
import "../../../Content/stylesheet/ag-grid-style.css";
import "../../../Content/stylesheet/common.css";
import "../../../Content/stylesheet/manageemployees.css";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import { Date24 } from "@bphxd/ds-core-react/lib/icons";
import "../../../Content/stylesheet/manageemployees.css";
import React from "react";
import "../../../Content/stylesheet/common.css";
import Flatpickr from "react-flatpickr";
import "../../../Content/stylesheet/assignModuleToEmployee.css";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../Content/stylesheet/ag-grid-style.css";
import CustomCheckboxFilter from "../../Shared/CustomCheckboxFilter";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { Button, Input, Label, Modal } from '@bphxd/ds-core-react';

const AssignTraining = (props) => {
  var dictModules = {};
  var dictdeslectedModuleAPI = {}; //API
  var dictselectedModulesAPI = {};
  const [selectedModulesAPI, setselectedModulesAPI] = useState({});
  const [deslectedModuleAPI, setdeslectedModuleAPI] = useState({});
  const { t } = useTranslation();

  const handleFlatpickrReady = (_, __, fp) => {
    fp.calendarContainer.classList.add("assign-flatpickr");
  };

  const [isChanged, setIsChanged] = useState(false);
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [gridData, setGridData] = useState();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [isLoadingSteps, setisLoadingSteps] = useState(false);

  const gridRef = useRef();

  useEffect(() => {
    initGridVar();
    getAllTrng(true);
    return () => {
      removeAllFlatPicketInstances();
    };
    // eslint-disable-next-line
  }, []);

  const handleDiscard = () => {
    props.close(false);
  };
  function setassignemployee() {
    for (let i = 0; i < dictModules.length; i++) {
      if (document.getElementById(dictModules[i]) !== null) {
        document.getElementById(dictModules[i]).checked = true;
      }
    }
  }

  // modify pageSize
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size-modtoemp").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    getAllTrng();
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
    // eslint-disable-next-line
  }, []);

  //Setting up AG Grid settings
  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      // minWidth: 50,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
      wrapText: true,
      autoHeight: true,
    });

    setGridColDefs([
      {
        headerName: "",
        field: "",
        resizable: false,
        suppressMovable: true,
        sortable: false,
        filter: CustomCheckboxFilter,
        minWidth: 70,
        maxWidth: 70,
        pinned: true,
        // headerClass: "trngchkbx-lefthead",
        // cellClass: "cellborder trngchkbx-leftcell",
        cellRenderer: CheckboxCellRenderer,

      },
      {
        headerName: t("AssignModulepage.AssignModuleHeader.MODULE"),
        headerTooltip: t("AssignModulepage.AssignModuleHeader.MODULE"),
        field: "task_Number",
        resizable: false,
        sort: "asc",
        minWidth: 120,
        maxWidth: 175,
        suppressMovable: true,
        pinned: true,
        cellClass: 'right-border',
        headerClass: 'right-border',
      },
      {
        headerName: t("AssignModulepage.AssignModuleHeader.MODULE_NAME"),
        headerTooltip: t("AssignModulepage.AssignModuleHeader.MODULE_NAME"),
        field: "task_Name",
        resizable: false,
        wrapText: true,
        suppressMovable: true,
        minWidth: 550,
      },
      {
        headerName: t("AssignModulepage.AssignModuleHeader.LAST_TRAINED_DATE"),
        headerTooltip: t(
          "AssignModulepage.AssignModuleHeader.LAST_TRAINED_DATE"
        ),
        field: "",
        resizable: false,
        suppressMovable: true,
        filter: false,
        sortable: false,
        minWidth: 150,
        suppressMenu: true,
        cellRenderer: TrngDateCellRenderer,
      },
    ]);
  };

  const removeAllFlatPicketInstances = () => {
    var allData = document.getElementsByClassName("flatpickr-calendar");
    let i = 0;
    while (allData.length > 0) {
      if (allData[i] === undefined) {
        i = 0;
      }
      allData[i].remove();
      i++;
    }
  };

  // const customComparator = (valueA, valueB) => {
  //   return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  // };

  //Setting up cell renderer for AG Grid row data
  // class TrngHeadlineCellRenderer {
  //   init(params) {
  //     var element = document.createElement("span");
  //     element.innerHTML = params.value;
  //     element.classList.add("headline");
  //     this.eGui = element;
  //   }
  //   getGui() {
  //     return this.eGui;
  //   }
  // }

  //Setting up DatePicker (in case there is no date assigned) and date label (in case there is a date assigned already)
  class TrngDateCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridCrudIcons");
      this.eGui = divElement;
      setassignemployee();
      const root = createRoot(divElement);
      root.render(
        <>
          <label
            id={"lbl" + params.node.data.taskID}
            style={
              params.node.data.isAssigned === "true" ||
                params.node.data.isAssigned === true
                ? // dictModules.hasOwnProperty(params.node.data.taskID)
                { display: "inline" }
                : { display: "none" }
            }
          >
            {params.node.data.pStart}
            {/* {(params.node.data.pStart!="" & params.node.data.pStart !=null)?params.node.data.pStart:selecteddate} */}
          </label>
          <div
            id={"date" + params.node.data.taskID}
            style={
              (params.node.data.isAssigned === "true" ||
                params.node.data.isAssigned === true) &&
                (params.node.data.pStart === null ||
                  params.node.data.pStart === "null")
                ? // dictModules.hasOwnProperty(params.node.data.taskID) &&
                // (dictModules[params.node.data.taskID] === "" ||
                //   dictModules[params.node.data.taskID] === null)
                {
                  display: "inline",
                  width: "60%",
                }
                : { display: "none" }
            }
          >
            <div
              className="assignModule"
              style={{
                width: "500px",
              }}
            >
              <Date24 id={"dateicon" + params.node.data.taskID} />
              <Flatpickr
                id={"taskDate" + params.node.data.taskID}
                name="taskDate"
                options={{
                  dateFormat: "d M Y",
                  maxDate: "today",
                  onReady: handleFlatpickrReady,
                }}
                onChange={() => saveDateNew(params.node.data)}
                style={{
                  left: "30.8% !important",
                  marginLeft: "-24px",
                  paddingLeft: "30px",
                  border: "0px"
                }}
                onOpen={() => {
                  // let index = params.node.rowIndex;
                  if (
                    document.getElementsByClassName("flatpickr-calendar") !==
                    undefined
                  ) {
                    var allNodes =
                      document.getElementsByClassName("flatpickr-calendar");
                    for (let i = 0; i < allNodes.length; i++) {
                      allNodes[i].classList.add("assignModuleDateClass");
                    }
                  }
                }}
              ></Flatpickr>
            </div>
          </div>

        </>);
    }

    getGui() {
      return this.eGui;
    }
  }

  //Function to Save Date when user selects a date in DatePicker
  const saveDateNew = (params) => {
    setIsChanged(true);

    document.getElementById("chk" + params.taskID).checked = true;
    var sdStatus = false;
    var selecteddate =
      moment(document.getElementById("taskDate" + params.taskID).value).format(
        "YYYY-MM-DD"
      ) + "T00:00:00";
    var threeYrsDate = new Date();
    var threeOldYrs = threeYrsDate.getFullYear() - 3;
    var presentMonth = new Date().getMonth(); //2019
    var presentDate = new Date().getDate();

    var selectedYr = new Date(
      document.getElementById("taskDate" + params.taskID).value
    ).getFullYear(); //2015
    var selectedMonth = new Date(
      document.getElementById("taskDate" + params.taskID).value
    ).getMonth();
    var selectedDt = new Date(
      document.getElementById("taskDate" + params.taskID).value
    ).getDate();

    if (threeOldYrs > selectedYr) {
      sdStatus = true;
    } else if (threeOldYrs === selectedYr) {
      if (selectedMonth < presentMonth) {
        sdStatus = true;
      } else if (selectedMonth === presentMonth) {
        if (presentDate > selectedDt) {
          sdStatus = true;
        } else {
          sdStatus = false;
        }
      } else if (selectedMonth > presentMonth) {
        sdStatus = false;
      }
    } else if (threeOldYrs < selectedYr) {
      sdStatus = false;
    }

    if (!sdStatus) {
      // setselectedDateStatus(false);
      // var empID = props.selectedempFeed.employeeID;
      var taskID = params.taskID;
      // var empTaskFeed = {
      //   fk_EmployeeID: empID,
      //   fk_TaskID: taskID,
      // };
      // var assessmentid="ASS00001";

      // var resCompFeed = {
      //   fk_AssessmentID: "QVNTMDAwMDE=",
      //   fk_EmployeeID: empID,
      //   fk_TaskID: taskID,
      //   SC10: "L2",
      //   pStart: selecteddate,
      //   Status: "C",
      // };

      // if (dictModules.hasOwnProperty(taskID)) {
      dictModules[taskID] = selecteddate;
      /// }
      dictselectedModulesAPI[taskID] = selecteddate;
      setselectedModulesAPI(dictselectedModulesAPI);
    } else if (sdStatus) {
      // setselectedDateStatus(true);
      setShowToast({ Name: "wrongDate", show: true });
      document.addEventListener("mousedown", DataSavingToastonClick);
      // document.addEventListener("mousedown", handleSuccessQuestionClickOutside);
    }
  };

  const saveData = () => {
    props.reload();
    var deselectedEmp = (deslectedModuleAPI);
    var selectedEmpAPI = (selectedModulesAPI);
    let allData = Object.assign({}, selectedEmpAPI, deselectedEmp);

    var empID = props.selectedempFeed.employeeID;
    var url = "EmployeeTask/assignTasks/" + empID;
    if (Object.keys(allData).length > 0) {
      try {

        setTimeout(() => {
          base.add(url, allData).then((res) => {
            props.reloadDone();
          });
        }, 300);

      } catch {
        props.reloadDone();
      }
    } else {
      props.reloadDone();
    }
  };
  //Setting up of checkboxes in AG Grid row data
  const CheckboxCellRenderer = (params) => {
    useEffect(() => {
      if (dictModules.hasOwnProperty(params.node.data.taskID)) {
        document.getElementById("chk" + params.node.data.taskID).checked = true;
      }
    }, [params.node.data.taskID]);
    return (
      <Input
        type="checkbox"
        id={"chk" + params.node.data.taskID}
        onChange={() => setModulewithDate(params.node.data)}
      />
    );
  };

  const setModulewithDate = (params) => {
    setIsChanged(true);
    // var empID = props.selectedempFeed.employeeID;
    // var TFeed = {
    //   fk_EmployeeID: empID,
    //   fk_TaskID: params.taskID,
    // };
    if (
      document.getElementById("chk" + params.taskID) != null &&
      document.getElementById("chk" + params.taskID) !== undefined
    ) {
      var checked = document.getElementById("chk" + params.taskID).checked;
      if (checked) {
        document.getElementById("chk" + params.taskID).checked = true;
        if (
          params.pStart !== null &&
          params.pStart !== undefined &&
          params.pStart !== "null" &&
          params.pStart !== ""
        ) {
          getAllTrng();
        } else {
          document.getElementById("date" + params.taskID).style.display =
            "inline";
          document.getElementById("dateicon" + params.taskID).style.display =
            "inline";
          document.getElementById("taskDate" + params.taskID).style.display =
            "inline";
          document.getElementById("date" + params.taskID).style.width = "60%";
        }
        dictModules[params.taskID] = "";

        dictselectedModulesAPI[params.taskID] = "";
        setselectedModulesAPI(dictselectedModulesAPI);
        removeDataDesected(params.taskID);
      } else if (!checked) {
        document.getElementById("chk" + params.taskID).checked = false;
        document.getElementById("date" + params.taskID).style.display = "none";

        removeData(params.taskID);
        dictdeslectedModuleAPI[params.taskID] = "false";
        setdeslectedModuleAPI(dictdeslectedModuleAPI);
      }
    }
  };

  function removeDataDesected(taskID) {
    if (dictdeslectedModuleAPI.hasOwnProperty(taskID)) {
      delete dictdeslectedModuleAPI[taskID];
    }
    setdeslectedModuleAPI(dictdeslectedModuleAPI);
  }

  function removeData(taskID) {
    if (dictModules.hasOwnProperty(taskID)) {
      delete dictModules[taskID];
    }
    // setselectedModules(dictModules);

    if (dictselectedModulesAPI.hasOwnProperty(taskID)) {
      delete dictselectedModulesAPI[taskID];
    }
    setselectedModulesAPI(dictselectedModulesAPI);
  }

  //Function to fetch all data for AG Grid
  const getAllTrng = (isFirst) => {
    var locationID = props.selectedempFeed.fk_LocationID;
    var empID = props.selectedempFeed.employeeID;

    setTimeout(() => {
      base
        .getAll("task/Qry_2/null/null/Y/" + locationID + "/ASSIGN/" + empID)
        .then((res) => {
          if (res !== undefined) {
            var data = res.filter(
              (x) => x.taskID !== "" && x.taskID != null
            );
            for (let i = 0; i < data.length; i++) {
              if (data[i].pStart !== null) {
                var dateFromAPIwithoutTime = data[i].pStart
                  .toString()
                  .split("T");
                //2021-11-16
                var dateParts = dateFromAPIwithoutTime[0]
                  .toString()
                  .split("-");
                var date = dateParts[2].toString();
                var mm = "";
                if (dateParts[1] === "01") {
                  mm = "Jan";
                } else if (dateParts[1] === "02") {
                  mm = "Feb";
                } else if (dateParts[1] === "03") {
                  mm = "Mar";
                } else if (dateParts[1] === "04") {
                  mm = "Apr";
                } else if (dateParts[1] === "05") {
                  mm = "May";
                } else if (dateParts[1] === "06") {
                  mm = "Jun";
                } else if (dateParts[1] === "07") {
                  mm = "Jul";
                } else if (dateParts[1] === "08") {
                  mm = "Aug";
                } else if (dateParts[1] === "09") {
                  mm = "Sep";
                } else if (dateParts[1] === "10") {
                  mm = "Oct";
                } else if (dateParts[1] === "11") {
                  mm = "Nov";
                } else if (dateParts[1] === "12") {
                  mm = "Dec";
                }
                var yyyy = dateParts[0].toString();
                data[i].pStart = date + " " + mm + " " + yyyy;
              }
            }

            for (let i = 0; i < data.length; i++) {
              data[i].task_Name = convertUnicode(data[i].task_Name);
              if (isFirst) {
                if (data[i].isAssigned === "true") {
                  dictModules[data[i].taskID] = data[i].pStart;
                }
              } else {
                if (dictModules.hasOwnProperty(data[i].taskID)) {
                  data[i].isAssigned = "true";
                  if (
                    dictModules[data[i].taskID] !== "" &&
                    dictModules[data[i].taskID] != null
                  ) {
                    dateFromAPIwithoutTime = dictModules[data[i].taskID]
                      .toString()
                      .split("T");
                    //2021-11-16
                    if (dateFromAPIwithoutTime.length > 1) {
                      dateParts = dateFromAPIwithoutTime[0]
                        .toString()
                        .split("-");
                      if (
                        dateParts[2] !== "" &&
                        dateParts[2] !== undefined
                      ) {
                        date = dateParts[2].toString();
                        mm = "";
                        if (dateParts[1] === "01") {
                          mm = "Jan";
                        } else if (dateParts[1] === "02") {
                          mm = "Feb";
                        } else if (dateParts[1] === "03") {
                          mm = "Mar";
                        } else if (dateParts[1] === "04") {
                          mm = "Apr";
                        } else if (dateParts[1] === "05") {
                          mm = "May";
                        } else if (dateParts[1] === "06") {
                          mm = "Jun";
                        } else if (dateParts[1] === "07") {
                          mm = "Jul";
                        } else if (dateParts[1] === "08") {
                          mm = "Aug";
                        } else if (dateParts[1] === "09") {
                          mm = "Sep";
                        } else if (dateParts[1] === "10") {
                          mm = "Oct";
                        } else if (dateParts[1] === "11") {
                          mm = "Nov";
                        } else if (dateParts[1] === "12") {
                          mm = "Dec";
                        }
                        yyyy = dateParts[0].toString();
                        data[i].pStart = date + " " + mm + " " + yyyy;
                      }
                    }
                  }
                } else {
                  data[i].isAssigned = "false";
                }
              }
            }
            setGridData(data);
            // setselectedModules(dictModules);
            setisLoadingSteps(false);
          }
        });
    }, 300);
  };

  //Function to convert unicode charaters for AG Grid
  function convertUnicode(input) {
    if (input != null) {
      return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
      });
    }
  }

  function DataSavingToastonClick() {
    setShowToast({ Name: "", show: false });
    //  setShowToast(false);
  }

  // function handleSuccessQuestionClickOutside() {
  //   setassignQSuccess(false);
  // }
  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const saveHandler = () => {
    if (!isChanged) {
      props.close(false);
    } else {
      props.close(false);
      saveData();
    }
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  return (
    <div>
      <Modal
        isOpen={props.isopen}
        className="modal-dialog-centered modal-90"
      >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
          />
        )}
        <LoadingOverlay
          active={isLoadingSteps}
          spinner={<Spinner style={{ color: "#111" }} />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.15)",
            }),

          }}
        >
          <Modal.Header
            className="modalPopupBGColour comm-header-bgPosition"
            onToggleOpen={cancelHandler}
          >
            <label className="modalHeaderGlobal ms-5">
              {t("AssignModulepage.Assign_Module")}
            </label>

            {showToast.Name === "wrongDate" && (
              <ErrorToastNew
                show={showToast.show}
                //Message={Messages.Training.WrongDate}
                Message={t("Training.WrongDate")}
              />
            )}
          </Modal.Header>
          <Modal.Body className="ps-8 pe-8">
            <Row>
              <Col>

                <Label
                  className="assngModToEmpEmpName"
                  style={{ marginLeft: "0%" }}
                >
                  {t("AssignModulepage.Employee_name")}
                </Label>
                <Input
                  id="emp_name"
                  name="emp_name"
                  className=" w-100"
                  // style={{ marginLeft: "0%" }}
                  disabled={true}
                  value={props.selectedempFeed.emp_DisplayName}
                ></Input>
              </Col>
              <Col>
                <Label
                  className="assngModToEmpLocation"
                  style={{ marginLeft: "0%" }}
                >
                  {t("AssignModulepage.Location")}
                </Label>
                <Input
                  id="emp_name"
                  name="emp_name"
                  className=" w-100"
                  // style={{ marginLeft: "0%", maxWidth: "100%" }}
                  disabled={true}
                  value={props.selectedempFeed.location_Description}
                ></Input>
              </Col>
            </Row>

            <div className="pt-4">
              <div className="gridDiv">

                <div
                  className="ag-theme-material gridHtWd"
                  style={{ position: 'relative' }}
                >
                  <div
                    className="rowDataInput"
                  >
                    {gridData ? (
                      <Input type="select"
                        onChange={onPageSizeChanged}
                        id="page-size-modtoemp"
                        defaultValue={10}

                      // className="ag-select-pagesize-manageassessment"
                      >
                        <option value="10" >
                          10
                        </option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Input>
                    ) : (
                      ""
                    )}

                  </div>
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={gridColDefs}
                    rowData={gridData}
                    suppressMenuHide={true}
                    // overlayLoadingTemplate={"testing..."}
                    overlayNoRowsTemplate="No records found"
                    defaultColDef={gridDefaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    suppressScrollOnNewData={true}
                    suppressHorizontalScroll={false}
                    enableRangeSelection={true}
                    onFilterChanged={onFilterChanged}
                    postProcessPopup={postProcessPopup}
                  // getRowHeight={getRowHeight}
                  ></AgGridReact>

                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer style={{ paddingRight: "2rem" }}>
            <Button color="standard-secondary" onClick={cancelHandler}
              className="rounded-pill btn-style"
            >
              {t("Common.Cancel")}
            </Button>{" "}
            <Button
              color="standard-primary"
              className="rounded-pill btn-style ms-2"
              // style={{ backgroundColor: "#007f00" }}
              disabled={!isChanged}
              onClick={saveHandler}
            >
              {t("Common.Save")}
            </Button>{" "}
          </Modal.Footer>
        </LoadingOverlay>
      </Modal>
    </div>
  );
};
export default AssignTraining;
