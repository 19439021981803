// TrainerActionContext.js
import { createContext, useContext, useState } from 'react';
import base from "../../Services/BaseService";

const AssessmentContext = createContext();

export const useAssessmentContext = () => useContext(AssessmentContext);

export const AssessmentProvider = ({ children }) => {
    const [assessmentCount, setAssessmentCount] = useState(0);

    const updateAssessmentCount = () => {
        base.getAll(`assessment/getCountOfEmp`).then((res) => {
            setAssessmentCount(res);
        })
            .catch((err) => {
                console.log(err)
            });
    };

    return (
        <AssessmentContext.Provider value={{ assessmentCount, updateAssessmentCount }}>
            {children}
        </AssessmentContext.Provider>
    );
};
