import React from "react";
// import cardsImg from "../../../Content/Images/airbpImages/AIR BP EDITED-5.png"
// import cardsImg from "../../../Content/Images/airbpImages/card_manager_landing_page.png"
// import breadcrumb from "../../../Content/Icons/arrow.png";
import { Toast } from '@bphxd/ds-core-react';
import { Breadcrumb, BreadcrumbItem, Button, Row, Col, ToastBody, UncontrolledPopover, PopoverBody } from "reactstrap";
import { Trans } from "react-i18next";
import { Alert32, Info24 } from "@bphxd/ds-core-react/lib/icons";
import { UncontrolledDropdown } from "reactstrap";
import { DropdownToggle } from "reactstrap";
import { Down16 } from "@bphxd/ds-core-react/lib/icons";
// import cardsImg from "../../../Content/Images/airbpImages/card_manager_landing_page.png"
import cardsImg from "../../../Content/Images/airbpImages/cardsImg.png"

export default function TitleBanner({ heading, subheading, breadcrumbArray, buttonHeading, showButton1, functionButton1, textButton1, showButton2, showButton3, functionButton2, textButton2, textButton3, infoText, isDisabledCreate, dropdownMenu }) {

    let standardHeader
    switch (heading) {
        case "Contact us":
        case "Site under maintenance":
        case "Error":
            standardHeader = false;
            break;
        default:
            standardHeader = true;
            break;
    }

    return (
        <>
            <div className="crop-container">
                <img src={cardsImg} alt="bp" width="100%" />
            </div>
            <div className={standardHeader ? "text-on-image" : "text-on-image-contact"}>
                <div className={`toast-container end-0 title-index`}>
                    <Toast className="toast-border disclaimer-toast" isOpen={heading === "CADOPS" ? true : false} light >
                        <ToastBody>
                            <div className="d-flex">
                                <div style={{ flex: "0 0 40px" }}>
                                    <Alert32 className="" />
                                </div>
                                <div>
                                    <Trans i18nKey="Homepage.bpText">
                                        All bp Corporate entities, employees and contractors
                                        must process personal information in accordance with the
                                        bp data privacy rules.
                                    </Trans>
                                </div>
                            </div>


                        </ToastBody>
                    </Toast>

                </div>
                <Row className="pt-3 pb-4 ps-4 pe-3">
                    <Col xs={(showButton1 === false && showButton2 === false && showButton3 === false) ? 12 : 5} className="mt-1 mb-4">
                        <Breadcrumb>
                            {breadcrumbArray.map((item, index) => {
                                return (
                                    <BreadcrumbItem style={{ color: "white", fontSize: "13px" }} key={`breadcrumb-${index}`}
                                    >
                                        {" "}
                                        {item}
                                    </BreadcrumbItem>
                                )
                            })}
                        </Breadcrumb>
                        <h1
                            className="mb-0 main-heading"
                        >
                            <Trans i18nKey={heading} />
                            {infoText !== "" &&
                                <>
                                    <Info24
                                        style={{ color: "#111" }}
                                        id="tskRetInfoBanner"
                                        className={"mt-1"}
                                    />
                                    <UncontrolledPopover trigger="hover focus" placement="right" target="tskRetInfoBanner" >
                                        <PopoverBody>{infoText}</PopoverBody>
                                    </UncontrolledPopover>
                                </>
                            }
                        </h1>
                        {subheading !== "" &&
                            <div
                                style={{
                                    color: "white",
                                    fontFamily: "Roboto-Medium",
                                    fontSize: "18px",
                                }}
                            >
                                {subheading}
                            </div>
                        }
                    </Col>
                    {(showButton1 === false && showButton2 === false && showButton3 === false) ? "" :
                        <>
                            <Col xs="7" className="mb-2">
                                <Row>
                                    <Col xs="12" sm="12" className="mt-3" style={{ height: "34px", width: "100%" }} >
                                        <div className="text-end button-heading" >
                                            {buttonHeading}
                                        </div>
                                    </Col>
                                    <Col xs="12" sm="12" className="text-end">
                                        {showButton1 &&
                                            <Button
                                                className="rounded-pill title-button mt-4"
                                                color="brand-secondary"
                                                size="md" onClick={functionButton1}
                                            >
                                                {textButton1}
                                            </Button>
                                        }
                                        {showButton2 &&
                                            <Button
                                                className="rounded-pill title-button mt-4 ms-2"
                                                color="brand-secondary"
                                                size="md" onClick={functionButton2}
                                            >
                                                {textButton2}
                                            </Button>
                                        }
                                        {showButton3 &&
                                            <UncontrolledDropdown
                                                disabled={isDisabledCreate}>
                                                <DropdownToggle
                                                    className="rounded-pill title-button mt-4 ms-2"
                                                    color="brand-secondary"
                                                    size="md"
                                                    style={{
                                                        backgroundColor: "white",
                                                        color: "black",
                                                    }}
                                                    caret
                                                >
                                                    <span
                                                        className={
                                                            isDisabledCreate
                                                                ? "disabledCreate"
                                                                : ""
                                                        }
                                                    >
                                                        {textButton3}
                                                    </span>{" "}
                                                    <Down16
                                                        className={isDisabledCreate ? "disabledCreate" : ""}
                                                    />
                                                </DropdownToggle>
                                                {dropdownMenu}
                                            </UncontrolledDropdown>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </>}
                </Row>
            </div >

        </>
    );
}