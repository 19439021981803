import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input } from "reactstrap";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import ErrorToastNew from "../../Toast/ErrorToastNew1";
import base from "../../../Services/BaseService";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../Content/stylesheet/ag-grid-style.css";
import Createsystemuser from "./createsystemuser";
import "../../../Content/stylesheet/systemuser.css"
import ConfirmDeleteToast from "../../Toast/ConfirmDeleteToast";
import UserdataMigration from "./UserdataMigration";
import Cookies from "universal-cookie";
import assignModule from "../../../Content/Icons/assign modulee.svg";
import AssignTraining from "../AssignModuleToEmployee/AssignModuleToEmployeeLandingNew";
import { useTranslation } from "react-i18next";
import { Service } from "../../../Service";
import TitleBanner from "../../Shared/Layout/TitleBanner";
import { Edit32, Trash32 } from "@bphxd/ds-core-react/lib/icons";
import LoadingView from "../../Shared/Layout/LoadingView";
import { createRoot } from "react-dom/client";

function SystemusersLandingpage() {
  const { t } = useTranslation();
  const [selectedempFeed, setselectedEmpFeed] = useState({});
  const [trngModule, setTrngModule] = useState(false);
  var cookies = new Cookies();
  const gridRef = useRef();

  useEffect(() => {
    const subscription = Service.getMessage().subscribe(({ text }) => {
      if (text.message === "Language changed") {
        initGridVar();
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    base.getAll("control/qry/SYSTEM_USERS").then((res) => {
    });

    base.getAll("formdata/qry/CAD_USER/frmCADUser").then((res) => {
    });
    getallUsers();
    initGridVar();
    // eslint-disable-next-line
  }, []);

  function getallUsers() {
    base.getAll("user/qry1").then((res) => {
      for (let i = 0; i < res.length; i++) {
        let level = "";
        if (res[i].accessLevel === "LOCN" && res[i].userLevel === "40") {
          level = "Site Manager";
        }
        if (res[i].accessLevel === "PU" && res[i].userLevel === "30") {
          level = "PU Manager";
        }
        if (res[i].accessLevel === "CTY" && res[i].userLevel === "30") {
          level = "Country Manager";
        }
        if (res[i].userLevel === "10") level = "System Administrator";
        if (res[i].userLevel === "15") level = "Global Administrator";
        if (res[i].userLevel === "20") level = "Global User";
        if (res[i].userLevel === "50") level = "Employee";

        res[i].customLevel = level;
      }
      setGridData(res);
      setIsloading(false);
    });
  }

  function deleteuser(uid) {

    setTimeout(() => {
      base
        .update("user/delete/" + uid, "")
        .then((res) => {
          setMessage(
            t("SystemUserpage.UserDeletedToast")
          );
          setConfirmdelete(false);
          Successshow();
          getallUsers();
        })
        .catch((err) => {
          setErrmessage(t("SystemUserpage.UserNotDeletedToast"));
          Errorshow();
        });
    }, 300);
  }

  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [gridData, setGridData] = useState();
  const [createsystemuser, setCreatesystemuser] = useState(false);
  const [editsystemuser, setEditsystemuser] = useState(false);
  const [message, setMessage] = useState("");
  const [errmessage, setErrmessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [confirmdelete, setConfirmdelete] = useState(false);
  const [deleteitem, setDeleteitem] = useState({});
  const [migrateuser, setMigrateuser] = useState(false);

  const [userdata, setuserdata] = useState("");
  const customComparator = (valueA, valueB) => {
    valueA = typeof valueA === "undefined" || valueA === null ? "" : valueA;
    valueB = typeof valueB === "undefined" || valueB === null ? "" : valueB;
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  function Successshow() {
    setSuccess(true);

    document.addEventListener("mousedown", DataSavingToastonClick);
  }

  function Errorshow() {
    setError(true);

    document.addEventListener("mousedown", DatanotsavedToastonClick);
  }

  function DatanotsavedToastonClick() {
    setError(false);
  }

  function DataSavingToastonClick() {
    setSuccess(false);
  }

  // modify pageSize
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }

    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
  }, []);

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      // minWidth: 50,
      sortable: true,
      filter: true,
      resizable: false,
      editable: false,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
      comparator: customComparator,
      // suppressKeyboardEvent:params => params.editing
    });

    setGridColDefs([
      {
        headerName: t("SystemUserpage.LandingGridHeader.USER_NAME"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.USER_NAME"),
        field: "userDisplayName",
        minWidth: 250,
        maxWidth: 300,
        cellClass: 'right-border',
        headerClass: 'right-border',
        pinned: true,
        sort: "asc",
        suppressMovable: true,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.USER_ID"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.USER_ID"),
        field: "userID",
        minWidth: 200,
        maxWidth: 300,
        // cellClass: "alignCenter",
        suppressMovable: true,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.USER_EMAIL_ID"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.USER_EMAIL_ID"),
        field: "email",
        minWidth: 200,
        maxWidth: 300,
        // cellClass: "alignCenter",
        suppressMovable: true,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.LEVEL"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.LEVEL"),
        field: "customLevel",
        minWidth: 150,
        maxWidth: 200,        // cellClass: "alignCenter",
        suppressMovable: true,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.JOB_TITLE"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.JOB_TITLE"),
        field: "jobTitle",
        minWidth: 150,
        maxWidth: 200,        // cellClass: "alignCenter",
        suppressMovable: true,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.EMPLOYEE_LOCATION"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.EMPLOYEE_LOCATION"),
        field: "locationID",
        minWidth: 150,
        maxWidth: 200,
        // cellClass: "alignCenter widthhhh",
        suppressMovable: true,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.ASSIGN_MODULE"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.ASSIGN_MODULE"),
        field: "",
        // cellClass: "alignCenter widthhhh",
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        suppressMovable: true,
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        suppressMenu: true,
        filter: false,
        cellRenderer: PeopleIconCellRenderer,
      },
      {
        headerName: t("SystemUserpage.LandingGridHeader.EDIT_DELETE"),
        headerTooltip: t("SystemUserpage.LandingGridHeader.EDIT_DELETE"),
        field: "",
        cellClass: "text-center",
        headerClass: 'ag-center-header',
        filter: false,
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: EditCellRenderer,
        suppressMovable: true,
        sortable: false,
        suppressMenu: true,
      },
    ]);
  };

  //Setting up of Assign Module Icon Cell Renderer
  class PeopleIconCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons2");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <div id="people">
            <img
              src={assignModule}
              alt=""
              className="pointer"
              style={
                params.node.data.emp_Absent === "true" ||
                  params.node.data.emp_Absent === true ||
                  (params.node.data.userLevel !== "50" &&
                    params.node.data.isTrainee === 0)
                  ? { display: "none" }
                  : { display: "inline" }
              }
              onClick={() => callSetupModule(params.node.data)}
            ></img>
          </div>
        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }
  //Function invoked when user clicks on Assign Module icon
  const callSetupModule = (event) => {
    if (
      event.employeeID != null &&
      event.employeeID !== "" &&
      event.employeeID !== "undefined" &&
      event.employeeID !== undefined
    ) {
      var assignTrng = {
        employeeID: event.employeeID,
        emp_DisplayName: event.userDisplayName,
        fk_LocationID: event.locationID,
        location_Description: event.locationID,
      };
      setselectedEmpFeed(assignTrng);
      setTrngModule("true");
    } else {
      setTrngModule("false");
    }
  };

  //Toast Messages Setup
  const assignSuccess = () => {
    // setShowToast({ Name: "successAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const assignDateSuccess = () => {
    // setShowToast({ Name: "successdateAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const unassignSuccess = () => {
    // setShowToast({ Name: "successUnassign", show: true });
    //setTimeout(() => setShowToast(false), 3000);
    document.addEventListener("mousedown", DataSavingToastonClick);
  };

  const assignFailure = () => {
    // setShowToast({ Name: "errorAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const assignDateFailure = () => {
    // setShowToast({ Name: "errordateAssign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  const unassignFailure = () => {
    // setShowToast({ Name: "errorUnassign", show: true });
    document.addEventListener("mousedown", DataSavingToastonClick);
  };
  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  // };

  const closeCreateManageEmp = (val) => {
    setTrngModule(val);
  };

  class EditCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridcellicons1");
      this.eGui = divElement;
      const root = createRoot(divElement);
      root.render(
        <>
          <Edit32
            onClick={() => {
              // if (userData.userID === params.data.userID) {
              //   setErrmessage("Permission to edit your own record is restricted.");
              //   Errorshow();
              // } else {
              setEditsystemuser(true);
              setuserdata(params.data);
              // }
            }}
            className="pointer"
          />
          <Trash32
            onClick={() => {
              setConfirmdelete(true);
              setDeleteitem(params.data.id);
            }}
            className="pointer trashOnSmallerScreen ms-2"
          />

        </>
      );
    }
    getGui() {
      return this.eGui;
    }
  }
  return (

    // <LoadingOverlay
    //   active={isloading}
    //   spinner={<Spinner className="spinnerstyle" />}
    //   styles={{
    //     overlay: (base) => ({
    //       ...base,
    //       background: "rgba(0, 0, 0, 0.5)",
    //       height: "110%",
    //     }),
    //   }}
    //   fadeSpeed={100}
    // >
    <div>
      {isloading && <LoadingView />}
      {
        createsystemuser ? (
          <Createsystemuser
            close={() => {
              setCreatesystemuser(false);
            }}
            reload={() => {
              setIsloading(true);
            }}
            update={() => {
              getallUsers();
            }}
            success={() => {
              setMessage("User saved");
              Successshow();
            }}
            error={() => {
              setErrmessage("User not saved. Try again");
              Errorshow();
            }}
            mode="create"
          ></Createsystemuser >
        ) : (
          ""
        )
      }
      {
        editsystemuser ? (
          <Createsystemuser
            close={() => {
              setEditsystemuser(false);
            }}
            reload={() => {
              setIsloading(true);
            }}
            update={() => {
              getallUsers();
            }}
            userdata={userdata}
            success={() => {
              setMessage("User saved");
              Successshow();
            }}
            error={() => {
              setErrmessage("User is not saved. Try again");
              Errorshow();
            }}
            mode="edit"
          ></Createsystemuser>
        ) : (
          ""
        )
      }
      {
        migrateuser ? (
          <UserdataMigration
            close={() => {
              setMigrateuser(false);
            }}
            success={() => {
              setMessage("User data migrated");
              Successshow();
            }}
            error={(msg) => {
              setErrmessage(msg);
              Errorshow();
            }}
          ></UserdataMigration>
        ) : (
          ""
        )
      }
      {
        confirmdelete ? (
          <ConfirmDeleteToast
            show={true}
            Message={"Are you sure want to delete this item?"}
            handleCancel={() => {
              setConfirmdelete(false);
            }}
            handleDelete={() => {
              deleteuser(deleteitem);
            }}
          ></ConfirmDeleteToast>
        ) : (
          ""
        )
      }
      <div>

        <TitleBanner
          heading={t("SystemUserpage.Users")}
          // subheading={<Trans i18nKey="Homepage.Title">Competency Assessment Database for Operations Staff</Trans>}
          subheading={""}
          infoText={""}
          breadcrumbArray={[t("Common.Manage Data"), t("SystemUserpage.Users")]}
          showButton1={cookies.get("rolelevel") === "30" ? false : true}
          textButton1={t("SystemUserpage.User Data Migration")}
          functionButton1={() => {
            setMigrateuser(true);
          }}
          functionButton2={() => {
            setCreatesystemuser(true);
          }}
          showButton2={true}
          textButton2={t("SystemUserpage.Create New User")}
          buttonHeading={""}
          showButton3={false}
        />

        <SuccessToastNew show={success} Message={message} />
        <ErrorToastNew show={error} Message={errmessage} />

      </div>

      <div className="ps-7 pe-7 pb-5 pt-4">
        <div className="gridDiv">

          <div
            className="ag-theme-material gridHtWd "
            style={{ width: "100%", position: 'relative' }}

          >
            <div
              className="rowDataInput"
            >
              {gridData ? (
                <Input type="select"
                  onChange={onPageSizeChanged}
                  id="page-size"
                  defaultValue={10}
                >
                  <option value="10" >
                    10
                  </option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </Input>
              ) : (
                ""
              )}

            </div>
            <AgGridReact
              ref={gridRef}
              columnDefs={gridColDefs}
              rowData={gridData}
              suppressMenuHide={true}
              // overlayLoadingTemplate={"testing..."}
              overlayNoRowsTemplate="No records found"
              defaultColDef={gridDefaultColDef}
              // onGridReady={onGridReady}
              pagination={true}
              paginationPageSize={10}
              suppressScrollOnNewData={true}
              suppressHorizontalScroll={false}

              enableRangeSelection={true}
              onFilterChanged={onFilterChanged}
              postProcessPopup={postProcessPopup}
            // getRowHeight={getRowHeight}
            ></AgGridReact>

          </div>
        </div>

      </div>

      {/* <Button
              className="rounded-pill savebtn m27 user-migration-btn"
              color="darker-tertiary"
              disabled={cookies.get("rolelevel") === "30" ? true : false}
              size="lg"
              onClick={() => {
                setMigrateuser(true);
              }}
            >
              {t("SystemUserpage.User Data Migration")}
            </Button> */}

      {
        trngModule ? (
          <AssignTraining
            close={closeCreateManageEmp}
            selectedempFeed={selectedempFeed}
            assignSuccess={assignSuccess}
            assignFailure={assignFailure}
            unassignSuccess={unassignSuccess}
            unassignFailure={unassignFailure}
            assignDateSuccess={assignDateSuccess}
            assignDateFailure={assignDateFailure}
            reload={() => {
              setIsloading(true);
            }}
            reloadDone={() => {
              setIsloading(false);
            }}
            isopen={true}
          ></AssignTraining>
        ) : (
          ""
        )
      }
    </div >
    // </LoadingOverlay>
  );
}

export default SystemusersLandingpage;
