import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import base from "../../../Services/BaseService";
import ErrorToastNew from "../../Toast/ErrorToastNew";
import { useValidation } from "../../Shared/ValidateForm";
import "../../../Content/stylesheet/common.css";
import "../../../Content/stylesheet/createnewsfeed.css";
import "../../../Content/stylesheet/createquestion.css";
import "../../../Content/stylesheet/tooltip.css";
import { Row, Col } from "reactstrap";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import "../../../Content/stylesheet/Location.css"
import "../../../Content/stylesheet/assignModuleToEmployee.css";
import { Input, Label, Modal, Button } from '@bphxd/ds-core-react';
import Select from "react-select";

const DeanonymizePopUp = (props) => {
  const { t } = useTranslation();
  var feed = {
    fk_UserID: props.DeanonymizeFeed.fk_UserID,
    fk_LocationID: props.DeanonymizeFeed.fk_LocationID,
    assign_Indicator: "DEANONYMISE",
    text01: "",
    text02: "",
    text03: "",
    text04: null,
    rel_rec: 1,
  };

  const deAnonymizeFeed = feed;
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [register] = useValidation();
  const [isChanged, setIsChanged] = useState(false);
  const [ddlReasonVal, setReasonVal] = useState(null);
  const [ddlDueToVal, setDueToVal] = useState(null);
  const [ddlReasonSelected, setDDLReasonSelected] = useState(null);
  const [ddlDueToSelected, setDDLDueToSelected] = useState(null);
  const [text03Remaining, settext03Remaining] = useState(
    deAnonymizeFeed.text03.length
  );
  const [invalidReason, setInvalidReason] = useState(false)
  const [invalidDueTo, setInvalidDueTo] = useState(false)
  const [invalidDescription, setInvalidDescription] = useState(false)

  useEffect(() => {
    setDropdownData();
    // eslint-disable-next-line
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.invalid ? 'red' : provided.borderColor,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "146px",
    }),
  };

  //Loading data for Reason drop down
  const setDropdownData = () => {

    let reasonArr = [];
    for (let i = 0; i < props.ReasonList.length; i++) {
      reasonArr.push({
        value: props.ReasonList[i].pValue1,
        label: props.ReasonList[i].pValue1,

      });
    }
    setReasonVal(reasonArr);

    let dueToArray = [];
    for (let i = 0; i < props.DueToList.length; i++) {
      dueToArray.push({
        value: props.DueToList[i].pValue1,
        label: props.DueToList[i].pValue1,

      });
    }
    setDueToVal(dueToArray);
  };

  const handleReasonDDL = (params) => {
    setInvalidReason(false)
    setIsChanged(true);
    setDDLReasonSelected(params.value);
  };


  const handleDueToDDL = (params) => {
    setInvalidDueTo(false)
    setIsChanged(true);
    setDDLDueToSelected(params.value);
    if (params.value === "OTHER") {
      document.getElementById("descBox").classList.remove("hideAsterisk");
    } else {
      document.getElementById("descBox").classList.add("hideAsterisk");
      setInvalidDescription(false)
    }
  };

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  const handleDiscard = () => {
    props.getAllUser();
    props.close(false);
  };

  //Function invoked when user types into text box
  const handletext03 = (e) => {
    setIsChanged(true);
    if (e.target.name === "text03") {
      settext03Remaining(e.target.value.length);
      setInvalidDescription(false);
    };
  };

  // Function invoked when the user clicks on Save button
  const DeAnonymizeUser = () => {
    var deanonymize = {
      fk_UserID: props.DeanonymizeFeed.fk_UserID,
      fk_LocationID: props.DeanonymizeFeed.fk_LocationID,
      assign_Indicator: "DEANONYMISE",
      text01: ddlReasonSelected,
      text02: ddlDueToSelected,
      text03: document.getElementById("text03").value.trim(),
      text04: null,
      rel_rec: 1,
    };

    var save = false;
    settext03Remaining(document.getElementById("text03").value.trim().length);

    if (
      ddlDueToSelected === "OTHER" &&
      document.getElementById("text03").value.trim() === ""
    ) {
      save = false;
      settext03Remaining(0);
      setInvalidDescription(true)
    }

    if (
      ddlDueToSelected === null ||
      ddlDueToSelected === "null" ||
      ddlDueToSelected === ""
    ) {
      save = false;
      setInvalidDueTo(true)
    }

    if (
      ddlReasonSelected === null ||
      ddlReasonSelected === "null" ||
      ddlReasonSelected === ""
    ) {
      save = false;
      setInvalidReason(true)
    } else {
      if (
        ddlReasonSelected !== null ||
        ddlReasonSelected !== "null" ||
        ddlReasonSelected !== ""
      ) {
        setInvalidReason(false)

        if (
          ddlDueToSelected === null ||
          ddlDueToSelected === "null" ||
          ddlDueToSelected === ""
        ) {
          save = false;
          setInvalidDueTo(true)
        } else {
          save = true;
          setInvalidDueTo(false)
          if (
            ddlDueToSelected === "OTHER" &&
            document.getElementById("text03").value.trim() === ""
          ) {
            save = false;
            settext03Remaining(0);
          }
        }
      }
    }

    if (save) {
      base
        .add(process.env.REACT_APP_DEANONYMISE_ADD, deanonymize)
        .then((response) => {
          props.getAllUser();
          props.saveSuccess();
          handleDiscard();
        })
        .catch((error) => {
          handleDiscard();
        });
    } else {
      if (
        ddlReasonSelected === null ||
        ddlReasonSelected === "null" ||
        ddlReasonSelected === ""
      ) {
        setInvalidReason(true)
      }
      if (
        ddlDueToSelected === null ||
        ddlDueToSelected === "null" ||
        ddlDueToSelected === ""
      ) {
        setInvalidDueTo(true)
      }
      if (
        ddlDueToSelected === "OTHER" &&
        document.getElementById("text03").value.trim() === ""
      ) {
        settext03Remaining(0);
        setInvalidDescription(true)
      }
    }
  };

  return (
    <>
      <Modal isOpen={props.isopen} centered className="modal-60 modal-dialog-centered " >
        {showToast.Name === "cancel" && (
          <ConfirmCancel className={"showPopup"} show={showToast.show} close={handleCancelDelete} handleYes={handleDiscard} />
        )}
        <Modal.Header
          className="modalPopupBGColour"
          onToggleOpen={cancelHandler}
        >
          <div className="">
            <div className="modalHeaderGlobal ms-5"
            // style={{ marginLeft: "2%" }}
            >
              {t("Regionpage.Deanonymise Location")}

            </div>
          </div>
          {/* Toast messages */}
          {showToast.Name === "wrongDate" && (
            <ErrorToastNew
              show={false}
              Message={t("Regionpage.WrongDate")}
            />
          )}
        </Modal.Header>
        {/* Toast messages */}

        <Modal.Body >
          <Row >
            <Col lg={8} md={12} className=" mt-2">
              <Label htmlFor="Hazard_Description">
                {t("Regionpage.Reason")}
                <span className="text-danger"> *</span>
              </Label>
              <br />
              <div className="">
                <div className="w-100">
                  <Select
                    id="Reason"
                    invalid={invalidReason}
                    placeholder={ddlReasonSelected}
                    value={ddlReasonSelected}
                    options={ddlReasonVal}
                    onChange={handleReasonDDL}
                    styles={customStyles}
                    classNamePrefix="react-select"
                  ></Select>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} md={12} className="mt-2 ">
              <Label htmlFor="Hazard_Description">
                {t("Regionpage.Due To")}
                <span className="text-danger"> *</span>
              </Label>
              <br />
              <div className="d-flex">
                <div className="w-100">
                  <Select
                    id="DueTo"
                    placeholder={ddlDueToSelected}
                    value={ddlDueToSelected}
                    options={ddlDueToVal}
                    onChange={handleDueToDDL}
                    styles={customStyles}
                    invalid={invalidDueTo}
                    classNamePrefix="react-select"
                  ></Select>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs="12">
              <Label
                htmlFor="Hazard_Description"
              >
                {t("Regionpage.Details")}
                <span id="descBox" className="text-danger hideAsterisk"> *</span>
              </Label>
              <Input
                type="textarea"
                id="text03"
                name="text03"
                onChange={handletext03}
                maxLength={100}
                {...register({
                  fieldName: "text03",
                })}
                className=""
                invalid={invalidDescription}
              ></Input>
            </Col>
            <Col xs="9">
            </Col>
            <Col xs="3" className="text-end">
              <span className="desclength" >
                {text03Remaining}/100
              </span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="">
            <Button color="standard-secondary" onClick={cancelHandler} className="rounded-pill btn-style" size="sm">
              {t("Common.Cancel")}
            </Button>
            <Button color="standard-primary" className="ms-2 rounded-pill btn-style" size="sm" onClick={() => DeAnonymizeUser()} >
              {t("Common.Save")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeanonymizePopUp;
