import React from "react";
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import "../../Content/stylesheet/common.css";
import { Label, Modal } from '@bphxd/ds-core-react';

function Viewnewsfeeddata(props) {
  function closemodal(val) {
    props.closed();
  }

  return (
    <>
      <Modal id="newsfeed__modal" isOpen={true} style={{ overflowX: "clip" }} className="modal-dialog-centered modal-70" >
        <Modal.Header
          className="comm-header-bgPosition"
          onToggleOpen={() => { closemodal(); }}>
          <Label className="modalHeaderGlobal m-4">
            {props.data.news_Headline}
          </Label>
        </Modal.Header>

        <Modal.Body className="view-newsfeed" id="scrolltop">
          <div className="margindown">
            {props.data.news_Desc}
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}

export default Viewnewsfeeddata;
