import React, { useEffect, useState } from "react";
import { Toast } from '@bphxd/ds-core-react';
import { Check24 } from "@bphxd/ds-core-react/lib/icons";

const SuccessToastNew = (props) => {

  const [show1, setShow1] = useState(props.show);

  useEffect(() => {
    setShow1(props.show);
  }, [props.show]);

  useEffect(() => {
    if (show1) {
      const timer = setTimeout(() => {
        setShow1(false)
        if (props.onClose && typeof props.onClose === 'function') {
          props.onClose(); // Call onClose function
        }
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [show1, props]);

  return (
    <>
      <div className={`${props.form ? "mt-n4" : "mt-4"} toast-container top-0 start-50 translate-middle-x`} >
        <Toast
          light
          border
          className="toast-width"
          isOpen={show1}
        >
          <Toast.Body>
            <div className="d-flex w-100">
              <div style={{ flex: "0 0 25px" }}>
                <Check24 />
              </div>
              <div style={{ flex: "0 0 1" }} className="toast-text mt-1">
                {props.Message}
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </div>

    </>
  );
};

export default SuccessToastNew;
