export const Messages = {
  NewsFeed: {
    Success: "Data saved",
    Error: "Data not saved. Try again",
    Discard: "Discard changes?",
    Delete: "Are you sure you want to delete this news item?",
    ErrorMorethanEightSteps: "A module cannot have more than 8 steps",
    ErrorCannotAsiignQtoSiteSpecVarient:
      "You cannot assign/unassign questions to site specific variant of a module ",
    ErrorCannotDeleteDummystep:
      "This step cannot be deleted",
    ErrorMoreThan22ItemstoStep:
      "A step cannot have more 22 underlying activities",
  },

  ManageData: {
    OnDeleteNewsFeedSuccessMsg:
      "News item deleted",
    OnDeleteSuccessMsg: "Delete successful",
    OnErrorMsg: "Data not deleted. Try again",
    AssignSuccess:
      "Question assigned",
    UnAssignSuccess: "Question unassigned",
  },
  ManageThreat: {
    OnDeleteSuccessMsg: "Threat deleted",
    OnErrorMsg: "Data not deleted. Try again",
    OnDeleteErrorMsg: "Threat not deleted. Try again",
    AssignSuccess:
      "Question assigned",
    UnAssignSuccess: "Question unassigned",
    SaveSuccess: "Threat updated",
    CreateSuccess: "Threat created",
    SaveFailure: "Data not saved. Try again",
    CreateFailure: "Threat not saved. Try again",
    DeleteConfirm: "Are you sure you want to delete this threat?",
  },
  ManageNewsFeed: {
    OnDeleteSuccessMsg: "News item deleted",
    OnErrorMsg: "News items not deleted. Try again",
    SaveSuccess: "News item updated",
    CreateSuccess: "News item created",
    SaveFailure: "News item not saved. Try again",
    CreateFailure: "News item not saved. Try again",
  },
  CurriculumStep: {
    Success: "Assigned",
    Error: "Data not saved. Try again.",
    Discard: "Changes have not been saved",
    Delete: "Are you sure you want to delete this item?",
    assign: "Are you sure you want to assign this question?",
    Unassign: "Are you sure you want to remove this question?",
    SureCancel: "Are you sure you want to cancel?",
    itemCannotMove: "This item cannot be moved",
  },
  manageCurriCulum: {
    stepCreated: "Step/activity saved",
    stepUpdated: "Step/activity saved",
    stepDelete: "Step/activity deleted",

    createModule: "Module saved",
    editModule: "Module saved",
    deleteModule: "Module deleted",

    lerningOutcomeCreated:
      "Learning outcome saved",
    lerningOutcomeUpdated:
      "Learning outcome saved",
    lerningOutcomeDeleted:
      "Learning outcome deleted",

    assignQuetion: "Question assigned",
    unAssignQuestion:
      "Question unassigned",

    ttbnotesSave: "TTB notes saved",

    questionCreated: "Question saved",

    stepMove: "Step/activity moved",
  },
  Training: {
    assign: "Are you sure you want to Assign this task?",
    dateassign: "Are you sure you want to assign this date to task?",
    Unassign: "Are you sure you want to remove selected task?",
    OnDeleteSuccessMsg: "Great Job! You have successfully deleted an employee",
    AssignSuccess: "Great Job! Module has been successfully assigned",
    UnAssignSuccess: "Great Job! Module has been successfully unassigned",
    AssignFailure: "Oops! Module is not assigned. Try Again!",
    UnAssignFailure: "Oops! Module is not unassigned. Try Again!",
    SelectUser: "Oops! Employee is not selected. Please select employee!",
    DateAssignSuccess:
      "Good Job! The last trained date has been successfully updated",
    DateAssignFailure: "Oops! Date is not updated. Try Again!",
    WrongDate: "Oops! You cannot select a date older than three years",
    SameEmp:
      "Oops! You cannot create a user with the same name as existing in the same location",
  },
  manageQuestion: {
    deleteQuestion: "Question deleted",
    questionCreated: "Question saved",
    questionUpdated: "Question saved",
  },
  manageRegion: {
    deanonymizeSuccess:
      "Site deanonymised for selected user",
    removedeanonymizeSuccess:
      "User unassigned",
    assignmanagerSuccess: "Site manager assigned",
    removeassignmanagerSuccess:
      "Site manager unassigned",
    questionUpdated: "Question updated",
    PUSaveSuccess: "PU saved",
    PUSaveFailure: "PU not saved. Try again",
    LOCSaveSuccess: "Location saved",
    LOCSaveFailure: "Location not saved. Try again",
    CTYSaveSuccess: "Country saved",
    CTYSaveFailure: "Country not saved. Try again",

    PUDeleteSuccess:
      "PU deleted",
    PUDeleteFailure: "PU not deleted. Try again",
    LOCDeleteSuccess: "Location deleted",
    LOCDeleteFailure: "Location not deleted. Try again",
    CTYDeleteSuccess: "Country deleted",
    CTYDeleteFailure: "Country not deleted. Try again",
    PUDeleteConfirm:
      "You are about to delete a Performance Unit (PU). This may have a business impact. Are you sure you want to proceed?",
    LOCDeleteConfirm:
      "You are about to delete a location. This may have a business impact. Are you sure you want to proceed?",
    CTYDeleteConfirm:
      "You are about to delete a country. This may have a business impact. Are you sure you want to proceed?",
    LOCReinstate:
      "This location was deleted earlier and archived. Do you wish to reinstate the deleted record?",
  },

  assignmouleToemp: {
    assignedSucees: "Employee assigned",
    unAssignedSucees: "Employee unassigned",
    errorinAssign: "Employee not assigned. Try again",
    errorinUnassign: "Employee not unassigned. Try again",
  },
};

// these are the navlink IDs (in Navbar) used in HeaderNew.js and useActiveNavLink files
export const navLinkItemsIDs = {
  home: "home",
  assessment: "assessment",
  reports: "report",
  manageData: "manageData",
  myTraining: "myTraining",
};
