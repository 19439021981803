import { createRoot } from 'react-dom/client';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { HashRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import "./i18n/config";
import "./index.css";
import MainSAML from "./Components/Shared/Layout/MainSAML";
import "./Content/stylesheet/typography.css";
import "./Content/stylesheet/common.css";
import "@bphxd/ds-core-react/lib/scss/bp-core.scss";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE);

//console.log = () => { };
// console.warn = () => {};
// console.info = () => {};
// console.error = () => {};

createRoot(document.getElementById("root")).render(
  <HashRouter>
    <CompatRouter>
      <MainSAML />
    </CompatRouter>
  </HashRouter>
);