import "../../Content/stylesheet/traineeassessment.css";
import imagePathCAS from "../../Content/Images/airbpImages/AssessmentCASImg1.png";
import imagePathTAR from "../../Content/Images/airbpImages/AssessmentTARImg1.png";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Card, CardImg, Badge } from 'reactstrap'
const OnlineAssessmentItem = (props) => {
  const { t } = useTranslation();
  const {
    assessmentType,
    taskNumber,
    taskName,
    assessmentTime,
    dueType
  } = props.assessmentData;

  // const TOTAL_TIME = {
  //   timeInMinutes: assessmentTime,
  //   timeInSeconds: 0,
  // };

  function getActiveFilterClassName(dueType) {

    switch (dueType) {
      case "Expired":
        return "danger";
      case "Overdue":
        return "warning";
      default:
        return "light";
    }
  }

  const translatedStatus = t(`MyTrainingpage.${dueType}`)

  return (
    <>
      <Card className="hover-shadow mb-4 assessment-card" >
        <Row className="">
          <Col xs="3" className="xs-hide">
            <CardImg
              height={100}
              className="object-fit-cover w-100 h-100"
              src={assessmentType === "CAS" ? imagePathCAS : imagePathTAR}

              alt={"CAS"}
              style={{ width: "100%", height: "100%" }}
            />
          </Col>
          <Col md="9" xs="12" >
            <Row>
              <Col xs="12">
                <div className="pe-4 pt-6 pb-0 ps-4 ">
                  <div className="txtcontent__header ">{taskNumber}</div>
                  <div className="txtcontent__body">{taskName}</div>
                  {/* <h5 className="lh-1-5 ls-0-3 mb-5">
          Turn on to electric &ndash; tailored charging solutions for local authorities
        </h5> */}

                  {/* <Col className="pt-0">
                    <span className="txtcontent__body">
                      {questionCount} questions
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="txtcontent__body">
                      {`${TOTAL_TIME.timeInMinutes}`} {t("MyTrainingpage.minutes")}
                      {TOTAL_TIME.timeInSeconds > 0 ? ` ${TOTAL_TIME.timeInSeconds} seconds` : ""}
                    </span>
                  </Col> */}

                  {/* </Row> */}
                  {/* </div> */}
                </div>
              </Col>
              <Col lg="9" md="8" xs="12" className="pt-3"  >
                <div className="pb-3 pt-3 ps-4">

                  {/* <Badge color="light" className="me-2 mt-3 badge-text" >
                    {assessmentType}
                  </Badge> */}
                  {/* <Badge color="light" className="me-2 mt-3 badge-text" >
                    {questionCount} questions
                  </Badge> */}

                  <Badge color={`${getActiveFilterClassName(translatedStatus)}`} className="me-2 mt-3 badge-text" >
                    {translatedStatus.toLowerCase()
                      .split(" ")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")
                    }
                  </Badge>

                  <Badge color="light" className="me-2 mt-3 badge-text" >
                    {assessmentTime} {t("MyTrainingpage.minutes")}
                  </Badge>
                </div>
              </Col >
              <Col lg="3" md="4" xs="12" className="mb-3 d-flex" >
                <div className="text-end pe-3 mt-auto ms-auto">
                  {/* <Button color="standard-primary" className="rounded-0">
                  Read about charging
                </Button> */}
                  <Button
                    className="rounded-pill btn-style"
                    color="standard-primary"
                    size="sm"
                    onClick={() => props.onLaunchAssessment(props.assessmentData)}
                  >
                    <span >{t("MyTrainingpage.Launch")}</span>

                  </Button>
                </div>
              </Col>
            </Row>

          </Col>
        </Row>




      </Card >


    </>
  );
};

export default OnlineAssessmentItem;
