import error from "../../Content/Icons/Red Alert.svg";

const ErrorToastNew = (props) => {
  return (
    <>
      {props.show && (
        <div className="toast-error-new">
          <img className="toast-img" src={error} alt="" />
          <span>{props.Message}</span>
        </div>
      )}
    </>
  );
};

export default ErrorToastNew;
