import { useEffect, useState, useRef, useCallback } from "react";
import base from "../../../Services/BaseService";
import { AgGridReact } from "ag-grid-react";
import { Row, Col } from "reactstrap";
import { Date24 } from "@bphxd/ds-core-react/lib/icons";
import SuccessToastNew from "../../Toast/SuccessToastNew";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import ConfirmCancel from "../../Toast/ConfirmCancel";
import CustomCheckboxFilter from "../../Shared/CustomCheckboxFilter";
import "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "../../../Content/stylesheet/ag-grid-style.css";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { useAssessmentContext } from "../../Shared/assessmentContext";
import { Label, Modal, Button, Input, Spinner } from '@bphxd/ds-core-react';

const AssignEmployees = (props) => {
  var dictselectedEmployees = {};
  var dictdelectedEmployees = {}; //API
  var dictselectedEmployeesToSendAPI = {};
  const [selectedEmployeesAPI, setselectedEmployeesAPI] = useState({});
  const [deSelectedEmployees, setDeSelectedEmployees] = useState({});
  const { t } = useTranslation();
  const [gridColDefs, setGridColDefs] = useState([]);
  const [gridData, setGridData] = useState();
  const [showToast, setShowToast] = useState({ Name: "", show: false });
  const [gridDefaultColDef, setGridDefaultColDef] = useState();
  const [showToastSuccess, setshowToastSuccess] = useState({
    Name: "",
    show: false,
    Message: "",
  });
  const [isChanged, setIsChanged] = useState(false);
  const [isLoadingSteps, setisLoadingSteps] = useState(false);
  const gridRef = useRef();
  const { updateAssessmentCount } = useAssessmentContext();

  useEffect(() => {
    initGridVar();
    getEmployees(true);
    return () => {
      removeAllFlatPicketInstances();
    };
    // eslint-disable-next-line
  }, []);

  function setassignemployee() {
    for (let i = 0; i < dictselectedEmployees.length; i++) {
      if (document.getElementById(dictselectedEmployees[i]) !== null) {
        document.getElementById(dictselectedEmployees[i]).checked = true;
      }
    }
  }

  const handleChkBx = (params) => {
    setIsChanged(true);
    if (
      document.getElementById("chk" + params.employeeID) != null &&
      document.getElementById("chk" + params.employeeID) !== undefined
    ) {
      var checked = document.getElementById("chk" + params.employeeID).checked;
      if (checked) {
        document.getElementById("chk" + params.employeeID).checked = true;
        if (
          params.pStart !== null &&
          params.pStart !== undefined &&
          params.pStart !== "null" &&
          params.pStart !== ""
        ) {
          getEmployees();
        } else {
          document.getElementById("date" + params.employeeID).style.display = "inline";
          document.getElementById("dateicon" + params.employeeID).style.display = "inline";
          document.getElementById("taskDate" + params.employeeID).style.display = "inline";
          document.getElementById("date" + params.employeeID).style.width = "60%";
        }
        dictselectedEmployees[params.employeeID] = "";
        dictselectedEmployeesToSendAPI[params.employeeID] = "";
        setselectedEmployeesAPI(dictselectedEmployeesToSendAPI);
        removeDataDesected(params.employeeID);
      } else if (!checked) {
        document.getElementById("chk" + params.employeeID).checked = false;
        document.getElementById("date" + params.employeeID).style.display =
          "none";
        removeData(params.employeeID);
        dictdelectedEmployees[params.employeeID] = "false";
        setDeSelectedEmployees(dictdelectedEmployees);
      }
    }
  };

  const handleFlatpickrReady = (_, __, fp) => {
    fp.calendarContainer.classList.add("assignemployee-flatpickr");
  };

  function removeDataDesected(employeeID) {
    if (dictdelectedEmployees.hasOwnProperty(employeeID)) {
      delete dictdelectedEmployees[employeeID];
    }
    setDeSelectedEmployees(dictdelectedEmployees);
  }

  function removeData(employeeID) {
    if (dictselectedEmployees.hasOwnProperty(employeeID)) {
      delete dictselectedEmployees[employeeID];
    }

    if (dictselectedEmployeesToSendAPI.hasOwnProperty(employeeID)) {
      delete dictselectedEmployeesToSendAPI[employeeID];
    }
    setselectedEmployeesAPI(dictselectedEmployeesToSendAPI);
  }

  const removeAllFlatPicketInstances = () => {
    var allData = document.getElementsByClassName("flatpickr-calendar");
    let i = 0;
    while (allData.length > 0) {
      if (allData[i] === undefined) {
        i = 0;
      }
      allData[i].remove();
      i++;
    }
  };

  function DataSavingToastonClick() {
    setshowToastSuccess({ Name: "", show: false });
  }

  const handleDiscard = () => {
    props.close(false);
  };

  // modify pageSize
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size-emp").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  // modify pageNumber on Filter Change
  function onFilterChanged(params) {
    const currPage = params.api.paginationGetCurrentPage();
    // CurrentPage is based on 0 index (0,1,2...)
    if (currPage > 0) params.api.paginationGoToFirstPage();
  }

  // modify Column Menu position
  const postProcessPopup = useCallback((params) => {
    getEmployees();
    // check callback is for menu
    if (params.type !== "columnMenu") {
      return;
    }
    const ePopup = params.ePopup;
    let oldTopStr = ePopup.style.top;
    // remove 'px' from the string (AG Grid uses px positioning)
    oldTopStr = oldTopStr.substring(0, oldTopStr.indexOf("px"));
    const oldTop = parseInt(oldTopStr);
    const newTop = oldTop + 30;
    ePopup.style.top = newTop + "px";
    // eslint-disable-next-line
  }, []);

  const initGridVar = () => {
    setGridDefaultColDef({
      flex: 1,
      sortable: true,
      filter: true,
      resizable: true,
      editable: false,
      wrapText: true,
      autoHeight: true,
      sortingOrder: ["asc", "desc"],
      filterParams: {
        buttons: ["clear"],
        newRowsAction: "keep",
      },
      menuTabs: ["filterMenuTab"],
    });

    setGridColDefs([
      {
        headerName: "",
        field: "",
        resizable: false,
        suppressMovable: true,
        sortable: false,
        filter: CustomCheckboxFilter,
        minWidth: 70,
        maxWidth: 70,
        pinned: true,
        cellRenderer: CheckboxCellRenderer,
        cellClass: 'right-border',
        headerClass: 'right-border',

      },
      {
        headerName: t("AssignEmppage.AssignEmployeeHeader.EMPLOYEE_NAME"),
        headerTooltip: t("AssignEmppage.AssignEmployeeHeader.EMPLOYEE_NAME"),
        field: "emp_DisplayName",
        resizable: false,
        suppressMovable: true,
        sort: "asc",
        minWidth: 350,
        maxWidth: 550,
      },
      {
        headerName: t("AssignEmppage.AssignEmployeeHeader.JOB_TITLE"),
        headerTooltip: t("AssignEmppage.AssignEmployeeHeader.JOB_TITLE"),
        field: "jobTitle",
        resizable: false,
        suppressMovable: true,
        minWidth: 0,
      },
      {
        headerName: t("AssignEmppage.AssignEmployeeHeader.LOCATION"),
        headerTooltip: t("AssignEmppage.AssignEmployeeHeader.LOCATION"),
        field: "location_Description",
        resizable: false,
        minWidth: 0,
        suppressMovable: true,
      },
      {
        headerName: t("AssignEmppage.AssignEmployeeHeader.LAST_TRAINED_DATE"),
        headerTooltip: t(
          "AssignEmppage.AssignEmployeeHeader.LAST_TRAINED_DATE"
        ),
        field: "",
        resizable: false,
        suppressMovable: true,
        filter: false,
        minWidth: 150,
        sortable: false,
        suppressMenu: true,
        cellRenderer: TrngDateCellRenderer,
      },
    ]);
  };

  class TrngDateCellRenderer {
    init(params) {
      var divElement = document.createElement("div");
      divElement.classList.add("gridCrudIcons");
      this.eGui = divElement;
      setassignemployee();
      const root = createRoot(divElement);
      root.render(
        <>
          <label
            id={"lbl" + params.node.data.employeeID}
            style={
              params.node.data.isAssigned === "true" ||
                params.node.data.isAssigned === true
                ?
                { display: "inline" }
                : { display: "none" }
            }
          >
            {params.node.data.pStart}
          </label>
          <div
            id={"date" + params.node.data.employeeID}
            style={
              (params.node.data.isAssigned === "true" ||
                params.node.data.isAssigned === true) &&
                (params.node.data.pStart === null ||
                  params.node.data.pStart === "null")
                ?
                {
                  display: "inline",
                  width: "60%",
                }
                : { display: "none" }
            }
          >
            <div
              className=""
              style={{
                width: "500px",
              }}
            >
              <Date24 id={"dateicon" + params.node.data.employeeID} />

              <Flatpickr
                id={"taskDate" + params.node.data.employeeID}
                name="taskDate"
                options={{
                  dateFormat: "d M Y",
                  maxDate: "today",
                  onReady: handleFlatpickrReady,

                }}
                onChange={() => saveDateNew(params.node.data)}
                style={{
                  left: "30.8% !important",
                  marginLeft: "-24px",
                  paddingLeft: "30px",
                  border: "0px"
                }}
                onOpen={() => {
                  if (
                    document.getElementsByClassName("flatpickr-calendar") !==
                    undefined
                  ) {
                    var allNodes =
                      document.getElementsByClassName("flatpickr-calendar");
                    for (let i = 0; i < allNodes.length; i++) {
                      allNodes[i].classList.add("assignEmpDateClass");
                    }
                  }
                }}
              ></Flatpickr>
            </div>
          </div>
        </>
      );
    }

    getGui() {
      return this.eGui;
    }
  }

  const saveDateNew = (params) => {
    setIsChanged(true);
    document.getElementById("chk" + params.employeeID).checked = true;
    var sdStatus = false;
    var selecteddate =
      moment(
        document.getElementById("taskDate" + params.employeeID).value
      ).format("YYYY-MM-DD") + "T00:00:00";
    var threeYrsDate = new Date();
    var threeOldYrs = threeYrsDate.getFullYear() - 3;
    var presentMonth = new Date().getMonth(); //2019
    var presentDate = new Date().getDate();

    var selectedYr = new Date(
      document.getElementById("taskDate" + params.employeeID).value
    ).getFullYear(); //2015
    var selectedMonth = new Date(
      document.getElementById("taskDate" + params.employeeID).value
    ).getMonth();
    var selectedDt = new Date(
      document.getElementById("taskDate" + params.employeeID).value
    ).getDate();

    if (threeOldYrs > selectedYr) {
      sdStatus = true;
    } else if (threeOldYrs === selectedYr) {
      if (selectedMonth < presentMonth) {
        sdStatus = true;
      } else if (selectedMonth === presentMonth) {
        if (presentDate > selectedDt) {
          sdStatus = true;
        } else {
          sdStatus = false;
        }
      } else if (selectedMonth > presentMonth) {
        sdStatus = false;
      }
    } else if (threeOldYrs < selectedYr) {
      sdStatus = false;
    }

    if (!sdStatus) {
      var empID = params.employeeID;
      dictselectedEmployees[empID] = selecteddate;
      dictselectedEmployeesToSendAPI[empID] = selecteddate;
      setselectedEmployeesAPI(dictselectedEmployeesToSendAPI);
    } else if (sdStatus) {
      setshowToastSuccess({ Name: "wrongDate", show: true });
      document.addEventListener("mousedown", DataSavingToastonClick);
    }
  };

  const saveData = () => {
    props.reload();
    var deselectedEmp = (deSelectedEmployees);
    var seselectedEmpAPI = (selectedEmployeesAPI);
    var taskID = props.empModel.taskID;
    var url = "EmployeeTask/assignEmployees/" + taskID;
    let allData = Object.assign({}, seselectedEmpAPI, deselectedEmp);
    if (Object.keys(allData).length > 0) {
      try {

        setTimeout(() => {
          base.add(url, allData).then((response) => {
            if (
              props.isFromAssessment !== undefined &&
              props.isFromAssessment === true
            ) {
              assignEmptoAssessment(allData, seselectedEmpAPI);
            } else props.reloadDone();
          });
        }, 300);
      } catch {
        props.reloadDone();
      }
    } else {
      props.reloadDone();
    }
  };

  const CheckboxCellRenderer = (params) => {
    useEffect(() => {
      if (dictselectedEmployees.hasOwnProperty(params.node.data.employeeID)) {
        document.getElementById(
          "chk" + params.node.data.employeeID
        ).checked = true;
      }
      // eslint-disable-next-line
    }, []);
    return (
      <Input
        type="checkbox"
        id={"chk" + params.node.data.employeeID}
        onChange={() => handleChkBx(params.node.data)}
      />
    );
  };

  const assignEmptoAssessment = (allData, newSelectedEmp) => {
    const KeysEmpIDs = Object.keys(newSelectedEmp);

    base
      .add("assessmentemployee/update/" + props.assID, allData)
      .then(() => {
        updateAssessmentCount()
        if (
          (props.assessment_Type === "TAR" && props.isAllMCQ) ||
          props.assessment_Type === "CAS"
        ) {
          if (KeysEmpIDs.length > 0) {
            sendEmailforAssigningAssessment(KeysEmpIDs);
          } else props.reloadDone();
        } else props.reloadDone();
      })
      .catch((err) => {
        props.reloadDone();
      });
  };

  function sendEmailforAssigningAssessment(empIDs) {
    var tskNum = encodeURIComponent(props.empModel.task_Number);

    setTimeout(() => {
      for (let i = 0; i < empIDs.length; i++) {
        base
          .get(
            "Email/SendEmailPart1",
            `${empIDs[i]}/${props.assessment_Type}/${tskNum}/${true}/${props.empModel.taskID
            }/${props.assID}`
          )
          .then((res) => {
            props.reloadDone();
          })
          .catch((err) => {
            props.reloadDone();
          });

        if (i === empIDs.length - 1) {
          props.reloadDone();
        }
      }
    }, 300);
  }

  const getEmployees = (isFirst) => {
    var puId = props.empModel.fk_PUID !== "" ? props.empModel.fk_PUID : "NULL";
    var countryId =
      props.empModel.fk_CountryID !== "" ? props.empModel.fk_CountryID : "NULL";
    var locationId =
      props.empModel.fk_LocationID !== ""
        ? props.empModel.fk_LocationID
        : "NULL";
    var tskID = props.empModel.taskID !== "" ? props.empModel.taskID : "NULL";

    setTimeout(() => {
      base
        .getAll(
          "employee/qry1/" +
          puId +
          "/" +
          countryId +
          "/" +
          locationId +
          "/" +
          tskID
        )
        .then((res) => {
          if (res !== undefined) {
            var data = res;

            for (let i = 0; i < data.length; i++) {
              if (data[i].pStart !== null) {
                var dateFromAPIwithoutTime = data[i].pStart
                  .toString()
                  .split("T");
                //2021-11-16
                var dateParts = dateFromAPIwithoutTime[0]
                  .toString()
                  .split("-");
                var date = dateParts[2].toString();
                var mm = "";
                if (dateParts[1] === "01") {
                  mm = "Jan";
                } else if (dateParts[1] === "02") {
                  mm = "Feb";
                } else if (dateParts[1] === "03") {
                  mm = "Mar";
                } else if (dateParts[1] === "04") {
                  mm = "Apr";
                } else if (dateParts[1] === "05") {
                  mm = "May";
                } else if (dateParts[1] === "06") {
                  mm = "Jun";
                } else if (dateParts[1] === "07") {
                  mm = "Jul";
                } else if (dateParts[1] === "08") {
                  mm = "Aug";
                } else if (dateParts[1] === "09") {
                  mm = "Sep";
                } else if (dateParts[1] === "10") {
                  mm = "Oct";
                } else if (dateParts[1] === "11") {
                  mm = "Nov";
                } else if (dateParts[1] === "12") {
                  mm = "Dec";
                }
                var yyyy = dateParts[0].toString();
                data[i].pStart = date + " " + mm + " " + yyyy;
              }
            }

            for (let i = 0; i < data.length; i++) {
              if (isFirst) {
                if (data[i].isAssigned === "true") {
                  dictselectedEmployees[data[i].employeeID] =
                    data[i].pStart;
                }
              } else {
                if (
                  dictselectedEmployees.hasOwnProperty(data[i].employeeID)
                ) {
                  data[i].isAssigned = "true";
                  if (
                    dictselectedEmployees[data[i].employeeID] !== "" &&
                    dictselectedEmployees[data[i].employeeID] != null
                  ) {
                    dateFromAPIwithoutTime = dictselectedEmployees[
                      data[i].employeeID
                    ]
                      .toString()
                      .split("T");
                    //2021-11-16
                    if (dateFromAPIwithoutTime.length > 1) {
                      dateParts = dateFromAPIwithoutTime[0]
                        .toString()
                        .split("-");
                      if (
                        dateParts[2] !== "" &&
                        dateParts[2] !== undefined
                      ) {
                        date = dateParts[2].toString();
                        mm = "";
                        if (dateParts[1] === "01") {
                          mm = "Jan";
                        } else if (dateParts[1] === "02") {
                          mm = "Feb";
                        } else if (dateParts[1] === "03") {
                          mm = "Mar";
                        } else if (dateParts[1] === "04") {
                          mm = "Apr";
                        } else if (dateParts[1] === "05") {
                          mm = "May";
                        } else if (dateParts[1] === "06") {
                          mm = "Jun";
                        } else if (dateParts[1] === "07") {
                          mm = "Jul";
                        } else if (dateParts[1] === "08") {
                          mm = "Aug";
                        } else if (dateParts[1] === "09") {
                          mm = "Sep";
                        } else if (dateParts[1] === "10") {
                          mm = "Oct";
                        } else if (dateParts[1] === "11") {
                          mm = "Nov";
                        } else if (dateParts[1] === "12") {
                          mm = "Dec";
                        }
                        yyyy = dateParts[0].toString();
                        data[i].pStart = date + " " + mm + " " + yyyy;
                      }
                    }
                  }
                } else {
                  data[i].isAssigned = "false";
                }
              }
            }
            setGridData(data);
            // setselectedEmployees(dictselectedEmployees);
            setisLoadingSteps(false);
            //assignedEmployees();
          }
        });
    }, 300);
  };

  // const onGridReady = (params) => {
  //   setGridApi(params.api);
  // };

  const cancelHandler = () => {
    if (!isChanged) props.close(false);
    else setShowToast({ Name: "cancel", show: true });
  };

  const saveHandler = () => {
    if (!isChanged) {
      props.close(false);
    } else {
      props.close(false);
      saveData();
    }
  };

  const handleCancelDelete = () => setShowToast({ Name: "", show: false });

  return (
    <div>
      <Modal
        isOpen={props.isopen}

        className="modal-dialog-centered modal-90"

      >
        {showToast.Name === "cancel" && (
          <ConfirmCancel
            className={"showPopup"}
            show={showToast.show}
            close={handleCancelDelete}
            handleYes={handleDiscard}
          />
        )}
        <LoadingOverlay
          active={isLoadingSteps}
          spinner={<Spinner style={{ color: "#111" }} />}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(0, 0, 0, 0.15)",
            }),

          }}
        >
          <Modal.Header
            className="comm-header-bgPosition"
            onToggleOpen={cancelHandler}>
            <Label className="modalHeaderGlobal m-4">
              {t("AssignEmppage.Assign_Employees")}
            </Label>
            {showToastSuccess.Name === "successAssign" && (
              <SuccessToastNew show={showToastSuccess.show} Message={t("AssignModulepage.assignedSucees")} form={true}
              />
            )}
          </Modal.Header>

          <Modal.Body className="ps-8 pe-8">
            <Row >
              <Col xs="6">
                <Label>{t("Common.Module")}</Label>
                <Input
                  id="emp_name"
                  name="emp_name"
                  className=" w-100"

                  disabled={true}
                  value={
                    props.empModel.task_Number +
                    " - " +
                    props.empModel.task_Name
                  }
                ></Input>
              </Col>
              <Col xs="6">
                <Label>
                  {t("AssignEmppage.Version")}
                </Label>
                <Input
                  id="emp_name"
                  name="emp_name"
                  className=" w-100"
                  disabled={true}
                  value={
                    // props.locationID == "" &&
                    props.empModel.task_Site
                    //  == "global"
                    //   ? "GLOBAL"
                    //   : props.locationID
                  }
                ></Input>
              </Col>
            </Row>
            {props.isFromAssessment ? (
              <div className="row">
                <span className="pb-2">
                  {t("AssignEmppage.AutoAssignEmployee")}
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="pt-1">
              <div className="gridDiv">

                <div
                  className="ag-theme-material gridHtWd"
                  style={{ position: 'relative' }}
                >
                  <div
                    className="rowDataInput"

                  >
                    {gridData ? (
                      <Input type="select"
                        onChange={onPageSizeChanged}
                        id="page-size-emp"
                        defaultValue={10}

                      // className="ag-select-pagesize-manageassessment"
                      >
                        <option value="10" >
                          10
                        </option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                      </Input>
                    ) : (
                      ""
                    )}

                  </div>
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={gridColDefs}
                    rowData={gridData}
                    suppressMenuHide={true}
                    // overlayLoadingTemplate={"testing..."}
                    overlayNoRowsTemplate="No records found"
                    defaultColDef={gridDefaultColDef}
                    // onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                    suppressScrollOnNewData={true}
                    suppressHorizontalScroll={false}
                    enableRangeSelection={true}
                    onFilterChanged={onFilterChanged}
                    postProcessPopup={postProcessPopup}
                  // getRowHeight={getRowHeight}
                  ></AgGridReact>

                </div>
              </div>

            </div>


          </Modal.Body>
          <Modal.Footer style={{ paddingRight: "2rem" }}>
            <Button color="standard-secondary" onClick={cancelHandler}
              className="rounded-pill btn-style" size="sm"
            >
              {t("Common.Cancel")}
            </Button>{" "}
            <Button
              color="standard-primary"
              className="rounded-pill ms-2 btn-style"
              // style={{ backgroundColor: "#007f00" }}
              disabled={!isChanged}
              size="sm"
              onClick={saveHandler}
            >
              {t("Common.Save")}
            </Button>{" "}
          </Modal.Footer>
        </LoadingOverlay>
      </Modal>
    </div>
  );
};
export default AssignEmployees;
